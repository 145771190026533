<template>
<svg data-svg-lvl-2="cover-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
<PreviewLayersCoverBlockFlyleaffrontR :params="params" v-if="!params.isFlap7" />
<PreviewLayersCoverBlockFlyleaffrontR_F7 :params="params" v-if="params.isFlap7" />
<PreviewLayersCoverBlockEdgeR :params="params" v-if="!params.isFlap7" />
<PreviewLayersCoverBlockEdgeR_F7 :params="params" v-if="params.isFlap7" />
<component :params="params" :is="`PreviewLayersCoverBlockCaptal${params.captalComponent}`" v-if="params.captalComponent" />
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>