import { render, staticRenderFns } from "./M25.vue?vue&type=template&id=2f52bf26&scoped=true"
import script from "./M25.vue?vue&type=script&lang=js"
export * from "./M25.vue?vue&type=script&lang=js"
import style0 from "./M25.vue?vue&type=style&index=0&id=2f52bf26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f52bf26",
  null
  
)

export default component.exports