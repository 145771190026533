<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Задник_Контур"><path class="bc-c-3" d="M257,151.34H73.46a12,12,0,0,0-12,12v114.9a12,12,0,0,0,12,12H257Z"/><path class="bc-c-3" d="M440.54,151.34H257v138.9H440.54a12,12,0,0,0,12-12V163.34A12,12,0,0,0,440.54,151.34Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:#727272;stroke:#232728;stroke-linejoin:round;stroke-width:0.75px;}</style>

