<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="tc-rg" cx="47.39" cy="130.61" r="438.04" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.2"/><stop offset="0.65" stop-color="#e5e5e5" stop-opacity="0"/></radialGradient><linearGradient id="tc-lg" x1="-2203.91" y1="-5801.94" x2="-2085.59" y2="-5801.94" gradientTransform="translate(2401.75 5990.43)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.25"/><stop offset="0.13" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="tc-lg-2" x1="46.38" y1="206.99" x2="467.61" y2="206.99" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.4"/><stop offset="0.01" stop-color="#fff" stop-opacity="0"/></linearGradient><clipPath id="tc-cp"><path class="tc-c-1" d="M467.62,269.65V129.33H46.38V269.65a15,15,0,0,0,15,15H452.62A15,15,0,0,0,467.62,269.65Z"/></clipPath></defs><g id="Крышки_Контур"><path class="tc-c-4" d="M467.61,269.65l0-140.32H46.38V269.65a15,15,0,0,0,15,15H452.61A15,15,0,0,0,467.61,269.65Z"/><rect class="tc-c-5" x="197.84" y="-22.12" width="118.33" height="421.23" transform="translate(445.49 -68.51) rotate(90)"/><path class="tc-c-6" d="M467.61,269.65l0-140.32H46.38V269.65a15,15,0,0,0,15,15H452.61A15,15,0,0,0,467.61,269.65Z"/><path class="tc-c-7" d="M464.57,268.59V129.33H49.43V268.59a13,13,0,0,0,13,13H451.57A13,13,0,0,0,464.57,268.59Z"/><g class="tc-c-8"><rect class="tc-c-9" x="46.36" y="67.43" width="421.29" height="278.56"/><image class="tc-c-10" width="514" height="422" transform="translate(496.69 414) rotate(180) scale(0.93 0.98)" :href="`${$CONST.cnstrAssetsUrl}/others/93BB-Ice-print.webp`"/></g><path class="tc-c-1" d="M467.62,269.65V129.33H46.38V269.65a15,15,0,0,0,15,15H452.62A15,15,0,0,0,467.62,269.65Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1,.tc-c-7{fill:none;}.tc-c-1,.tc-c-9{stroke:#252525;}.tc-c-1{stroke-miterlimit:10;}.tc-c-2{opacity:0.3;}.tc-c-3{fill:#64ff00;}.tc-c-4{fill:url(#tc-rg);}.tc-c-5{fill:url(#tc-lg);}.tc-c-6{fill:url(#tc-lg-2);}.tc-c-7{stroke:#232728;stroke-width:0.46px;}.tc-c-7,.tc-c-9{stroke-miterlimit:2;}.tc-c-8{clip-path:url(#tc-cp);}.tc-c-9{fill:#b3b3b3;stroke-width:1.14px;opacity:0.2;}.tc-c-10{opacity:0.5;}</style>

