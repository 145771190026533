<template>
<svg data-svg-lvl-3="spread-block-spread" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="ls-cp"><polygon class="ls-c-1" points="67.57 72.08 67.57 203.7 67.57 203.7 67.57 335.32 446.32 335.32 446.32 203.7 446.32 203.7 446.32 72.08 67.57 72.08"/></clipPath></defs><g id="Разворот"><g class="ls-c-4"> <polygon :style="{ fill: params.slice.color }"  class="ls-c-1" points="67.57 72.08 67.57 203.7 67.57 203.7 67.57 335.32 446.32 335.32 446.32 203.7 446.32 203.7 446.32 72.08 67.57 72.08"/> <image width="1200" height="840" transform="translate(66.25 70.21) scale(0.32)" :href="params.blockImage"  v-show="params.blockImage" preserveAspectRatio="xMidYMid slice" /></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.ls-c-1{fill:none;}.ls-c-2{opacity:0.3;}.ls-c-3{fill:#64ff00;}.ls-c-4{clip-path:url(#ls-cp);}</style>

