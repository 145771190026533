import { getPersonalizations } from '/src/utils/api/getPersonalizations'

export default {
  namespaced: true,

  state() {
    return {
      request: {
        locale: '',
        product: 0,
        classId: 0,
        model: 0,
        block: 0,
        tab: 0,
        stitching: 0,
        materials: [0, 0, 0, 0],
      },
      personalizations: [],
    }
  },

  mutations: {
    SET_REQUEST(state, payload) {
      state.request = payload
    },
    CLEAR_PERSONALIZATIONS(state) {
      state.personalizations = []
    },
    SET_PERSONALIZATIONS(state, payload) {
      state.personalizations = payload
    },
  },

  actions: {
    async GET_ITEMS(
      { commit, state },
      { api, CONST, locale, product, classId, model, block, tab, stitching, materials },
    ) {
      // model, block,   materials
      if (
        locale !== state.request.locale ||
        product !== state.request.product ||
        classId !== state.request.classId ||
        model !== state.request.model ||
        block !== state.request.block ||
        tab !== state.request.tab ||
        stitching !== state.request.stitching ||
        materials[0] !== state.request.materials[0] ||
        materials[1] !== state.request.materials[1] ||
        materials[2] !== state.request.materials[2] ||
        materials[3] !== state.request.materials[3]
      ) {
        if (state.request.locale !== '') commit('CLEAR_PERSONALIZATIONS')
        const data = await getPersonalizations(api, {
          model,
          block,
          stitching,
          materials,
          CONST,
        })
        commit('SET_REQUEST', {
          locale,
          product,
          classId,
          model,
          block,
          tab,
          stitching,
          materials,
        })

        commit('SET_PERSONALIZATIONS', data)
      }
    },
  },

  getters: {
    PERSONALIZATIONS: (state) => state.personalizations,
  },
}
