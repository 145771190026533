<template>
<svg data-svg-lvl-3="flyleafend-block-pocket" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lp-lg" x1="357.88" y1="318.81" x2="445.42" y2="318.81" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#333"/><stop offset="1" stop-color="#b2b2b2" stop-opacity="0.5"/></linearGradient><linearGradient id="lp-lg-2" x1="401.64" y1="301.58" x2="401.64" y2="347.26" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.05"/><stop offset="1" stop-opacity="0.3"/></linearGradient><clipPath id="lp-cp"><path class="lp-c-1" d="M357.88,289.63h87.55a0,0,0,0,1,0,0v54.75A3.62,3.62,0,0,1,441.8,348H361.49a3.62,3.62,0,0,1-3.62-3.62V289.63A0,0,0,0,1,357.88,289.63Z"/></clipPath></defs><g id="КонвертКонтур_Пластиковый_Карман" ><g class="lp-c-4"><path class="lp-c-5" d="M357.88,289.63h87.55a0,0,0,0,1,0,0v54.75A3.62,3.62,0,0,1,441.8,348H361.49a3.62,3.62,0,0,1-3.62-3.62V289.63A0,0,0,0,1,357.88,289.63Z"/><path class="lp-c-6" d="M357.88,301h87.55a0,0,0,0,1,0,0v43.36A3.62,3.62,0,0,1,441.8,348H361.49a3.62,3.62,0,0,1-3.62-3.62V301A0,0,0,0,1,357.88,301Z"/><path class="lp-c-7" d="M441.75,347.57H361.52a3.19,3.19,0,0,1-3.19-3.19V301h.58v43.37a2.61,2.61,0,0,0,2.61,2.61h80.23a2.61,2.61,0,0,0,2.61-2.61V301h.58v43.37A3.19,3.19,0,0,1,441.75,347.57Z"/></g><g class="lp-c-4"><g class="lp-c-8"><g class="lp-c-9"><image width="276" height="396" transform="translate(342.02 215.19) scale(0.43 0.43)" :href="`${$CONST.cnstrAssetsUrl}/others/pocket.webp`"/></g></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lp-c-1{fill:none;}.lp-c-2{opacity:0.3;}.lp-c-3{fill:#64ff00;}.lp-c-4{opacity:0.8;}.lp-c-5,.lp-c-9{opacity:0.5;}.lp-c-5{fill:url(#lp-lg);}.lp-c-6{fill:#828282;opacity:0.4;}.lp-c-7{fill:url(#lp-lg-2);}.lp-c-8{clip-path:url(#lp-cp);}</style>

