export async function getOrderByDog(api, { dog101, dog100 }) {
  const response = await api('constructor-new/get-order-by-dog', {
    dog100,
  })
  const data = modifyItems(response.result, dog101, dog100)

  return data
}

function modifyItems(order, dog101, dog100) {
  if (order?.query) {
    order.query.dog101 = dog101
    order.query.dog100 = dog100
  } else {
    throw new TypeError(`getOrderByDog: Ожидается непустой response.result.query`)
  }
  return order
}
