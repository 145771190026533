<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bc-lg" x1="61.46" y1="225.16" x2="452.54" y2="225.16" gradientUnits="userSpaceOnUse"><stop offset="0.47" stop-color="#727272"/><stop offset="0.48" stop-color="#545454"/><stop offset="0.48" stop-color="#525252"/><stop offset="0.49" stop-color="#484848"/><stop offset="0.5" stop-color="#454545"/><stop offset="0.51" stop-color="#494949"/><stop offset="0.52" stop-color="#545454"/><stop offset="0.53" stop-color="#727272"/></linearGradient></defs><g id="Задник_Контур"><path class="bc-c-3" d="M264,363.82H444.75a3,3,0,0,0,2.15-.89l5.42-5.43a.74.74,0,0,0-.52-1.26H271.56a6.19,6.19,0,0,1-6.17-6.17V97.71H264a6.17,6.17,0,0,0-6.17,6.17V357.65A6.19,6.19,0,0,0,264,363.82Z"/><path class="bc-c-4" d="M267.4,91.88H443.6a6.17,6.17,0,0,1,6.17,6.17v250a6.17,6.17,0,0,1-6.17,6.17H267.4a0,0,0,0,1,0,0V91.88A0,0,0,0,1,267.4,91.88Z" transform="translate(717.17 446.12) rotate(-180)"/><path class="bc-c-5" d="M260.05,355.41a6.17,6.17,0,0,0,6.17,6.17H444.61a1.45,1.45,0,0,0,1-.41l6.36-6.35a.34.34,0,0,0-.24-.58H273.57a6.17,6.17,0,0,1-6.17-6.17v-250a6.16,6.16,0,0,1,1.79-4.34h0L262,100.93h0a6.14,6.14,0,0,0-1.93,4.48Z"/><path class="bc-c-3" d="M265.39,347.74a8.51,8.51,0,0,0,8.5,8.5h177.9a.74.74,0,0,1,.74.85h0v-264a3,3,0,0,0-3-3H273.89a8.52,8.52,0,0,0-8.5,8.5Zm0-31.4a1.21,1.21,0,0,1,.05.18,1,1,0,0,0-.05-.18Z"/><path class="bc-c-6" d="M452.54,217.65V102.13a12,12,0,0,0-12-12h-174A3.16,3.16,0,0,0,264,91.42a6,6,0,0,1-4.82,2.44h-4.94a6,6,0,0,1-4.82-2.44,3.16,3.16,0,0,0-2.54-1.29H73.46a12,12,0,0,0-12,12V217.65a3.15,3.15,0,0,0,1.66,2.68,3.31,3.31,0,0,1,0,5.71,3.14,3.14,0,0,0-1.66,2.68V344.24a12,12,0,0,0,12,12H247.34a2.17,2.17,0,0,1,1.82,1.07,6,6,0,0,0,5.11,2.88h4.94a6,6,0,0,0,5.13-2.9,2.1,2.1,0,0,1,1.78-1.05H440.54a12,12,0,0,0,12-12V228.72a3.12,3.12,0,0,0-1.66-2.68,3.31,3.31,0,0,1,0-5.71A3.15,3.15,0,0,0,452.54,217.65Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:#727272;}.bc-c-3,.bc-c-4,.bc-c-5,.bc-c-6{stroke:#232728;}.bc-c-3,.bc-c-6{stroke-linejoin:round;}.bc-c-3,.bc-c-5,.bc-c-6{stroke-width:0.75px;}.bc-c-4{fill:#fff;stroke-width:0.46px;}.bc-c-4,.bc-c-5{stroke-miterlimit:2;}.bc-c-5{fill:#bebebe;}.bc-c-6{fill:url(#bc-lg);}</style>

