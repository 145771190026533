<template>
<svg data-svg-lvl-2="flyleafend-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
<PreviewLayersFlyleafendBlockShadowRHm :params="params" />
<PreviewLayersFlyleafendBlockFlyleafendRHm :params="params" />
<PreviewLayersFlyleafendBlockContourRHm :params="params" />
<PreviewLayersFlyleafendBlockEnvelopeR :params="params" v-if="params.envelopeColor" />
<PreviewLayersFlyleafendBlockPocketRm :params="params" v-if="params.flyleafendPocket" />
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>