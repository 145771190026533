<template>
<svg data-svg-lvl-3="cover-top-plastic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="tpl-m-p" x="0" y="0" width="500" height="430" fill="#ffffff" ><path   d="M216,226.61A174.19,174.19,0,0,0,390.14,400.8V52.42A174.19,174.19,0,0,0,216,226.61Z"/></mask><clipPath id="tpl-cp"><path class="tpl-c-1" d="M390.14,400.8a174.19,174.19,0,0,1,0-348.38Z"/></clipPath><linearGradient id="tpl-lg" x1="389.17" y1="53.51" x2="270.67" y2="391" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#b2b2b2" stop-opacity="0.1"/><stop offset="0.5" stop-color="#b2b2b2" stop-opacity="0.11"/><stop offset="0.69" stop-color="#b2b2b2" stop-opacity="0.15"/><stop offset="0.82" stop-color="#b2b2b2" stop-opacity="0.22"/><stop offset="0.93" stop-color="#b2b2b2" stop-opacity="0.31"/><stop offset="1" stop-color="#b2b2b2" stop-opacity="0.4"/></linearGradient><radialGradient id="tpl-rg" cx="390.25" cy="226.25" r="174.25" gradientUnits="userSpaceOnUse"><stop offset="0.98" stop-opacity="0"/><stop offset="1"/></radialGradient></defs><g id="Комбинация_Картинка"><path :style="{ fill: params.flyleaffrontColor }"  class="tpl-c-4" d="M216,226.61A174.19,174.19,0,0,0,390.14,400.8V52.42A174.19,174.19,0,0,0,216,226.61Z"/> <image v-if="params.flyleaffrontImage" width="500" height="430" :href="params.flyleaffrontImage" :mask="`url(#tpl-m-p)`" /> </g><g id="Комбинация_Пластик"><g class="tpl-c-5"><image width="514" height="422" transform="translate(0 4)" :href="`${$CONST.cnstrAssetsUrl}/others/93BB-Ice-print.webp`"/></g><path class="tpl-c-6" d="M390.14,400.8a174.19,174.19,0,0,1,0-348.38Z"/><path class="tpl-c-7" d="M390.14,400.8a174.19,174.19,0,0,1,0-348.38Z"/><path class="tpl-c-8" d="M390.14,400.8a174.19,174.19,0,0,1,0-348.38"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tpl-c-1,.tpl-c-8{fill:none;}.tpl-c-2{opacity:0.3;}.tpl-c-3{fill:#64ff00;}.tpl-c-4{fill:lime;}.tpl-c-5{clip-path:url(#tpl-cp);}.tpl-c-6{fill:url(#tpl-lg);}.tpl-c-7{opacity:0.4;fill:url(#tpl-rg);}.tpl-c-8{stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

