<template>
<svg data-svg-lvl-3="edge-top-base" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышки_Подложка"><path class="tb-c-3" d="M53.1,234.55v-.85c-6.24-.43-11.2-5.95-11.2-12.69s4.84-12.14,11-12.69v-.86h3.91a12.56,12.56,0,0,0-2.89-.34c-7.24,0-13.13,6.23-13.13,13.89s5.89,13.88,13.13,13.88a12.64,12.64,0,0,0,2.9-.34Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tb-c-1{opacity:0.3;}.tb-c-2{fill:#64ff00;}.tb-c-3{fill:#232728;}</style>

