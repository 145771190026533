import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const perforation = {
  id: 'perforation',
  // tName:  'personalizations.perforation.perforation',
  template: 'modal',
  preview: 'cover',

  originPersonalizationsIds: [
    586, // Перфорация корешка форматов 10х16 и 10.5х16
    587, // Перфорация корешка/обложки форматов 15х21 и 15.5х21
    588, // Перфорация корешка форматов 20х26 и 20.5х27
    589, // Перфорация корешка форматов 33х15
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getPerforation(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  const idParam = 102
  const idValue = modelValues?.color?.id

  if (idValue) {
    const selectedPers = [586, 587, 588, 589]
    const idsBuhta = lodash.intersection(this.originIdsAvailable, selectedPers)
    idBuhta = idsBuhta[0]
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
  ])
}

/**
 *  Выбор цвета
 */
function getPerforation(params) {
  const { oldModelValues, items, modelValues, originPersonalizations, originIdsAvailable } = params

  let idBuhta
  const idParam = 102

  if (originIdsAvailable.length > 0) {
    idBuhta = originIdsAvailable[0]
  } else {
    return
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.perforation.color',
      template: 'selectClassicColor',
      sort: 10,
      values,
    })

    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[0]
    } else {
      modelValues.color = values[0]
    }
  }
}
