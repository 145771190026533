<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M176,426l.18-.95c15.71-81.89,20.09-160.18,22.2-197.81.18-3.19.34-6.11.49-8.73-9.61-14.76-15.1-36.93-15.1-61.21,0-23.4,5.62-46.07,15.07-61.1V96c-.91-11.19-3-37.37-11.14-67.35l-.44-1.41"/><g class="tss-c-4"><path class="tss-c-5" d="M176.16,426.2l.18-.95.57-3"/><path class="tss-c-6" d="M177.64,418.35C192.29,339,196.49,264,198.54,227.44c.18-3.19.34-6.11.49-8.73-9.61-14.76-15.1-36.93-15.1-61.21,0-23.4,5.62-46.07,15.07-61.1v-.22a372.48,372.48,0,0,0-10-63"/><path class="tss-c-5" d="M188.5,31.27c-.22-.81-.43-1.63-.65-2.44l-.44-1.41"/></g><g class="tss-c-7"><path class="tss-c-8" d="M175.76,425.8l.18-.95.57-3"/><path class="tss-c-9" d="M177.24,418c14.65-79.36,18.85-154.34,20.9-190.91.18-3.19.34-6.11.49-8.73-9.61-14.77-15.1-36.93-15.1-61.21,0-23.4,5.62-46.07,15.07-61.1v-.22a372.48,372.48,0,0,0-10-63"/><path class="tss-c-8" d="M188.1,30.87c-.22-.81-.43-1.63-.65-2.44L187,27"/></g><path class="tss-c-10" d="M192.69,27.22h0c8.76,32.35,10.65,60.38,11.47,70.26C195.42,110.57,189,132.33,189,157.3c0,25.41,6.18,46.83,15.19,59.82-2,33.09-5.5,118.34-22.87,208.87"/><path class="tss-c-11" d="M192.44,27.22h0c8.76,32.35,10.65,60.38,11.47,70.26-8.75,13.08-15.19,34.84-15.19,59.81,0,25.41,6.18,46.83,15.19,59.82-2,33.09-5.5,118.34-22.87,208.87"/></g><g id="Шов_Цвет"><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M176,426l.18-.95.57-3"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M177.44,418.15c14.65-79.36,18.85-154.34,20.9-190.91.18-3.19.34-6.11.49-8.73-9.61-14.76-15.1-36.93-15.1-61.21,0-23.4,5.62-46.07,15.07-61.1V96a373.21,373.21,0,0,0-10-63"/><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M188.3,31.07c-.22-.81-.43-1.63-.65-2.44l-.44-1.41"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-5,.tss-c-6{stroke:#000;}.tss-c-10,.tss-c-11,.tss-c-3{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-4{opacity:0.6;}.tss-c-12,.tss-c-13,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{stroke-linecap:round;stroke-width:1.14px;}.tss-c-13,.tss-c-6,.tss-c-9{stroke-dasharray:7.97 3.99;}.tss-c-7{opacity:0.7;}.tss-c-10,.tss-c-8,.tss-c-9{stroke:#fff;}.tss-c-11,.tss-c-12,.tss-c-13{stroke:#232728;}</style>

