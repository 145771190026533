<template>
<svg data-svg-lvl-3="flyleaffront-block-shadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Тень_Блока"><image width="291" height="218" transform="translate(111.05 202)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/flyleaffront/block/shadow/DPH26_1.webp`"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lsh-c-1{opacity:0.3;}.lsh-c-2{fill:#64ff00;}</style>

