<template>
<svg data-svg-lvl-3="flyleaffront-block-pocket" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lp-lg" x1="68.31" y1="317.64" x2="163.75" y2="317.64" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#333"/><stop offset="1" stop-color="#b2b2b2" stop-opacity="0.5"/></linearGradient><linearGradient id="lp-lg-2" x1="116.02" y1="298.85" x2="116.02" y2="348.66" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.05"/><stop offset="1" stop-opacity="0.3"/></linearGradient><clipPath id="lp-cp"><path class="lp-c-1" d="M68.31,285.82h95.44a0,0,0,0,1,0,0V345.5a3.94,3.94,0,0,1-3.94,3.94H72.26a3.94,3.94,0,0,1-3.94-3.94V285.82A0,0,0,0,1,68.31,285.82Z"/></clipPath></defs><g id="КонвертКонтур_Пластиковый_Карман" ><g class="lp-c-4"><path class="lp-c-5" d="M68.31,285.82h95.44a0,0,0,0,1,0,0V345.5a3.94,3.94,0,0,1-3.94,3.94H72.26a3.94,3.94,0,0,1-3.94-3.94V285.82A0,0,0,0,1,68.31,285.82Z"/><path class="lp-c-6" d="M68.31,298.24h95.44a0,0,0,0,1,0,0V345.5a3.94,3.94,0,0,1-3.94,3.94H72.26a3.94,3.94,0,0,1-3.94-3.94V298.24A0,0,0,0,1,68.31,298.24Z"/><path class="lp-c-7" d="M159.76,349H72.28a3.48,3.48,0,0,1-3.47-3.47V298.24h.63v47.28a2.85,2.85,0,0,0,2.84,2.84h87.48a2.85,2.85,0,0,0,2.84-2.84V298.24h.63v47.28A3.48,3.48,0,0,1,159.76,349Z"/></g><g class="lp-c-4"><g class="lp-c-8"><g class="lp-c-9"><image width="276" height="396" transform="translate(51.02 204.67) scale(0.47 0.47)" :href="`${$CONST.cnstrAssetsUrl}/others/pocket.webp`"/></g></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lp-c-1{fill:none;}.lp-c-2{opacity:0.3;}.lp-c-3{fill:#64ff00;}.lp-c-4{opacity:0.8;}.lp-c-5,.lp-c-9{opacity:0.5;}.lp-c-5{fill:url(#lp-lg);}.lp-c-6{fill:#828282;opacity:0.4;}.lp-c-7{fill:url(#lp-lg-2);}.lp-c-8{clip-path:url(#lp-cp);}</style>

