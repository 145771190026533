<template>
  <div class="ac-checkbox">
    <div class="item" @click="toggle">
      <svg class="item__image" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class="contour" x="0.5" y="0.5" width="14" height="14" rx="3.5" />
        <g v-if="value.checked">
          <rect class="checked" width="15" height="15" rx="4" />
          <path
            d="M11.7681 4.32603C11.4594 4.01763 10.9578 4.01763 10.6494 4.32603L5.9802 8.99523L4.3506 7.36563C4.0521 7.06713 3.5298 7.06743 3.2322 7.36533C3.0825 7.51443 3 7.71333 3 7.92483C3 8.13633 3.0825 8.33523 3.2319 8.48433L5.421 10.6734C5.5704 10.8231 5.769 10.9053 5.9805 10.9053C6.192 10.9053 6.3909 10.8228 6.54 10.6734L11.7681 5.44533C11.9175 5.29593 12 5.09703 12 4.88553C12 4.67403 11.9178 4.47543 11.7681 4.32603Z"
          />
        </g>
      </svg>

      <div class="item__name">{{ tLabel ? $t(tLabel) : label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    /** @var value - выбранный объект из items */
    value: {
      type: Object,
      required: true,
    },
    /** @var label - имя метки */
    label: {
      type: String,
      default: () => 'Чекбокс',
    },
    /** @var label - метка */
    tLabel: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    toggle() {
      if (this.value.checked === this.items[0].checked) {
        this.$emit('input', this.items[1])
      } else {
        this.$emit('input', this.items[0])
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.item
  display: flex
  cursor: pointer
  align-items: center
  &__image
    display: block
    width: 15px
    height: 15px
    // ::v-deep svg
    //   vertical-align: unset
  &__name
    padding-left: 8px
svg
  display: block
  transition: .3s
  rect
    transition: .3s
    &.contour
      stroke: $alternative
      fill: $white
    &.checked
        fill:$alternative

  path
    transition: .3s
    fill: $white
</style>
