<template>
<svg data-svg-lvl-3="cover-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Задник_Контур"><path class="bc-c-3" d="M126.44,19h260.8a9.13,9.13,0,0,1,9.13,9.13v375a9.13,9.13,0,0,1-9.13,9.13H126.44a0,0,0,0,1,0,0V19A0,0,0,0,1,126.44,19Z" transform="translate(522.82 431.25) rotate(-180)"/><path class="bc-c-4" d="M410.14,6a1.1,1.1,0,0,0-1.09-1.21H143.82A9.16,9.16,0,0,0,134.69,14l-.05,1.64c0,.14,0,.29,0,.46V396.35a9.16,9.16,0,0,0,9.13,9.13H409.83a1.1,1.1,0,0,0,.32-.78V6Z"/><path class="bc-c-4" d="M392.24,16H124.66a4.51,4.51,0,0,0-3.18,1.32l-8,8a1.13,1.13,0,0,0-.33.76V413.73a1.1,1.1,0,0,0,1.1,1.1l275.92-.06h2.1a9.13,9.13,0,0,0,9.13-9.13V25.13A9.16,9.16,0,0,0,392.24,16Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:#fff;stroke-width:0.46px;}.bc-c-3,.bc-c-4{stroke:#232728;stroke-miterlimit:2;}.bc-c-4{fill:gray;stroke-width:1.14px;}</style>

