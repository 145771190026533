<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="tc-rg" cx="378.24" cy="37.08" r="465.42" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.2"/><stop offset="0.44" stop-color="#e5e5e5" stop-opacity="0"/></radialGradient><linearGradient id="tc-lg" x1="111.57" y1="226.61" x2="227.86" y2="226.61" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.15"/><stop offset="0.6" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="tc-lg-2" x1="111.57" y1="226.61" x2="135.86" y2="226.61" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.4"/><stop offset="0.16" stop-color="#fff" stop-opacity="0"/></linearGradient></defs><g id="Крышки_Контур"><path class="tc-c-3" d="M390.14,27.22H111.57V426H390.14Z"/><rect class="tc-c-4" x="111.57" y="27.23" width="116.29" height="398.77"/><rect class="tc-c-5" x="111.57" y="27.23" width="24.29" height="398.77"/><polygon class="tc-c-6" points="390.14 27.23 111.59 27.23 111.57 27.23 111.57 426 390.14 426 390.14 27.23"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#64ff00;}.tc-c-3{fill:url(#tc-rg);}.tc-c-4{fill:url(#tc-lg);}.tc-c-5{fill:url(#tc-lg-2);}.tc-c-6{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

