<template>
<svg data-svg-lvl-3="flyleaffront-block-pocket" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lp-lg" x1="15000.55" y1="-6526.9" x2="15111.72" y2="-6526.9" gradientTransform="translate(-14924 6641.58)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#333"/><stop offset="1" stop-color="#b2b2b2" stop-opacity="0.5"/></linearGradient><linearGradient id="lp-lg-2" x1="15056.12" y1="-6548.78" x2="15056.12" y2="-6490.77" gradientTransform="translate(15188.28 -6412.23) rotate(180)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.05"/><stop offset="1" stop-opacity="0.3"/></linearGradient><clipPath id="lp-cp"><path class="lp-c-1" d="M76.55,77.62H187.72a0,0,0,0,1,0,0v69.52a4.59,4.59,0,0,1-4.59,4.59h-102a4.59,4.59,0,0,1-4.59-4.59V77.62a0,0,0,0,1,0,0Z" transform="translate(264.28 229.35) rotate(-180)"/></clipPath></defs><g id="КонвертКонтур_Пластиковый_Карман" ><g class="lp-c-4"><path class="lp-c-5" d="M76.55,77.62H187.72a0,0,0,0,1,0,0v69.52a4.59,4.59,0,0,1-4.59,4.59h-102a4.59,4.59,0,0,1-4.59-4.59V77.62a0,0,0,0,1,0,0Z" transform="translate(264.28 229.35) rotate(-180)"/><path class="lp-c-6" d="M76.55,77.62H187.72a0,0,0,0,1,0,0v55.06a4.59,4.59,0,0,1-4.59,4.59h-102a4.59,4.59,0,0,1-4.59-4.59V77.62a0,0,0,0,1,0,0Z" transform="translate(264.28 214.89) rotate(-180)"/><path class="lp-c-7" d="M81.21,78.15H183.1a4.05,4.05,0,0,1,4,4v55.07h-.73V82.2a3.31,3.31,0,0,0-3.31-3.31H81.21A3.31,3.31,0,0,0,77.9,82.2v55.07h-.74V82.2A4.06,4.06,0,0,1,81.21,78.15Z"/></g><g class="lp-c-4"><g class="lp-c-8"><g class="lp-c-9"><image width="276" height="396" transform="translate(207.86 246.26) rotate(180) scale(0.55 0.55)" :href="`${$CONST.cnstrAssetsUrl}/others/pocket.webp`"/></g></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lp-c-1{fill:none;}.lp-c-2{opacity:0.3;}.lp-c-3{fill:#64ff00;}.lp-c-4{opacity:0.8;}.lp-c-5,.lp-c-9{opacity:0.5;}.lp-c-5{fill:url(#lp-lg);}.lp-c-6{fill:#828282;opacity:0.4;}.lp-c-7{fill:url(#lp-lg-2);}.lp-c-8{clip-path:url(#lp-cp);}</style>

