// import lodash from 'lodash'

export async function getBlocks(api, { product, classId, model, getters, CONST }) {
  // const data = {}
  const response = await api('constructor-new/get-blocks', {
    product,
    class: classId,
    model,
  })
  if (!Array.isArray(response.result)) {
    throw new TypeError(`getBlocks: Ожидается массив в response.result`)
  }
  const data = modifyItems(response.result, getters, CONST)

  return data
}
function modifyItems(items, getters, CONST) {
  return items
    .sort((a, b) => getters.FORMATS_KEYS[a.format]?.width - getters.FORMATS_KEYS[b.format]?.width)
    .map((n) => {
      return {
        ...n,
        name: `${n.id} ${getters.LINES_KEYS[n.line].name}`,
        image: `${CONST.assetsUrl}/blocks/small/${n.id}.jpg`,
        imagePreview: `${CONST.assetsUrl}/blocks/original/${n.id}.jpg`,
        typeRef: getters.TYPES_KEYS[n.type],
        lineRef: getters.LINES_KEYS[n.line],
        formatRef: getters.FORMATS_KEYS[n.format],
      }
    })
}
