<template>
<svg data-svg-lvl-3="spread-back-flap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bf-lg" x1="452.53" y1="214.4" x2="496.55" y2="214.4" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.3"/><stop offset="0.29" stop-opacity="0.05"/><stop offset="1" stop-opacity="0"/></linearGradient></defs><g id="Хлястик_Контур"><path class="bf-c-3" d="M497.36,213.58l-5.81-24.87a3,3,0,0,0-1.82-1.89,2.88,2.88,0,0,1,.62,1.06l5.81,24.88a7.93,7.93,0,0,1-3.1,9c-9.63,6.5-30.33,20.57-34.4,20.8l-6.13.4v1.5l1.2-.07,5.53-.4c4.07-.23,25.37-14.5,35-21A8.5,8.5,0,0,0,497.36,213.58Z"/><path class="bf-c-4" d="M458.66,201.61l-6.12.28V243l6.13-.4c4.07-.23,24.77-14.3,34.4-20.8a7.93,7.93,0,0,0,3.1-9l-5.81-24.88a3,3,0,0,0-4.42-1.6C478.79,190.81,462,201.61,458.66,201.61Z"/><path class="bf-c-5" d="M458.66,201.61l-6.12.28V243l6.13-.4c4.07-.23,24.77-14.3,34.4-20.8a7.93,7.93,0,0,0,3.1-9l-5.81-24.88a3,3,0,0,0-4.42-1.6C478.79,190.81,462,201.61,458.66,201.61Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bf-c-1{opacity:0.3;}.bf-c-2{fill:#64ff00;}.bf-c-3{fill:#232728;}.bf-c-4{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.bf-c-5{fill:url(#bf-lg);}</style>

