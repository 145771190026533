<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M344.27,27.42V258.1a30.76,30.76,0,0,0,18.61,28.26l27.46,11.81"/><path class="tss-c-4" d="M344.07,27.22V257.9a30.76,30.76,0,0,0,18.61,28.26L390.14,298"/><path class="tss-c-5" d="M390.14,303.42l-29.43-12.66a35.75,35.75,0,0,1-21.64-32.86V27.22"/><g class="tss-c-6"><line class="tss-c-7" x1="390.34" y1="303.62" x2="386.67" y2="302.04"/><path class="tss-c-8" d="M382.94,300.43l-22-9.47a35.75,35.75,0,0,1-21.64-32.86V33.45"/><line class="tss-c-7" x1="339.27" y1="31.42" x2="339.27" y2="27.42"/></g><g class="tss-c-9"><line class="tss-c-10" x1="389.94" y1="303.22" x2="386.27" y2="301.64"/><path class="tss-c-11" d="M382.54,300l-22-9.47a35.75,35.75,0,0,1-21.64-32.86V33.05"/><line class="tss-c-10" x1="338.87" y1="31.02" x2="338.87" y2="27.02"/></g></g><g id="Шов_Цвет"><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="390.14" y1="303.42" x2="386.47" y2="301.84"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M382.74,300.23l-22-9.47a35.75,35.75,0,0,1-21.64-32.86V33.25"/><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="339.07" y1="31.22" x2="339.07" y2="27.22"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-4,.tss-c-5,.tss-c-7,.tss-c-8{fill:none;stroke-miterlimit:2;}.tss-c-10,.tss-c-11,.tss-c-3{stroke:#fff;}.tss-c-3,.tss-c-4,.tss-c-5{stroke-width:0.46px;}.tss-c-12,.tss-c-13,.tss-c-4{stroke:#232728;}.tss-c-5,.tss-c-7,.tss-c-8{stroke:#000;}.tss-c-5{opacity:0.5;}.tss-c-6{opacity:0.6;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-7,.tss-c-8{stroke-linecap:round;stroke-width:1.14px;}.tss-c-11,.tss-c-13,.tss-c-8{stroke-dasharray:8.12 4.06;}.tss-c-9{opacity:0.7;}</style>

