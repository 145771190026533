<template>
<svg data-svg-lvl-3="flyleafend-block-pocket" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lp-lg" x1="76.55" y1="297.28" x2="187.72" y2="297.28" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#333"/><stop offset="1" stop-color="#b2b2b2" stop-opacity="0.5"/></linearGradient><linearGradient id="lp-lg-2" x1="132.12" y1="275.4" x2="132.12" y2="333.41" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.05"/><stop offset="1" stop-opacity="0.3"/></linearGradient><clipPath id="lp-cp"><path class="lp-c-1" d="M76.55,260.22H187.72a0,0,0,0,1,0,0v69.52a4.59,4.59,0,0,1-4.59,4.59h-102a4.59,4.59,0,0,1-4.59-4.59V260.22A0,0,0,0,1,76.55,260.22Z"/></clipPath></defs><g id="КонвертКонтур_Пластиковый_Карман" ><g class="lp-c-4"><path class="lp-c-5" d="M76.55,260.22H187.72a0,0,0,0,1,0,0v69.52a4.59,4.59,0,0,1-4.59,4.59h-102a4.59,4.59,0,0,1-4.59-4.59V260.22A0,0,0,0,1,76.55,260.22Z"/><path class="lp-c-6" d="M76.55,274.68H187.72a0,0,0,0,1,0,0v55.06a4.59,4.59,0,0,1-4.59,4.59h-102a4.59,4.59,0,0,1-4.59-4.59V274.68a0,0,0,0,1,0,0Z"/><path class="lp-c-7" d="M183.07,333.8H81.17a4.06,4.06,0,0,1-4-4.05V274.68h.74v55.07a3.31,3.31,0,0,0,3.3,3.31h101.9a3.31,3.31,0,0,0,3.31-3.31V274.68h.73v55.07A4.05,4.05,0,0,1,183.07,333.8Z"/></g><g class="lp-c-4"><g class="lp-c-8"><g class="lp-c-9"><image width="276" height="396" transform="translate(56.41 165.69) scale(0.55 0.55)" :href="`${$CONST.cnstrAssetsUrl}/others/pocket.webp`"/></g></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lp-c-1{fill:none;}.lp-c-2{opacity:0.3;}.lp-c-3{fill:#64ff00;}.lp-c-4{opacity:0.8;}.lp-c-5,.lp-c-9{opacity:0.5;}.lp-c-5{fill:url(#lp-lg);}.lp-c-6{fill:#828282;opacity:0.4;}.lp-c-7{fill:url(#lp-lg-2);}.lp-c-8{clip-path:url(#lp-cp);}</style>

