<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tc-lg" x1="126.37" y1="226.61" x2="123.44" y2="226.61" gradientTransform="translate(249.85 453.22) rotate(180)" gradientUnits="userSpaceOnUse"><stop offset="0.38" stop-opacity="0.4"/><stop offset="0.69" stop-color="#fff" stop-opacity="0.5"/></linearGradient><radialGradient id="tc-rg" cx="378.31" cy="37.08" r="465.41" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.2"/><stop offset="0.44" stop-color="#e5e5e5" stop-opacity="0"/></radialGradient><linearGradient id="tc-lg-2" x1="113.12" y1="226.61" x2="229.42" y2="226.61" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.4"/><stop offset="0.13" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="tc-lg-3" x1="113.12" y1="226.61" x2="137.42" y2="226.61" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.3"/><stop offset="0.13" stop-opacity="0"/></linearGradient></defs><g id="Крышки_Контур"><rect class="tc-c-3" x="123.33" y="27.22" width="3.19" height="398.78" transform="translate(249.85 453.22) rotate(-180)"/><path class="tc-c-4" d="M381,27.22H113.13V426H381a9.16,9.16,0,0,0,9.13-9.13V36.35A9.13,9.13,0,0,0,381,27.22Z"/><rect class="tc-c-5" x="113.12" y="27.23" width="116.29" height="398.77"/><rect class="tc-c-6" x="113.12" y="27.23" width="24.29" height="398.77"/><path class="tc-c-7" d="M390.14,36.35A9.13,9.13,0,0,0,381,27.22H114.24A1.1,1.1,0,0,1,113.15,26h0v399a1.1,1.1,0,0,0,1.1,1.1H381a9.16,9.16,0,0,0,9.13-9.13Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#64ff00;}.tc-c-3{fill:url(#tc-lg);}.tc-c-4{fill:url(#tc-rg);}.tc-c-5{fill:url(#tc-lg-2);}.tc-c-6{fill:url(#tc-lg-3);}.tc-c-7{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

