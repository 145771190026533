import lodash from 'lodash'

export default {
  namespaced: true,

  state() {
    return {
      seo: {},
      items: [],
    }
  },

  mutations: {
    CLEAR_REFERENCES(state) {
      state.seo = {}
      state.items = []
    },
    SET_REFERENCES(state, payload) {
      state.seo = payload.seo
      state.items = payload.products
    },
  },

  getters: {
    SEO: (state) => state.seo,
    ITEMS: (state) => state.items,
    ITEMS_KEYS: (state) => lodash.keyBy(state.items, (item) => item.id),
  },
}
