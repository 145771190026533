<template>
<svg data-svg-lvl-5="cover-top-draggableUpOrDown-draggable-metallic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.93 56.25" preserveAspectRatio="xMidYMid slice" :x="draggable.x" :y="draggable.y" :width="draggable.w" :height="draggable.h"><defs><linearGradient id="tms-lg" x1="0.44" y1="-1.25" x2="52.49" y2="57.74" gradientUnits="userSpaceOnUse">
<template v-if="draggable.color === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.42" stop-color="#fbe6a6" />
  <stop offset="0.83" stop-color="#6c4209" />
  <stop offset="1" stop-color="#efe9cd" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".3" stop-color="#fff" />
  <stop offset=".83" stop-color="#3c3e45" />
  <stop offset="1" stop-color="#979a9f" />
</template>
</linearGradient><linearGradient id="tms-lg-2" x1="-9.58" y1="10.16" x2="46.96" y2="64.34" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.5"/><stop offset="0.5" stop-color="#fff" stop-opacity="0.8"/><stop offset="1" stop-color="#fff" stop-opacity="0.5"/></linearGradient><linearGradient id="tms-lg-3" x1="1.79" y1="-1.71" x2="58.34" y2="52.46" href="#tms-lg-2"/></defs><g id="Наклейки_Металлостикер" ><path class="tms-c-1" d="M24.8,20.16V.5H.5v9.83H13.63V55.75H56.43V20.16ZM45.29,45.92H24.79V30.79h20.5Z"/><g class="tms-c-2"><polygon class="tms-c-3" points="24.12 30.12 24.12 46.58 45.95 46.58 45.95 45.92 24.79 45.92 24.79 30.12 24.12 30.12"/><polygon class="tms-c-3" points="24.8 20.16 24.79 0.5 0.5 0.5 1.16 1.16 24.12 1.16 24.14 20.82 55.77 20.82 55.77 55.08 56.43 55.74 56.43 20.16 24.8 20.16"/></g><polygon class="tms-c-4" points="14.28 55.08 14.29 9.67 1.16 9.66 1.16 1.16 0.5 0.5 0.5 10.33 13.63 10.33 13.62 55.74 56.43 55.74 55.77 55.08 14.28 55.08"/><polygon class="tms-c-5" points="45.95 45.92 45.95 30.12 24.79 30.12 24.79 30.79 45.29 30.79 45.29 45.92 45.95 45.92"/><g class="tms-c-6"><path d="M24.79.5V20.16H56.43V55.75H13.62V10.33H.5V.5H24.79m0,45.42h20.5V30.79H24.79V45.92M25.29,0H0V10.83H13.13V56.25H56.93V19.66H25.3V0Zm0,31.29h19.5V45.42H25.29V31.29Z"/></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, draggable: { type: Object, required: true }, }, data() { return {} },}
</script>

<style scoped>.tms-c-1{fill:url(#tms-lg);}.tms-c-2{opacity:0.3;}.tms-c-3{fill:#fff;}.tms-c-4{fill:url(#tms-lg-2);}.tms-c-5{fill:url(#tms-lg-3);}.tms-c-6{opacity:0.7;}</style>

