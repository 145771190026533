<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><g class="tss-c-3"><path class="tss-c-4" d="M113.13,220.26C214,208.57,290,125.7,290,27.27"/><path class="tss-c-4" d="M300,27.27c0,103.71-80.09,191-186.3,203l-.59.06"/></g><g class="tss-c-5"><path class="tss-c-6" d="M113.33,220.46c1.32-.16,2.65-.32,4-.5"/><path class="tss-c-7" d="M121.23,219.39c94.88-14.68,165.94-92.72,168.86-185.93"/><path class="tss-c-6" d="M290.15,31.47c0-1.33,0-2.66,0-4"/><path class="tss-c-6" d="M300.19,27.47c0,1.34,0,2.67,0,4"/><path class="tss-c-8" d="M300,35.5c-3.94,98.41-80,180.36-180.76,194.28"/><path class="tss-c-6" d="M117.27,230.05l-3.38.41-.59.06"/></g><g class="tss-c-9"><path class="tss-c-10" d="M112.93,220.06c1.32-.16,2.65-.32,4-.5"/><path class="tss-c-11" d="M120.83,219c94.88-14.68,165.94-92.72,168.86-185.93"/><path class="tss-c-10" d="M289.75,31.07c0-1.33,0-2.66,0-4"/><path class="tss-c-10" d="M299.79,27.07c0,1.34,0,2.67,0,4"/><path class="tss-c-12" d="M299.63,35.1c-3.94,98.41-80,180.36-180.76,194.28"/><path class="tss-c-10" d="M116.87,229.65l-3.38.41-.59.06"/></g><path class="tss-c-13" d="M295.19,27.42v0c0,102.21-79.47,186.43-181.86,198"/><path class="tss-c-14" d="M295,27.22v.05c0,102.21-79.47,186.43-181.86,198"/></g><g id="Шов_Цвет"><path :style="{ stroke: params.seamColor }"  class="tss-c-15" d="M113.13,220.26c1.32-.16,2.65-.32,4-.5"/><path :style="{ stroke: params.seamColor }"  class="tss-c-16" d="M121,219.19C215.91,204.51,287,126.47,289.89,33.26"/><path :style="{ stroke: params.seamColor }"  class="tss-c-15" d="M290,31.27c0-1.33,0-2.66,0-4"/><path :style="{ stroke: params.seamColor }"  class="tss-c-15" d="M300,27.27c0,1.34,0,2.67,0,4"/><path :style="{ stroke: params.seamColor }"  class="tss-c-17" d="M299.83,35.3c-3.94,98.41-80,180.36-180.76,194.28"/><path :style="{ stroke: params.seamColor }"  class="tss-c-15" d="M117.07,229.85l-3.38.41-.59.06"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-3{opacity:0.5;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-14,.tss-c-15,.tss-c-16,.tss-c-17,.tss-c-4,.tss-c-6,.tss-c-7,.tss-c-8{fill:none;stroke-miterlimit:2;}.tss-c-4,.tss-c-6,.tss-c-7,.tss-c-8{stroke:#000;}.tss-c-13,.tss-c-14,.tss-c-4{stroke-width:0.46px;}.tss-c-5{opacity:0.6;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-15,.tss-c-16,.tss-c-17,.tss-c-6,.tss-c-7,.tss-c-8{stroke-linecap:round;stroke-width:1.14px;}.tss-c-11,.tss-c-16,.tss-c-7{stroke-dasharray:7.95 3.98;}.tss-c-12,.tss-c-17,.tss-c-8{stroke-dasharray:8.06 4.03;}.tss-c-9{opacity:0.7;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13{stroke:#fff;}.tss-c-14,.tss-c-15,.tss-c-16,.tss-c-17{stroke:#232728;}</style>

