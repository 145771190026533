<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><g class="tss-c-3"><line class="tss-c-4" x1="186.64" y1="123.19" x2="186.64" y2="331.11"/><line class="tss-c-4" x1="196.64" y1="123.19" x2="196.64" y2="331.11"/></g><g class="tss-c-5"><line class="tss-c-6" x1="186.84" y1="123.39" x2="186.84" y2="331.31"/><line class="tss-c-6" x1="196.84" y1="123.39" x2="196.84" y2="331.31"/></g><g class="tss-c-7"><line class="tss-c-8" x1="186.44" y1="122.99" x2="186.44" y2="330.91"/><line class="tss-c-8" x1="196.44" y1="122.99" x2="196.44" y2="330.91"/></g><line class="tss-c-9" x1="191.84" y1="123.19" x2="191.84" y2="331.11"/><line class="tss-c-10" x1="191.64" y1="123.19" x2="191.64" y2="331.11"/></g><g id="Шов_Цвет"><line :style="{ stroke: params.seamColor }"  class="tss-c-11" x1="186.64" y1="123.19" x2="186.64" y2="331.11"/><line :style="{ stroke: params.seamColor }"  class="tss-c-11" x1="196.64" y1="123.19" x2="196.64" y2="331.11"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-3{opacity:0.5;}.tss-c-10,.tss-c-11,.tss-c-4,.tss-c-6,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-4,.tss-c-6{stroke:#000;}.tss-c-11,.tss-c-4,.tss-c-6,.tss-c-8{stroke-linecap:round;}.tss-c-4{stroke-width:0.5px;}.tss-c-5{opacity:0.6;}.tss-c-11,.tss-c-6,.tss-c-8{stroke-width:1.14px;stroke-dasharray:8 4;}.tss-c-7{opacity:0.7;}.tss-c-8,.tss-c-9{stroke:#fff;}.tss-c-10,.tss-c-9{stroke-width:0.46px;}.tss-c-10,.tss-c-11{stroke:#232728;}</style>

