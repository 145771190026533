<template>
<svg data-svg-lvl-3="edge-block-edge" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs>
      <mask id="le-m-si" x="0" y="0" width="500" height="430" fill="#ffffff"><polygon  points="54.73 207.46 54.73 209.27 54.96 209.27 54.96 211.07 54.96 212.88 54.96 214.69 55.22 214.69 55.22 216.49 55.07 216.49 55.07 218.3 54.96 218.3 54.96 220.1 54.96 220.1 54.96 221.91 55.09 221.91 55.09 223.71 54.96 223.71 54.96 225.52 55.25 225.52 55.25 227.32 55.15 227.32 55.15 229.13 54.96 229.13 54.96 230.94 55.27 230.94 55.27 232.74 54.96 232.74 54.96 234.55 472.94 234.55 472.94 232.74 473.25 232.74 473.25 230.94 472.94 230.94 472.94 229.13 473.12 229.13 473.12 227.32 473.22 227.32 473.22 225.52 472.94 225.52 472.94 223.71 473.07 223.71 473.07 221.91 472.94 221.91 472.94 220.1 472.94 220.1 472.94 218.3 473.04 218.3 473.04 216.49 473.2 216.49 473.2 214.69 472.94 214.69 472.94 212.88 472.94 212.88 472.94 211.07 472.94 209.27 472.71 209.27 472.71 207.46 54.73 207.46"/></mask>
  <linearGradient id="lg-silver" x1="45.52" x2="459.83"  y1="197.49" y2="197.49" gradientUnits="userSpaceOnUse" >
    <stop offset="0" stop-color="#989ba0" />
    <stop offset=".3" stop-color="#fff" /><stop offset=".83" stop-color="#3c3e45" /><stop offset="1" stop-color="#979a9f" />
  </linearGradient>
  <linearGradient id="lg-gold" x1="45.52" x2="459.83"  y1="197.49" y2="197.49" gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#9c6b00" /><stop offset=".3" stop-color="#fbe6a6" />
    <stop offset=".83" stop-color="#6c4209" /><stop offset="1" stop-color="#efe9cd" />
  </linearGradient></defs><g id="Обрез"><polygon :style="{ fill: params.slice.color === '#9C6B00' ? 'url(#lg-gold)' : params.slice.color === '#989BA0' ? 'url(#lg-silver)' : params.slice.color }"  class="le-c-3" points="54.73 207.46 54.73 209.27 54.96 209.27 54.96 211.07 54.96 212.88 54.96 214.69 55.22 214.69 55.22 216.49 55.07 216.49 55.07 218.3 54.96 218.3 54.96 220.1 54.96 220.1 54.96 221.91 55.09 221.91 55.09 223.71 54.96 223.71 54.96 225.52 55.25 225.52 55.25 227.32 55.15 227.32 55.15 229.13 54.96 229.13 54.96 230.94 55.27 230.94 55.27 232.74 54.96 232.74 54.96 234.55 472.94 234.55 472.94 232.74 473.25 232.74 473.25 230.94 472.94 230.94 472.94 229.13 473.12 229.13 473.12 227.32 473.22 227.32 473.22 225.52 472.94 225.52 472.94 223.71 473.07 223.71 473.07 221.91 472.94 221.91 472.94 220.1 472.94 220.1 472.94 218.3 473.04 218.3 473.04 216.49 473.2 216.49 473.2 214.69 472.94 214.69 472.94 212.88 472.94 212.88 472.94 211.07 472.94 209.27 472.71 209.27 472.71 207.46 54.73 207.46"/> <image v-if="params.slice.image" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.slice.image" mask="url(#le-m-si)" /></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.le-c-1{opacity:0.3;}.le-c-2{fill:#64ff00;}.le-c-3{fill:#f4e8ce;}</style>

