<template>
<svg data-svg-lvl-3="edge-top-slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышка_Срез"><path :style="{ fill: params.sliceColor }"  class="tsl-c-3" d="M468,200.78a1.24,1.24,0,0,0,1.23-1.24v-3.39a1.24,1.24,0,0,0-1.23-1.24H41.63a25,25,0,0,0-25,25v2.18a25,25,0,0,0,25,25H468a1.24,1.24,0,0,0,1.23-1.24v-3.39a1.24,1.24,0,0,0-1.23-1.24H42a20,20,0,0,1-20-20v-.44a20,20,0,0,1,20-20Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsl-c-1{opacity:0.3;}.tsl-c-2{fill:#64ff00;}.tsl-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

