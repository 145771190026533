<template>
<svg data-svg-lvl-3="spread-block-lasseNameplate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lln-m" x="265.3" y="362.43" width="27.45" height="39.38" maskUnits="userSpaceOnUse"><path class="lln-c-1" d="M290.6,362.43l-8.89.5.21,3.45h.35a.46.46,0,0,1,.48.44l.06,1.16a.5.5,0,0,1-.48.53l-8.29.39a.48.48,0,0,1-.52-.46l0-1.13a.51.51,0,0,1,.48-.54h.26l-.18-3.39-8.73.49,2.14,38,25.31-1.43Z"/></mask></defs><g id="Шильда_Контур"><path class="lln-c-4" d="M290.6,362.43l-8.89.5.21,3.45h.35a.46.46,0,0,1,.48.44l.06,1.16a.5.5,0,0,1-.48.53l-8.29.39a.48.48,0,0,1-.52-.46l0-1.13a.51.51,0,0,1,.48-.54h.26l-.18-3.39-8.73.49,2.14,38,25.31-1.43Z"/><g class="lln-c-5"><path class="lln-c-6" d="M291.25,395.32,288,366.43a1,1,0,0,0-1.07-.94l-19.25-.26L288.27,364a1,1,0,0,1,1.1,1Z"/></g><path class="lln-c-7" d="M266.63,368.9,270,397.57a1,1,0,0,0,1,.85l18.25.78-19.74,1.22a.93.93,0,0,1-1-.87Z"/><path class="lln-c-8" d="M277.33,380.1l-.37-6.6-8.15.46.18,3.3,4.41-.24.85,15.23,14.37-.81L288,379.5Zm7.36,8.26-6.87.39-.29-5.08,6.88-.39Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lln-c-1,.lln-c-6,.lln-c-7{fill:#fff;}.lln-c-2{opacity:0.3;}.lln-c-3{fill:#64ff00;}.lln-c-4{fill:#277fff;stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.lln-c-5{mask:url(#lln-m);}.lln-c-6,.lln-c-7{fill-rule:evenodd;}.lln-c-6{opacity:0.4;}.lln-c-7{opacity:0.2;}.lln-c-8{fill:#ffb62b;}</style>

