<template>
<svg data-svg-lvl-3="spread-block-lasseNameplate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="lln-rg" cx="279.78" cy="380.79" r="20.16" gradientUnits="userSpaceOnUse"><stop offset="0.6" stop-opacity="0"/><stop offset="0.85" stop-opacity="0.05"/><stop offset="0.93" stop-color="#fff" stop-opacity="0.15"/><stop offset="1" stop-opacity="0.1"/></radialGradient><radialGradient id="lln-rg-2" cx="277.95" cy="382.37" r="20.16" gradientUnits="userSpaceOnUse"><stop offset="0.85" stop-color="#fff" stop-opacity="0"/><stop offset="0.93" stop-color="#fff" stop-opacity="0.3"/><stop offset="1" stop-color="#fff" stop-opacity="0.1"/></radialGradient></defs><g id="Шильда_Контур"><path class="lln-c-3" d="M281.73,363.16l.19,3.22h.33a.47.47,0,0,1,.49.44l.05,1.16a.5.5,0,0,1-.48.53l-8.29.39a.49.49,0,0,1-.52-.46l-.05-1.13a.51.51,0,0,1,.49-.54h.27l-.17-3.3a18.72,18.72,0,1,0,7.69-.29Z"/><path class="lln-c-4" d="M281.73,363.16l.19,3.22h.33a.47.47,0,0,1,.49.44l.05,1.16a.5.5,0,0,1-.48.53l-8.29.39a.49.49,0,0,1-.52-.46l-.05-1.13a.51.51,0,0,1,.49-.54h.27l-.17-3.3a18.72,18.72,0,1,0,7.69-.29Z"/><path class="lln-c-5" d="M281.73,363.16l.19,3.22h.33a.47.47,0,0,1,.49.44l.05,1.16a.5.5,0,0,1-.48.53l-8.29.39a.49.49,0,0,1-.52-.46l-.05-1.13a.51.51,0,0,1,.49-.54h.27l-.17-3.3a18.72,18.72,0,1,0,7.69-.29Z"/><path class="lln-c-6" d="M277.13,379.5l-.37-6.6-8.15.46.18,3.3,4.41-.24.85,15.23,14.37-.81-.67-11.94Zm7.36,8.26-6.87.39-.29-5.08,6.88-.39Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lln-c-1{opacity:0.3;}.lln-c-2{fill:#64ff00;}.lln-c-3{fill:#277fff;}.lln-c-3,.lln-c-4,.lln-c-5{stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.lln-c-4{fill:url(#lln-rg);}.lln-c-5{fill:url(#lln-rg-2);}.lln-c-6{fill:#ffb62b;}</style>

