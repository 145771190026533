<template>
<svg data-svg-lvl-5="cover-top-draggableUpOrDown-draggable-cutting" xmlns="http://www.w3.org/2000/svg" viewBox="-24.7 -24.7 173 173" preserveAspectRatio="xMidYMid slice" :x="draggable.x" :y="draggable.y" :width="draggable.w" :height="draggable.h"><defs><mask id="tct-m-c" x="0" y="0" width="500" height="430" fill="#ffffff"><circle  
  :style="{ transform: `rotate(${draggable.r}deg)` }"
  class="rotate"
    cx="61.19" cy="61.19" r="60.94"/></mask></defs><g 
  :style="{ transform: `rotate(${draggable.r}deg)` }"
  class="rotate"
  ><g id="Вырубка_Маска"><circle :style="{ fill: draggable.fromInner ? '#FFFFFF' : params.flyleaffrontColor }"  class="tct-c-1" cx="61.19" cy="61.19" r="60.94"/></g> </g>  <image v-if="!draggable.fromInner && params.flyleaffrontImage"
    preserveAspectRatio="xMidYMid slice"
    :x="-24.7 - (173 / draggable.w) * draggable.x"
    :y="-24.7 - (173 / draggable.h) * draggable.y"
    :width="(173 / draggable.w) * 500"
    :height="(173 / draggable.h) * 430"
    :href="params.flyleaffrontImage" mask="url(#tct-m-c)" /> <g 
  :style="{ transform: `rotate(${draggable.r}deg)` }"
  class="rotate"
  ><g id="Вырубка_Блик" class="blur" ><path class="tct-c-2" d="M105,18.85A60.93,60.93,0,0,1,18.85,105,60.93,60.93,0,1,0,105,18.85Z"/></g><g id="Вырубка_Контур"><g class="tct-c-3"><path class="tct-c-4" d="M20.25,106.32a60.94,60.94,0,1,1,85.5-86.7"/><path class="tct-c-5" d="M20.25,106.33a60.93,60.93,0,1,1,85.5-86.71"/><path class="tct-c-6" d="M20.25,106.33a60.92,60.92,0,1,1,85.5-86.71"/><path class="tct-c-7" d="M20.25,106.34a60.92,60.92,0,1,1,85.5-86.72"/><path class="tct-c-8" d="M20.25,106.34a60.92,60.92,0,1,1,85.5-86.72"/><path class="tct-c-9" d="M20.25,106.34a60.91,60.91,0,1,1,85.5-86.72"/><path class="tct-c-10" d="M20.25,106.35a60.91,60.91,0,1,1,85.5-86.73"/><path class="tct-c-11" d="M20.25,106.35a60.91,60.91,0,1,1,85.5-86.73"/><path class="tct-c-12" d="M20.25,106.36a60.91,60.91,0,1,1,85.5-86.74"/><path class="tct-c-13" d="M20.25,106.36a60.9,60.9,0,1,1,85.5-86.74"/><path class="tct-c-14" d="M20.25,106.36a60.9,60.9,0,1,1,85.5-86.74"/><path class="tct-c-15" d="M20.25,106.37a60.9,60.9,0,1,1,85.5-86.75"/><path class="tct-c-16" d="M20.25,106.37a60.9,60.9,0,1,1,85.5-86.75"/><path class="tct-c-17" d="M20.25,106.38a60.9,60.9,0,0,1,85.5-86.76"/><path class="tct-c-18" d="M20.25,106.38a60.91,60.91,0,0,1,85.5-86.76"/><path class="tct-c-19" d="M20.25,106.38a60.91,60.91,0,0,1,85.5-86.76"/><path class="tct-c-20" d="M20.25,106.39a60.91,60.91,0,0,1,85.5-86.77"/><path class="tct-c-21" d="M20.25,106.39a60.91,60.91,0,0,1,85.5-86.77"/><path class="tct-c-22" d="M20.25,106.39a60.92,60.92,0,0,1,85.5-86.77"/><path class="tct-c-23" d="M20.25,106.4a60.92,60.92,0,0,1,85.5-86.78"/><path class="tct-c-24" d="M20.25,106.4a60.93,60.93,0,0,1,85.5-86.78"/><path class="tct-c-25" d="M20.25,106.41a60.94,60.94,0,0,1,85.5-86.79"/></g><circle class="tct-c-26" cx="61.19" cy="61.19" r="60.94"/></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, draggable: { type: Object, required: true }, }, data() { return {} },}
</script>

<style scoped>
  .rotate {
    transform-box: fill-box;
    transform-origin: center;
  }
  .blur {
    filter: blur(1px);
  }
  .tct-c-1{fill:#f4e8ce;}.tct-c-2{fill:#fff;opacity:0.5;}.tct-c-3{opacity:0.3;}.tct-c-10,.tct-c-11,.tct-c-12,.tct-c-13,.tct-c-14,.tct-c-15,.tct-c-16,.tct-c-17,.tct-c-18,.tct-c-19,.tct-c-20,.tct-c-21,.tct-c-22,.tct-c-23,.tct-c-24,.tct-c-25,.tct-c-26,.tct-c-4,.tct-c-5,.tct-c-6,.tct-c-7,.tct-c-8,.tct-c-9{fill:none;}.tct-c-10,.tct-c-11,.tct-c-12,.tct-c-13,.tct-c-14,.tct-c-15,.tct-c-16,.tct-c-17,.tct-c-18,.tct-c-19,.tct-c-20,.tct-c-21,.tct-c-22,.tct-c-23,.tct-c-24,.tct-c-25,.tct-c-4,.tct-c-5,.tct-c-6,.tct-c-7,.tct-c-8,.tct-c-9{stroke:#000;stroke-miterlimit:10;stroke-width:0.5px;}.tct-c-5{opacity:0.95;}.tct-c-6{opacity:0.9;}.tct-c-7{opacity:0.86;}.tct-c-8{opacity:0.81;}.tct-c-9{opacity:0.76;}.tct-c-10{opacity:0.71;}.tct-c-11{opacity:0.67;}.tct-c-12{opacity:0.62;}.tct-c-13{opacity:0.57;}.tct-c-14{opacity:0.52;}.tct-c-15{opacity:0.48;}.tct-c-16{opacity:0.43;}.tct-c-17{opacity:0.38;}.tct-c-18{opacity:0.33;}.tct-c-19{opacity:0.29;}.tct-c-20{opacity:0.24;}.tct-c-21{opacity:0.19;}.tct-c-22{opacity:0.14;}.tct-c-23{opacity:0.1;}.tct-c-24{opacity:0.05;}.tct-c-25{opacity:0;}.tct-c-26{stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}</style>

