<template>
<svg data-svg-lvl-3="edge-top-base" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышки_Подложка"><path class="tb-c-3" d="M48.79,240.23v-1.17h-.33l0-.29C41,236.83,35.35,229.62,35.35,221A18.16,18.16,0,0,1,47.76,203.5v-.56H48V201.8h-.25C39.7,204,33.7,211.79,33.7,221a19.76,19.76,0,0,0,13.93,19.19Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tb-c-1{opacity:0.3;}.tb-c-2{fill:#64ff00;}.tb-c-3{fill:#232728;}</style>

