<template>
<svg data-svg-lvl-2="edge-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
<PreviewLayersEdgeTopBasePH2L :params="params" />
<PreviewLayersEdgeTopSlicePH2L :params="params" />
<component :is="`PreviewLayersEdgeTopFlapF${params.flapComponent}`" :params="params" v-if="params.flapComponent" />
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>