<template>
<svg data-svg-lvl-3="spread-block-lasseNameplate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lln-lg" x1="345.28" y1="371.67" x2="371.39" y2="397.87" gradientTransform="translate(-99.39 17.4) rotate(-3.08)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#383838"/><stop offset="0.54" stop-color="#949494" stop-opacity="0.3"/><stop offset="1" stop-color="#2e2e2e"/></linearGradient></defs><g id="Шильда_Контур"><path class="lln-c-3" d="M282.83,367.26l0,.87a.69.69,0,0,1-.66.73l-8,.43a.7.7,0,0,1-.74-.66l0-.86-4.74,1.82L263.22,378l.54,10,6.32,7.8,9.7,2.58,9.37-3.61,5.45-8.43-.54-10-6.32-7.79Z"/><path class="lln-c-4" d="M289.58,365.73l-7.8-2.08.14,2.68h.16a.69.69,0,0,1,.73.66l.06,1.14a.69.69,0,0,1-.66.73l-8,.43a.7.7,0,0,1-.74-.66l-.06-1.14a.69.69,0,0,1,.66-.73h.15l-.14-2.69L266.54,367l-6.6,10.18.66,12.11,7.64,9.42L280,401.8l11.32-4.36,6.6-10.18-.66-12.12Z"/><path class="lln-c-5" d="M289.58,365.73l-7.8-2.08.14,2.68h.16a.69.69,0,0,1,.73.66l.06,1.14a.69.69,0,0,1-.66.73l-8,.43a.7.7,0,0,1-.74-.66l-.06-1.14a.69.69,0,0,1,.66-.73h.15l-.14-2.69L266.54,367l-6.6,10.18.66,12.11,7.64,9.42L280,401.8l11.32-4.36,6.6-10.18-.66-12.12Z"/><path class="lln-c-6" d="M277.09,379.41l-.38-6.91-8.55.46.19,3.45,4.62-.24.85,16,15.08-.81-.68-12.52Zm7.7,8.69-7.22.38-.29-5.32,7.22-.39Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lln-c-1,.lln-c-4{opacity:0.3;}.lln-c-2{fill:#64ff00;}.lln-c-3{fill:#fa4e19;}.lln-c-4{fill:url(#lln-lg);}.lln-c-5{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.lln-c-6{fill:#fff;}</style>

