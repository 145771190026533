<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M124.55,284.45c0-73.54,59.51-133.36,132.65-133.36s132.65,59.82,132.65,133.36"/><g class="tss-c-4"><path class="tss-c-5" d="M124.75,284.65c0-1.34,0-2.67.06-4"/><path class="tss-c-6" d="M125,276.68c4.11-69.84,61.93-125.39,132.41-125.39,71.15,0,129.39,56.6,132.52,127.37"/><path class="tss-c-5" d="M390,280.65c0,1.33.06,2.66.06,4"/></g><g class="tss-c-7"><path class="tss-c-8" d="M124.35,284.25c0-1.34,0-2.67.06-4"/><path class="tss-c-9" d="M124.59,276.28C128.7,206.44,186.52,150.89,257,150.89c71.15,0,129.39,56.6,132.52,127.37"/><path class="tss-c-8" d="M389.59,280.25c0,1.33.06,2.66.06,4"/></g><path class="tss-c-10" d="M129.55,284.9v-.2c0-70.89,57.15-128.36,127.65-128.36S384.85,213.81,384.85,284.7v.2"/><path class="tss-c-11" d="M129.55,284.65v-.2c0-70.89,57.15-128.36,127.65-128.36s127.65,57.47,127.65,128.36v.2"/></g><g id="Шов_Цвет"><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M124.55,284.45c0-1.34,0-2.67.06-4"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M124.79,276.48c4.11-69.84,61.93-125.39,132.41-125.39,71.15,0,129.39,56.6,132.52,127.37"/><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M389.79,280.45c0,1.33.06,2.66.06,4"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-5,.tss-c-6{stroke:#000;}.tss-c-10,.tss-c-11,.tss-c-3{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-4{opacity:0.6;}.tss-c-12,.tss-c-13,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{stroke-linecap:round;stroke-width:1.14px;}.tss-c-13,.tss-c-6,.tss-c-9{stroke-dasharray:7.96 3.98;}.tss-c-7{opacity:0.7;}.tss-c-10,.tss-c-8,.tss-c-9{stroke:#fff;}.tss-c-11,.tss-c-12,.tss-c-13{stroke:#232728;}</style>

