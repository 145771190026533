<template>
<svg data-svg-lvl-3="edge-top-base" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tb-ng_2" x1="279.1" y1="191.91" x2="279.07" y2="187.05" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#989ba0"/><stop offset="0.3" stop-color="#fff"/><stop offset="0.83" stop-color="#3c3e45"/><stop offset="1" stop-color="#979a9f"/></linearGradient><linearGradient id="tb-ng_2-2" x1="280.49" y1="193.71" x2="280.44" y2="184.76" href="#tb-ng_2"/></defs><g id="Крышки_Подложка"><rect class="tb-c-3" x="274.59" y="186.61" width="9" height="6.15" rx="2"/><rect class="tb-c-4" x="281.54" y="182.51" width="9.18" height="13.95" rx="2"/><rect class="tb-c-5" x="279.4" y="183.94" width="2.14" height="11.36"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tb-c-1{opacity:0.3;}.tb-c-2{fill:#64ff00;}.tb-c-3,.tb-c-4,.tb-c-5{stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}.tb-c-3{fill:url(#tb-ng_2);}.tb-c-4{fill:#232728;}.tb-c-5{fill:url(#tb-ng_2-2);}</style>

