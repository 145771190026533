import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'
import { getDraggableAreaMm, getPosition } from '/src/utils/draggable'

export const cuttingPrint = {
  id: 'cuttingPrint',
  tName: 'personalizations.cuttingPrint.cuttingPrint',
  template: 'boolean',

  originPersonalizationsIds: [
    715, // Индивидуальная вырубка с УФ-печатью на пластике
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getCuttingPrint(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */

function getItemsBuhta({ modelValues }) {
  if (modelValues?.cuttingPrint?.checked) {
    const originPersonalizations = this.originPersonalizations
    const idBuhta = 715

    return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [])
  } else return []
}

/**
 * Выбор УФ-лака
 */

function getCuttingPrint(params) {
  const { store, oldModelValues, items, modelValues, originPersonalizations } = params

  let values = []

  if (originPersonalizations[715]) {
    values = [
      { id: 0, checked: true, r: 0, w: 58, h: 138 },
      { id: 1, checked: false },
    ]

    if (values.length > 0) {
      items.push({
        id: 'cuttingPrint',
        tName: 'personalizations.cuttingPrint.cuttingPrint',
        template: 'checkbox',
        sort: 10,
        values,
      })

      if (oldModelValues.cuttingPrint) {
        modelValues.cuttingPrint = values.find((i) => i.checked === oldModelValues.cuttingPrint.checked) ?? values[0]
        modelValues.cuttingPrint = {
          ...modelValues.cuttingPrint,
          ...oldModelValues.cuttingPrint,
        }
      } else {
        const draggableArea = getDraggableAreaMm(store)
        const draggablePosition = getPosition({ w: values[0].w, h: values[0].h }, draggableArea)
        modelValues.cuttingPrint = { ...values[0], ...draggablePosition }
      }
    }
  }
}
