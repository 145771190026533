<template>
<svg data-svg-lvl-3="edge-top-slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышка_Срез"><path :style="{ fill: params.sliceColor }"  class="tsl-c-3" d="M49.57,196.31H468a1.24,1.24,0,0,1,1.23,1.24v2a1.24,1.24,0,0,1-1.23,1.24H49.57c-3.76,0-16.52,12-24.56,16.61A11.4,11.4,0,0,1,19.15,219c-5.7,0-11.72.06-11.72,1s6.32.75,11.78.75c2.2,0,3.92,1.15,5.52,2.12,7.87,4.76,21.12,18.3,24.84,18.3H468a1.24,1.24,0,0,1,1.23,1.24v2a1.24,1.24,0,0,1-1.23,1.23l-418.39-.2c-7.27,0-25-21.38-31.4-21.38-1.22,0-5.59-.19-6.86-.19-4.51,0-7.42-.64-7.42-4,0-3.15,3.66-4.35,7.84-4.35,1.7,0,5.95-.4,7.55-.4C25.9,215.18,42.42,196.31,49.57,196.31Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsl-c-1{opacity:0.3;}.tsl-c-2{fill:#64ff00;}.tsl-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

