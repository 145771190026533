<template>
<svg data-svg-lvl-5="cover-top-draggableUpOrDown-draggable-cutting" xmlns="http://www.w3.org/2000/svg" viewBox="-69 -30 210.3 210.3" preserveAspectRatio="xMidYMid slice" :x="draggable.x" :y="draggable.y" :width="draggable.w" :height="draggable.h"><defs><mask id="tct-m-c" x="0" y="0" width="500" height="430" fill="#ffffff"><path  
  :style="{ transform: `rotate(${draggable.r}deg)` }"
  class="rotate"
    d="M70.55,148.43,58.22,61.53A34.91,34.91,0,1,0,.43,35.12a34.7,34.7,0,0,0,12,26.25L.26,148.43Z"/></mask></defs><g 
  :style="{ transform: `rotate(${draggable.r}deg)` }"
  class="rotate"
  ><g id="Вырубка_Маска"><path :style="{ fill: draggable.fromInner ? '#FFFFFF' : params.flyleaffrontColor }"  class="tct-c-1" d="M70.55,148.43,58.22,61.53A34.91,34.91,0,1,0,.43,35.12a34.7,34.7,0,0,0,12,26.25L.26,148.43Z"/></g> </g>  <image v-if="!draggable.fromInner && params.flyleaffrontImage"
    preserveAspectRatio="xMidYMid slice"
    :x="-69 - (210.3 / draggable.w) * draggable.x"
    :y="-30 - (210.3 / draggable.h) * draggable.y"
    :width="(210.3 / draggable.w) * 500"
    :height="(210.3 / draggable.h) * 430"
    :href="params.flyleaffrontImage" mask="url(#tct-m-c)" /> <g 
  :style="{ transform: `rotate(${draggable.r}deg)` }"
  class="rotate"
  ><g id="Вырубка_Блик" class="blur" ><g class="tct-c-2"><path class="tct-c-1" d="M11.48,60.52c.3.32.61.63.92.94v-.09C12.09,61.1,11.78,60.81,11.48,60.52Z"/><path class="tct-c-1" d="M60,63.31a34.77,34.77,0,0,0,1.06-51.88,34.75,34.75,0,0,1-2.83,50.1l12.33,86.9H.26L2,150.2H72.32Z"/></g></g><g id="Вырубка_Контур"><path class="tct-c-3" d="M70.55,148.43,58.22,61.53A34.91,34.91,0,1,0,.43,35.12a34.7,34.7,0,0,0,12,26.25L.26,148.43Z"/><g class="tct-c-4"><path class="tct-c-5" d="M61.62,12.05A34.91,34.91,0,1,0,12.23,61.21l.17.2-12.14,87"/><path class="tct-c-6" d="M61.61,12.05A35,35,0,0,0,.71,35.39,34.62,34.62,0,0,0,12.2,61.17l.48.51L.57,148.43"/><path class="tct-c-7" d="M61.61,12.05A34.95,34.95,0,0,0,1,35.66,34.53,34.53,0,0,0,12.16,61.13L13,62,.88,148.43"/><path class="tct-c-8" d="M61.61,12.05A34.93,34.93,0,0,0,1.25,35.93,34.49,34.49,0,0,0,12.13,61.1l1.09,1.12-12,86.21"/><path class="tct-c-9" d="M61.61,12.05A34.92,34.92,0,0,0,1.52,36.21,34.44,34.44,0,0,0,12.1,61.06l1.4,1.43-12,85.94"/><path class="tct-c-10" d="M61.61,12.05A34.91,34.91,0,0,0,1.8,36.48,34.38,34.38,0,0,0,12.07,61l1.7,1.74L1.82,148.43"/><path class="tct-c-11" d="M61.6,12.05A34.91,34.91,0,0,0,2.07,36.75,34.35,34.35,0,0,0,12,61L14,63,2.13,148.43"/><path class="tct-c-12" d="M61.6,12.05A34.91,34.91,0,0,0,2.34,37,34.39,34.39,0,0,0,12,61l2.32,2.35L2.44,148.43"/><path class="tct-c-13" d="M61.6,12.05a34.92,34.92,0,0,0-59,25.25A34.42,34.42,0,0,0,12,60.91l2.62,2.65L2.75,148.43"/><path class="tct-c-14" d="M61.6,12.05A34.93,34.93,0,0,0,2.89,37.57a34.45,34.45,0,0,0,9,23.3l2.93,3-11.8,84.6"/><path class="tct-c-15" d="M61.6,12.05A35,35,0,0,0,3.16,37.84a34.51,34.51,0,0,0,8.74,23l3.23,3.27L3.37,148.43"/><path class="tct-c-16" d="M61.6,12.05A35,35,0,0,0,3.43,38.12,34.64,34.64,0,0,0,11.87,60.8l3.54,3.57L3.68,148.43"/></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, draggable: { type: Object, required: true }, }, data() { return {} },}
</script>

<style scoped>
  .rotate {
    transform-box: fill-box;
    transform-origin: center;
  }
  .blur {
    filter: blur(1px);
  }
  .tct-c-1{fill:#fff;}.tct-c-2{opacity:0.5;}.tct-c-10,.tct-c-11,.tct-c-12,.tct-c-13,.tct-c-14,.tct-c-15,.tct-c-16,.tct-c-3,.tct-c-5,.tct-c-6,.tct-c-7,.tct-c-8,.tct-c-9{fill:none;}.tct-c-3{stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}.tct-c-4{opacity:0.3;}.tct-c-10,.tct-c-11,.tct-c-12,.tct-c-13,.tct-c-14,.tct-c-15,.tct-c-16,.tct-c-5,.tct-c-6,.tct-c-7,.tct-c-8,.tct-c-9{stroke:#000;stroke-miterlimit:10;stroke-width:0.5px;}.tct-c-6{opacity:0.91;}.tct-c-7{opacity:0.82;}.tct-c-8{opacity:0.73;}.tct-c-9{opacity:0.64;}.tct-c-10{opacity:0.55;}.tct-c-11{opacity:0.45;}.tct-c-12{opacity:0.36;}.tct-c-13{opacity:0.27;}.tct-c-14{opacity:0.18;}.tct-c-15{opacity:0.09;}.tct-c-16{opacity:0;}</style>

