<template>
<svg data-svg-lvl-3="spread-back-plastic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bpl-lg" x1="106.26" y1="90.79" x2="106.26" y2="355.97" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4d4d4d" stop-opacity="0.3"/><stop offset="0.59" stop-color="#4d4d4d" stop-opacity="0.32"/><stop offset="0.82" stop-color="#4d4d4d" stop-opacity="0.41"/><stop offset="0.98" stop-color="#4d4d4d" stop-opacity="0.57"/><stop offset="1" stop-color="#4d4d4d" stop-opacity="0.6"/></linearGradient><clipPath id="bpl-cp"><path class="bpl-c-1" d="M73.46,90.13a12,12,0,0,0-12,12V344.24a12,12,0,0,0,12,12h77.6V90.13Z"/></clipPath><linearGradient id="bpl-lg-2" x1="215" y1="223.18" x2="217.98" y2="223.18" gradientTransform="matrix(1, 0, 0, -1, -66.88, 446.36)" gradientUnits="userSpaceOnUse"><stop offset="0"/><stop offset="1" stop-opacity="0"/></linearGradient></defs><g id="Комбинация_ЗадникМаска"><path class="bpl-c-4" d="M73.46,90.13a12,12,0,0,0-12,12V344.24a12,12,0,0,0,12,12h77.6V90.13Z"/></g><g id="Комбинация_Задник"><path class="bpl-c-5" d="M73.46,90.13a12,12,0,0,0-12,12V344.24a12,12,0,0,0,12,12h77.6V90.13Z"/><g class="bpl-c-6"><image width="514" height="422" transform="translate(0 4)" :href="`${$CONST.cnstrAssetsUrl}/others/93BB-Ice-print.webp`"/></g><rect class="bpl-c-7" x="148.06" y="90.13" width="3" height="266.11" transform="translate(299.12 446.36) rotate(-180)"/><path class="bpl-c-8" d="M73.46,90.13a12,12,0,0,0-12,12V344.24a12,12,0,0,0,12,12h77.6V90.13Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bpl-c-1,.bpl-c-8{fill:none;}.bpl-c-2{opacity:0.3;}.bpl-c-3{fill:#64ff00;}.bpl-c-4{fill:#fff;}.bpl-c-5{fill:url(#bpl-lg);}.bpl-c-6{clip-path:url(#bpl-cp);}.bpl-c-7{opacity:0.4;fill:url(#bpl-lg-2);}.bpl-c-8{stroke:#232728;stroke-linejoin:round;stroke-width:0.75px;}</style>

