<template>
<svg data-svg-lvl-3="cover-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Задник_Контур"><rect class="bc-c-3" x="49.43" y="129.33" width="415.15" height="188.57"/><rect class="bc-c-4" x="162.57" y="16.18" width="188.36" height="414.65" transform="translate(480.26 -33.24) rotate(90)"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1,.bc-c-4{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

