import lodash from 'lodash'
import { colors, colorsHex } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const lasse = {
  id: 'lasse',
  // tName:  'personalizations.lasse.lasse',
  template: 'modal',
  preview: 'spread',

  originPersonalizationsIds: [
    // Одно ляссе
    156, // Вклейка индивидуального ляссе
    135, // Печать и вклейка индивидуального ляссе 1 краска
    136, // Печать и вклейка индивидуального ляссе 2 краски

    // Два ляссе
    584, // Вклейка двух индивидуальных ляссе
    157, // Печать и вклейка двух индивидуальных ляссе 1 краска
    158, // Печать и вклейка двух индивидуальных ляссе 2 краски

    // Шильды
    566, // Двухсторонний логопринт на ляссе
    592, // Металлическая шильда на ляссе_ГРАВИРОВКА (одна сторона)
    743, // Металлическая шильда на ляссе_ГРАВИРОВКА (две стороны)
    602, // Металлическая шильда на ляссе_УФ печать (одна сторона)
    742, // Металлическая шильда на ляссе_УФ печать (две стороны)
    669, // Шильда из оргстекла  на ляссе с УФ печатью
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getLasse(params)
  getNameplate(params)
  getDrawing(params)
  getSidesQty(params)
  getForm(params)
  getNameplateColor(params)

  getPrinting(params)
  getColor(params)
  getPrinting2(params)
  getColor2(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  let idParam
  const idValue = modelValues?.color?.id
  let idParamAdd
  const idValueAdd = modelValues?.color2?.id

  let items = []

  const keyNameplate = modelValues?.nameplate?.id
  const keyDrawing = modelValues?.drawing?.id
  const keySidesQty = modelValues?.sidesQty?.id
  const keyLasse = modelValues?.lasse?.id
  const keyPrinting = modelValues?.printing?.id
  const keyPrinting2 = modelValues?.printing2?.id
  const keyForm = modelValues?.form?.id
  const keyNameplateColor = modelValues?.nameplateColor?.id

  if (keyLasse && keyPrinting) {
    if (keyLasse === 'single') {
      idParam = 111
      if (keyPrinting === 'withoutPrinting') {
        idBuhta = 156
      } else if (keyPrinting === 'oneColor') {
        idBuhta = 135
      } else if (keyPrinting === 'twoColors') {
        idBuhta = 136
      }
    } else if (keyLasse === 'double') {
      idParam = 54
      idParamAdd = 59
      if (keyPrinting === 'twoColors' || keyPrinting2 === 'twoColors') {
        idBuhta = 158
      } else if (keyPrinting === 'oneColor' || keyPrinting2 === 'oneColor') {
        idBuhta = 157
      } else if (keyPrinting === 'withoutPrinting' || keyPrinting2 === 'withoutPrinting') {
        idBuhta = 584
      }
    }

    if (!(keyLasse === 'single' && keyPrinting === 'withoutPrinting' && keyNameplate !== 'without')) {
      items = getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
        {
          idParam,
          idValue,
        },
        {
          idParam: idParamAdd,
          idValue: idValueAdd,
        },
      ])
    }
  }

  let idBuhta2

  const idParam2 = 54
  const idValue2 = modelValues?.color?.id

  let idParam2Add
  let idValue2Add

  let idWork
  let value
  if (keyNameplate) {
    if (keyNameplate === 'twosided') {
      idBuhta2 = 566
      idParam2Add = 83
      idValue2Add = keyForm
    } else if (keyNameplate === 'plexiglass') {
      idBuhta2 = 669
    } else if (keyNameplate === 'metal') {
      idParam2Add = 87
      if (keyDrawing === 'engraving') {
        if (keySidesQty === 'withoutSides') {
          idBuhta2 = 592
          idWork = 1431
          value = false
        } else if (keySidesQty === 'oneSide') {
          idBuhta2 = 592
          idWork = 1431
          value = true
        } else if (keySidesQty === 'bothSides') {
          idBuhta2 = 743
        }
      } else if (keyDrawing === 'uvPrinting') {
        if (keySidesQty === 'oneSide') {
          idBuhta2 = 602
        } else if (keySidesQty === 'bothSides') {
          idBuhta2 = 742
        }
      }
      if (keyNameplateColor === 'gold') {
        if (keyForm === 'square') {
          idValue2Add = 3247
        } else if (keyForm === 'circle') {
          idValue2Add = 3249
        } else if (keyForm === 'rect') {
          idValue2Add = 3251
        }
      } else if (keyNameplateColor === 'silver') {
        if (keyForm === 'square') {
          idValue2Add = 3248
        } else if (keyForm === 'circle') {
          idValue2Add = 3250
        } else if (keyForm === 'rect') {
          idValue2Add = 3252
        }
      }
    }
  }

  return items.concat(
    getItemsBuhtaByConfig(originPersonalizations, idBuhta2, [
      {
        idParam: idParam2,
        idValue: idValue2,
      },
      {
        idParam: idParam2Add,
        idValue: idValue2Add,
      },
      {
        idWork,
        value,
      },
    ]),
  )
}

/**
 * Выбор ляссе(Индивидуальное ляссе/ Двойное индивидуальное ляссе)
 */
function getLasse(params) {
  const { oldModelValues, items, modelValues, originIdsAvailable } = params

  const values = []
  const single = [156, 135, 136, 566, 592, 743, 602, 742, 669]
  if (lodash.intersection(originIdsAvailable, single).length > 0) {
    values.push({
      id: 'single',
      tName: 'personalizations.lasse.single',
    })
  }
  const double = [584, 157, 158]
  if (lodash.intersection(originIdsAvailable, double).length > 0) {
    values.push({
      id: 'double',
      tName: 'personalizations.lasse.double',
    })
  }

  if (values.length > 0) {
    items.push({
      id: 'lasse',
      tName: 'personalizations.lasse.lasse',
      template: 'selectTab',
      sort: 10,
      hideHr: true,
      values,
    })

    if (oldModelValues.lasse) {
      modelValues.lasse = values.find((i) => i.id === oldModelValues.lasse.id) ?? values[0]
    } else {
      modelValues.lasse = values[0]
    }
  }
}

/**
 * Выбор шильды
 */
function getNameplate(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  if (modelValues.lasse?.id === 'double') {
    return
  }

  const personals = {
    without: [156, 135, 136 /* 584, 157, 158 */],
    twosided: [566],
    metal: [592, 743, 602, 742],
    plexiglass: [669],
  }

  const values = []
  for (const key in personals) {
    if (lodash.intersection(originIdsAvailable, personals[key]).length > 0) {
      values.push({
        id: key,
        tName: `personalizations.lasse.${key}`,
        img: `${cnstrAssetsUrl}/personalizations/lasse/${key}.webp`,
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'nameplate',
      tName: 'personalizations.lasse.nameplate',
      template: 'selectClassicImage',
      sort: 60,
      values,
    })

    if (oldModelValues.nameplate) {
      modelValues.nameplate = values.find((i) => i.id === oldModelValues.nameplate.id) ?? values[0]
    } else {
      modelValues.nameplate = values[0]
    }
  }
}

/**
 * Выбор типа нанесения
 */
function getDrawing(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originPersonalizations,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  if (modelValues?.nameplate?.id !== 'metal') {
    return
  }

  const values = []
  let id
  if (originPersonalizations[592] || originPersonalizations[743]) {
    id = 'engraving'
    values.push({
      id,
      tName: `personalizations.lasse.${id}`,
      img: `${cnstrAssetsUrl}/personalizations/lasse/${id}.webp`,
    })
  }
  if (originPersonalizations[602] || originPersonalizations[742]) {
    id = 'uvPrinting'
    values.push({
      id,
      tName: `personalizations.lasse.${id}`,
      img: `${cnstrAssetsUrl}/personalizations/lasse/${id}.webp`,
    })
  }

  if (values.length > 0) {
    items.push({
      id: 'drawing',
      tName: 'personalizations.lasse.drawing',
      template: 'selectClassicImage',
      mod: 'aboveSelect',
      sort: 90,
      values,
    })

    if (oldModelValues.drawing) {
      modelValues.drawing = values.find((i) => i.id === oldModelValues.drawing.id) ?? values[0]
    } else {
      modelValues.drawing = values[0]
    }
  }
}

/**
 * Выбор количества сторон гравировки/УФ-печати
 */
function getSidesQty(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  if (
    modelValues.drawing === undefined ||
    !(modelValues.drawing?.id === 'uvPrinting' || modelValues.drawing?.id === 'engraving')
  ) {
    return
  }
  let values = []
  if (modelValues.drawing?.id === 'engraving') {
    if (originPersonalizations[592]) {
      values.push({ id: 'withoutSides', tName: `personalizations.lasse.withoutSides`, idBuhta: 592, idWork: 333 })
      values.push({ id: 'oneSide', tName: `personalizations.lasse.oneSide`, idBuhta: 592, idWork: 333 })
    }
    if (originPersonalizations[743]) {
      values.push({ id: 'bothSides', tName: `personalizations.lasse.bothSides`, idBuhta: 743 })
    }
  } else if (modelValues.drawing?.id === 'uvPrinting') {
    if (originPersonalizations[602]) {
      values.push({ id: 'oneSide', tName: `personalizations.lasse.oneSide`, idBuhta: 602 })
    }
    if (originPersonalizations[742]) {
      values.push({ id: 'bothSides', tName: `personalizations.lasse.bothSides`, idBuhta: 742 })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'sidesQty',
      tName: 'personalizations.lasse.sidesQty',
      template: 'selectRadio',
      sort: 100,
      values,
    })

    // Дефолтная сторона - на одной стороне
    let defaultIndex = values.findIndex((n) => n.id === 'oneSide')
    if (oldModelValues.sidesQty) {
      modelValues.sidesQty = values.find((i) => i.id === oldModelValues.sidesQty.id) ?? values[defaultIndex]
    } else {
      modelValues.sidesQty = values[defaultIndex]
    }
  }
}

/**
 * Выбор формы(для Металлическая шильда/Двухсторонний логопринт)
 */
function getForm(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originPersonalizations,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  let idBuhta
  let idParam
  if (modelValues.drawing?.id === 'engraving' || modelValues.drawing?.id === 'uvPrinting') {
    idBuhta = modelValues.sidesQty.idBuhta
    idParam = 87
  } else if (modelValues?.nameplate?.id === 'twosided') {
    idBuhta = 566
    idParam = 83
  } else {
    return
  }

  let values = []

  if (originPersonalizations[idBuhta]) {
    const param = originPersonalizations[idBuhta].params.find((i) => i.id === idParam)

    if (modelValues.nameplate?.id === 'twosided') {
      if (param?.values?.length) {
        values = param.values.map((n) => ({
          ...n,
          img: `${cnstrAssetsUrl}/personalizations/lasse/${n.id}.webp`,
        }))
      }
    } else {
      if (param.values.find((p) => p.id === 3247 || p.id === 3248)) {
        if (!values.find((k) => k.id === 'square')) {
          values.push({
            id: 'square',
            tName: `personalizations.lasse.square`,
            img: `${cnstrAssetsUrl}/personalizations/lasse/square.webp`,
          })
        }
      }

      if (param.values.find((p) => p.id === 3249 || p.id === 3250)) {
        if (!values.find((k) => k.id === 'circle')) {
          values.push({
            id: 'circle',
            tName: `personalizations.lasse.circle`,
            img: `${cnstrAssetsUrl}/personalizations/lasse/circle.webp`,
          })
        }
      }
      if (param.values.find((p) => p.id === 3251 || p.id === 3252)) {
        if (!values.find((k) => k.id === 'rect')) {
          values.push({
            id: 'rect',
            tName: `personalizations.lasse.rect`,
            img: `${cnstrAssetsUrl}/personalizations/lasse/rect.webp`,
          })
        }
      }
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'form',
      tName: 'personalizations.lasse.form',
      template: 'selectClassicImage',
      sort: 70,
      values,
    })

    if (oldModelValues.form) {
      modelValues.form = values.find((i) => i.id === oldModelValues.form.id) ?? values[0]
    } else {
      modelValues.form = values[0]
    }
  }
}

/**
 * Выбор цвета металлической шильды
 */
function getNameplateColor(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originPersonalizations,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  let idBuhta
  const idParam = 87

  if (modelValues.drawing?.id === 'uvPrinting' || modelValues.drawing?.id === 'engraving') {
    idBuhta = modelValues.sidesQty.idBuhta
  } else {
    return
  }

  const values = []

  if (originPersonalizations[idBuhta]) {
    let id

    const param = originPersonalizations[idBuhta].params.find((i) => i.id === idParam)

    if (param.values.find((p) => p.id === 3247 || p.id === 3249 || p.id === 3251)) {
      if (!values.find((k) => k.id === 'gold')) {
        values.push({
          id: 'gold',
          tName: `personalizations.lasse.gold`,
          background: colors[32],
          hex: colorsHex[32],
        })
      }
    }

    if (param.values.find((p) => p.id === 3248 || p.id === 3250 || p.id === 3252)) {
      if (!values.find((k) => k.id === 'silver')) {
        values.push({
          id: 'silver',
          tName: `personalizations.lasse.silver`,
          background: colors[33],
          hex: colorsHex[33],
        })
      }
    }

    if (id) {
      values.push({
        id,
        tName: `personalizations.lasse.${id}`,
        img: `${cnstrAssetsUrl}/personalizations/lasse/${id}.webp`,
        idBuhta,
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'nameplateColor',
      tName: 'personalizations.lasse.nameplateColor',
      template: 'selectClassicColor',
      sort: 80,
      values,
    })

    if (oldModelValues.nameplateColor) {
      modelValues.nameplateColor = values.find((i) => i.id === oldModelValues.nameplateColor.id) ?? values[0]
    } else {
      modelValues.nameplateColor = values[0]
    }
  }
}

/**
 * Выбор печати
 */
function getPrinting(params) {
  const { oldModelValues, items, modelValues, originIdsAvailable } = params
  const values = []

  let idsBuhta

  if (modelValues.lasse.id === 'single') {
    idsBuhta = {
      withoutPrinting: [156],
      oneColor: [135],
      twoColors: [136],
    }
  } else if (modelValues.lasse.id === 'double') {
    idsBuhta = {
      withoutPrinting: [584],
      oneColor: [157],
      twoColors: [158],
    }
  } else {
    return
  }

  for (const key in idsBuhta) {
    if (lodash.intersection(originIdsAvailable, idsBuhta[key]).length > 0) {
      values.push({
        id: key,
        tName: `personalizations.lasse.${key}`,
        idBuhta: idsBuhta[key][0],
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'printing',
      tName: 'personalizations.lasse.printing',
      template: 'selectRadio',
      sort: 30,
      values,
    })

    if (oldModelValues.printing) {
      modelValues.printing = values.find((i) => i.id === oldModelValues.printing.id) ?? values[0]
    } else {
      modelValues.printing = values[0]
    }
  }
}

/**
 * Выбор цвета ляссе
 */
function getColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let idBuhta
  let idParam

  if (modelValues?.nameplate?.id === 'twosided') {
    idBuhta = 566
    idParam = 54
  } else if (modelValues?.nameplate?.id === 'metal') {
    if (modelValues.drawing.id === 'engraving') {
      if (modelValues.sidesQty.id === 'withoutSides' || modelValues.sidesQty.id === 'oneSide') {
        idBuhta = 592
      } else if (modelValues.sidesQty.id === 'bothSides') {
        idBuhta = 743
      } else {
        return
      }
      idParam = 54
    } else if (modelValues.drawing.id === 'uvPrinting') {
      if (modelValues.sidesQty.id === 'oneSide') {
        idBuhta = 602
      } else if (modelValues.sidesQty.id === 'bothSides') {
        idBuhta = 742
      } else {
        return
      }
      idParam = 54
    } else {
      return
    }
  } else if (modelValues?.nameplate?.id === 'plexiglass') {
    idBuhta = 669
    idParam = 54
  } else {
    idBuhta = modelValues.printing.idBuhta
    if (modelValues.lasse.id === 'single') {
      idParam = 111
    } else if (modelValues.lasse.id === 'double') {
      idParam = 54
    } else {
      return
    }
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.lasse.color',
      template: 'selectClassicColor',
      sort: 20,
      values,
    })
    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[0]
    } else {
      modelValues.color = values[0]
    }
  }
}

/**
 * Выбор печати второго ляссе
 */
function getPrinting2(params) {
  const { oldModelValues, items, modelValues, originIdsAvailable } = params
  const values = []

  let idsBuhta
  if (modelValues.lasse.id === 'double') {
    idsBuhta = {
      withoutPrinting: [584],
      oneColor: [157],
      twoColors: [158],
    }
  } else {
    return
  }

  for (const key in idsBuhta) {
    if (lodash.intersection(originIdsAvailable, idsBuhta[key]).length > 0) {
      values.push({
        id: key,
        tName: `personalizations.lasse.${key}`,
        idBuhta: idsBuhta[key][0],
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'printing2',
      tName: 'personalizations.lasse.printing2',
      template: 'selectRadio',
      sort: 50,
      values,
    })

    if (oldModelValues.printing2) {
      modelValues.printing2 = values.find((i) => i.id === oldModelValues.printing2.id) ?? values[0]
    } else {
      modelValues.printing2 = values[0]
    }
  }
}

/**
 * Выбор цвета ляссе
 */
function getColor2(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let idParam

  const idBuhta = modelValues.printing.idBuhta
  if (modelValues.lasse.id === 'double') {
    idParam = 59
  } else {
    return
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color2',
      tName: 'personalizations.lasse.color2',
      template: 'selectClassicColor',
      sort: 40,
      values,
    })
    if (oldModelValues.color2) {
      modelValues.color2 = values.find((i) => i.id === oldModelValues.color2.id) ?? values[0]
    } else {
      modelValues.color2 = values[0]
    }
  }
}
