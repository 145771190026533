<template>
<svg data-svg-lvl-3="edge-top-slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышка_Срез"><path :style="{ fill: params.sliceColor }"  class="tsl-c-3" d="M469.6,193.77l-184.35-12.4c-.85,0-1.54,7.49-1.54,8.34s.69,7.15,1.54,7.15H469.6c10.67,0,16.54,9.56,16.54,23.14,0,13.32-6.29,21.16-16.7,21.16H49.73C47,241.16,36,237.25,36,221.9s11.93-21,13.86-21h419.8a1.55,1.55,0,0,0,0-3.09h-420c-4.27,0-17.12,7.85-17.12,24.1s12.69,22.35,17,22.35H469.65c12,0,19.58-8.36,19.58-24.25C489.23,203.85,482.18,193.77,469.6,193.77Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsl-c-1{opacity:0.3;}.tsl-c-2{fill:#64ff00;}.tsl-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

