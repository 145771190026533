<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M259.5,316.5a158.25,158.25,0,0,1,0-180.28"/><path class="tss-c-4" d="M259.5,307a153.12,153.12,0,0,1,0-161.2"/><path class="tss-c-5" d="M259.5,307.38a153.13,153.13,0,0,1,0-162"/></g><g id="Шов_Цвет"><g class="tss-c-6"><path :style="{ stroke: params.seamColor }"  class="tss-c-7" d="M259.7,316.7c-.76-1.09-1.51-2.2-2.25-3.31"/><path :style="{ stroke: params.seamColor }"  class="tss-c-8" d="M255.29,310a158.36,158.36,0,0,1,1.09-168.63"/><path :style="{ stroke: params.seamColor }"  class="tss-c-7" d="M257.46,139.74c.73-1.12,1.48-2.22,2.24-3.32"/></g><g class="tss-c-9"><path class="tss-c-10" d="M259.3,316.3c-.76-1.09-1.51-2.2-2.25-3.31"/><path class="tss-c-11" d="M254.89,309.63A158.36,158.36,0,0,1,256,141"/><path class="tss-c-10" d="M257.06,139.34c.73-1.12,1.48-2.22,2.24-3.32"/></g><path class="tss-c-12" d="M259.5,316.5c-.76-1.09-1.51-2.2-2.25-3.31"/><path class="tss-c-13" d="M255.09,309.83a158.36,158.36,0,0,1,1.09-168.63"/><path class="tss-c-12" d="M257.26,139.54c.73-1.12,1.48-2.22,2.24-3.32"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-4,.tss-c-5,.tss-c-7,.tss-c-8{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-7,.tss-c-8{stroke:#000;}.tss-c-3,.tss-c-4,.tss-c-5{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-10,.tss-c-11,.tss-c-4{stroke:#fff;}.tss-c-12,.tss-c-13,.tss-c-5{stroke:#232728;}.tss-c-6{opacity:0.6;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-7,.tss-c-8{stroke-linecap:round;stroke-width:1.14px;}.tss-c-11,.tss-c-13,.tss-c-8{stroke-dasharray:7.99 4;}.tss-c-9{opacity:0.7;}</style>

