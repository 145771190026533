<template>
<svg data-svg-lvl-3="flyleafend-block-flyleafend" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Нахзац"><rect class="lfe-c-3" x="67.39" y="86.62" width="189.87" height="261.48"/><rect class="lfe-c-3" x="269.1" y="93.63" width="178.03" height="259.1" transform="translate(716.22 446.36) rotate(-180)"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lfe-c-1{opacity:0.3;}.lfe-c-2{fill:#64ff00;}.lfe-c-3{fill:#f4e8ce;}</style>

