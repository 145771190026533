<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M193.17,331.11l64.21-92.55c3.1-4.46,3.1-7.53,3.1-12.17s-.21-8-3.1-12.15l-63.23-91"/><g class="tss-c-4"><line class="tss-c-5" x1="193.37" y1="331.31" x2="195.65" y2="328.02"/><path class="tss-c-6" d="M197.9,324.77l59.68-86c3.1-4.46,3.1-7.53,3.1-12.17s-.21-8-3.1-12.15L197.76,128.3"/><line class="tss-c-5" x1="196.63" y1="126.68" x2="194.35" y2="123.4"/></g><g class="tss-c-7"><line class="tss-c-8" x1="192.97" y1="330.91" x2="195.25" y2="327.62"/><path class="tss-c-9" d="M197.5,324.37l59.68-86c3.1-4.46,3.1-7.53,3.1-12.17s-.21-8-3.1-12.15L197.36,127.9"/><line class="tss-c-8" x1="196.23" y1="126.28" x2="193.95" y2="123"/></g><path class="tss-c-10" d="M199.5,331.11l62.24-89.7c4-5.74,4-10.06,4-15s-.21-9.56-4-15l-61.26-88.2"/><path class="tss-c-11" d="M199.25,331.11l62.24-89.7c4-5.74,4-10.06,4-15s-.21-9.56-4-15l-61.26-88.2"/></g><g id="Шов_Цвет"><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="193.17" y1="331.11" x2="195.45" y2="327.82"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M197.7,324.57l59.68-86c3.1-4.46,3.1-7.53,3.1-12.17s-.21-8-3.1-12.15L197.56,128.1"/><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="196.43" y1="126.48" x2="194.15" y2="123.2"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-5,.tss-c-6{stroke:#000;}.tss-c-10,.tss-c-11,.tss-c-3{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-4{opacity:0.6;}.tss-c-12,.tss-c-13,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{stroke-linecap:round;stroke-width:1.14px;}.tss-c-13,.tss-c-6,.tss-c-9{stroke-dasharray:7.9 3.95;}.tss-c-7{opacity:0.7;}.tss-c-10,.tss-c-8,.tss-c-9{stroke:#fff;}.tss-c-11,.tss-c-12,.tss-c-13{stroke:#232728;}</style>

