<template>
<svg data-svg-lvl-3="cover-block-captal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
  <defs>
    
  </defs>

  <g id="Каптал">
    <path :style="{ fill: params.captalColor }"  class="lcp-c-3"
      d="M113.98,29.33l9.4-9.4c.39-.39,.93-.62,1.48-.62h6.68l-10.69,10.87h-6.52c-.45,0-.67-.54-.35-.85Z" />
  </g>
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>
      .lcp-c-3 {
        fill: #fff;
        stroke: #232728;
        stroke-miterlimit: 2;
        stroke-width: .46px;
      }
    </style>

