<template>
<svg data-svg-lvl-3="edge-top-flap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tf-lg" x1="461.72" y1="220.92" x2="478.12" y2="220.92" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#383838"/><stop offset="1" stop-color="#545454"/></linearGradient><radialGradient id="tf-rg" cx="17739.13" cy="-1811.04" r="13.35" gradientTransform="translate(2205.79 17925.47) rotate(-90)" gradientUnits="userSpaceOnUse"> <template v-if="params.flap.buttonColor === '#A31A30'">
  <!-- Бордовый -->
  <stop offset="0" stop-color="#debac0" />
  <stop offset=".4" stop-color="#a31a30" />
  <stop offset=".78" stop-color="#990019" />
  <stop offset=".83" stop-color="#9a071f" />
  <stop offset=".89" stop-color="#9d1c32" />
  <stop offset=".97" stop-color="#a33e50" />
  <stop offset="1" stop-color="#a54b5b" />
</template>
<template v-else-if="params.flap.buttonColor === '#191919'">
  <!-- Черный -->
  <stop offset="0" stop-color="#dedede" />
  <stop offset=".4" stop-color="#000" />
  <stop offset=".78" stop-color="#191919" />
  <stop offset=".81" stop-color="#202020" />
  <stop offset=".85" stop-color="#353535" />
  <stop offset=".91" stop-color="#585858" />
  <stop offset=".97" stop-color="#878787" />
  <stop offset="1" stop-color="#a5a5a5" />
</template>
<template v-else-if="params.flap.buttonColor === '#1E2F87'">
  <!-- Синий -->
  <stop offset="0" stop-color="#bac0de" />
  <stop offset=".4" stop-color="#1e2f87" />
  <stop offset=".78" stop-color="#1e2f87" />
  <stop offset=".84" stop-color="#25368c" />
  <stop offset=".94" stop-color="#3a4a9a" />
  <stop offset="1" stop-color="#4b5aa5" />
</template>

<template v-else-if="params.flap.buttonColor === '#808080'">
  <!--  Серый -->
  <stop offset="0" stop-color="#dedede" />
  <stop offset=".4" stop-color="gray" />
  <stop offset=".78" stop-color="#666" />
  <stop offset=".83" stop-color="#6d6d6d" />
  <stop offset=".91" stop-color="#828282" />
  <stop offset="1" stop-color="#a4a4a4" />
  <stop offset="1" stop-color="#a5a5a5" />
</template>
<template v-else-if="params.flap.buttonColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#fbffff" />
  <stop offset=".47" stop-color="#c3a22a" />
  <stop offset=".7" stop-color="#9c6b00" />
  <stop offset=".83" stop-color="#6c4209" />
  <stop offset="1" stop-color="#efd691" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#fff" />
  <stop offset=".78" stop-color="#363636" />
  <stop offset=".81" stop-color="#3d3d3d" />
  <stop offset=".86" stop-color="#525252" />
  <stop offset=".92" stop-color="#757575" />
  <stop offset=".99" stop-color="#a4a4a4" />
  <stop offset="1" stop-color="#ababab" />
</template> </radialGradient><radialGradient id="tf-rg-2" cx="17736.72" cy="-1799.67" r="10.23" gradientTransform="translate(2205.79 17925.47) rotate(-90)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff"/><stop offset="0.78" stop-color="#fff" stop-opacity="0"/></radialGradient></defs><g id="Хлястик_Контур"><path :style="{ fill: params.flap.sliceColor }"  class="tf-c-3" d="M469.19,197.7v1.84a1.24,1.24,0,0,1-1.23,1.24h-5v.36h0v1.8h-.25v1.81h-.15v1.8h-.16v3.62h-.15V212h-.22v1.81h-.19v1.8h-.14v3.61h.14v3.62H462v3.61h.16v1.8h.17V230h-.17v1.8h.17v3.62h.29v1.8h.19v1.81h.2v1.8h0v.36h5a1.24,1.24,0,0,1,1.23,1.24v1.68c4.17-3.42,8.93-10.37,8.93-23.64C478.12,207.48,473.34,200.9,469.19,197.7Z"/><path class="tf-c-4" d="M462.78,191.21H381.42a1.85,1.85,0,0,0,0,3.7H468a1.24,1.24,0,0,1,1.23,1.24v1.55c4.15,3.2,8.93,9.78,8.93,22.8,0,13.27-4.76,20.22-8.93,23.64v1.71a1.24,1.24,0,0,1-1.23,1.24H381.42a1.85,1.85,0,0,0,0,3.7h81.64c4.91,0,18.77-6,18.77-30.42S467.36,191.21,462.78,191.21Z"/></g><g id="Кнопки_Градиент"><path class="tf-c-5" d="M398.33,185.52c5.17,0,9.36,1.72,9.36,4.15,0,1.54-4.19,1.54-9.36,1.54s-9.36.08-9.36-1.77C389,186.81,393.16,185.52,398.33,185.52Z"/></g><g id="Кнопки_Контур"><path class="tf-c-6" d="M398.33,185.52c5.17,0,9.36,1.72,9.36,4.15,0,1.54-4.19,1.54-9.36,1.54s-9.36.08-9.36-1.77C389,186.81,393.16,185.52,398.33,185.52Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tf-c-1{opacity:0.3;}.tf-c-2{fill:#64ff00;}.tf-c-3,.tf-c-4,.tf-c-5,.tf-c-6{stroke:#232728;stroke-miterlimit:2;}.tf-c-3,.tf-c-4{stroke-width:1.14px;}.tf-c-3{fill:url(#tf-lg);}.tf-c-4{fill:gray;}.tf-c-5,.tf-c-6{stroke-width:1.14px;}.tf-c-5{fill:url(#tf-rg);}.tf-c-6{opacity:0.8;fill:url(#tf-rg-2);}</style>

