<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="bc-cp"><path class="bc-c-1" d="M450.43,204.49h-387V68.57a5.5,5.5,0,0,1,5.5-5.5h376a5.5,5.5,0,0,1,5.5,5.5Z"/></clipPath><clipPath id="bc-cp-2"><path class="bc-c-1" d="M63.44,44.57V426H450.37V44.57a5.5,5.5,0,0,0-5.5-5.5H68.94A5.5,5.5,0,0,0,63.44,44.57Z"/></clipPath></defs><g id="Задник_Контур"><path class="bc-c-4" d="M445.09,381.33H68.91a7.5,7.5,0,0,1-7.5-7.5V204.52H452.59V373.83A7.5,7.5,0,0,1,445.09,381.33Z"/><path class="bc-c-4" d="M452.59,204.52H61.41V68.17a7.5,7.5,0,0,1,7.5-7.5H445.09a7.5,7.5,0,0,1,7.5,7.5Z"/><g class="bc-c-5"><g class="bc-c-6"><image width="1024" height="1024" transform="translate(63.44 39.07) scale(0.38)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/spread/back/contour/PH2V_1.webp`"/></g></g><path class="bc-c-7" d="M446.32,204.49h4.11V68.57a5.5,5.5,0,0,0-5.5-5.5H69a5.5,5.5,0,0,0-5.5,5.5V204.49h4.1"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1,.bc-c-7{fill:none;}.bc-c-2{opacity:0.3;}.bc-c-3{fill:#64ff00;}.bc-c-4{fill:gray;}.bc-c-4,.bc-c-7{stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.bc-c-5{clip-path:url(#bc-cp);}.bc-c-6{clip-path:url(#bc-cp-2);}</style>

