<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bc-lg" x1="-105.67" y1="223.18" x2="-102.67" y2="223.18" gradientTransform="matrix(1, 0, 0, -1, 434.33, 446.36)" gradientUnits="userSpaceOnUse"><stop offset="0.01"/><stop offset="1" stop-opacity="0"/></linearGradient></defs><g id="Задник_Контур"><rect class="bc-c-3" x="61.46" y="90.13" width="391.08" height="266.11" rx="12"/><rect class="bc-c-4" x="328.67" y="90.13" width="3" height="266.11" transform="translate(660.33 446.36) rotate(-180)"/><line class="bc-c-3" x1="331.67" y1="90.13" x2="331.67" y2="356.24"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1,.bc-c-4{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:#727272;stroke:#232728;stroke-linejoin:round;stroke-width:0.75px;}.bc-c-4{fill:url(#bc-lg);}</style>

