<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M390.1,316.84c-49.71-.46-90.17-40.92-90.2-90.18a90.48,90.48,0,0,1,54-82.73"/><g class="tss-c-4"><path class="tss-c-5" d="M390.3,317c-1.34,0-2.68-.05-4-.12"/><path class="tss-c-6" d="M382.21,316.61c-45.94-4.49-82.08-43.19-82.11-89.75a90.51,90.51,0,0,1,48.52-80.11"/><path class="tss-c-5" d="M350.45,145.82c1.19-.59,2.4-1.16,3.62-1.69"/></g><g class="tss-c-7"><path class="tss-c-8" d="M389.9,316.64c-1.34,0-2.68-.05-4-.12"/><path class="tss-c-9" d="M381.81,316.21c-45.94-4.49-82.08-43.19-82.11-89.75a90.51,90.51,0,0,1,48.52-80.11"/><path class="tss-c-8" d="M350.05,145.42c1.19-.59,2.4-1.16,3.62-1.69"/></g><path class="tss-c-10" d="M390.39,311.84c-46.82-.43-85.21-38.26-85.24-85.18a85.25,85.25,0,0,1,51.3-78.26"/><path class="tss-c-11" d="M390.14,311.84c-46.82-.43-85.21-38.26-85.24-85.18a85.27,85.27,0,0,1,51.52-78.36"/></g><g id="Шов_Цвет"><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M390.1,316.84c-1.34,0-2.68,0-4-.12"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M382,316.41c-45.94-4.49-82.08-43.19-82.11-89.75a90.51,90.51,0,0,1,48.52-80.11"/><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M350.25,145.62c1.19-.59,2.4-1.16,3.62-1.69"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-5,.tss-c-6{stroke:#000;}.tss-c-10,.tss-c-11,.tss-c-3{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-4{opacity:0.6;}.tss-c-12,.tss-c-13,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{stroke-linecap:round;stroke-width:1.14px;}.tss-c-13,.tss-c-6,.tss-c-9{stroke-dasharray:8.2 4.1;}.tss-c-7{opacity:0.7;}.tss-c-10,.tss-c-8,.tss-c-9{stroke:#fff;}.tss-c-11,.tss-c-12,.tss-c-13{stroke:#232728;}</style>

