<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tc-lg" x1="127.03" y1="226.61" x2="113.63" y2="226.61" gradientTransform="translate(240.83 453.22) rotate(180)" gradientUnits="userSpaceOnUse"><stop offset="0.01" stop-color="#fff" stop-opacity="0.1"/><stop offset="0.06" stop-opacity="0"/><stop offset="0.53" stop-opacity="0"/><stop offset="0.83" stop-color="#fff" stop-opacity="0.34"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="tc-lg-2" x1="126.33" y1="226.61" x2="242.63" y2="226.61" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.3"/><stop offset="0.13" stop-color="#fff" stop-opacity="0"/></linearGradient></defs><g id="Крышки_Контур"><image width="265" height="400" transform="translate(126 26.77)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/top/contour/M7_1.webp`"/><image width="265" height="400" transform="translate(126 26.77)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/top/contour/M7_2.webp`"/><rect class="tc-c-3" x="113.12" y="27.22" width="14.58" height="398.78" transform="translate(240.83 453.22) rotate(-180)"/><rect class="tc-c-4" x="126.33" y="27.22" width="116.29" height="398.77"/><path class="tc-c-5" d="M381,27.22H126.33V426H381a9.16,9.16,0,0,0,9.13-9.13V36.35A9.13,9.13,0,0,0,381,27.22Z"/><path class="tc-c-5" d="M114.24,27.22A1.1,1.1,0,0,1,113.15,26h0v399a1.1,1.1,0,0,0,1.1,1.1h12.1V27.22Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#64ff00;}.tc-c-3{fill:url(#tc-lg);}.tc-c-4{fill:url(#tc-lg-2);}.tc-c-5{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

