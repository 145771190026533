<template>
  <div class="thumbnail-links">
    <div class="items d-flex flex-wrap">
      <div
        :class="['item', 'd-flex', 'align-items-center', { selected: item.id === value.id }]"
        v-for="item in items"
        :key="item.id"
        @click="input(item)"
      >
        <div class="item-image" :style="{ 'background-image': `url('${item.img}')` }"></div>
        <div class="name">{{ $t(`materials.${item.key}`) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /** @var items - массив объектов для отрисовки списка. Объект должен
     * содержать следующие поля
     * @property {number} id - ключ для списка
     * @property {string} name - наименования для надписи
     */
    items: {
      type: Array,
      required: true,
    },
    // /** @var selectedMaterials - массив выбранных материалов для отрисовки их
    //  * изображений. Объект должен содержать следующие поля
    //  * @property {number} image - изображение материала
    //  * @property {string} name - наименования для подсказки
    //  */
    // selectedMaterials: {
    //   type: Array,
    //   required: true,
    // },
    /** @var value - выбранный объект из items */
    value: {
      type: [Object],
      required: true,
    },
  },

  data() {
    return {}
  },

  methods: {
    input(item) {
      if (item.id !== this.value.id) {
        this.$emit('input', item)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.thumbnail-links
  // border-bottom: 1px $gray-200 solid
  margin-top: 40px
.item
  position: relative
  cursor: pointer
  border: 2px $stroke-color solid
  border-radius: 10px
  height: 50px
  margin-right: 10px
  margin-top: 10px
  .name
    font-size: 14px

    margin: 0 15px 0 10px

  .item-image
    width: 50px
    height: 50px
    border: 2px $stroke-color solid
    border-radius: 10px
    margin-left: -2px

  &.selected
    color: $contrast-regular
    border-color: $contrast-regular
    .item-image
      border-color:  $contrast-regular
</style>
