<template>
<svg data-svg-lvl-3="cover-back-corners" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bcr-lg" x1="452.79" y1="15723.62" x2="452.79" y2="15719.48" gradientTransform="matrix(1, 0, 0, -1, 0, 16037.72)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-2" x1="460.97" y1="15731.6" x2="464.94" y2="15731.6" gradientTransform="matrix(1, 0, 0, -1, 0, 16037.72)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#e0ce94" />
  <stop offset="0.31" stop-color="#b38e40" />
  <stop offset="0.32" stop-color="#b49043" />
  <stop offset="0.39" stop-color="#ba9953" />
  <stop offset="0.48" stop-color="#be9e5c" />
  <stop offset="0.59" stop-color="#bfa05f" />
  <stop offset="0.93" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#83858a" />
  <stop offset=".18" stop-color="#e0e0e0" />
  <stop offset=".31" stop-color="#83858a" />
  <stop offset=".31" stop-color="#84868b" />
  <stop offset=".36" stop-color="#9fa2a8" />
  <stop offset=".4" stop-color="#b4b7be" />
  <stop offset=".45" stop-color="#c4c7ce" />
  <stop offset=".51" stop-color="#cdd0d8" />
  <stop offset=".59" stop-color="#d0d3db" />
  <stop offset=".93" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-3" x1="61.21" y1="314.26" x2="61.21" y2="318.33" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" href="#bcr-lg"/><linearGradient id="bcr-lg-4" x1="11599.55" y1="15731.6" x2="11595.59" y2="15731.6" gradientTransform="translate(11648.68 16037.72) rotate(180)" href="#bcr-lg-2"/></defs><g id="УголкиЗадник"><path class="bcr-c-3" d="M463.51,318.34H441l4.18-4.18h15.65l3.73,3.74A1.49,1.49,0,0,1,463.51,318.34Z"/><path class="bcr-c-4" d="M460.84,314.16V298.51l4.17-4.17v22.5a1.46,1.46,0,0,1-.44,1.06Z"/><path class="bcr-c-5" d="M465,294.34v22.5a1.5,1.5,0,0,1-1.5,1.5H441l4.18-4.18h15.65V298.51l4.17-4.17m-.8-.81L460,297.7l-.33.34v15h-15l-.33.34-4.17,4.17.8,2h22.5a2.65,2.65,0,0,0,2.65-2.64v-22.5l-2-.81Z"/><path class="bcr-c-6" d="M50.49,318.34H73l-4.18-4.18H53.16l-3.73,3.74A1.49,1.49,0,0,0,50.49,318.34Z"/><path class="bcr-c-7" d="M53.16,314.16V298.51L49,294.34v22.5a1.46,1.46,0,0,0,.44,1.06Z"/><path class="bcr-c-5" d="M49,294.34l4.17,4.17v15.65H68.81L73,318.34H50.49a1.5,1.5,0,0,1-1.5-1.5v-22.5m.8-.81-1.95.81v22.5a2.65,2.65,0,0,0,2.65,2.64H73l.8-2-4.17-4.17-.33-.34h-15V298L54,297.7l-4.18-4.17Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bcr-c-1{opacity:0.3;}.bcr-c-2{fill:#64ff00;}.bcr-c-3{fill:url(#bcr-lg);}.bcr-c-4{fill:url(#bcr-lg-2);}.bcr-c-5{fill:#232728;}.bcr-c-6{fill:url(#bcr-lg-3);}.bcr-c-7{fill:url(#bcr-lg-4);}</style>

