import { render, staticRenderFns } from "./Upload2.vue?vue&type=template&id=4651f4ea&scoped=true"
import script from "./Upload2.vue?vue&type=script&lang=js"
export * from "./Upload2.vue?vue&type=script&lang=js"
import style0 from "./Upload2.vue?vue&type=style&index=0&id=4651f4ea&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4651f4ea",
  null
  
)

export default component.exports