<template>
<svg data-svg-lvl-3="flyleaffront-block-flyleaffront" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lff-m-f" x="0" y="0" width="500" height="430" fill="#ffffff"><path  d="M445.79,159.77a12,12,0,0,0-12-12H268.32c-4.69,0-12.4,1.62-12.4,6V155H78.07a12,12,0,0,0-12,12V275a12,12,0,0,0,12,12H256.12c0-2.57,5.58-4.65,12.4-4.65H433.78a12,12,0,0,0,12-12Z"/></mask></defs><g id="Форзац"> <path  :style="{ fill: params.flyleaffrontColor }"  class="lff-c-3" d="M445.79,159.77a12,12,0,0,0-12-12H268.32c-4.69,0-12.4,1.62-12.4,6V155H78.07a12,12,0,0,0-12,12V275a12,12,0,0,0,12,12H256.12c0-2.57,5.58-4.65,12.4-4.65H433.78a12,12,0,0,0,12-12Z"/> <image v-if="params.flyleaffrontImage" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.flyleaffrontImage" mask="url(#lff-m-f)" /> </g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lff-c-1{opacity:0.3;}.lff-c-2{fill:#64ff00;}.lff-c-3{fill:#f4e8ce;}</style>

