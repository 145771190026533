<template>
<svg data-svg-lvl-3="edge-top-slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышка_Срез"><path :style="{ fill: params.sliceColor }"  class="tsl-c-3" d="M229.08,195.31a1.54,1.54,0,0,1,1.55-1.54h233c12.58,0,19.63,10.08,19.63,26.23,0,15.89-7.63,24.25-19.58,24.25H49.53c-4.33,0-17-6.1-17-22.35s12.85-24.1,17.12-24.1h416a1.58,1.58,0,0,1,1.55,1.15,1.54,1.54,0,0,1-1.49,1.94H49.85c-1.93,0-13.86,5.66-13.86,21s11,19.26,13.74,19.26H463.44c10.41,0,16.7-7.84,16.7-21.16,0-13.58-5.87-23.14-16.54-23.14h-233a1.54,1.54,0,0,1-1.55-1.55Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsl-c-1{opacity:0.3;}.tsl-c-2{fill:#64ff00;}.tsl-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

