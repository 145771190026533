<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M150,426,270,253c6.87-9.88,6.87-17.15,6.87-26.34,0-9.63-.5-17.15-6.87-26.32L149.82,27.22"/><g class="tss-c-4"><line class="tss-c-5" x1="150.16" y1="426.19" x2="152.44" y2="422.91"/><path class="tss-c-6" d="M154.71,419.64,270.23,253.15c6.87-9.88,6.87-17.15,6.87-26.34,0-9.63-.5-17.15-6.87-26.32L153.43,32.34"/><line class="tss-c-5" x1="152.3" y1="30.7" x2="150.02" y2="27.42"/></g><g class="tss-c-7"><line class="tss-c-8" x1="149.76" y1="425.79" x2="152.04" y2="422.51"/><path class="tss-c-9" d="M154.31,419.24,269.83,252.75c6.86-9.88,6.86-17.15,6.86-26.34,0-9.63-.49-17.15-6.86-26.32L153,31.94"/><line class="tss-c-8" x1="151.9" y1="30.3" x2="149.62" y2="27.02"/></g><path class="tss-c-10" d="M156.3,426,274.39,255.8c7.75-11.16,7.76-19.56,7.76-29.19s-.42-18.6-7.76-29.17L156.16,27.22"/><path class="tss-c-11" d="M156.05,426,274.14,255.8c7.75-11.16,7.76-19.56,7.76-29.19s-.42-18.6-7.76-29.17L155.91,27.22"/></g><g id="Шов_Цвет"><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="149.96" y1="425.99" x2="152.24" y2="422.71"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M154.5,419.44,270,253c6.87-9.88,6.87-17.15,6.87-26.34,0-9.63-.5-17.15-6.87-26.32L153.23,32.14"/><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="152.1" y1="30.5" x2="149.82" y2="27.22"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-5,.tss-c-6{stroke:#000;}.tss-c-10,.tss-c-11,.tss-c-3{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-4{opacity:0.6;}.tss-c-12,.tss-c-13,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{stroke-linecap:round;stroke-width:1.14px;}.tss-c-13,.tss-c-6,.tss-c-9{stroke-dasharray:7.95 3.97;}.tss-c-7{opacity:0.7;}.tss-c-10,.tss-c-8,.tss-c-9{stroke:#fff;}.tss-c-11,.tss-c-12,.tss-c-13{stroke:#232728;}</style>

