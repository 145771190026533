<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M113.12,413.4H374.94a2.6,2.6,0,0,0,2.6-2.59V42.42a2.61,2.61,0,0,0-2.6-2.6H113.12"/><g class="tss-c-4"><line class="tss-c-5" x1="113.32" y1="413.6" x2="117.32" y2="413.6"/><path class="tss-c-6" d="M121.33,413.6H375.14a2.6,2.6,0,0,0,2.6-2.59V42.62a2.61,2.61,0,0,0-2.6-2.6H119.33"/><line class="tss-c-5" x1="117.32" y1="40.02" x2="113.32" y2="40.02"/></g><g class="tss-c-7"><line class="tss-c-8" x1="112.92" y1="413.2" x2="116.92" y2="413.2"/><path class="tss-c-9" d="M120.93,413.2H374.74a2.6,2.6,0,0,0,2.6-2.59V42.22a2.61,2.61,0,0,0-2.6-2.6H118.92"/><line class="tss-c-8" x1="116.92" y1="39.62" x2="112.92" y2="39.62"/></g><path class="tss-c-10" d="M113.33,418.6H375.14a7.6,7.6,0,0,0,7.6-7.59V42.62a7.61,7.61,0,0,0-7.6-7.6H113.33"/><path class="tss-c-11" d="M113.13,418.4H374.94a7.6,7.6,0,0,0,7.6-7.59V42.42a7.61,7.61,0,0,0-7.6-7.6H113.13"/></g><g id="Шов_Цвет"><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="113.12" y1="413.4" x2="117.12" y2="413.4"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M121.13,413.4H374.94a2.6,2.6,0,0,0,2.6-2.59V42.42a2.61,2.61,0,0,0-2.6-2.6H119.13"/><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="117.12" y1="39.82" x2="113.12" y2="39.82"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-5,.tss-c-6{stroke:#000;}.tss-c-10,.tss-c-11,.tss-c-3{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-4{opacity:0.6;}.tss-c-12,.tss-c-13,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{stroke-linecap:round;stroke-width:1.14px;}.tss-c-13,.tss-c-6,.tss-c-9{stroke-dasharray:8 4;}.tss-c-7{opacity:0.7;}.tss-c-10,.tss-c-8,.tss-c-9{stroke:#fff;}.tss-c-11,.tss-c-12,.tss-c-13{stroke:#232728;}</style>

