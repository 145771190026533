export function thousands(value, fractionDigits = null) {
  const conf = {}
  if (fractionDigits !== null) {
    conf.minimumFractionDigits = fractionDigits
    conf.maximumFractionDigits = fractionDigits
  }
  return value.toLocaleString('ru-RU', conf).replace(',', '.')
}

export const focus = {
  inserted(el, binding, vnode) {
    if (
      vnode.componentOptions &&
      (vnode.componentOptions.tag === 'UiInput' || vnode.componentOptions.tag === 'UiPasswordInput')
    ) {
      /** Для инпутов фокус наводится не на корень элемента, а на input */
      const arr = el.getElementsByTagName('input')
      if (arr[0]) {
        arr[0].focus()
      }
    } else {
      /** Если v-focus установлен для инпута, который стоит
      в модалке(а в модалках по дефолту ставится фокус, чтоб esc работал),
      то фокус для модалки не ставится, иначе фокус с инпута перейдёт на модалку */
      let skipFocusing = false
      for (const element of el.getElementsByTagName('input')) {
        if (element === document.activeElement) {
          skipFocusing = true
          break
        }
      }
      if (!skipFocusing) {
        el.focus()
      }
    }
  },
}

export const clickOutside = {
  bind(el, binding, vnode) {
    el.eventSetDrag = function () {
      el.setAttribute('data-dragging', 'yes')
    }
    el.eventClearDrag = function () {
      el.removeAttribute('data-dragging')
    }
    el.eventOnClick = function (event) {
      const dragging = el.getAttribute('data-dragging')
      // Check that the click was outside the el and its children, and wasn't a drag
      if (!(el === event.target || el.contains(event.target)) && !dragging) {
        // call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.addEventListener('touchstart', el.eventClearDrag)
    document.addEventListener('touchmove', el.eventSetDrag)
    document.addEventListener('click', el.eventOnClick)
    document.addEventListener('touchend', el.eventOnClick)
  },
  unbind(el) {
    document.removeEventListener('touchstart', el.eventClearDrag)
    document.removeEventListener('touchmove', el.eventSetDrag)
    document.removeEventListener('click', el.eventOnClick)
    document.removeEventListener('touchend', el.eventOnClick)
    el.removeAttribute('data-dragging')
  },
}
