<template>
<svg data-svg-lvl-3="edge-top-flap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="tf-rg" cx="11057.13" cy="4856.73" r="13.35" gradientTransform="matrix(0, -1, 1.14, 0, -5154.96, 11243.47)" gradientUnits="userSpaceOnUse"> <template v-if="params.flap.buttonColor === '#A31A30'">
  <!-- Бордовый -->
  <stop offset="0" stop-color="#debac0" />
  <stop offset=".4" stop-color="#a31a30" />
  <stop offset=".78" stop-color="#990019" />
  <stop offset=".83" stop-color="#9a071f" />
  <stop offset=".89" stop-color="#9d1c32" />
  <stop offset=".97" stop-color="#a33e50" />
  <stop offset="1" stop-color="#a54b5b" />
</template>
<template v-else-if="params.flap.buttonColor === '#191919'">
  <!-- Черный -->
  <stop offset="0" stop-color="#dedede" />
  <stop offset=".4" stop-color="#000" />
  <stop offset=".78" stop-color="#191919" />
  <stop offset=".81" stop-color="#202020" />
  <stop offset=".85" stop-color="#353535" />
  <stop offset=".91" stop-color="#585858" />
  <stop offset=".97" stop-color="#878787" />
  <stop offset="1" stop-color="#a5a5a5" />
</template>
<template v-else-if="params.flap.buttonColor === '#1E2F87'">
  <!-- Синий -->
  <stop offset="0" stop-color="#bac0de" />
  <stop offset=".4" stop-color="#1e2f87" />
  <stop offset=".78" stop-color="#1e2f87" />
  <stop offset=".84" stop-color="#25368c" />
  <stop offset=".94" stop-color="#3a4a9a" />
  <stop offset="1" stop-color="#4b5aa5" />
</template>

<template v-else-if="params.flap.buttonColor === '#808080'">
  <!--  Серый -->
  <stop offset="0" stop-color="#dedede" />
  <stop offset=".4" stop-color="gray" />
  <stop offset=".78" stop-color="#666" />
  <stop offset=".83" stop-color="#6d6d6d" />
  <stop offset=".91" stop-color="#828282" />
  <stop offset="1" stop-color="#a4a4a4" />
  <stop offset="1" stop-color="#a5a5a5" />
</template>
<template v-else-if="params.flap.buttonColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#fbffff" />
  <stop offset=".47" stop-color="#c3a22a" />
  <stop offset=".7" stop-color="#9c6b00" />
  <stop offset=".83" stop-color="#6c4209" />
  <stop offset="1" stop-color="#efd691" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#fff" />
  <stop offset=".78" stop-color="#363636" />
  <stop offset=".81" stop-color="#3d3d3d" />
  <stop offset=".86" stop-color="#525252" />
  <stop offset=".92" stop-color="#757575" />
  <stop offset=".99" stop-color="#a4a4a4" />
  <stop offset="1" stop-color="#ababab" />
</template> </radialGradient><radialGradient id="tf-rg-2" cx="11054.72" cy="4868.1" r="10.23" gradientTransform="matrix(0, -1, 1.14, 0, -5154.96, 11243.47)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff"/><stop offset="0.78" stop-color="#fff" stop-opacity="0"/></radialGradient></defs><g id="Хлястик_Контур"><path :style="{ fill: params.flap.sliceColor }"  class="tf-c-3" d="M468.42,250.79h-57.2a1.85,1.85,0,0,1,0-3.7h57.45c1.87,0,4.83-4.72,4.83-26.59s-3.52-25.59-4.83-25.59h-110a1.85,1.85,0,0,1,0-3.7H468.14c4.58,0,9.26,4.79,9.26,29.16S473.33,250.79,468.42,250.79Z"/></g><g id="Кнопки_Градиент"><path class="tf-c-4" d="M384.1,185.52c5.9,0,10.67,1.72,10.67,4.15,0,1.54-4.77,1.54-10.67,1.54s-10.66.08-10.66-1.77C373.44,186.81,378.21,185.52,384.1,185.52Z"/></g><g id="Кнопки_Контур"><path class="tf-c-5" d="M384.1,185.52c5.9,0,10.67,1.72,10.67,4.15,0,1.54-4.77,1.54-10.67,1.54s-10.66.08-10.66-1.77C373.44,186.81,378.21,185.52,384.1,185.52Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tf-c-1{opacity:0.3;}.tf-c-2{fill:#64ff00;}.tf-c-3{fill:gray;stroke-width:1.14px;}.tf-c-3,.tf-c-4,.tf-c-5{stroke:#232728;stroke-miterlimit:2;}.tf-c-4,.tf-c-5{stroke-width:1.14px;}.tf-c-4{fill:url(#tf-rg);}.tf-c-5{opacity:0.8;fill:url(#tf-rg-2);}</style>

