<template>
<svg data-svg-lvl-3="spread-back-flap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bf-lg" x1="452.53" y1="212.02" x2="498" y2="212.02" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.3"/><stop offset="0.29" stop-opacity="0.05"/><stop offset="1" stop-opacity="0"/></linearGradient></defs><g id="Хлястик_Контур"><path class="bf-c-3" d="M497,157.9a2.08,2.08,0,0,1,1,1.85v66.07c0,2.65-1.63,4.91-3.42,6.66l-27,28.22a17.32,17.32,0,0,1-10.33,5.21l-4.67.6,0,1.19,1.1-.09,4.68-.6a17.4,17.4,0,0,0,10.33-5.21l27-28.22c1.79-1.76,3.42-4,3.42-6.66V160.84A3.29,3.29,0,0,0,497,157.9Z"/><path class="bf-c-4" d="M458.66,177.94l-6.12.28v88.29l4.69-.6a17.32,17.32,0,0,0,10.33-5.21l27-28.22c1.79-1.75,3.42-4,3.42-6.66V159.75c0-1.92-1.95-2.73-3.72-1.91l-27.06,17.37A17.48,17.48,0,0,1,458.66,177.94Z"/><path class="bf-c-5" d="M458.66,177.94l-6.12.28v88.29l4.69-.6a17.32,17.32,0,0,0,10.33-5.21l27-28.22c1.79-1.75,3.42-4,3.42-6.66V159.75c0-1.92-1.95-2.73-3.72-1.91l-27.06,17.37A17.48,17.48,0,0,1,458.66,177.94Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bf-c-1{opacity:0.3;}.bf-c-2{fill:#64ff00;}.bf-c-3{fill:#232728;}.bf-c-4{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.bf-c-5{fill:url(#bf-lg);}</style>

