/** Возвращает персоналку по id персоналки или персоналки бухты */
export function getPersById(id, personalizationsTransformed) {
  let foundPers = null
  for (const group of personalizationsTransformed) {
    if (foundPers !== null) break

    for (const persona of group.personalizations) {
      if (persona.id === id || persona.originPersonalizations[id]) {
        foundPers = persona
        break
      }
    }
  }
  return foundPers
}
