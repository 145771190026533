<template>
<svg data-svg-lvl-5="cover-top-draggableUpOrDown-draggable-cutting" xmlns="http://www.w3.org/2000/svg" viewBox="-28.2 -28.2 197.7 197.7" preserveAspectRatio="xMidYMid slice" :x="draggable.x" :y="draggable.y" :width="draggable.w" :height="draggable.h"><defs><mask id="tct-m-c" x="0" y="0" width="500" height="430" fill="#ffffff"><rect  
  :style="{ transform: `rotate(${draggable.r}deg)` }"
  class="rotate"
    x="0.25" y="0.25" width="139.28" height="139.28"/></mask></defs><g 
  :style="{ transform: `rotate(${draggable.r}deg)` }"
  class="rotate"
  ><g id="Вырубка_Маска"><rect :style="{ fill: draggable.fromInner ? '#FFFFFF' : params.flyleaffrontColor }"  class="tct-c-1" x="0.25" y="0.25" width="139.28" height="139.28"/></g> </g>  <image v-if="!draggable.fromInner && params.flyleaffrontImage"
    preserveAspectRatio="xMidYMid slice"
    :x="-28.2 - (197.7 / draggable.w) * draggable.x"
    :y="-28.2 - (197.7 / draggable.h) * draggable.y"
    :width="(197.7 / draggable.w) * 500"
    :height="(197.7 / draggable.h) * 430"
    :href="params.flyleaffrontImage" mask="url(#tct-m-c)" /> <g 
  :style="{ transform: `rotate(${draggable.r}deg)` }"
  class="rotate"
  ><g id="Вырубка_Блик" class="blur" ><polygon class="tct-c-2" points="139.53 0.25 139.53 139.53 0.25 139.53 1.92 141.21 141.21 141.21 141.21 1.93 139.53 0.25"/></g><g id="Вырубка_Контур"><g class="tct-c-3"><polyline class="tct-c-4" points="139.53 0.25 0.25 0.25 0.25 139.53"/><polyline class="tct-c-5" points="139.53 0.52 0.52 0.52 0.52 139.53"/><polyline class="tct-c-6" points="139.53 0.8 0.8 0.8 0.8 139.53"/><polyline class="tct-c-7" points="139.53 1.07 1.07 1.07 1.07 139.53"/><polyline class="tct-c-8" points="139.53 1.34 1.34 1.34 1.34 139.53"/><polyline class="tct-c-9" points="139.53 1.61 1.61 1.61 1.61 139.53"/><polyline class="tct-c-10" points="139.53 1.89 1.89 1.89 1.89 139.53"/><polyline class="tct-c-11" points="139.53 2.16 2.16 2.16 2.16 139.53"/><polyline class="tct-c-12" points="139.53 2.43 2.43 2.43 2.43 139.53"/><polyline class="tct-c-13" points="139.53 2.71 2.71 2.71 2.71 139.53"/><polyline class="tct-c-14" points="139.53 2.98 2.98 2.98 2.98 139.53"/><polyline class="tct-c-15" points="139.53 3.25 3.25 3.25 3.25 139.53"/></g><rect class="tct-c-16" x="0.25" y="0.25" width="139.28" height="139.28"/></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, draggable: { type: Object, required: true }, }, data() { return {} },}
</script>

<style scoped>
  .rotate {
    transform-box: fill-box;
    transform-origin: center;
  }
  .blur {
    filter: blur(1px);
  }
  .tct-c-1{fill:#f4e8ce;}.tct-c-2{fill:#fff;opacity:0.5;}.tct-c-3{opacity:0.3;}.tct-c-10,.tct-c-11,.tct-c-12,.tct-c-13,.tct-c-14,.tct-c-15,.tct-c-16,.tct-c-4,.tct-c-5,.tct-c-6,.tct-c-7,.tct-c-8,.tct-c-9{fill:none;}.tct-c-10,.tct-c-11,.tct-c-12,.tct-c-13,.tct-c-14,.tct-c-15,.tct-c-4,.tct-c-5,.tct-c-6,.tct-c-7,.tct-c-8,.tct-c-9{stroke:#000;stroke-miterlimit:10;stroke-width:0.5px;}.tct-c-5{opacity:0.91;}.tct-c-6{opacity:0.82;}.tct-c-7{opacity:0.73;}.tct-c-8{opacity:0.64;}.tct-c-9{opacity:0.55;}.tct-c-10{opacity:0.45;}.tct-c-11{opacity:0.36;}.tct-c-12{opacity:0.27;}.tct-c-13{opacity:0.18;}.tct-c-14{opacity:0.09;}.tct-c-15{opacity:0;}.tct-c-16{stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}</style>

