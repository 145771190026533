import { render, staticRenderFns } from "./RHm.vue?vue&type=template&id=5f048a67&scoped=true"
import script from "./RHm.vue?vue&type=script&lang=js"
export * from "./RHm.vue?vue&type=script&lang=js"
import style0 from "./RHm.vue?vue&type=style&index=0&id=5f048a67&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f048a67",
  null
  
)

export default component.exports