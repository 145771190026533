<template>
<svg data-svg-lvl-3="edge-block-edge" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs>
      <mask id="le-m-si" x="0" y="0" width="500" height="430" fill="#ffffff"><polygon  points="53.61 210.75 53.61 212.15 53.78 212.15 53.78 213.54 53.78 214.93 53.78 216.32 53.99 216.32 53.99 217.71 53.87 217.71 53.87 219.1 53.78 219.1 53.78 220.49 53.78 220.49 53.78 221.88 53.89 221.88 53.89 223.27 53.78 223.27 53.78 224.66 54.01 224.66 54.01 226.05 53.93 226.05 53.93 227.44 53.78 227.44 53.78 228.83 54.03 228.83 54.03 230.22 53.78 230.22 53.78 231.62 372.82 231.62 372.82 230.22 373.06 230.22 373.06 228.83 372.82 228.83 372.82 227.44 372.96 227.44 372.96 226.05 373.04 226.05 373.04 224.66 372.82 224.66 372.82 223.27 372.93 223.27 372.93 221.88 372.82 221.88 372.82 220.49 372.82 220.49 372.82 219.1 372.9 219.1 372.9 217.71 373.02 217.71 373.02 216.32 372.82 216.32 372.82 214.93 372.82 214.93 372.82 213.54 372.82 212.15 372.65 212.15 372.65 210.75 53.61 210.75"/></mask>
  <linearGradient id="lg-silver" x1="45.52" x2="459.83"  y1="197.49" y2="197.49" gradientUnits="userSpaceOnUse" >
    <stop offset="0" stop-color="#989ba0" />
    <stop offset=".3" stop-color="#fff" /><stop offset=".83" stop-color="#3c3e45" /><stop offset="1" stop-color="#979a9f" />
  </linearGradient>
  <linearGradient id="lg-gold" x1="45.52" x2="459.83"  y1="197.49" y2="197.49" gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#9c6b00" /><stop offset=".3" stop-color="#fbe6a6" />
    <stop offset=".83" stop-color="#6c4209" /><stop offset="1" stop-color="#efe9cd" />
  </linearGradient></defs><g id="Обрез"><polygon :style="{ fill: params.slice.color === '#9C6B00' ? 'url(#lg-gold)' : params.slice.color === '#989BA0' ? 'url(#lg-silver)' : params.slice.color }"  class="le-c-3" points="53.61 210.75 53.61 212.15 53.78 212.15 53.78 213.54 53.78 214.93 53.78 216.32 53.99 216.32 53.99 217.71 53.87 217.71 53.87 219.1 53.78 219.1 53.78 220.49 53.78 220.49 53.78 221.88 53.89 221.88 53.89 223.27 53.78 223.27 53.78 224.66 54.01 224.66 54.01 226.05 53.93 226.05 53.93 227.44 53.78 227.44 53.78 228.83 54.03 228.83 54.03 230.22 53.78 230.22 53.78 231.62 372.82 231.62 372.82 230.22 373.06 230.22 373.06 228.83 372.82 228.83 372.82 227.44 372.96 227.44 372.96 226.05 373.04 226.05 373.04 224.66 372.82 224.66 372.82 223.27 372.93 223.27 372.93 221.88 372.82 221.88 372.82 220.49 372.82 220.49 372.82 219.1 372.9 219.1 372.9 217.71 373.02 217.71 373.02 216.32 372.82 216.32 372.82 214.93 372.82 214.93 372.82 213.54 372.82 212.15 372.65 212.15 372.65 210.75 53.61 210.75"/> <image v-if="params.slice.image" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.slice.image" mask="url(#le-m-si)" /></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.le-c-1{opacity:0.3;}.le-c-2{fill:#64ff00;}.le-c-3{fill:#f4e8ce;}</style>

