import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const spine = {
  id: 'spine',
  // tName:  'personalizations.spine.spine',
  template: 'modal',
  preview: 'cover',
  hidden: true,

  originPersonalizationsIds: [
    559, // Выбор параметров для Спайна
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getColor(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues, selectedMaterials }) {
  const originPersonalizations = this.originPersonalizations
  const idBuhta = 559
  const idParam = 29
  const idValue = modelValues?.color?.id
  const idParamAdd = 71
  let idValueAdd
  // добавление материала спайна
  const color = selectedMaterials[1]?.color
  const texture = selectedMaterials[1]?.texture
  if (color && texture) {
    const foundParam = originPersonalizations[idBuhta]?.params?.find((i) => i.id === idParamAdd)

    const foundValue = foundParam?.values?.find((i) => i.colorId === color && i.textureId === texture)
    if (foundValue?.id) {
      idValueAdd = foundValue.id
    }
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
    {
      idParam: idParamAdd,
      idValue: idValueAdd,
    },
  ])
}

/**
 * Выбор цвета резинки
 */
function getColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  const idBuhta = 559
  const idParam = 29

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.spine.color',
      template: 'selectClassicColor',
      sort: 10,
      values,
    })

    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[0]
    } else {
      modelValues.color = values[0]
    }
  }
}
