<template>
<svg data-svg-lvl-3="edge-top-base" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышки_Подложка"><path class="tb-c-3" d="M50,201.1h0Z"/><path class="tb-c-3" d="M45,239h1.24v-.19C38.53,237,32.73,229.73,32.73,221s5.92-16.2,13.76-17.89v-.2H45.17v-1.15c-8.09,2.23-14.08,10-14.08,19.24A19.78,19.78,0,0,0,45,240.2Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tb-c-1{opacity:0.3;}.tb-c-2{fill:#64ff00;}.tb-c-3{fill:#232728;}</style>

