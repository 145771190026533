<template>
<svg data-svg-lvl-3="spread-back-frames" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="bfr-rg" cx="481.12" cy="60.9" fx="481.12" fy="60.9" r="308.65" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#686a6d"/><stop offset=".18" stop-color="#bcc3cd"/><stop offset=".41" stop-color="#bdc0c8"/><stop offset=".61" stop-color="#80828a"/><stop offset=".8" stop-color="#505256"/><stop offset="1" stop-color="#747578"/></radialGradient></defs><g id="УголкиЗадник"><image width="667" height="35" transform="translate(289.2 93.68) scale(.24)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/spread/back/frames/M2_1.webp`"/><path class="bfr-c-3" d="M288.51,356.62v-4.18c0-1.19.97-2.16,2.16-2.16h152.22c1.74,0,3.16-1.41,3.16-3.16V99.05c0-1.74-1.41-3.16-3.16-3.16h-151.21c-1.19,0-2.16-.97-2.16-2.16v-4.19c0-.08.07-.15.15-.15h156.61c3.46,0,6.26,2.8,6.26,6.26v254.86c0,3.46-2.8,6.26-6.26,6.26h-157.62c-.08,0-.15-.07-.15-.15Z"/><image width="685" height="1117" transform="translate(288.24 89.12) scale(.24)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/spread/back/frames/M2_2.webp`"/><path class="bfr-c-2" d="M446.28,89.4c3.46,0,6.26,2.8,6.26,6.26v254.86c0,3.46-2.8,6.26-6.26,6.26h-157.62c-.08,0-.15-.07-.15-.15v-4.19c0-1.19.97-2.16,2.16-2.16h152.22c1.74,0,3.16-1.41,3.16-3.16V99.05c0-1.74-1.41-3.16-3.16-3.16h-151.21c-1.19,0-2.16-.97-2.16-2.16v-4.19c0-.08.07-.15.15-.15h156.61M446.28,88.26h-156.61c-.71,0-1.29.58-1.29,1.29v4.19c0,1.82,1.48,3.3,3.3,3.3h151.21c1.11,0,2.02.9,2.02,2.02v248.07c0,1.11-.9,2.02-2.02,2.02h-152.22c-1.82,0-3.3,1.48-3.3,3.3v4.19c0,.71.58,1.29,1.29,1.29h157.62c4.08,0,7.4-3.32,7.4-7.4V95.66c0-4.08-3.32-7.4-7.4-7.4h0Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bfr-c-1{opacity:.3;}.bfr-c-2{fill:#232728;}.bfr-c-2,.bfr-c-3,.bfr-c-4{stroke-width:0px;}.bfr-c-3{fill:url(#bfr-rg);}.bfr-c-4{fill:#64ff00;}</style>

