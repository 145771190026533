<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="tc-rg" cx="378.24" cy="37.08" r="465.42" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.2"/><stop offset="0.64" stop-color="#fff" stop-opacity="0"/></radialGradient><linearGradient id="tc-lg" x1="111.57" y1="226.61" x2="227.86" y2="226.61" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.15"/><stop offset="0.6" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="tc-lg-2" x1="111.57" y1="226.61" x2="135.86" y2="226.61" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.4"/><stop offset="0.16" stop-color="#fff" stop-opacity="0"/></linearGradient><clipPath id="tc-cp"><path class="tc-c-1" d="M390.14,36.35A9.13,9.13,0,0,0,381,27.22H111.57V426H381a9.13,9.13,0,0,0,9.13-9.13Z"/></clipPath></defs><g id="Крышки_Контур"><path class="tc-c-4" d="M381,27.22H111.57V426H381a9.13,9.13,0,0,0,9.13-9.13V36.35A9.13,9.13,0,0,0,381,27.22Z"/><rect class="tc-c-5" x="111.57" y="27.23" width="116.29" height="398.77"/><rect class="tc-c-6" x="111.57" y="27.23" width="24.29" height="398.77"/><g class="tc-c-7"><rect class="tc-c-8" x="111.59" y="27.22" width="278.56" height="398.77"/><g class="tc-c-9"><image width="514" height="422" transform="translate(3.06 8.25) scale(0.99 0.99)" :href="`${$CONST.cnstrAssetsUrl}/others/93BB-Ice-print.webp`"/></g></g><path class="tc-c-1" d="M390.14,36.35A9.13,9.13,0,0,0,381,27.22H111.57V426H381a9.13,9.13,0,0,0,9.13-9.13Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{fill:none;}.tc-c-1,.tc-c-8{stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}.tc-c-2{opacity:0.3;}.tc-c-3{fill:#64ff00;}.tc-c-4{fill:url(#tc-rg);}.tc-c-5{fill:url(#tc-lg);}.tc-c-6{fill:url(#tc-lg-2);}.tc-c-7{clip-path:url(#tc-cp);}.tc-c-8{fill:#b3b3b3;opacity:0.2;}.tc-c-9{opacity:0.5;}</style>

