<template>
<svg data-svg-lvl-3="edge-top-slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышка_Срез"><path :style="{ fill: params.sliceColor }"  class="tsl-c-3" d="M475.23,244.25a17,17,0,0,1-3.45-.27,1.54,1.54,0,0,1,.6-3,14.39,14.39,0,0,0,3.19.21h.32c8.58,0,12.57-6.41,12.57-20.16v-.3c0-4.06,0-10.2-2.7-15.14-3.21-5.86-9.4-8.7-18.93-8.7H407a1.55,1.55,0,0,1,0-3.09h59.88c10.61,0,17.89,3.47,21.64,10.31,3.08,5.63,3.08,12.24,3.08,16.62v.3c0,15.43-5.27,23.25-15.66,23.25h-.66Z"/><path class="tsl-c-3" d="M466.61,241.15c-3.32-4.14-4.75-10.6-4.75-20.15,0-13,5-20.5,13.61-20.5,5.81,0,12.59,5.37,12.59,20.5,0,16.08-6.53,23.25-21.18,23.25H50.19c-3.35-.4-9.7-3-9.7-9.93V209.47c0-7.16,6.2-10.73,9.48-11.62l.4,0H465.05a1.55,1.55,0,0,1,0,3.09H50.6c-1.13.36-7,2.52-7,8.58v24.84c0,5.69,6.07,6.74,6.89,6.85H466.68c2,0,5.17-.33,5.17-.33C481.21,239.42,485,233.5,485,221c0-12.85-5.11-17.42-9.5-17.42C466.31,203.58,465,214.5,465,221c0,10.11,1.63,16.45,4.86,18.84a6.11,6.11,0,0,0,1.94,1Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsl-c-1{opacity:0.3;}.tsl-c-2{fill:#64ff00;}.tsl-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

