<template>
<svg data-svg-lvl-3="cover-top-seamPerimeter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Прошивка_по_периметру_Контур" ><path class="tsp-c-3" d="M110.67,411.07c.85,0,2.28,3.08,3,4.08.59.82,1.22.84,2.75.84H374.08a6.07,6.07,0,0,0,6.06-6.06V43.28a6.06,6.06,0,0,0-6.06-6.06H115.82c-1.59,0-1.53.17-2.15-.83s-2.83-4.12-2.83-4.12"/><g class="tsp-c-4"><path class="tsp-c-5" d="M110.87,411.27c.65,0,1.64,1.81,2.4,3.11"/><path class="tsp-c-6" d="M116.48,416.19h257.8a6.07,6.07,0,0,0,6.06-6.06V43.48a6.06,6.06,0,0,0-6.06-6.06H116a4.77,4.77,0,0,1-1.51-.07"/><path class="tsp-c-5" d="M113.32,35.76c-.88-1.31-2.28-3.29-2.28-3.29"/></g><g class="tsp-c-7"><path class="tsp-c-8" d="M110.47,410.87c.65,0,1.64,1.81,2.4,3.11"/><path class="tsp-c-9" d="M116.08,415.79h257.8a6.07,6.07,0,0,0,6.06-6.06V43.08A6.06,6.06,0,0,0,373.88,37H115.62a4.77,4.77,0,0,1-1.51-.07"/><path class="tsp-c-8" d="M112.92,35.36c-.88-1.31-2.28-3.29-2.28-3.29"/></g></g><g id="Прошивка_по_периметру_Цвет" ><path :style="{ stroke: params.seamColor }"  class="tsp-c-10" d="M110.67,411.07c.65,0,1.64,1.81,2.4,3.11"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-11" d="M116.28,416h257.8a6.07,6.07,0,0,0,6.06-6.06V43.28a6.06,6.06,0,0,0-6.06-6.06H115.82a4.77,4.77,0,0,1-1.51-.07"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-10" d="M113.12,35.56c-.88-1.31-2.28-3.29-2.28-3.29"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsp-c-1{opacity:0.3;}.tsp-c-2{fill:#64ff00;}.tsp-c-10,.tsp-c-11,.tsp-c-3,.tsp-c-5,.tsp-c-6,.tsp-c-8,.tsp-c-9{fill:none;stroke-linecap:round;stroke-miterlimit:2;}.tsp-c-3,.tsp-c-5,.tsp-c-6{stroke:#000;}.tsp-c-3{stroke-width:0.5px;opacity:0.5;}.tsp-c-4{opacity:0.6;}.tsp-c-10,.tsp-c-11,.tsp-c-5,.tsp-c-6,.tsp-c-8,.tsp-c-9{stroke-width:1.14px;}.tsp-c-11,.tsp-c-6,.tsp-c-9{stroke-dasharray:8.05 4.02;}.tsp-c-7{opacity:0.7;}.tsp-c-8,.tsp-c-9{stroke:#fff;}.tsp-c-10,.tsp-c-11{stroke:#e51f1f;}</style>

