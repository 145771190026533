<template>
<svg data-svg-lvl-3="spread-back-corners" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bcr-lg" x1="-5206.14" y1="-388.42" x2="-5206.14" y2="-385.02" gradientTransform="matrix(-1, 0, 0, 1, -5135.06, 448.72)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-2" x1="-5196.22" y1="-378.38" x2="-5199.48" y2="-378.38" gradientTransform="matrix(-1, 0, 0, 1, -5135.06, 448.72)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-3" x1="-231.61" y1="-388.42" x2="-231.61" y2="-385.02" gradientTransform="translate(674.53 448.72)" href="#bcr-lg"/><linearGradient id="bcr-lg-4" x1="-221.69" y1="-378.38" x2="-224.95" y2="-378.38" gradientTransform="translate(674.53 448.72)" href="#bcr-lg-2"/></defs><g id="УголкиЗадник"><path class="bcr-c-3" d="M62.28,60.31H80.75l-3.42,3.43H64.47l-3.06-3.07A1.23,1.23,0,0,1,62.28,60.31Z"/><path class="bcr-c-4" d="M64.47,63.74V76.59L61.05,80V61.54a1.19,1.19,0,0,1,.36-.87Z"/><path class="bcr-c-5" d="M80.75,60.31l-3.42,3.43H64.47V76.59L61.05,80V61.54a1.23,1.23,0,0,1,1.23-1.23H80.75m0-.75H62.28a2,2,0,0,0-2,2V80a.75.75,0,0,0,.46.69.72.72,0,0,0,.29.06.79.79,0,0,0,.53-.22L65,77.12a.75.75,0,0,0,.22-.53V64.49H77.33a.75.75,0,0,0,.53-.22l3.42-3.43a.75.75,0,0,0-.53-1.28Z"/><path class="bcr-c-6" d="M451.72,60.31H433.25l3.42,3.43h12.86l3.06-3.07A1.23,1.23,0,0,0,451.72,60.31Z"/><path class="bcr-c-7" d="M449.53,63.74V76.59L453,80V61.54a1.19,1.19,0,0,0-.36-.87Z"/><path class="bcr-c-5" d="M451.72,60.31A1.23,1.23,0,0,1,453,61.54V80l-3.42-3.43V63.74H436.67l-3.42-3.43h18.47m0-.75H433.25a.75.75,0,0,0-.53,1.28l3.42,3.43a.75.75,0,0,0,.53.22h12.11v12.1a.71.71,0,0,0,.22.53l3.42,3.43a.79.79,0,0,0,.53.22.72.72,0,0,0,.29-.06.75.75,0,0,0,.46-.69V61.54a2,2,0,0,0-2-2Z"/><line class="bcr-c-8" x1="61.19" y1="60.45" x2="64.69" y2="63.95"/><line class="bcr-c-8" x1="449.31" y1="63.95" x2="452.81" y2="60.45"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bcr-c-1{opacity:0.3;}.bcr-c-2{fill:#64ff00;}.bcr-c-3{fill:url(#bcr-lg);}.bcr-c-4{fill:url(#bcr-lg-2);}.bcr-c-5{fill:#232728;}.bcr-c-6{fill:url(#bcr-lg-3);}.bcr-c-7{fill:url(#bcr-lg-4);}.bcr-c-8{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:0.4px;}</style>

