<template>
<svg data-svg-lvl-5="cover-top-draggableUpOrDown-draggable-embossing" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 27" preserveAspectRatio="xMidYMid slice" :x="draggable.x" :y="draggable.y" :width="draggable.w" :height="draggable.h">
	<defs>
		
		<linearGradient :id="`te-ng_1_${draggable.id.pers}_${draggable.id.idx}`" x1="85.2748" y1="0.5817" x2="85.2748" y2="28.721"
			gradientUnits="userSpaceOnUse">
<template v-if="!draggable.gradient">
  <stop :stop-color="draggable.color" />
</template>
<template v-else-if="draggable.color === '#840808'">
  <!-- Красный -->
  <stop offset="0" stop-color="#840808" />
  <stop offset=".3" stop-color="#ff1711" />
  <stop offset=".83" stop-color="#6a0404" />
  <stop offset="1" stop-color="#ff6d66" />
</template>
<template v-else-if="draggable.color === '#048B1B'">
  <!-- Зелёный -->
  <stop offset="0" stop-color="#048b1b" />
  <stop offset=".3" stop-color="#4cf664" />
  <stop offset=".64" stop-color="#113a17" />
  <stop offset="1" stop-color="#4cf664" />
</template>
<template v-else-if="draggable.color === '#1A1D97'">
  <!-- Синий -->
  <stop offset=".03" stop-color="#1a1d97" />
  <stop offset=".3" stop-color="#9bc7ff" />
  <stop offset=".64" stop-color="#1f2986" />
  <stop offset="1" stop-color="#818feb" />
</template>
<template v-else-if="draggable.color === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.3" stop-color="#fbe6a6" />
  <stop offset="0.83" stop-color="#6c4209" />
  <stop offset="1" stop-color="#efe9cd" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".3" stop-color="#fff" />
  <stop offset=".83" stop-color="#3c3e45" />
  <stop offset="1" stop-color="#979a9f" />
</template>
  </linearGradient>
	</defs>
	
	<g id="Тиснение_Цвет" :style="{ opacity: draggable.opacity }" >
		<path    :style="`fill: url(#te-ng_1_${draggable.id.pers}_${draggable.id.idx});`" d="M10.7,15.9h-6v10.3H1.3V0.8h8.4c2.9,0,5.1,0.7,6.6,2C18,4,18.7,5.9,18.7,8.4c0,1.6-0.4,3-1.3,4.1
		c-0.9,1.2-2.1,2.1-3.6,2.6l6,10.8v0.2h-3.6L10.7,15.9z M4.7,13.2h5.2c1.7,0,3-0.4,4-1.3c1-0.9,1.5-2,1.5-3.5c0-1.6-0.5-2.8-1.4-3.6
		C13,4,11.7,3.5,9.9,3.5H4.7V13.2z M37.8,11.7H26.7V3.5h12.7V0.8h-16v25.5h16.2v-2.7H26.7v-9h11.1V11.7z M51.2,14.9
		c2.3,0.7,3.9,1.3,4.8,2c0.9,0.7,1.3,1.6,1.3,2.9c0,1.2-0.5,2.2-1.4,3c-0.9,0.7-2.3,1.1-4.1,1.1c-1.9,0-3.4-0.4-4.6-1.3
		c-1.1-0.9-1.7-2.1-1.7-3.6h-3.4c0,1.5,0.4,2.8,1.2,4c0.8,1.2,2,2.1,3.5,2.8c1.5,0.7,3.2,1,4.9,1c2.7,0,4.9-0.6,6.5-1.9
		c1.6-1.2,2.4-2.9,2.4-5c0-1.3-0.3-2.4-0.9-3.4c-0.6-1-1.5-1.8-2.7-2.5c-1.2-0.7-2.9-1.4-5-2c-2.1-0.6-3.6-1.3-4.5-2
		c-0.9-0.7-1.3-1.6-1.3-2.6c0-1.3,0.5-2.3,1.4-3c0.9-0.7,2.2-1.1,3.9-1.1c1.8,0,3.2,0.4,4.2,1.3c1,0.9,1.5,2.1,1.5,3.6h3.4
		c0-1.4-0.4-2.7-1.1-3.9c-0.8-1.2-1.8-2.1-3.2-2.8c-1.4-0.7-2.9-1-4.7-1c-2.6,0-4.7,0.7-6.3,2c-1.6,1.3-2.4,3-2.4,5
		c0,1.8,0.7,3.3,2,4.5C46.2,13,48.3,14.1,51.2,14.9 M79.5,11.7H68.4V3.5h12.7V0.8h-16v25.5h16.2v-2.7H68.4v-9h11.1V11.7z M94.8,15.9
		h-6v10.3h-3.4V0.8h8.4c2.9,0,5.1,0.7,6.6,2c1.5,1.3,2.3,3.2,2.3,5.7c0,1.6-0.4,3-1.3,4.1c-0.9,1.2-2.1,2.1-3.6,2.6l6,10.8v0.2h-3.6
		L94.8,15.9z M88.8,13.2H94c1.7,0,3-0.4,4-1.3c1-0.9,1.5-2,1.5-3.5c0-1.6-0.5-2.8-1.4-3.6c-0.9-0.8-2.3-1.3-4.1-1.3h-5.1V13.2z
		 M108.6,0.8h-3.7l9.4,25.5h3l9.5-25.5h-3.7l-7.3,21L108.6,0.8z M144.8,11.7h-11.1V3.5h12.7V0.8h-16v25.5h16.2v-2.7h-12.8v-9h11.1
		V11.7z M150.8,26.2V0.8h7.2c2.2,0,4.2,0.5,5.9,1.5c1.7,1,3,2.4,3.9,4.2c0.9,1.8,1.4,3.9,1.4,6.2v1.6c0,2.4-0.5,4.5-1.4,6.3
		c-0.9,1.8-2.3,3.2-4,4.2s-3.7,1.5-6,1.5H150.8z M154.1,3.5v20h3.5c2.6,0,4.6-0.8,6.1-2.4c1.4-1.6,2.2-3.9,2.2-6.9v-1.5
		c0-2.9-0.7-5.1-2-6.7c-1.4-1.6-3.3-2.4-5.8-2.4H154.1z" />
	</g>
	<g id="Тиснение_Свет_Тень" >
		<g class="te-c-3">
			<rect x="68.9" y="3" class="te-c-2" width="12.2" height="0.5" />
			<rect x="68.9" y="14" class="te-c-2" width="10.6" height="0.5" />
			<path class="te-c-2" d="M101,13.1c-0.7,0.7-1.6,1.2-2.6,1.6l0.1,0.2C99.5,14.5,100.4,13.9,101,13.1z" />
			<path class="te-c-2"
				d="M56.3,4c-1-0.9-2.4-1.3-4.2-1.3c-1.7,0-3,0.4-3.9,1.1c-0.2,0.2-0.4,0.4-0.6,0.6c0,0,0.1-0.1,0.1-0.1
			c0.9-0.7,2.2-1.1,3.9-1.1c1.8,0,3.2,0.4,4.2,1.3c1,0.9,1.5,2.1,1.5,3.6h3.4c0-0.2,0-0.3,0-0.5h-2.8C57.7,6.1,57.2,4.9,56.3,4z" />
			<rect x="27.2" y="14" class="te-c-2" width="10.6" height="0.5" />
			<path class="te-c-2" d="M51.2,14.9c2.3,0.7,3.9,1.3,4.8,2c0.9,0.7,1.3,1.6,1.3,2.9c0,0.9-0.3,1.7-0.8,2.3c0.8-0.7,1.3-1.6,1.3-2.8
			c0-1.2-0.4-2.2-1.3-2.9c-0.9-0.7-2.5-1.4-4.8-2c-2.9-0.8-5-1.8-6.3-3.1c-1.3-1.2-2-2.7-2-4.5c0-1.6,0.5-3,1.6-4.2
			c-1.4,1.3-2.1,2.8-2.1,4.7c0,1.8,0.7,3.3,2,4.5C46.2,13,48.3,14.1,51.2,14.9z" />
			<path class="te-c-2" d="M98.4,11.4c1-0.9,1.5-2,1.5-3.5c0-1.6-0.5-2.8-1.4-3.6C97.6,3.5,96.2,3,94.4,3h-5.1v0.5h4.6
			c1.8,0,3.1,0.4,4.1,1.3c0.9,0.8,1.4,2,1.4,3.6c0,1.2-0.4,2.2-1.1,3C98.4,11.4,98.4,11.4,98.4,11.4z" />
			<rect x="134.3" y="14" class="te-c-2" width="10.6" height="0.5" />
			<path class="te-c-2"
				d="M163.7,21.1c0.2-0.2,0.3-0.3,0.5-0.5c1.4-1.6,2.2-3.9,2.2-6.9v-1.5c0-2.9-0.7-5.1-2-6.7
			C163,3.9,161,3,158.5,3h-3.9v0.5h3.4c2.5,0,4.4,0.8,5.8,2.4c1.4,1.6,2,3.9,2,6.7v1.5C165.9,17.2,165.2,19.5,163.7,21.1z" />
			<rect x="134.3" y="3" class="te-c-2" width="12.2" height="0.5" />
			<polygon class="te-c-2" points="116.4,21.3 123.5,0.8 123.1,0.8 116.2,20.8 		" />
			<rect x="27.2" y="3" class="te-c-2" width="12.2" height="0.5" />
			<polygon class="te-c-2" points="100.8,25.7 95.3,15.4 89.3,15.4 89.3,15.9 94.8,15.9 100.3,26.2 103.9,26.2 103.9,26 103.8,25.7 		
			" />
			<polygon class="te-c-2" points="65.6,25.7 65.6,0.8 65.1,0.8 65.1,26.2 81.3,26.2 81.3,25.7 		" />
			<polygon class="te-c-2" points="114.9,25.7 105.6,0.8 104.9,0.8 114.4,26.2 117.4,26.2 117.5,25.7 		" />
			<path class="te-c-2" d="M14.4,11.4c1-0.9,1.5-2,1.5-3.5c0-1.6-0.5-2.8-1.4-3.6C13.5,3.5,12.2,3,10.4,3H5.2v0.5h4.6
			C11.7,3.5,13,4,14,4.8c0.9,0.8,1.4,2,1.4,3.6c0,1.2-0.4,2.2-1.1,3C14.3,11.4,14.3,11.4,14.4,11.4z" />
			<polygon class="te-c-2" points="130.9,25.7 130.9,0.8 130.4,0.8 130.4,26.2 146.6,26.2 146.6,25.7 		" />
			<path class="te-c-2" d="M164.3,24.2c-1.7,1-3.7,1.5-6,1.5h-7.1v-25h-0.5v25.5h7.1c2.3,0,4.3-0.5,6-1.5c0.9-0.5,1.7-1.2,2.4-2
			C165.7,23.3,165.1,23.8,164.3,24.2z" />
			<polygon class="te-c-2" points="85.9,25.7 85.9,0.8 85.4,0.8 85.4,26.2 88.8,26.2 88.8,25.7 		" />
			<path class="te-c-2" d="M58.7,24.2c-1.6,1.2-3.8,1.9-6.5,1.9c-1.8,0-3.4-0.3-4.9-1c-1.5-0.7-2.7-1.6-3.5-2.8c-0.7-1-1.1-2.2-1.2-3.5
			h-0.5c0,1.5,0.4,2.8,1.2,4c0.8,1.2,2,2.1,3.5,2.8c1.5,0.7,3.2,1,4.9,1c2.7,0,4.9-0.6,6.5-1.9c0.3-0.3,0.6-0.5,0.9-0.8
			C59,24,58.9,24.1,58.7,24.2z" />
			<path class="te-c-2" d="M17,13.1c-0.7,0.7-1.6,1.2-2.6,1.6l0.1,0.2C15.5,14.5,16.3,13.9,17,13.1z" />
			<polygon class="te-c-2" points="1.8,25.7 1.8,0.8 1.3,0.8 1.3,26.2 4.7,26.2 4.7,25.7 		" />
			<polygon class="te-c-2"
				points="16.8,25.7 11.2,15.4 5.2,15.4 5.2,15.9 10.7,15.9 16.3,26.2 19.9,26.2 19.9,26 19.7,25.7 		" />
			<polygon class="te-c-2" points="23.8,25.7 23.8,0.8 23.3,0.8 23.3,26.2 39.5,26.2 39.5,25.7 		" />
		</g>
		<g class="te-c-3">
			<path d="M44.8,2.9c1.6-1.3,3.7-2,6.3-2c1.8,0,3.3,0.3,4.7,1c1.4,0.7,2.4,1.6,3.2,2.8c0.7,1,1,2.2,1.1,3.4h0.5
			c0-1.4-0.4-2.7-1.1-3.9c-0.8-1.2-1.8-2.1-3.2-2.8c-1.4-0.7-2.9-1-4.7-1c-2.6,0-4.7,0.7-6.3,2c-0.3,0.2-0.6,0.5-0.8,0.8
			C44.6,3.1,44.7,3,44.8,2.9z" />
			<polygon points="80.6,1.3 80.6,3.5 81.1,3.5 81.1,0.8 65.1,0.8 65.1,1.3 		" />
			<path
				d="M45,19.4c0,1.5,0.6,2.8,1.7,3.6c1.1,0.9,2.7,1.3,4.6,1.3c1.8,0,3.1-0.4,4.1-1.1c0.3-0.2,0.5-0.4,0.7-0.7
			c-0.1,0-0.1,0.1-0.2,0.2c-0.9,0.7-2.3,1.1-4.1,1.1c-1.9,0-3.4-0.4-4.6-1.3c-1.1-0.9-1.7-2.1-1.7-3.6h-3.4c0,0.2,0,0.3,0,0.5H45z" />
			<path
				d="M97.8,12c-1,0.8-2.3,1.2-3.9,1.2h-5.2V4h-0.5v9.7h5.2c1.7,0,3-0.4,4-1.3C97.6,12.3,97.7,12.1,97.8,12z" />
			<polygon points="38.9,1.3 38.9,3.5 39.4,3.5 39.4,0.8 23.3,0.8 23.3,1.3 		" />
			<polygon points="26.2,4 26.2,12.2 37.3,12.2 37.3,14.5 37.8,14.5 37.8,11.7 26.7,11.7 26.7,4 		" />
			<path d="M153.6,4v20h3.5c2.6,0,4.6-0.8,6.1-2.4c0,0,0,0,0,0c-1.4,1.3-3.2,1.9-5.6,1.9h-3.5V4H153.6z" />
			<polygon points="145.9,1.3 145.9,3.5 146.4,3.5 146.4,0.8 130.4,0.8 130.4,1.3 		" />
			<polygon points="133.3,4 133.3,12.2 144.3,12.2 144.3,14.5 144.8,14.5 144.8,11.7 133.8,11.7 133.8,4 		" />
			<path d="M93.4,1.3c2.9,0,5.1,0.7,6.6,2c1.5,1.3,2.3,3.2,2.3,5.7c0,1.6-0.4,3-1.3,4.1c-0.1,0.2-0.3,0.4-0.5,0.6
			c0.4-0.3,0.7-0.7,1-1.1c0.9-1.2,1.3-2.6,1.3-4.1c0-2.5-0.8-4.4-2.3-5.7c-1.5-1.3-3.8-2-6.6-2h-8.4v0.5H93.4z" />
			<polygon points="108.1,1.3 115.4,22.3 115.7,21.3 108.6,0.8 104.9,0.8 105.1,1.3 		" />
			<polygon points="67.9,4 67.9,12.2 79,12.2 79,14.5 79.5,14.5 79.5,11.7 68.4,11.7 68.4,4 		" />
			<path d="M97.4,15.7l5.8,10.5h0.7V26l-5.9-10.6C97.9,15.5,97.7,15.6,97.4,15.7z" />
			<path d="M9.3,1.3c2.9,0,5.1,0.7,6.6,2c1.5,1.3,2.3,3.2,2.3,5.7c0,1.6-0.4,3-1.3,4.1c-0.1,0.2-0.3,0.4-0.5,0.6
			c0.4-0.3,0.7-0.7,1-1.1c0.9-1.2,1.3-2.6,1.3-4.1c0-2.5-0.8-4.4-2.3-5.7c-1.5-1.3-3.8-2-6.6-2H1.3v0.5H9.3z" />
			<polygon points="123,1.3 126.3,1.3 117,26.2 117.4,26.2 126.8,0.8 123.1,0.8 		" />
			<rect x="88.3" y="16.4" width="0.5" height="9.8" />
			<path d="M167.8,6.4c-0.9-1.8-2.2-3.2-3.9-4.2c-1.7-1-3.7-1.5-5.9-1.5h-7.2v0.5h6.7c2.2,0,4.2,0.5,5.9,1.5c1.7,1,3,2.4,3.9,4.2
			c0.9,1.8,1.4,3.9,1.4,6.2v1.6c0,2.4-0.5,4.5-1.4,6.3c-0.4,0.8-0.9,1.6-1.5,2.2c0.8-0.8,1.5-1.7,2-2.7c0.9-1.8,1.4-3.9,1.4-6.3
			v-1.6C169.2,10.3,168.7,8.2,167.8,6.4z" />
			<polygon points="133.8,15 133.3,15 133.3,24 146.1,24 146.1,26.2 146.6,26.2 146.6,23.5 133.8,23.5 		" />
			<polygon points="68.4,15 67.9,15 67.9,24 80.8,24 80.8,26.2 81.3,26.2 81.3,23.5 68.4,23.5 		" />
			<path
				d="M57.1,13.9c-1.2-0.7-2.9-1.4-5-2c-2.1-0.6-3.6-1.3-4.5-2c-0.9-0.7-1.3-1.6-1.3-2.6c0-1,0.3-1.8,0.8-2.4
			c-0.8,0.7-1.3,1.7-1.3,2.9c0,1,0.4,1.9,1.3,2.6c0.9,0.7,2.4,1.4,4.5,2c2.1,0.6,3.8,1.3,5,2c1.2,0.7,2.1,1.6,2.7,2.5
			c0.6,1,0.9,2.1,0.9,3.4c0,1.6-0.5,3-1.5,4.1c1.3-1.2,2-2.7,2-4.6c0-1.3-0.3-2.4-0.9-3.4C59.2,15.4,58.3,14.6,57.1,13.9z" />
			<rect x="4.2" y="16.4" width="0.5" height="9.8" />
			<path
				d="M13.8,12c-1,0.8-2.3,1.2-3.9,1.2H4.7V4H4.2v9.7h5.2c1.7,0,3-0.4,4-1.3C13.5,12.3,13.6,12.1,13.8,12z" />
			<path d="M13.4,15.7l5.8,10.5h0.7V26L14,15.4C13.8,15.5,13.6,15.6,13.4,15.7z" />
			<polygon points="26.7,15 26.2,15 26.2,24 39,24 39,26.2 39.5,26.2 39.5,23.5 26.7,23.5 		" />
		</g>
	</g>
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, draggable: { type: Object, required: true }, }, data() { return {} },}
</script>

<style scoped>
			.te-c-1 {
				fill-rule: evenodd;
				fill: url(#te-ng_1);
			}

			.te-c-2 {
				fill: #fff;
			}


			.te-c-3 {
				opacity: 0.5;
			}
		</style>

