<template>
<svg data-svg-lvl-3="cover-top-seamPerimeter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Прошивка_по_периметру_Контур" ><g class="tsp-c-3"><path class="tsp-c-4" d="M380.14,77.45V409.93a6.07,6.07,0,0,1-6.06,6.06H116.68a3.55,3.55,0,0,1-3.55-3.55"/><path class="tsp-c-4" d="M113.13,33.67a3.55,3.55,0,0,0,3.55,3.55H357"/></g><g class="tsp-c-5"><line class="tsp-c-6" x1="380.34" y1="77.65" x2="380.34" y2="81.65"/><path class="tsp-c-7" d="M380.34,85.68V410.13a6.07,6.07,0,0,1-6.06,6.06h-257"/><path class="tsp-c-6" d="M115.35,415.85a3.54,3.54,0,0,1-2-3.21"/><path class="tsp-c-6" d="M113.33,33.87a3.55,3.55,0,0,0,2,3.21"/><line class="tsp-c-8" x1="119.41" y1="37.42" x2="351.18" y2="37.42"/><line class="tsp-c-6" x1="353.23" y1="37.42" x2="357.23" y2="37.42"/></g><g class="tsp-c-9"><line class="tsp-c-10" x1="379.94" y1="77.25" x2="379.94" y2="81.25"/><path class="tsp-c-11" d="M379.94,85.28V409.73a6.07,6.07,0,0,1-6.06,6.06h-257"/><path class="tsp-c-10" d="M115,415.45a3.54,3.54,0,0,1-2-3.21"/><path class="tsp-c-10" d="M112.93,33.47a3.55,3.55,0,0,0,2,3.21"/><line class="tsp-c-12" x1="119.01" y1="37.02" x2="350.78" y2="37.02"/><line class="tsp-c-10" x1="352.83" y1="37.02" x2="356.83" y2="37.02"/></g></g><g id="Прошивка_по_периметру_Цвет" ><line :style="{ stroke: params.seamColor }"  class="tsp-c-13" x1="380.14" y1="77.45" x2="380.14" y2="81.45"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-14" d="M380.14,85.48V409.93a6.07,6.07,0,0,1-6.06,6.06h-257"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-13" d="M115.15,415.65a3.54,3.54,0,0,1-2-3.21"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-13" d="M113.13,33.67a3.56,3.56,0,0,0,2,3.21"/><line :style="{ stroke: params.seamColor }"  class="tsp-c-15" x1="119.21" y1="37.22" x2="350.98" y2="37.22"/><line :style="{ stroke: params.seamColor }"  class="tsp-c-13" x1="353.03" y1="37.22" x2="357.03" y2="37.22"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsp-c-1{opacity:0.3;}.tsp-c-2{fill:#64ff00;}.tsp-c-3{opacity:0.5;}.tsp-c-10,.tsp-c-11,.tsp-c-12,.tsp-c-13,.tsp-c-14,.tsp-c-15,.tsp-c-4,.tsp-c-6,.tsp-c-7,.tsp-c-8{fill:none;stroke-linecap:round;stroke-miterlimit:2;}.tsp-c-4,.tsp-c-6,.tsp-c-7,.tsp-c-8{stroke:#000;}.tsp-c-4{stroke-width:0.5px;}.tsp-c-5{opacity:0.6;}.tsp-c-10,.tsp-c-11,.tsp-c-12,.tsp-c-13,.tsp-c-14,.tsp-c-15,.tsp-c-6,.tsp-c-7,.tsp-c-8{stroke-width:1.14px;}.tsp-c-11,.tsp-c-14,.tsp-c-7{stroke-dasharray:8.07 4.03;}.tsp-c-12,.tsp-c-15,.tsp-c-8{stroke-dasharray:8.2 4.1;}.tsp-c-9{opacity:0.7;}.tsp-c-10,.tsp-c-11,.tsp-c-12{stroke:#fff;}.tsp-c-13,.tsp-c-14,.tsp-c-15{stroke:#667222;}</style>

