<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M113.12,413.4H374.94a2.6,2.6,0,0,0,2.6-2.59V65.58L351.78,39.82H113.12"/><line class="tss-c-4" x1="354.08" y1="35.02" x2="113.33" y2="35.02"/><path class="tss-c-4" d="M113.33,418.6H375.14a7.6,7.6,0,0,0,7.6-7.59V63.67"/><line class="tss-c-5" x1="353.88" y1="34.82" x2="113.13" y2="34.82"/><path class="tss-c-5" d="M113.13,418.4H374.94a7.6,7.6,0,0,0,7.6-7.59V63.47"/><g class="tss-c-6"><line class="tss-c-7" x1="113.32" y1="413.6" x2="117.32" y2="413.6"/><path class="tss-c-8" d="M121.32,413.6H375.14a2.6,2.6,0,0,0,2.6-2.59V65.78L356.22,44.26"/><polyline class="tss-c-7" points="354.81 42.85 351.98 40.02 347.98 40.02"/><line class="tss-c-9" x1="344" y1="40.02" x2="119.31" y2="40.02"/><line class="tss-c-7" x1="117.32" y1="40.02" x2="113.32" y2="40.02"/></g><g class="tss-c-10"><line class="tss-c-11" x1="112.92" y1="413.2" x2="116.92" y2="413.2"/><path class="tss-c-12" d="M120.92,413.2H374.74a2.6,2.6,0,0,0,2.6-2.59V65.38L355.82,43.86"/><polyline class="tss-c-11" points="354.41 42.45 351.58 39.62 347.58 39.62"/><line class="tss-c-13" x1="343.6" y1="39.62" x2="118.91" y2="39.62"/><line class="tss-c-11" x1="116.92" y1="39.62" x2="112.92" y2="39.62"/></g></g><g id="Шов_Цвет"><line :style="{ stroke: params.seamColor }"  class="tss-c-14" x1="113.12" y1="413.4" x2="117.12" y2="413.4"/><path :style="{ stroke: params.seamColor }"  class="tss-c-15" d="M121.12,413.4H374.94a2.6,2.6,0,0,0,2.6-2.59V65.58L356,44.06"/><polyline :style="{ stroke: params.seamColor }"  class="tss-c-14" points="354.61 42.65 351.78 39.82 347.78 39.82"/><line :style="{ stroke: params.seamColor }"  class="tss-c-16" x1="343.8" y1="39.82" x2="119.11" y2="39.82"/><line :style="{ stroke: params.seamColor }"  class="tss-c-14" x1="117.12" y1="39.82" x2="113.12" y2="39.82"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#ff931e;}.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-14,.tss-c-15,.tss-c-16,.tss-c-3,.tss-c-4,.tss-c-5,.tss-c-7,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-7,.tss-c-8,.tss-c-9{stroke:#000;}.tss-c-3,.tss-c-4,.tss-c-5{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-4{stroke:#fff;}.tss-c-14,.tss-c-15,.tss-c-16,.tss-c-5{stroke:#232728;}.tss-c-6{opacity:0.6;}.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-14,.tss-c-15,.tss-c-16,.tss-c-7,.tss-c-8,.tss-c-9{stroke-linecap:round;stroke-width:1.14px;}.tss-c-12,.tss-c-15,.tss-c-8{stroke-dasharray:7.99 4;}.tss-c-13,.tss-c-16,.tss-c-9{stroke-dasharray:7.95 3.98;}.tss-c-10{opacity:0.7;}</style>

