<template>
<svg data-svg-lvl-3="cover-top-seamPerimeter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Прошивка_по_периметру_Контур" ><path class="tsp-c-3" d="M113.13,33.67a3.55,3.55,0,0,0,3.55,3.55h257.4a6.06,6.06,0,0,1,6.06,6.06V409.93a6.07,6.07,0,0,1-6.06,6.06H116.68a3.55,3.55,0,0,1-3.55-3.55"/><g class="tsp-c-4"><path class="tsp-c-5" d="M113.33,33.87a3.55,3.55,0,0,0,2,3.21"/><path class="tsp-c-6" d="M119.31,37.42h255a6.06,6.06,0,0,1,6.06,6.06V410.13a6.07,6.07,0,0,1-6.06,6.06h-257"/><path class="tsp-c-5" d="M115.35,415.85a3.54,3.54,0,0,1-2-3.21"/></g><g class="tsp-c-7"><path class="tsp-c-8" d="M112.93,33.47a3.55,3.55,0,0,0,2,3.21"/><path class="tsp-c-9" d="M118.91,37h255a6.06,6.06,0,0,1,6.06,6.06V409.73a6.07,6.07,0,0,1-6.06,6.06h-257"/><path class="tsp-c-8" d="M115,415.45a3.54,3.54,0,0,1-2-3.21"/></g></g><g id="Прошивка_по_периметру_Цвет" ><path :style="{ stroke: params.seamColor }"  class="tsp-c-10" d="M113.13,33.67a3.56,3.56,0,0,0,2,3.21"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-11" d="M119.11,37.22h255a6.06,6.06,0,0,1,6.06,6.06V409.93a6.07,6.07,0,0,1-6.06,6.06h-257"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-10" d="M115.15,415.65a3.54,3.54,0,0,1-2-3.21"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsp-c-1{opacity:0.3;}.tsp-c-2{fill:#64ff00;}.tsp-c-10,.tsp-c-11,.tsp-c-3,.tsp-c-5,.tsp-c-6,.tsp-c-8,.tsp-c-9{fill:none;stroke-linecap:round;stroke-miterlimit:2;}.tsp-c-3,.tsp-c-5,.tsp-c-6{stroke:#000;}.tsp-c-3{stroke-width:0.5px;opacity:0.5;}.tsp-c-4{opacity:0.6;}.tsp-c-10,.tsp-c-11,.tsp-c-5,.tsp-c-6,.tsp-c-8,.tsp-c-9{stroke-width:1.14px;}.tsp-c-11,.tsp-c-6,.tsp-c-9{stroke-dasharray:8 4;}.tsp-c-7{opacity:0.7;}.tsp-c-8,.tsp-c-9{stroke:#fff;}.tsp-c-10,.tsp-c-11{stroke:#1b5b28;}</style>

