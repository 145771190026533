<template>
<svg data-svg-lvl-5="cover-top-draggableUpOrDown-draggable-embossing" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 53" preserveAspectRatio="xMidYMid slice" :x="draggable.x" :y="draggable.y" :width="draggable.w" :height="draggable.h"><defs><linearGradient :id="`te-ng_1_${draggable.id.pers}_${draggable.id.idx}`" x1="15" y1="0.58" x2="15" y2="53.71" gradientUnits="userSpaceOnUse">
<template v-if="!draggable.gradient">
  <stop :stop-color="draggable.color" />
</template>
<template v-else-if="draggable.color === '#840808'">
  <!-- Красный -->
  <stop offset="0" stop-color="#840808" />
  <stop offset=".3" stop-color="#ff1711" />
  <stop offset=".83" stop-color="#6a0404" />
  <stop offset="1" stop-color="#ff6d66" />
</template>
<template v-else-if="draggable.color === '#048B1B'">
  <!-- Зелёный -->
  <stop offset="0" stop-color="#048b1b" />
  <stop offset=".3" stop-color="#4cf664" />
  <stop offset=".64" stop-color="#113a17" />
  <stop offset="1" stop-color="#4cf664" />
</template>
<template v-else-if="draggable.color === '#1A1D97'">
  <!-- Синий -->
  <stop offset=".03" stop-color="#1a1d97" />
  <stop offset=".3" stop-color="#9bc7ff" />
  <stop offset=".64" stop-color="#1f2986" />
  <stop offset="1" stop-color="#818feb" />
</template>
<template v-else-if="draggable.color === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.3" stop-color="#fbe6a6" />
  <stop offset="0.83" stop-color="#6c4209" />
  <stop offset="1" stop-color="#efe9cd" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".3" stop-color="#fff" />
  <stop offset=".83" stop-color="#3c3e45" />
  <stop offset="1" stop-color="#979a9f" />
</template>
  </linearGradient></defs><g id="Тиснение_Цвет" :style="{ opacity: draggable.opacity }" ><path    :style="`fill: url(#te-ng_1_${draggable.id.pers}_${draggable.id.idx});`" d="M25.7.86H4.3A3.6,3.6,0,0,0,.76,4.51v44A3.6,3.6,0,0,0,4.3,52.14H25.7a3.6,3.6,0,0,0,3.54-3.65v-44A3.6,3.6,0,0,0,25.7.86M12.25,3.63H17.6a.88.88,0,0,1,0,1.76H12.25a.88.88,0,0,1,0-1.76m2.68,46.81a2.82,2.82,0,1,1,2.74-2.82,2.77,2.77,0,0,1-2.74,2.82M26.62,43H3.38V8.16H26.62Z"/></g><g id="Тиснение_Свет_Тень" ><g class="te-c-2"><path class="te-c-3" d="M19,4a.87.87,0,0,0-.86-.88H12.75a.86.86,0,0,0-.79.56.94.94,0,0,1,.29-.06H17.6a.87.87,0,0,1,.86.88.83.83,0,0,1-.06.32A.88.88,0,0,0,19,4Z"/><path class="te-c-3" d="M18.17,47.12a2.78,2.78,0,0,0-2.74-2.82,2.7,2.7,0,0,0-2.13,1.06,2.68,2.68,0,0,1,1.63-.56,2.78,2.78,0,0,1,2.74,2.82,2.85,2.85,0,0,1-.62,1.76A2.86,2.86,0,0,0,18.17,47.12Z"/><polygon class="te-c-3" points="27.11 42.53 27.11 7.66 3.88 7.66 3.88 8.16 26.61 8.16 26.61 42.53 27.11 42.53"/><path class="te-c-3" d="M26.2,51.64H4.8A3.6,3.6,0,0,1,1.26,48V4a3.61,3.61,0,0,1,.85-2.34A3.63,3.63,0,0,0,.76,4.51v44A3.6,3.6,0,0,0,4.3,52.14H25.7a3.46,3.46,0,0,0,2.69-1.31A3.41,3.41,0,0,1,26.2,51.64Z"/></g><g class="te-c-2"><path d="M11.69,48.12a2.77,2.77,0,0,0,2.74,2.82,2.69,2.69,0,0,0,2.12-1.06,2.63,2.63,0,0,1-1.62.56,2.77,2.77,0,0,1-2.74-2.82,2.82,2.82,0,0,1,.61-1.76A2.85,2.85,0,0,0,11.69,48.12Z"/><path d="M10.89,5a.87.87,0,0,0,.86.88H17.1a.86.86,0,0,0,.8-.56.73.73,0,0,1-.3.06H12.25a.86.86,0,0,1-.79-1.2A.88.88,0,0,0,10.89,5Z"/><path d="M25.7.86H4.3A3.46,3.46,0,0,0,1.61,2.17,3.41,3.41,0,0,1,3.8,1.36H25.2A3.6,3.6,0,0,1,28.74,5V49a3.61,3.61,0,0,1-.85,2.34,3.63,3.63,0,0,0,1.35-2.84v-44A3.6,3.6,0,0,0,25.7.86Z"/><polygon points="2.88 8.66 2.88 43.53 26.11 43.53 26.11 43.03 3.38 43.03 3.38 8.66 2.88 8.66"/></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, draggable: { type: Object, required: true }, }, data() { return {} },}
</script>

<style scoped>.te-c-1{fill:url(#te-ng_1);}.te-c-2{opacity:0.5;}.te-c-3{fill:#fff;}</style>

