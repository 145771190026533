<template>
<svg data-svg-lvl-3="flyleaffront-block-flyleaffront" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lff-m-f" x="0" y="0" width="500" height="430" fill="#ffffff"><path  d="M446.61,98.63a12,12,0,0,0-12-12H269.14c-4.7,0-12.4,1.62-12.4,6v1.27H173c-4.59,16.55-22.69,84.61-22.69,129.64,0,44.75,17.87,112.17,22.6,129.23h84c0-2.56,5.57-4.64,12.39-4.64H434.6a12,12,0,0,0,12-12Z"/></mask></defs><g id="Форзац"> <path  :style="{ fill: params.flyleaffrontColor }"  class="lff-c-3" d="M446.61,98.63a12,12,0,0,0-12-12H269.14c-4.7,0-12.4,1.62-12.4,6v1.27H173c-4.59,16.55-22.69,84.61-22.69,129.64,0,44.75,17.87,112.17,22.6,129.23h84c0-2.56,5.57-4.64,12.39-4.64H434.6a12,12,0,0,0,12-12Z"/> <image v-if="params.flyleaffrontImage" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.flyleaffrontImage" mask="url(#lff-m-f)" /> </g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lff-c-1{opacity:0.3;}.lff-c-2{fill:#64ff00;}.lff-c-3{fill:#f4e8ce;}</style>

