<template>
  <div class="cntnr options">
    <div class="header">
      <div class="header__text" v-if="optionsData.header">{{ optionsData.header }}</div>
      <div class="header__close" @click="$emit('input', false)">
        <IconsIconClose />
      </div>
    </div>
    <div class="options-content scroller">
      <div class="options-item d-flex" v-for="(val, key) in optionsData.params" :key="key">
        <div class="options-key">{{ key }}</div>
        <div class="options-val">{{ val }}</div>
      </div>

      <div class="options-item-pers" v-for="(val, key) in optionsData.params2" :key="key">
        <div class="options-name">
          <span class="_bold">{{ val.name }}</span
          >{{ val.params ? ` - ${val.params}` : '' }}
        </div>
        <div class="options-price _bold">{{ val.price | thousands }} {{ $t(`priceBar.rub`) }}</div>
        <div class="options-trash" :class="{ _disabled: !val.enabled }" @click="delPers(val)">
          <IconsIconTrash />
        </div>
      </div>

      <div class="options-item d-flex" v-if="optionsData.availability">
        <div class="options-key">{{ $t(`ui.availability`) }}</div>
        <div class="options-val">{{ optionsData.availability }}</div>
      </div>
      <div class="options-item d-flex" v-if="optionsData.delivery">
        <div class="options-key">{{ $t(`block.options.delivery`) }}</div>
        <div class="options-val">{{ optionsData.delivery }}</div>
      </div>

      <div class="description" v-if="optionsData.description" v-html="optionsData.description"></div>
    </div>

    <div class="ac-footer" v-if="optionsData.params2 && optionsData.params2.length > 0">
      <UiButton class="btn-lg m-4" btnSize="lg" :disabled="disabledClearBtn" @click="delPersAll">
        {{ $t(`personalizations.clear`) }}
      </UiButton>
    </div>
  </div>
</template>

<script>
// import { mapActions } from 'vuex'
import changeQuery from '/src/mixins/changeQuery'

export default {
  mixins: [changeQuery],
  props: {
    /** @var optionsData - объект для отрисовки параметров материала или блока.
     * Объект должен содержать следующие поля
     * @property {string} header - заголовок
     * @property {object} params - список параметров в формате ключ-значения
     * @property {string} description - описание(можно в html)
     * @property {string} availability - наличие
     * @property {string} delivery - ближайшая поставка
     */
    optionsData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    disabledClearBtn() {
      let disabledClearBtn = true
      if (this.optionsData?.params2?.length > 0) {
        for (let pers of this.optionsData.params2) {
          if (pers.enabled) {
            disabledClearBtn = false
          }
        }
      }
      return disabledClearBtn
    },
  },

  methods: {
    async delPersAll() {
      if (this.optionsData?.params2?.length > 0) {
        for (let pers of this.optionsData.params2) {
          if (pers.enabled) {
            await this.delPers(pers)
          }
        }
      }
    },

    async delPers(pers) {
      if (pers.enabled !== true) {
        return
      }
      const newPersGroup = [...this.$route.query.personalizations[pers.idGroup]]
      newPersGroup.splice(pers.idPers, 1)

      const newQuery = {
        ...this.$route.query,
        personalizations: { ...this.$route.query.personalizations },
      }
      if (newPersGroup.length) {
        newQuery.personalizations[pers.idGroup] = newPersGroup
      } else {
        delete newQuery.personalizations[pers.idGroup]
      }

      await this.changeQuery(newQuery)
    },
  },
}
</script>

<style lang="sass" scoped>
.options
  display: flex
  flex-direction: column
  height: 100%
  &-content
    flex-grow: 1
  .header
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin-top: 20px
    height: 62px
    margin-bottom: 30px

    &__text
      align-self: flex-end
      font-family: 'Eurostile'
      font-weight: 600
      font-size: 24px
      line-height: 32px
    &__close
      width: 40px
      height: 40px
      cursor: pointer
      flex-shrink: 0
      &::v-deep path
        fill: $icon2-color
      &:hover::v-deep path
        fill: $dark-text
    @media (max-width: 1199px)
      margin-top: 0
      height: auto
      &__text
        margin-top: 6px
        align-self: flex-start


  .options-item
    margin-top: 10px
    font-size: 16px
    line-height: 24px
    .options-key
      width: 170px
      margin-right: 30px
      font-weight: bold
    .options-val
      width: 290px
    &.availability
      line-height: 32px
      .options-key
        font-family: 'Eurostile'
        font-weight: 600
        font-size: 24px
    &.delivery
      .options-key, .options-val
        margin-top: 5px
        font-size: 14px
        line-height: 21px
        font-weight: normal
        color: $light-text-dark-stroke


  .options-item-pers
    display: flex
    justify-content: flex-end
    margin-top: 11px
    .options-name
      flex-grow: 1
      font-size: 14px
    .options-price
      flex-shrink: 0
      white-space: nowrap
      font-size: 16px
      margin: 0 10px
    .options-trash
      flex-shrink: 0
      line-height: 20px
      width: 24px
      height: 24px

      ::v-deep path
        fill: $icon2-color
      &:hover::v-deep path
        fill: $dark-text
      &._disabled::v-deep path
          fill: $stroke-color
      &:not(._disabled)
        cursor: pointer

  .description
    font-size: 16px
    line-height: 24px
    margin-top: 30px
    margin-bottom: 35px
  @media (max-width: 1199px)
    padding: 10px

  .ac-footer
    display: flex
    justify-content: center
    margin: 30px
._bold
  font-weight: bold
</style>
