<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bc-lg" x1="61.46" y1="226.86" x2="452.54" y2="226.86" gradientUnits="userSpaceOnUse"><stop offset="0.47" stop-color="#727272"/><stop offset="0.48" stop-color="#545454"/><stop offset="0.48" stop-color="#4d4d4d"/><stop offset="0.49" stop-color="#454545"/><stop offset="0.49" stop-color="#424242"/><stop offset="0.5" stop-color="#3a3a3a"/><stop offset="0.5" stop-color="#2b2b2b"/><stop offset="0.5" stop-color="#232323"/><stop offset="0.5" stop-color="#272727"/><stop offset="0.5" stop-color="#383838"/><stop offset="0.5" stop-color="#424242"/><stop offset="0.51" stop-color="#454545"/><stop offset="0.51" stop-color="#494949"/><stop offset="0.52" stop-color="#545454"/><stop offset="0.53" stop-color="#727272"/></linearGradient></defs><g id="Задник_Контур"><path class="bc-c-3" d="M255.59,362.05c.17,1.41.36,2.89,1.27,2.89s1.09-1.37,1.18-2.89"/><path class="bc-c-4" d="M440.54,90.13H268.1a13.52,13.52,0,0,0-10.33,4.72,9.47,9.47,0,0,0-1,2.41,11.12,11.12,0,0,0-1.09-2.42,13.89,13.89,0,0,0-10.35-4.71H73.46a12,12,0,0,0-12,12V344.24a12,12,0,0,0,12,12H247.29c3.05,0,7.8,3.12,9.14,5.73l.43,1.62.24-1.52c.94-2.12,5.44-5.83,9.11-5.83H440.54a12,12,0,0,0,12-12V102.13A12,12,0,0,0,440.54,90.13Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:none;stroke-linecap:round;}.bc-c-3,.bc-c-4{stroke:#232728;stroke-linejoin:round;stroke-width:0.75px;}.bc-c-4{fill:url(#bc-lg);}</style>

