<template>
<svg data-svg-lvl-3="spread-back-label" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="blb-lg" x1="227.69" y1="304.91" x2="236.09" y2="304.91" gradientTransform="translate(-167.07 609.82) scale(1 -1)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#83858a"/><stop offset=".18" stop-color="#ccc"/><stop offset=".63" stop-color="#83858a"/><stop offset=".77" stop-color="#c2c5cc"/><stop offset=".92" stop-color="#3c3e45"/></linearGradient></defs><g id="УголкиЗадник"><path class="blb-c-1" d="M63.62,267.02h5.4v75.77h-5.4c-1.66,0-3-1.34-3-3v-69.77c0-1.66,1.34-3,3-3Z" transform="translate(129.64 609.82) rotate(-180)"/><path class="blb-c-2" d="M66.02,267.02c1.66,0,3,1.34,3,3v69.77c0,1.66-1.34,3-3,3h-5.4v-75.77h5.4M66.02,266.27h-6.15v77.27h6.15c2.07,0,3.75-1.68,3.75-3.75v-69.77c0-2.07-1.68-3.75-3.75-3.75h0Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.blb-c-1{fill:url(#blb-lg);}.blb-c-1,.blb-c-2,.blb-c-3{stroke-width:0px;}.blb-c-4{opacity:.3;}.blb-c-2{fill:#232728;}.blb-c-3{fill:#64ff00;}</style>

