<template>
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19" stroke-width="2" />
    <path
      d="M27.6359 15.1348L24.8103 12.3092C24.4415 11.9628 23.9582 11.764 23.4525 11.7507C22.9467 11.7374 22.4536 11.9105 22.0671 12.237L12.7858 21.5184C12.4524 21.8545 12.2449 22.2951 12.198 22.7662L11.7545 27.0665C11.7406 27.2176 11.7602 27.3698 11.8119 27.5124C11.8636 27.655 11.9461 27.7845 12.0536 27.8915C12.1499 27.9871 12.2642 28.0627 12.3899 28.1141C12.5155 28.1654 12.6501 28.1914 12.7858 28.1906H12.8786L17.179 27.7987C17.65 27.7518 18.0906 27.5443 18.4268 27.2109L27.7081 17.9296C28.0684 17.549 28.2631 17.0411 28.2495 16.5173C28.236 15.9934 28.0153 15.4963 27.6359 15.1348V15.1348ZM16.9933 25.7362L13.8995 26.025L14.178 22.9312L20.0046 17.1767L22.789 19.9612L16.9933 25.7362ZM24.1297 18.5793L21.3659 15.8155L23.3768 13.753L26.1922 16.5683L24.1297 18.5793Z"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="sass" scoped>
svg
  display: block
  transition: .3s
  circle
    fill: $contrast-regular
    stroke: $white
  path
    fill: $white
</style>
