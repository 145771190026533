<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="bc-cp"><rect class="bc-c-1" x="63.47" y="63.07" width="386.96" height="141.42"/></clipPath><clipPath id="bc-cp-2"><rect class="bc-c-1" x="63.44" y="39.07" width="386.93" height="386.93"/></clipPath></defs><g id="Задник_Контур"><rect class="bc-c-4" x="61.41" y="204.52" width="391.19" height="176.81"/><rect class="bc-c-4" x="61.41" y="60.67" width="391.19" height="143.85"/><g class="bc-c-5"><g class="bc-c-6"><image width="1024" height="1024" transform="translate(63.44 39.07) scale(0.38)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/spread/back/contour/PH1V_1.webp`"/></g></g><polyline class="bc-c-7" points="446.32 204.49 450.43 204.49 450.43 63.06 63.47 63.06 63.47 204.49 67.57 204.49"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1,.bc-c-7{fill:none;}.bc-c-2{opacity:0.3;}.bc-c-3{fill:#64ff00;}.bc-c-4{fill:gray;}.bc-c-4,.bc-c-7{stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.bc-c-5{clip-path:url(#bc-cp);}.bc-c-6{clip-path:url(#bc-cp-2);}</style>

