<template>
  <div class="tab">
    <div class="tab-inner" ref="tabInner">
      <template v-for="(item, idx) in items">
        <div
          ref="item"
          v-if="item.hidden !== true"
          :key="item.id"
          class="item"
          :class="{ selected: item.id === value.id, thin: thin }"
          @click="input(item, $event, idx)"
        >
          <div class="name">
            {{ item.tName ? $t(item.tName) : item.name }}
            <span v-if="item.quantity > 0"> ({{ item.quantity }})</span>
          </div>
        </div>
      </template>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    /** @var items - массив объектов для отрисовки списка. Объект должен
     * содержать следующие поля
     * @property {number} id - ключ для списка
     * @property {string} tName - ключ i18n для надписи
     * Необязательные поля
     * @property {string} name - наименования для надписи(вместо tName)
     * @property {boolean} hidden - скрытый элемент
     * @property {number} quantity - количество
     *
     */
    items: {
      type: Array,
      required: true,
    },
    /** @var value - выбранный объект из items */
    value: {
      type: Object,
      required: true,
    },
    /** @var thin - плоский таб */
    thin: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {}
  },
  mounted() {
    // this.$nextTick(() => {
    this.toCenter()
    // })
  },
  computed: {
    ...mapGetters({
      windowWidth: 'window/WIDTH',
    }),
  },
  watch: {
    value() {
      this.toCenter()
    },
    windowWidth() {
      this.toCenter()
    },
  },
  methods: {
    input(item) {
      if (item.id !== this.value.id) {
        this.$emit('input', item)
      }
    },

    toCenter() {
      this.$nextTick(() => {
        let idx = 0
        let valueIdx = this.items.findIndex((n) => n.id === this.value.id)
        if (valueIdx > 0) {
          idx = valueIdx
        }

        let leftWidth = 0
        let valueWidth = 0
        for (let idxCurrent in this.$refs.item) {
          let sizes = this.$refs.item[idxCurrent].getBoundingClientRect()
          if (idxCurrent < idx) {
            leftWidth += sizes.width
          }
          if (Number(idxCurrent) === idx) {
            valueWidth = sizes.width
          }
        }

        let { width: tabWidth } = this.$refs.tabInner.getBoundingClientRect()
        let offset = leftWidth - (tabWidth - valueWidth) / 2
        this.$refs.tabInner.scrollTo({
          left: offset,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.tab
  width: 100%
  max-width: 100vw
  margin-right: auto
  margin-left: auto
  overflow: hidden
  .tab-inner
    display: flex
    flex-wrap: nowrap
    overflow: auto
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none
    .item
      position: relative
      padding: 14px
      cursor: pointer
      .name
        font-size: 14px
        line-height: 20px
        white-space: nowrap
      &.thin
        padding: 5px 14px
      &.selected
        border-bottom: 2px $contrast-regular solid
</style>
