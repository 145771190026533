<template>
  <div id="cnstr" :class="{ mt80, mb0 }">
    <UiNavigator />

    <!-- <component class="trans" :is="stepComponent" /> -->
    <!-- <transition name="slide"> <DevPage v-if="currentStep === 'dev'" /> </transition>
    <transition name="slide"> <OrdersPage v-if="currentStep === 'orders'" /> </transition>
    <transition name="slide"> <PersonalizationsPage v-if="currentStep === 'personalizations'" /> </transition>
    <transition name="slide"> <MaterialsPage v-if="currentStep === 'materials'" /> </transition>
    <transition name="slide"> <BlockPage v-if="currentStep === 'block'" /> </transition>
    <transition name="slide"> <ModelPage v-if="currentStep === 'model'" /> </transition>
    <transition name="slide"> <ProductPage v-if="currentStep === 'product'" /> </transition> -->
    <div class="wrapper">
      <transition name="slide">
        <component :is="`${currentStep}-page`" />
      </transition>
    </div>

    <UiPricebar />
    <UiPreloader v-show="showPreloader" fullscreen />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getOrderByDog } from '/src/utils/api/getOrderByDog'
import currentStep from './mixins/currentStep'
import api from '/src/mixins/api'
import changeQuery from '/src/mixins/changeQuery'

export default {
  name: 'App',

  mixins: [currentStep, api, changeQuery],
  components: {
    UiNavigator: () => import('/src/components/Navigator'),
    UiPricebar: () => import('/src/components/Pricebar'),
    ProductPage: () => import('/src/components/product/Page'),
    ModelPage: () => import('/src/components/model/Page'),
    BlockPage: () => import('/src/components/block/Page'),
    MaterialsPage: () => import('/src/components/materials/Page'),
    PersonalizationsPage: () => import('/src/components/personalizations/Page'),
    OrdersPage: () => import('/src/components/orders/Page'),

    DevPage: () => import('/src/components/dev/Page'),
  },

  data: function () {
    return {
      show: true,
    }
  },

  async created() {
    const { product } = this.$route.query
    let { dog101, dog100 } = this.$CONST
    if (!(dog101 > 0) && this.$route?.query?.dog101) {
      dog101 = this.$route.query.dog101
    }
    if (!(dog100 > 0) && this.$route?.query?.dog100) {
      dog100 = this.$route.query.dog100
    }

    if (this.$CONST.editMode && this.$CONST.order) {
      let order = { ...this.$CONST.order }
      this.$CONST.order = null
      if (dog101 > 0) order.query.dog101 = dog101
      if (dog100 > 0) order.query.dog100 = dog100
      this.clearConstructors()
      await this.addUpdateConstructors(order)

      if (this.currentStep !== 'orders') {
        await this.changeQuery({ ...order.query, step: 'personalizations' })
      }
      return
    }

    let orderId
    if (typeof this.$route.query.orderId === 'number' && this.$route.query.orderId > 0) {
      orderId = this.$route.query.orderId
    } else {
      orderId = Math.floor(Date.now() / 1000) // .toString(36)
      this.$router.replace({ query: { ...this.$route.query, orderId } })
    }

    if (!(product > 0) && dog101 > 0) {
      if (dog100 > 0) {
        const order = await getOrderByDog(this.api, { dog101, dog100 })
        this.$router.replace({
          query: {
            ...order.query,
            step: 'personalizations',
            dog101,
            dog100,
          },
        })
      } else {
        if (Number(dog101) !== Number(this.$route.query?.dog101)) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              dog101,
            },
          })
        }
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.setOffsetTop)
    window.addEventListener('resize', this.setWidth)
    this.setOffsetTop()
    this.setWidth()
  },

  destroyed() {
    window.removeEventListener('scroll', this.setOffsetTop)
    window.removeEventListener('resize', this.setWidth)
  },

  methods: {
    ...mapActions({
      setOffsetTop: 'window/SET_OFFSET_TOP',
      setWidth: 'window/SET_WIDTH',
      addUpdateConstructors: 'cart/ADD_UPDATE_CONSTRUCTORS',
      clearConstructors: 'cart/CLEAR_CONSTRUCTORS',
    }),
  },

  computed: {
    ...mapGetters({
      bp: 'window/BP',
      showPreloader: 'control/SHOW_PRELOADER',
    }),
    fixed() {
      return this.offsetTop > 40
    },
    mt80() {
      return !this.fixed && (this.currentStep === 'product' || this.currentStep === 'model')
    },
    mb0() {
      return this.currentStep === 'block'
    },
  },

  metaInfo() {
    return {
      title: this.$t(`title.${this.currentStep}`),
      // titleTemplate: '%s | Адъютант',
    }
  },
}
</script>
<style lang="sass" scoped>
#cnstr
  margin: 50px 0 80px
  .wrapper
    margin: 0 auto
    max-width: 1820px
    @media (max-width: 779px)
      margin:30px 0 15px 0
  &.mt80
    margin-top: 80px
  &.mb0
    margin-bottom: 0
.slide-enter-active
  transition: all .3s

.slide-enter
  opacity: 0
  transform: translateY(200px)
</style>

<style lang="sass">
@import "/src/assets/scss/main.sass"
</style>
