import lodash from 'lodash'
import { colors, colorsHex } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const envelope = {
  id: 'envelope',
  // tName:  'personalizations.envelope.envelope',
  template: 'modal',
  preview: 'flyleafend',

  originPersonalizationsIds: [
    // белые
    316, // Конверт на нахзац 9х13 белый
    318, // Конверт на нахзац  10х16 и 10.5х16 белый
    320, // Конверт на нахзац 15х21 и 15.5х21 белый
    326, // Конверт на нахзац  20х26, 20.5х27 белый

    // тонированные
    317, // Конверт на нахзац FreeNote 8.5х13 и 9х13 тонированный
    319, // Конверт на нахзац  Classic и  FreeNote FreeNote 10х16 и 10.5х16 тонированный
    323, // Конверт на нахзац 13x21,15х21,15.5х21 тонированный
    327, // Конверт на нахзац 20х26, 20.5х27 тонированный

    // цветные
    671, // Конверт на нахзац 10х16 и 10.5х16 цветной
    432, // Конверт на нахзац 15х21 и 15.5х21 цветной
    670, // Конверт на нахзац 20х26 и 20.5х27 цветной
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getColor(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  const idParam = 121
  const idValue = modelValues?.color?.id

  const personals = {
    white: [316, 318, 320, 326],
    toned: [317, 319, 323, 327],
    colored: [671, 432, 670],
  }

  let key = modelValues?.color?.id
  if (key !== 'white' && key !== 'toned') {
    key = 'colored'
  }
  const idsBuhta = lodash.intersection(this.originIdsAvailable, personals[key])
  const idBuhta = idsBuhta[0]

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
  ])
}

/**
 * Выбор цвета конверта
 */
function getColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations, originIdsAvailable } = params

  const personals = {
    white: [316, 318, 320, 326],
    toned: [317, 319, 323, 327],
    colored: [671, 432, 670],
  }
  let values = []
  const idParams = [121] // ,41

  for (const key in personals) {
    const idsBuhta = lodash.intersection(originIdsAvailable, personals[key])
    const idBuhta = idsBuhta[0]
    if (idBuhta) {
      if (key === 'colored') {
        if (originPersonalizations[idBuhta].params) {
          const param = originPersonalizations[idBuhta].params.find((item) => idParams.includes(item.id))
          if (param) {
            values = values.concat(
              param.values.map((n) => ({
                ...n,
                background: n.hex,
              })),
            )
          }
        }
      } else {
        values.push({
          id: key,
          tName: `personalizations.envelope.${key}`,
          background: colors[key],
          hex: colorsHex[key],
        })
      }
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.envelope.color',
      template: 'selectClassicColor',
      sort: 30,
      values,
    })

    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[0]
    } else {
      modelValues.color = values[0]
    }
  }
}
