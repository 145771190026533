<template>
<svg data-svg-lvl-3="spread-block-lasseNameplate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lln-ng_2" x1="286.73" y1="366.73" x2="270.36" y2="386.52" gradientUnits="userSpaceOnUse">
  <template v-if="params.lasse.nameplateColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset=".3" stop-color="#fbe6a6" />
  <stop offset=".83" stop-color="#6c4209" />
  <stop offset="1" stop-color="#efe9cd" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset="0.3" stop-color="#fff" />
  <stop offset="0.83" stop-color="#3c3e45" />
  <stop offset="1" stop-color="#979a9f" />
</template>
</linearGradient></defs><g id="Шильда_Контур"><path class="lln-c-3" d="M281.78,364.11l.16,2.51h.12a.45.45,0,0,1,.47.43l.05,1.1a.48.48,0,0,1-.46.51l-7.95.38a.47.47,0,0,1-.49-.45l-.05-1.08a.48.48,0,0,1,.46-.51h.14l-.19-2.56a13.12,13.12,0,1,0,7.74-.31Z"/><path class="lln-c-4" d="M277.76,376.33l-.27-4.72-5.84.33.14,2.36,3.15-.18.62,10.92,10.29-.58-.49-8.55Zm5.27,5.92-4.92.28-.21-3.64,4.93-.28Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lln-c-1{opacity:0.3;}.lln-c-2{fill:#64ff00;}.lln-c-3{stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;fill:url(#lln-ng_2);}.lln-c-4{opacity:0.5;}</style>

