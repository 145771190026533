import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'
import { getDraggableAreaMm, getPosition } from '/src/utils/draggable'

export const uvVarnish = {
  id: 'uvVarnish',
  tName: 'personalizations.uvVarnish.uvVarnish',
  template: 'boolean',

  originPersonalizationsIds: [
    388, // выборочный  УФ-лак
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getUVVarnish(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */

function getItemsBuhta({ modelValues }) {
  if (modelValues?.uvVarnish?.checked) {
    const originPersonalizations = this.originPersonalizations
    const idBuhta = 388

    return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [])
  } else return []
}

/**
 * Выбор УФ-лака
 */

function getUVVarnish(params) {
  const { store, oldModelValues, items, modelValues, originPersonalizations } = params

  let values = []

  if (originPersonalizations[388]) {
    values = [
      { id: 0, checked: true, r: 0, w: 49, h: 58 },
      { id: 1, checked: false },
    ]

    if (values.length > 0) {
      items.push({
        id: 'uvVarnish',
        tName: 'personalizations.uvVarnish.uvVarnish',
        template: 'checkbox',
        sort: 10,
        values,
      })
      if (oldModelValues.uvVarnish) {
        modelValues.uvVarnish = values.find((i) => i.checked === oldModelValues.uvVarnish.checked) ?? values[0]
        modelValues.uvVarnish = {
          ...modelValues.uvVarnish,
          ...oldModelValues.uvVarnish,
        }
      } else {
        const draggableArea = getDraggableAreaMm(store)
        const draggablePosition = getPosition({ w: values[0].w, h: values[0].h }, draggableArea)
        modelValues.uvVarnish = { ...values[0], ...draggablePosition }
      }
    }
  }
}
