<template>
<svg data-svg-lvl-2="flyleafend-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
<PreviewLayersFlyleafendBlockShadowD :params="params" v-if="!params.isFlap7" />
<PreviewLayersFlyleafendBlockShadowD_F7 :params="params" v-if="params.isFlap7" />
<PreviewLayersFlyleafendBlockFlyleafendD :params="params" v-if="!params.isFlap7" />
<PreviewLayersFlyleafendBlockFlyleafendD_F7 :params="params" v-if="params.isFlap7" />
<PreviewLayersFlyleafendBlockContourD :params="params" v-if="!params.isFlap7" />
<PreviewLayersFlyleafendBlockContourD_F7 :params="params" v-if="params.isFlap7" />
<component :params="params" :is="`PreviewLayersFlyleafendBlockPlasticP${params.plastic.flyleafendBlockComponent}`" v-if="params.plastic.flyleafendBlockComponent" />
<PreviewLayersFlyleafendBlockEnvelopeR :params="params" v-if="params.envelopeColor" />
<PreviewLayersFlyleafendBlockPocketR :params="params" v-if="params.flyleafendPocket" />
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>