<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="bc-cp"><path class="bc-c-1" d="M452.59,204.52H61.41V68.17a7.5,7.5,0,0,1,7.5-7.5H445.09a7.5,7.5,0,0,1,7.5,7.5Z"/></clipPath></defs><g id="Задник_Контур"><g class="bc-c-4"><rect class="bc-c-5" x="61.43" y="60.72" width="391.84" height="272.13"/><g class="bc-c-6"><image width="276" height="396" transform="matrix(0, 0.99, -0.99, 0, 453.25, 60.67)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/spread/back/contour/PH11V_1.webp`"/></g></g><path class="bc-c-1" d="M452.59,204.52H61.41V68.17a7.5,7.5,0,0,1,7.5-7.5H445.09a7.5,7.5,0,0,1,7.5,7.5Z"/><rect class="bc-c-7" x="61.41" y="204.52" width="391.19" height="176.81"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{fill:none;stroke-miterlimit:10;}.bc-c-1,.bc-c-5{stroke:#252525;stroke-width:0.75px;}.bc-c-2{opacity:0.3;}.bc-c-3{fill:#64ff00;}.bc-c-4{clip-path:url(#bc-cp);}.bc-c-5{fill:#b3b3b3;opacity:0.2;}.bc-c-5,.bc-c-7{stroke-miterlimit:2;}.bc-c-6{opacity:0.5;}.bc-c-7{fill:gray;stroke:#232728;stroke-width:0.75px;}</style>

