<template>
<svg data-svg-lvl-3="edge-top-flap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Хлястик_Контур"><path :style="{ fill: params.flap.sliceColor }"  class="tf-c-3" d="M468.42,250.79H379a1.85,1.85,0,1,1,0-3.7h89.63c1.87,0,4.23-4.72,4.23-26.59s-2.92-25.59-4.23-25.59H335.85a1.85,1.85,0,0,1,0-3.7H468.14c4.58,0,8.46,4.79,8.46,29.16S473.33,250.79,468.42,250.79Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tf-c-1{opacity:0.3;}.tf-c-2{fill:#64ff00;}.tf-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

