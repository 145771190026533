<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="tc-rg" cx="378.24" cy="128.33" r="242.97" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.2"/><stop offset="0.44" stop-color="#e5e5e5" stop-opacity="0"/></radialGradient><linearGradient id="tc-lg" x1="111.57" y1="227.15" x2="227.86" y2="227.15" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.15"/><stop offset="0.68" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="tc-lg-2" x1="111.67" y1="227.15" x2="172.76" y2="227.15" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.4"/><stop offset="0.07" stop-color="#fff" stop-opacity="0"/></linearGradient></defs><g id="Крышки_Контур"><path class="tc-c-3" d="M381,123.19H111.57V331.11H381.89a8.25,8.25,0,0,0,8.25-8.25V132.32A9.13,9.13,0,0,0,381,123.19Z"/><rect class="tc-c-4" x="111.57" y="123.2" width="116.29" height="207.92"/><rect class="tc-c-5" x="111.57" y="123.2" width="61.1" height="207.92"/><path class="tc-c-6" d="M390.14,132.32a9.13,9.13,0,0,0-9.13-9.13H111.57V331.11H381.89a8.25,8.25,0,0,0,8.25-8.25Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#64ff00;}.tc-c-3{fill:url(#tc-rg);}.tc-c-4{fill:url(#tc-lg);}.tc-c-5{fill:url(#tc-lg-2);}.tc-c-6{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

