<template>
<svg data-svg-lvl-3="spread-block-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Контур"><polygon class="lc-c-3" points="66.88 112.84 66.88 352.73 446.61 352.73 446.61 112.46 420.76 86.61 93.1 86.61 66.88 112.84"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lc-c-1{opacity:0.3;}.lc-c-2{fill:#ff931e;}.lc-c-3{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}</style>

