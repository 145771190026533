<template>
<svg data-svg-lvl-5="cover-top-draggableUpOrDown-draggable-embossing" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 27" preserveAspectRatio="xMidYMid slice" :x="draggable.x" :y="draggable.y" :width="draggable.w" :height="draggable.h">
	<defs>
		
		<linearGradient :id="`te-ng_1_${draggable.id.pers}_${draggable.id.idx}`" x1="65.3972" y1="7.900817e-02" x2="65.3972" y2="27.7075"
			gradientUnits="userSpaceOnUse">
<template v-if="!draggable.gradient">
  <stop :stop-color="draggable.color" />
</template>
<template v-else-if="draggable.color === '#840808'">
  <!-- Красный -->
  <stop offset="0" stop-color="#840808" />
  <stop offset=".3" stop-color="#ff1711" />
  <stop offset=".83" stop-color="#6a0404" />
  <stop offset="1" stop-color="#ff6d66" />
</template>
<template v-else-if="draggable.color === '#048B1B'">
  <!-- Зелёный -->
  <stop offset="0" stop-color="#048b1b" />
  <stop offset=".3" stop-color="#4cf664" />
  <stop offset=".64" stop-color="#113a17" />
  <stop offset="1" stop-color="#4cf664" />
</template>
<template v-else-if="draggable.color === '#1A1D97'">
  <!-- Синий -->
  <stop offset=".03" stop-color="#1a1d97" />
  <stop offset=".3" stop-color="#9bc7ff" />
  <stop offset=".64" stop-color="#1f2986" />
  <stop offset="1" stop-color="#818feb" />
</template>
<template v-else-if="draggable.color === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.3" stop-color="#fbe6a6" />
  <stop offset="0.83" stop-color="#6c4209" />
  <stop offset="1" stop-color="#efe9cd" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".3" stop-color="#fff" />
  <stop offset=".83" stop-color="#3c3e45" />
  <stop offset="1" stop-color="#979a9f" />
</template>
  </linearGradient>
	</defs>
	
	<g id="Тиснение_Цвет" :style="{ opacity: draggable.opacity }" >
		<path    :style="`fill: url(#te-ng_1_${draggable.id.pers}_${draggable.id.idx});`"
			d="M28.4,23.7h12.9v2.7H25V1h16.1v2.7H28.4v8.2h11.1v2.7H28.4V23.7z M20.4,8.8c0,2.5-0.8,4.4-2.3,5.7
		c-1.6,1.3-3.8,2-6.7,2h-6v10H2V1h9.4c2.8,0,5,0.7,6.6,2.1C19.6,4.5,20.4,6.4,20.4,8.8z M17,8.8c0-1.5-0.5-2.7-1.4-3.6
		c-1-0.9-2.3-1.4-4-1.4H5.4v10h6.1c1.8,0,3.2-0.4,4.1-1.3C16.5,11.6,17,10.4,17,8.8z M58.2,13.4c1.3-0.6,2.3-1.3,3-2.3
		c0.7-1,1.1-2,1.1-3.2c0-2.3-0.8-4.1-2.4-5.3c-1.6-1.3-3.8-1.9-6.7-1.9c-1.6,0-3.1,0.3-4.4,0.9C47.6,2.1,46.5,3,45.8,4
		c-0.7,1.1-1.1,2.3-1.1,3.6H48c0-1.2,0.5-2.2,1.5-3.1c1-0.8,2.3-1.2,3.8-1.2c1.8,0,3.2,0.4,4.2,1.2c1,0.8,1.5,1.9,1.5,3.2
		c0,1.3-0.4,2.4-1.3,3.1c-0.9,0.7-2.2,1.1-3.9,1.2h-3.1v2.8h3.2c3.7,0.1,5.6,1.6,5.6,4.6c0,1.4-0.5,2.5-1.6,3.4
		c-1.1,0.9-2.6,1.3-4.4,1.3c-1.6,0-3-0.4-4.1-1.3c-1.1-0.9-1.6-2-1.6-3.3h-3.4c0,1.5,0.4,2.8,1.2,4c0.8,1.1,1.9,2,3.2,2.6
		c1.4,0.6,2.9,0.9,4.6,0.9c2.9,0,5.1-0.7,6.8-2c1.7-1.3,2.6-3.2,2.6-5.5C62.7,16.4,61.2,14.4,58.2,13.4z M128.8,19.1
		c0,2.3-0.7,4.1-2.2,5.4c-1.5,1.3-3.6,2-6.3,2h-8.9V1h8.4c2.8,0,4.9,0.6,6.3,1.7c1.4,1.1,2.1,2.8,2.1,5.1c0,1.2-0.3,2.2-1,3.2
		c-0.7,0.9-1.6,1.6-2.8,2.1c1.4,0.4,2.5,1.1,3.3,2.2C128.4,16.3,128.8,17.6,128.8,19.1z M114.8,11.9h5.1c1.5,0,2.7-0.4,3.5-1.1
		c0.9-0.7,1.3-1.7,1.3-3c0-1.4-0.4-2.4-1.2-3.1c-0.8-0.6-2.1-1-3.7-1h-5V11.9z M125.4,19.2c0-3.1-1.7-4.6-5-4.6h-5.6v9.2h5.6
		c1.6,0,2.8-0.4,3.7-1.2C125,21.7,125.4,20.6,125.4,19.2z M106.7,8.8c0,2.5-0.8,4.4-2.3,5.7c-1.6,1.3-3.8,2-6.7,2h-6v10h-3.4V1h9.4
		c2.8,0,5,0.7,6.6,2.1C105.9,4.5,106.7,6.4,106.7,8.8z M103.4,8.8c0-1.5-0.5-2.7-1.4-3.6c-1-0.9-2.3-1.4-4-1.4h-6.3v10h6.1
		c1.8,0,3.2-0.4,4.1-1.3C102.9,11.6,103.4,10.4,103.4,8.8z M70.9,14.7H82v-2.7H70.9V3.7h12.7V1H67.5v25.5h16.2v-2.7H70.9V14.7z" />

	</g>
	<g id="Тиснение_Свет_Тень" >
		<g class="te-c-3">
			<polygon points="40.6,3.7 41.1,3.7 41.1,1 25,1 25,1.5 40.6,1.5 		" />
			<polygon points="39.5,14.7 39.5,11.9 28.4,11.9 28.4,4.2 27.9,4.2 27.9,12.4 39,12.4 39,14.7 		" />
			<polygon points="28.4,15.2 27.9,15.2 27.9,24.2 40.8,24.2 40.8,26.5 41.3,26.5 41.3,23.7 28.4,23.7 		" />
			<path
				d="M5.4,13.8V4.2H4.9v10h6.1c1.8,0,3.2-0.4,4.1-1.3c0.1-0.1,0.2-0.3,0.4-0.4c-1,0.8-2.3,1.2-4,1.2H5.4z" />
			<rect x="4.9" y="17" width="0.5" height="9.5" />
			<path d="M18,3.1C16.4,1.7,14.2,1,11.4,1H2v0.5h8.9c2.8,0,5,0.7,6.6,2.1c1.6,1.4,2.4,3.3,2.4,5.7c0,2.1-0.6,3.8-1.7,5.1
			c1.5-1.3,2.2-3.2,2.2-5.6C20.4,6.4,19.6,4.5,18,3.1z" />
			<path d="M50.6,12.6h2.6c1.7,0,3-0.4,3.9-1.2c0.2-0.1,0.3-0.3,0.4-0.5c-0.9,0.7-2.1,1.1-3.8,1.1h-3.1V12.6z" />
			<path d="M53.3,24.1c-1.6,0-3-0.4-4.1-1.3c-1.1-0.9-1.6-2-1.6-3.3h-3.4c0,0.2,0,0.3,0,0.5h2.8c0,1.3,0.5,2.5,1.6,3.3
			c1.1,0.9,2.4,1.3,4.1,1.3c1.8,0,3.3-0.4,4.4-1.3c0.3-0.2,0.5-0.5,0.8-0.7c-0.1,0.1-0.2,0.2-0.3,0.2C56.6,23.7,55.1,24.1,53.3,24.1
			z" />
			<path d="M52.8,1.1c2.9,0,5.1,0.6,6.7,1.9c1.6,1.3,2.4,3.1,2.4,5.3c0,1.2-0.4,2.3-1.1,3.2c-0.1,0.1-0.2,0.3-0.4,0.4
			c0.3-0.3,0.6-0.6,0.9-0.9c0.7-1,1.1-2,1.1-3.2c0-2.3-0.8-4.1-2.4-5.3c-1.6-1.3-3.8-1.9-6.7-1.9c-1.6,0-3.1,0.3-4.4,0.9
			C47.8,2,47,2.7,46.3,3.4c0.6-0.6,1.3-1,2.1-1.4C49.7,1.4,51.2,1.1,52.8,1.1z" />
			<path d="M58.5,13.5c-0.2,0.1-0.5,0.3-0.8,0.4c3,1,4.5,3,4.5,6c0,1.8-0.6,3.4-1.7,4.6c1.4-1.3,2.2-3,2.2-5.1
			C62.7,16.5,61.3,14.5,58.5,13.5z" />
			<path d="M48,7.7c0-0.9,0.3-1.7,0.8-2.4c-0.7,0.7-1.2,1.5-1.3,2.4H48z" />
			<path d="M125.5,3.2c1.4,1.1,2.1,2.8,2.1,5.1c0,1.2-0.3,2.2-1,3.2c-0.1,0.1-0.2,0.3-0.3,0.4c0.3-0.3,0.6-0.6,0.8-0.9
			c0.7-0.9,1-2,1-3.2c0-2.2-0.7-3.9-2.1-5.1c-1.4-1.1-3.5-1.7-6.3-1.7h-8.4v0.5h7.9C122,1.5,124.1,2.1,125.5,3.2z" />
			<path
				d="M114.3,4.2v8.1h5.1c1.5,0,2.7-0.4,3.5-1.1c0.2-0.1,0.3-0.3,0.4-0.5c-0.9,0.7-2,1.1-3.5,1.1h-5.1V4.2H114.3z" />
			<path
				d="M114.8,23.7v-8.7h-0.5v9.2h5.6c1.6,0,2.8-0.4,3.7-1.2c0.1-0.1,0.2-0.3,0.4-0.4c-0.9,0.7-2.1,1.1-3.6,1.1H114.8z" />
			<path d="M128.8,19.1c0-1.5-0.4-2.8-1.2-3.9c-0.7-1-1.7-1.7-3-2.1c-0.3,0.1-0.5,0.3-0.8,0.4c1.4,0.4,2.5,1.1,3.3,2.2
			c0.8,1.1,1.2,2.4,1.2,3.9c0,2-0.6,3.6-1.7,4.8C128.1,23.2,128.8,21.4,128.8,19.1z" />
			<rect x="91.2" y="17" width="0.5" height="9.5" />
			<path d="M104.4,3.1c-1.6-1.4-3.8-2.1-6.6-2.1h-9.4v0.5h8.9c2.8,0,5,0.7,6.6,2.1c1.6,1.4,2.4,3.3,2.4,5.7c0,2.1-0.6,3.8-1.7,5.1
			c1.5-1.3,2.2-3.2,2.2-5.6C106.7,6.4,105.9,4.5,104.4,3.1z" />
			<path
				d="M91.7,13.8V4.2h-0.5v10h6.1c1.8,0,3.2-0.4,4.1-1.3c0.1-0.1,0.2-0.3,0.4-0.4c-1,0.8-2.3,1.2-4,1.2H91.7z" />
			<polygon points="83.1,3.7 83.6,3.7 83.6,1 67.5,1 67.5,1.5 83.1,1.5 		" />
			<polygon points="70.9,15.2 70.4,15.2 70.4,24.2 83.3,24.2 83.3,26.5 83.8,26.5 83.8,23.7 70.9,23.7 		" />
			<polygon points="82,14.7 82,11.9 70.9,11.9 70.9,4.2 70.4,4.2 70.4,12.4 81.5,12.4 81.5,14.7 		" />
		</g>

		<g class="te-c-2">
			<rect x="28.9" y="14.2" class="te-c-3" width="10.6" height="0.5" />
			<polygon class="te-c-3" points="25.5,1 25,1 25,26.5 41.3,26.5 41.3,26 25.5,26 		" />
			<rect x="28.9" y="3.2" class="te-c-3" width="12.2" height="0.5" />
			<path class="te-c-3" d="M15.6,5.2c1,0.9,1.4,2.1,1.4,3.6c0,1.4-0.4,2.5-1.1,3.3c0,0,0.1-0.1,0.1-0.1c1-0.8,1.4-2.1,1.4-3.7
			c0-1.5-0.5-2.7-1.4-3.6c-1-0.9-2.3-1.4-4-1.4H5.9v0.5h5.8C13.3,3.8,14.6,4.2,15.6,5.2z" />
			<path class="te-c-3" d="M11.9,16h-6v0.5h5.5c2.9,0,5.1-0.7,6.7-2c0.2-0.2,0.4-0.4,0.6-0.6c0,0-0.1,0.1-0.1,0.1
			C17,15.3,14.8,16,11.9,16z" />
			<polygon class="te-c-3" points="2.5,1 2,1 2,26.5 5.4,26.5 5.4,26 2.5,26 		" />
			<path class="te-c-3"
				d="M58.7,12.9c0.1,0,0.2,0.1,0.3,0.1c0.8-0.4,1.4-0.9,1.9-1.5C60.3,12.1,59.6,12.5,58.7,12.9z" />
			<path class="te-c-3" d="M53.3,3.4c1.8,0,3.2,0.4,4.2,1.2c1,0.8,1.5,1.9,1.5,3.2c0,1.1-0.3,2-0.9,2.7c0,0,0.1,0,0.1,0
			c0.9-0.7,1.3-1.8,1.3-3.1C59.5,6,59,4.9,58,4.1c-1-0.8-2.4-1.2-4.2-1.2c-1.5,0-2.8,0.4-3.8,1.2c-0.3,0.2-0.5,0.4-0.7,0.7
			c0.1-0.1,0.1-0.1,0.2-0.2C50.6,3.8,51.8,3.4,53.3,3.4z" />
			<path class="te-c-3" d="M48.1,7.2h-2.9c0-1.4,0.4-2.6,1.1-3.6c0.2-0.2,0.3-0.4,0.5-0.6c-0.4,0.3-0.7,0.7-1,1.1
			c-0.7,1.1-1.1,2.3-1.1,3.6H48C48,7.5,48.1,7.4,48.1,7.2z" />
			<path class="te-c-3" d="M59.9,18.9c0-3-1.9-4.5-5.6-4.6h-3.2v-2.3h-0.5v2.8h3.2c3.7,0.1,5.6,1.6,5.6,4.6c0,1-0.3,1.9-0.9,2.6
			C59.4,21.2,59.9,20.2,59.9,18.9z" />
			<path class="te-c-3" d="M53.8,26.3c-1.7,0-3.2-0.3-4.6-0.9c-1.4-0.6-2.5-1.5-3.2-2.6c-0.7-1-1-2.1-1.1-3.5h-0.5c0,1.5,0.4,2.8,1.2,4
			c0.8,1.1,1.9,2,3.2,2.6c1.4,0.6,2.9,0.9,4.6,0.9c2.9,0,5.1-0.7,6.8-2c0.3-0.3,0.6-0.5,0.9-0.8c-0.1,0.1-0.2,0.2-0.4,0.3
			C59,25.7,56.7,26.3,53.8,26.3z" />
			<path class="te-c-3" d="M123.5,4.7c0.8,0.6,1.2,1.7,1.2,3.1c0,1-0.3,1.9-0.9,2.5c0,0,0,0,0.1,0c0.9-0.7,1.3-1.7,1.3-3
			c0-1.4-0.4-2.4-1.2-3.1c-0.8-0.6-2.1-1-3.7-1h-5v0.5h4.5C121.4,3.7,122.7,4.1,123.5,4.7z" />
			<path class="te-c-3"
				d="M125.1,12.7c0.6-0.4,1.2-0.8,1.6-1.3c-0.5,0.5-1.2,0.9-1.9,1.2C124.9,12.6,125,12.6,125.1,12.7z" />
			<path class="te-c-3" d="M125.4,19.2c0,1.2-0.3,2.2-1,3c0,0,0.1-0.1,0.1-0.1c0.9-0.8,1.4-1.9,1.4-3.4c0-3.1-1.7-4.6-5-4.6h-5.6v0.5
			h5.1C123.7,14.6,125.4,16.1,125.4,19.2z" />
			<path class="te-c-3" d="M127.1,24c-1.5,1.3-3.6,2-6.3,2h-8.9V1h-0.5v25.5h8.9c2.7,0,4.8-0.7,6.3-2C126.8,24.4,126.9,24.2,127.1,24
			C127.1,24,127.1,24,127.1,24z" />
			<polygon class="te-c-3" points="88.8,1 88.3,1 88.3,26.5 91.7,26.5 91.7,26 88.8,26 		" />
			<path class="te-c-3" d="M101.9,5.2c1,0.9,1.4,2.1,1.4,3.6c0,1.4-0.4,2.5-1.1,3.3c0,0,0.1-0.1,0.1-0.1c1-0.8,1.4-2.1,1.4-3.7
			c0-1.5-0.5-2.7-1.4-3.6c-1-0.9-2.3-1.4-4-1.4h-6.3v0.5H98C99.6,3.8,101,4.2,101.9,5.2z" />
			<path class="te-c-3" d="M98.2,16h-6v0.5h5.5c2.9,0,5.1-0.7,6.7-2c0.2-0.2,0.4-0.4,0.6-0.6c0,0-0.1,0.1-0.1,0.1
			C103.4,15.3,101.1,16,98.2,16z" />
			<rect x="71.4" y="14.2" class="te-c-3" width="10.6" height="0.5" />
			<polygon class="te-c-3" points="68,1 67.5,1 67.5,26.5 83.8,26.5 83.8,26 68,26 		" />
			<rect x="71.4" y="3.2" class="te-c-3" width="12.2" height="0.5" />
		</g>
	</g>
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, draggable: { type: Object, required: true }, }, data() { return {} },}
</script>

<style scoped>
			.te-c-1 {
				fill-rule: evenodd;
				fill: url(#te-ng_1);
			}

			.te-c-2 {
				fill: #fff;
			}


			.te-c-3 {
				opacity: 0.5;
			}
		</style>

