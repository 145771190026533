<template>
<svg data-svg-lvl-3="cover-top-seamPerimeter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Прошивка_по_периметру_Контур" ><path class="tsp-c-3" d="M113.13,414.89a1.1,1.1,0,0,0,1.1,1.1H374.08a6.07,6.07,0,0,0,6.06-6.06V43.28a6.06,6.06,0,0,0-6.06-6.06H114.24A1.1,1.1,0,0,1,113.15,36h0"/><g class="tsp-c-4"><path class="tsp-c-5" d="M113.33,415.09a1.1,1.1,0,0,0,1.1,1.1h2.28"/><path class="tsp-c-6" d="M120.69,416.19H374.28a6.07,6.07,0,0,0,6.06-6.06V43.48a6.06,6.06,0,0,0-6.06-6.06H118.54"/><path class="tsp-c-5" d="M116.55,37.42h-2.11a1.1,1.1,0,0,1-1.09-1.27h0"/></g><g class="tsp-c-7"><path class="tsp-c-8" d="M112.93,414.69a1.1,1.1,0,0,0,1.1,1.1h2.28"/><path class="tsp-c-9" d="M120.29,415.79H373.88a6.07,6.07,0,0,0,6.06-6.06V43.08A6.06,6.06,0,0,0,373.88,37H118.14"/><path class="tsp-c-8" d="M116.15,37H114A1.1,1.1,0,0,1,113,35.75h0"/></g></g><g id="Прошивка_по_периметру_Цвет" ><path :style="{ stroke: params.seamColor }"  class="tsp-c-8" d="M113.13,414.89a1.1,1.1,0,0,0,1.1,1.1h2.28"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-9" d="M120.49,416H374.08a6.07,6.07,0,0,0,6.06-6.06V43.28a6.06,6.06,0,0,0-6.06-6.06H118.34"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-8" d="M116.35,37.22h-2.11A1.1,1.1,0,0,1,113.15,36h0"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsp-c-1{opacity:0.3;}.tsp-c-2{fill:#64ff00;}.tsp-c-3,.tsp-c-5,.tsp-c-6,.tsp-c-8,.tsp-c-9{fill:none;stroke-linecap:round;stroke-miterlimit:2;}.tsp-c-3,.tsp-c-5,.tsp-c-6{stroke:#000;}.tsp-c-3{stroke-width:0.5px;opacity:0.5;}.tsp-c-4{opacity:0.6;}.tsp-c-5,.tsp-c-6,.tsp-c-8,.tsp-c-9{stroke-width:1.14px;}.tsp-c-6,.tsp-c-9{stroke-dasharray:7.97 3.99;}.tsp-c-7{opacity:0.7;}.tsp-c-8,.tsp-c-9{stroke:#fff;}</style>

