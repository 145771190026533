import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const heatShrink = {
  id: 'heatShrink',
  tName: 'personalizations.heatShrink.heatShrink',
  template: 'boolean',

  originPersonalizationsIds: [
    340, // Упаковка изделий формата до 15x21 в термоусадочную пленку
    341, // Упаковка изделий формата  15х21, 15.5х21 см в термоусадочную пленку
    342, // Упаковка изделий формата больше 15х21 см и планингов в термоусадочную пленку
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getHeatShrink(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  if (modelValues?.heatShrink?.checked) {
    const originPersonalizations = this.originPersonalizations
    let idBuhta
    if (this.originPersonalizations[340]) {
      idBuhta = 340
    } else if (this.originPersonalizations[341]) {
      idBuhta = 341
    } else if (this.originPersonalizations[342]) {
      idBuhta = 342
    } else return []
    return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [])
  } else return []
}

/**
 * Выбор форзаца и/или нахзаца
 */
function getHeatShrink(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let values = []

  if (originPersonalizations[340] || originPersonalizations[341] || originPersonalizations[342]) {
    values = [
      { id: 0, checked: true },
      { id: 1, checked: false },
    ]

    if (values.length > 0) {
      items.push({
        id: 'heatShrink',
        tName: 'personalizations.heatShrink.heatShrink',
        template: 'checkbox',
        sort: 10,
        values,
      })
      if (oldModelValues.heatShrink) {
        modelValues.heatShrink = values.find((i) => i.checked === oldModelValues.heatShrink.checked) ?? values[0]
      } else {
        modelValues.heatShrink = values[0]
      }
    }
  }
}
