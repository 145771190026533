import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'
import { getDraggableAreaMm, getPosition } from '/src/utils/draggable'

export const magnet = {
  id: 'magnet',
  // tName:  'personalizations.magnet.magnet',
  template: 'modal',
  preview: 'cover',

  originPersonalizationsIds: [
    701, // Скрытые магниты под ручку 13х21, 15х21
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getCliche(params)
  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  const idBuhta = 701
  const idParam = 137
  const idValue = modelValues?.cliche?.id

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
  ])
}

/**
 * Выбор типа тиснения
 */
function getCliche(params) {
  const { store, oldModelValues, items, modelValues, originIdsAvailable, originPersonalizations } = params

  if (originIdsAvailable.length === 0) {
    return
  }

  const idBuhta = 701
  const idParam = 137
  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values
    }
  }
  if (values.length > 0) {
    items.push({
      id: 'cliche',
      tName: 'personalizations.magnet.cliche',
      template: 'selectRadio',
      sort: 10,
      values,
    })

    // Дефолтноое клише 60
    let defaultIndex = values.findIndex((n) => n.id === 4898)
    if (defaultIndex < 0) {
      defaultIndex = 0
    }

    values[defaultIndex] = { ...values[defaultIndex], r: 0, w: 14, h: 156 }
    if (oldModelValues.cliche) {
      modelValues.cliche = values.find((i) => i.id === oldModelValues.cliche.id) ?? values[defaultIndex]
      if (oldModelValues.cliche.id === 4898) {
        const { r, x, y, w, h } = oldModelValues.cliche
        if (x === undefined || y === undefined) {
          const draggableArea = getDraggableAreaMm(store)
          const draggablePosition = getPosition({ w, h }, draggableArea)
          modelValues.cliche = { ...modelValues.cliche, ...draggablePosition, x: 20 }
        } else {
          modelValues.cliche = { ...modelValues.cliche, r, x, y, w, h }
        }
      }
    } else {
      const draggableArea = getDraggableAreaMm(store)
      const draggablePosition = getPosition({ w: values[defaultIndex].w, h: values[defaultIndex].h }, draggableArea)
      modelValues.cliche = { ...values[defaultIndex], ...draggablePosition, x: 20 }
    }
  }
}
