<template>
  <div class="upload">
    <div class="item" @click="open">
      <div class="item__name">{{ $t('ui.upload') }}</div>
      <div class="item__image" :class="{ loading }">
        <IconsIconArrowCircle />
      </div>
    </div>
    <input id="file" ref="fileInput" type="file" hidden="hidden" @input="upload" />
  </div>
</template>

<script>
import api from '/src/mixins/api'
import { uploadImage } from '/src/utils/api/uploadImage'

export default {
  mixins: [api],
  props: {
    value: {
      type: Object,
      required: true,
    },
    /** @var label - имя метки */
    label: {
      type: String,
      default: () => 'Загрузить изображение',
    },
    /** @var tLabel - перевод метки */
    tLabel: {
      type: String,
      default: () => '',
    },
    width: {
      type: Number,
      default: () => 500,
    },
    height: {
      type: Number,
      default: () => 430,
    },
  },
  data() {
    return {
      file: '',
      loading: false,
    }
  },
  methods: {
    open() {
      if (!this.loading) {
        this.$refs.fileInput.click()
      }
    },

    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },

    async upload() {
      if (!(this.$refs.fileInput?.files?.length > 0)) {
        return
      }
      this.file = this.$refs.fileInput.files[0]
      this.loading = true

      this.$emit('loading', true)

      let type = this.file.type
      if (type === 'image/svg+xml') {
        type = 'image/svg'
      }
      const imgUrl = await uploadImage(this.api, {
        type,
        data: await this.toBase64(this.file),
        width: this.width,
        height: this.height,
      })
      this.$emit('input', {
        ...this.value,
        url: imgUrl,
      })
      this.loading = false
      this.$emit('loading', false)
    },
  },
}
</script>

<style lang="sass" scoped>
.upload

  .item
    background: #ffffff
    border-radius: 25px
    padding: 7px
    display: flex
    flex-direction: row
    gap: 5px
    align-items: center
    justify-content: flex-end
    position: relative
    box-shadow: var(--overlay-box-shadow, 0px 4px 4px 0px rgba(5, 45, 43, 0.3))
    overflow: hidden
    transition: .3s
    width: 44px
    &__name
      transition: .3s
      color: $white
      white-space: nowrap
    &:hover
      padding: 7px 7px 7px 12px
      width: 290px
      .item__name
        color: $black
      ::v-deep svg *
        fill: $light-text-dark-stroke



    &__image
      flex-shrink: 0
      position: relative
      overflow: visible
      width: 30px

      &.loading::v-deep path
        animation: loading .2s infinite alternate ease-in-out


@keyframes loading
  0%
    fill: $icon-color
  100%
    fill: $light-text-dark-stroke
</style>
