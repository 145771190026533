<template>
<svg data-svg-lvl-2="spread-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
<PreviewLayersSpreadBlockShadowD :params="params" v-if="!params.isFlap7" />
<PreviewLayersSpreadBlockShadowD_F7 :params="params" v-if="params.isFlap7" />
<PreviewLayersSpreadBlockSpreadD :params="params" v-if="!params.isFlap7" />
<PreviewLayersSpreadBlockSpreadD_F7 :params="params" v-if="params.isFlap7" />
<PreviewLayersSpreadBlockInsertsD :params="params" v-if="!params.isFlap7 && params.isInserts" />
<PreviewLayersSpreadBlockInsertsD_F7 :params="params" v-if="params.isFlap7 && params.isInserts" />
<PreviewLayersSpreadBlockContourD :params="params" v-if="!params.isFlap7" />
<PreviewLayersSpreadBlockContourD_F7 :params="params" v-if="params.isFlap7" />
<component :params="params" :is="`PreviewLayersSpreadBlockLasseRD`" v-if="params.isLasse" />
<component :params="params" :is="`PreviewLayersSpreadBlockLasseNameplate${params.lasseNameplateComponent}`" v-if="params.lasseNameplateComponent" />
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>