<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M113.13,318.5H374.94a2.6,2.6,0,0,0,2.6-2.59V138.39a2.61,2.61,0,0,0-2.6-2.6H113.13"/><g class="tss-c-4"><line class="tss-c-5" x1="113.33" y1="318.7" x2="117.33" y2="318.7"/><path class="tss-c-6" d="M121.34,318.7h253.8a2.6,2.6,0,0,0,2.6-2.59V138.59a2.61,2.61,0,0,0-2.6-2.6H119.33"/><line class="tss-c-5" x1="117.33" y1="135.99" x2="113.33" y2="135.99"/></g><g class="tss-c-7"><line class="tss-c-8" x1="112.93" y1="318.3" x2="116.93" y2="318.3"/><path class="tss-c-9" d="M120.94,318.3h253.8a2.6,2.6,0,0,0,2.6-2.59V138.19a2.61,2.61,0,0,0-2.6-2.6H118.94"/><line class="tss-c-8" x1="116.93" y1="135.59" x2="112.93" y2="135.59"/></g><path class="tss-c-10" d="M113.33,323.7H375.14a7.61,7.61,0,0,0,7.6-7.59V138.59a7.61,7.61,0,0,0-7.6-7.6H113.33"/><path class="tss-c-11" d="M113.13,323.5H374.94a7.61,7.61,0,0,0,7.6-7.59V138.39a7.61,7.61,0,0,0-7.6-7.6H113.13"/></g><g id="Шов_Цвет"><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="113.13" y1="318.5" x2="117.13" y2="318.5"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M121.14,318.5h253.8a2.6,2.6,0,0,0,2.6-2.59V138.39a2.61,2.61,0,0,0-2.6-2.6H119.13"/><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="117.13" y1="135.79" x2="113.13" y2="135.79"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-5,.tss-c-6{stroke:#000;}.tss-c-10,.tss-c-11,.tss-c-3{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-4{opacity:0.6;}.tss-c-12,.tss-c-13,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{stroke-linecap:round;stroke-width:1.14px;}.tss-c-13,.tss-c-6,.tss-c-9{stroke-dasharray:8.01 4.01;}.tss-c-7{opacity:0.7;}.tss-c-10,.tss-c-8,.tss-c-9{stroke:#fff;}.tss-c-11,.tss-c-12,.tss-c-13{stroke:#232728;}</style>

