<template>
  <div>
    <div class="hover" :class="{ area }" @click="$emit('click', true)">
      <div class="ac-button" :class="{ large }">
        <slot></slot>
      </div>
    </div>
    <div v-if="quantity > 0" class="circle">{{ quantity }}</div>
  </div>
</template>

<script>
export default {
  props: {
    quantity: {
      type: Number,
      default: () => 0,
    },
    large: {
      type: Boolean,
      default: () => false,
    },
    area: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style lang="sass" scoped>
.hover
  width: 40px
  height: 40px
  cursor: pointer
  .ac-button
    border-radius: 10px
    padding: 5px
    background-color: rgba(255, 255, 255, 1)
    box-shadow: 0px 2px 2px 0px rgba(5, 45, 43, 0.15)
    transition: .3s
    &.large
      padding: 10px
      width: 50px
      height: 50px
      position: absolute
      right: 0

  &:hover .ac-button
    box-shadow: 0px 4px 15px 0px rgba(0, 52, 49, 0.30)
    &::v-deep svg *
      fill: $light-text-dark-stroke
  &.area
    padding: 0 0 10px 10px
    width: 50px
    height: 50px
.circle
  font-size: 9px
  line-height: 16px
  font-weight: bold
  text-align: center
  position: absolute
  left: -5px
  bottom: -5px
  width: 16px
  height: 16px
  border-radius: 50%
  background: $contrast-regular
  color: $white
  transition: .3s
</style>
