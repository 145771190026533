import lodash from 'lodash'
import { colors, colorsHex } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'
import { getDraggableAreaMm, getPosition } from '/src/utils/draggable'

export const printing = {
  id: 'printing',
  // tName:  'personalizations.printing.printing',
  template: 'modal',
  preview: 'cover',
  multiple: true,

  originPersonalizationsIds: [
    // Сольвентная печать
    400, // Сольвентная печать до 1200 см2
    401, // Сольвентная печать до 680 см2
    402, // Сольвентная печать до 340 см2
    403, // Сольвентная печать до 200 см2
    404, // Сольвентная печать до 100 см2

    // УФ печать
    548, // УФ печать до 100 см2
    549, // УФ печать до 1200 см2
    550, // УФ печать до 200 см2
    551, // УФ печать до 340 см2
    552, // УФ печать до 680 см2

    // Рельефная печать
    603, // Рельефная УФ печать до 100 см2
    604, // Рельефная УФ печать до 200 см2
    605, // Рельефная УФ печать до 340 см2
    606, // Рельефная УФ печать до 680 см2
    607, // Рельефная УФ печать до 1200 см2

    // Печать фольгой
    730, // Печать фольгой до 50 см2
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getPrinting(params)
  getArea(params)
  getAreaFull(params)
  getUpload(params)
  getFoilColor(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  let idParam
  let idValue

  const keyPrinting = modelValues?.printing?.id
  const keyArea = modelValues?.area?.id
  const keyAreaFull = modelValues?.areaFull?.areaId
  let area = keyArea
  if (keyAreaFull) {
    area = keyAreaFull
  }
  if (keyPrinting && area) {
    if (keyPrinting === 'solvent') {
      if (area === 'to100') {
        idBuhta = 404
      } else if (area === 'to200') {
        idBuhta = 403
      } else if (area === 'to340') {
        idBuhta = 402
      } else if (area === 'to680') {
        idBuhta = 401
      } else if (area === 'to1200') {
        idBuhta = 400
      }
    } else if (keyPrinting === 'colorUV') {
      if (area === 'to100') {
        idBuhta = 548
      } else if (area === 'to200') {
        idBuhta = 550
      } else if (area === 'to340') {
        idBuhta = 551
      } else if (area === 'to680') {
        idBuhta = 552
      } else if (area === 'to1200') {
        idBuhta = 549
      }
    } else if (keyPrinting === 'reliefUV') {
      if (area === 'to100') {
        idBuhta = 603
      } else if (area === 'to200') {
        idBuhta = 604
      } else if (area === 'to340') {
        idBuhta = 605
      } else if (area === 'to680') {
        idBuhta = 606
      } else if (area === 'to1200') {
        idBuhta = 607
      }
    } else if (keyPrinting === 'foil') {
      if (area === 'to50') {
        idBuhta = 730
        idParam = 143
        idValue = modelValues?.foilColor?.id
      }
    }
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
  ])
}

/**
 * Выбор типа печати (На белом материале/На цветном материале/Рельефная УФ-печать)
 */
function getPrinting(params) {
  const { oldModelValues, items, modelValues, originIdsAvailable } = params

  const values = []
  const solvent = [400, 401, 402, 403, 404]
  if (lodash.intersection(originIdsAvailable, solvent).length > 0) {
    values.push({
      id: 'solvent',
      tName: 'personalizations.printing.solvent',
    })
  }
  const colorUV = [548, 549, 550, 551, 552]
  if (lodash.intersection(originIdsAvailable, colorUV).length > 0) {
    values.push({
      id: 'colorUV',
      tName: 'personalizations.printing.colorUV',
    })
  }

  const reliefUV = [603, 604, 605, 606, 607]
  if (lodash.intersection(originIdsAvailable, reliefUV).length > 0) {
    values.push({
      id: 'reliefUV',
      tName: 'personalizations.printing.reliefUV',
    })
  }

  const foil = [730]
  if (lodash.intersection(originIdsAvailable, foil).length > 0) {
    values.push({
      id: 'foil',
      tName: 'personalizations.printing.foil',
    })
  }

  if (values.length > 0) {
    items.push({
      id: 'printing',
      tName: 'personalizations.printing.printing',
      template: 'selectTab',
      sort: 10,
      hideHr: true,
      values,
    })

    if (oldModelValues.printing) {
      modelValues.printing = values.find((i) => i.id === oldModelValues.printing.id) ?? values[0]
    } else {
      modelValues.printing = values[0]
    }
  }
}

/**
 * Выбор площади Печати по материалу( до 100 см² / до 200 см² / до 340 см² / до 680 см² / до 1200 см²)
 */
function getArea({ store, store: { selectedBlock }, oldModelValues, items, modelValues, originPersonalizations }) {
  let areas = []
  if (modelValues.printing.id === 'solvent') {
    areas = [400, 401, 402, 403, 404]
  } else if (modelValues.printing.id === 'colorUV') {
    areas = [548, 549, 550, 551, 552]
  } else if (modelValues.printing.id === 'reliefUV') {
    areas = [603, 604, 605, 606, 607]
  } else if (modelValues.printing.id === 'foil') {
    areas = [730]
  }

  let values = []
  for (const idIndividual of areas) {
    let area = 0
    let w = 514
    let h = 430
    if (originPersonalizations[idIndividual]) {
      if ([730].includes(idIndividual)) {
        area = 50
      }
      if ([404, 548, 603].includes(idIndividual)) {
        area = 100
      }
      if ([403, 550, 604].includes(idIndividual)) {
        area = 200
      }
      if ([402, 551, 605].includes(idIndividual)) {
        area = 340
      }
      if ([401, 552, 606].includes(idIndividual)) {
        area = 680
      }
      if ([400, 549, 607].includes(idIndividual)) {
        area = 1200
      }
      let imgW = 508
      let imgH = 558

      if (modelValues.printing.id === 'reliefUV') {
        imgW = 850
        imgH = 1158
      } else if (modelValues.printing.id === 'foil') {
        imgW = 1
        imgH = 1
      }

      w = Math.round(imgW * Math.sqrt((area * 100) / imgH / imgW))
      h = Math.round(imgH * Math.sqrt((area * 100) / imgH / imgW))

      if (selectedBlock.area >= area) {
        values.push({
          id: `to${area}`,
          sort: area,
          tName: `personalizations.printing.to${area}`,
          idBuhta: idIndividual,
          r: 0,
          w,
          h,
        })
      }
    }
  }

  values = lodash.sortBy(values, ['sort'])

  if (values.length > 0) {
    items.push({
      id: 'area',
      tName: 'personalizations.printing.area',
      template: 'selectRadio',
      sort: 20,
      disabled: modelValues.printing.id !== 'reliefUV' && oldModelValues.areaFull?.checked === true,
      values,
    })

    if (oldModelValues.area && values.find((i) => i.id === oldModelValues.area.id)) {
      modelValues.area = values.find((i) => i.id === oldModelValues.area.id) ?? values[0]
      const { r, x, y, w, h } = oldModelValues.area
      if (x === undefined || y === undefined) {
        const draggableArea = getDraggableAreaMm(store)
        const draggablePosition = getPosition({ w, h }, draggableArea)
        modelValues.area = { ...modelValues.area, ...draggablePosition }
      } else {
        modelValues.area = { ...modelValues.area, r, x, y, w, h }
      }
    } else {
      const draggableArea = getDraggableAreaMm(store)
      const draggablePosition = getPosition({ w: values[0].w, h: values[0].h }, draggableArea)
      modelValues.area = { ...values[0], ...draggablePosition }
    }
  }
}

/**
 * Выбор печати на всю площадь
 */
function getAreaFull(params) {
  const { oldModelValues, items, modelValues } = params

  if (modelValues.printing.id === 'reliefUV' || modelValues.printing.id === 'foil') {
    return
  }
  let areaId = ''
  const areaItem = items.find((n) => n.id === 'area')
  if (areaItem?.values) {
    areaId = areaItem.values[areaItem.values.length - 1].id
  }

  const values = [
    { id: 0, checked: false },
    { id: 1, checked: true, areaId },
  ]

  if (values.length > 0) {
    items.push({
      id: 'areaFull',
      tName: 'personalizations.printing.areaFull',
      template: 'checkbox',
      sort: 30,
      values,
    })
    if (oldModelValues.areaFull) {
      modelValues.areaFull = values.find((i) => i.checked === oldModelValues.areaFull.checked) ?? values[0]
    } else {
      modelValues.areaFull = values[0]
    }
  }
}

/**
 * Выбор загрузки изображения
 */
function getUpload(params) {
  const { oldModelValues, items, modelValues } = params

  if (modelValues.printing.id === 'reliefUV' || modelValues.printing.id === 'foil') {
    return
  }

  if (oldModelValues.upload) {
    modelValues.upload = oldModelValues.upload
  } else {
    modelValues.upload = {
      url: '',
    }
  }
  const values = [modelValues.upload]
  items.push({
    id: 'upload',
    tName: 'personalizations.printing.upload',
    template: 'upload',
    sort: 40,
    values,
  })
}

/**
 * Выбор цвета фольги
 */
function getFoilColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  if (modelValues.printing.id !== 'foil') {
    return
  }

  let idBuhta = 730
  let idParam = 143

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: colors[n.id] ?? n.hex,
        hex: colorsHex[n.id] ?? n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'foilColor',
      tName: 'personalizations.embossing.foilColor',
      template: 'selectClassicColor',
      sort: 20,
      values,
    })

    if (oldModelValues.foilColor) {
      modelValues.foilColor = values.find((i) => i.id === oldModelValues.foilColor.id) ?? values[0]
    } else {
      modelValues.foilColor = values[0]
    }
  }
}
