<template>
<svg data-svg-lvl-3="flyleafend-block-shadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lsh-m" x="29" y="34" width="456" height="213" maskUnits="userSpaceOnUse"><rect class="lsh-c-1" x="61.41" y="60.67" width="391.19" height="156.28"/></mask></defs><g id="Тень_Блока"><g class="lsh-c-4"><g class="lsh-c-2"><image width="456" height="213" transform="translate(29 34)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/flyleafend/block/shadow/DPHL_1.webp`"/></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lsh-c-1{fill:#fff;}.lsh-c-2{opacity:0.3;}.lsh-c-3{fill:#64ff00;}.lsh-c-4{mask:url(#lsh-m);}</style>

