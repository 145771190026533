<template>
<svg data-svg-lvl-3="flyleafend-block-flyleafend" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lfe-m-f" x="0" y="0" width="500" height="430" fill="#ffffff"><path  d="M67.39,98.63a12,12,0,0,1,12-12H244.86c4.7,0,12.4,1.62,12.4,6v1.27H435.11a12,12,0,0,1,12,12V340.72a12,12,0,0,1-12,12h-178c0-2.56-5.57-4.64-12.39-4.64H79.4a12.05,12.05,0,0,1-12-12Z"/></mask></defs><g id="Нахзац"> <path  :style="{ fill: params.flyleafendColor }"  class="lfe-c-3" d="M67.39,98.63a12,12,0,0,1,12-12H244.86c4.7,0,12.4,1.62,12.4,6v1.27H435.11a12,12,0,0,1,12,12V340.72a12,12,0,0,1-12,12h-178c0-2.56-5.57-4.64-12.39-4.64H79.4a12.05,12.05,0,0,1-12-12Z"/> <image v-if="params.flyleafendImage" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.flyleafendImage" mask="url(#lfe-m-f)" /> </g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lfe-c-1{opacity:0.3;}.lfe-c-2{fill:#64ff00;}.lfe-c-3{fill:#f4e8ce;}</style>

