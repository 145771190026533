<template>
<svg data-svg-lvl-3="cover-top-seamPerimeter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Прошивка_по_периметру_Контур" ><g class="tsp-c-3"><path class="tsp-c-4" d="M113.13,414.7a1.1,1.1,0,0,0,1.1,1.1H374.1a6.08,6.08,0,0,0,6.07-6.06V75.24L342.34,37.42H114.24a1.1,1.1,0,0,1-1.09-1.27h0"/></g><g class="tsp-c-5"><path class="tsp-c-6" d="M113.33,414.9a1.1,1.1,0,0,0,1.1,1.1h2.28"/><path class="tsp-c-7" d="M120.7,416H374.3a6.08,6.08,0,0,0,6.07-6.06V75.44L346.79,41.86"/><polyline class="tsp-c-6" points="345.37 40.45 342.54 37.62 338.54 37.62"/><line class="tsp-c-8" x1="334.51" y1="37.62" x2="118.56" y2="37.62"/><path class="tsp-c-6" d="M116.55,37.62h-2.11a1.1,1.1,0,0,1-1.09-1.27h0"/></g><g class="tsp-c-9"><path class="tsp-c-10" d="M112.93,414.5a1.1,1.1,0,0,0,1.1,1.1h2.28"/><path class="tsp-c-11" d="M120.3,415.6H373.9a6.08,6.08,0,0,0,6.07-6.06V75L346.39,41.46"/><polyline class="tsp-c-10" points="344.97 40.05 342.14 37.22 338.14 37.22"/><line class="tsp-c-12" x1="334.11" y1="37.22" x2="118.16" y2="37.22"/><path class="tsp-c-10" d="M116.15,37.22H114A1.1,1.1,0,0,1,113,36h0"/></g></g><g id="Прошивка_по_периметру_Цвет" ><path :style="{ stroke: params.seamColor }"  class="tsp-c-13" d="M113.13,414.7a1.1,1.1,0,0,0,1.1,1.1h2.28"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-14" d="M120.5,415.8H374.1a6.08,6.08,0,0,0,6.07-6.06V75.24L346.58,41.66"/><polyline :style="{ stroke: params.seamColor }"  class="tsp-c-13" points="345.17 40.25 342.34 37.42 338.34 37.42"/><line :style="{ stroke: params.seamColor }"  class="tsp-c-15" x1="334.31" y1="37.42" x2="118.36" y2="37.42"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-13" d="M116.35,37.42h-2.11a1.1,1.1,0,0,1-1.09-1.27h0"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsp-c-1{opacity:0.3;}.tsp-c-2{fill:#64ff00;}.tsp-c-3{opacity:0.5;}.tsp-c-10,.tsp-c-11,.tsp-c-12,.tsp-c-13,.tsp-c-14,.tsp-c-15,.tsp-c-4,.tsp-c-6,.tsp-c-7,.tsp-c-8{fill:none;stroke-linecap:round;stroke-miterlimit:2;}.tsp-c-4,.tsp-c-6,.tsp-c-7,.tsp-c-8{stroke:#000;}.tsp-c-4{stroke-width:0.5px;}.tsp-c-5{opacity:0.6;}.tsp-c-10,.tsp-c-11,.tsp-c-12,.tsp-c-13,.tsp-c-14,.tsp-c-15,.tsp-c-6,.tsp-c-7,.tsp-c-8{stroke-width:1.14px;}.tsp-c-11,.tsp-c-14,.tsp-c-7{stroke-dasharray:7.99 3.99;}.tsp-c-12,.tsp-c-15,.tsp-c-8{stroke-dasharray:8.07 4.04;}.tsp-c-9{opacity:0.7;}.tsp-c-10,.tsp-c-11,.tsp-c-12{stroke:#fff;}.tsp-c-13,.tsp-c-14,.tsp-c-15{stroke:#594a99;}</style>

