import lodash from 'lodash'
import { colors, colorsHex } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const spring = {
  id: 'spring',
  // tName:  'personalizations.spring.spring',
  template: 'modal',
  preview: 'cover',

  originPersonalizationsIds: [
    568, // Пружина 9/16"
    570, // Пружина 7/16"
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getColor(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  let idParam
  const idValue = modelValues?.color?.id
  if (this.originPersonalizations[568]) {
    idBuhta = 568
    idParam = 76
  } else if (this.originPersonalizations[570]) {
    idBuhta = 570
    idParam = 75
  } else return []

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
  ])
}

/**
 * Выбор цвета резинки
 */
function getColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let idBuhta = 568
  let idParam = 76
  if (originPersonalizations[570]) {
    idBuhta = 570
    idParam = 75
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: colors[n.id] ?? n.hex,
        hex: colorsHex[n.id] ?? n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.spring.color',
      template: 'selectClassicColor',
      sort: 10,
      values,
    })

    // Дефолтный цвет пружины - чёрный
    let defaultIndex = values.findIndex((n) => n.hex === '#191919')
    if (defaultIndex < 0) {
      defaultIndex = 0
    }
    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[defaultIndex]
    } else {
      modelValues.color = values[defaultIndex]
    }
  }
}
