import lodash from 'lodash'
import { colors, colorsHex } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const metallicAccessories = {
  id: 'metallicAccessories',
  // tName: 'personalizations.metallicAccessories.cornersMetal',
  template: 'modal',
  preview: 'cover',

  originPersonalizationsIds: [
    408, // Стандартные уголки, 2 шт
    411, // Стандартные уголки, 4 шт
    737, // Металлический лейбл
    738, // Металлическая рамка, 2шт
    739, // Металлическая рамка, 1шт
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }
  getMetallicAccessories(params)
  getCorners(params)
  getColor(params)
  getFrames(params)
  getEngravingMaking(params)
  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations

  let idBuhta
  let idParam
  let idValue
  let idWork
  let value

  const keyCorners = modelValues?.corners?.id
  const keyFrames = modelValues?.frames?.id
  const keyEngravingMaking = modelValues?.engravingMaking?.checked

  if (modelValues.metallicAccessories?.id === 'corners') {
    idParam = 56
    idValue = modelValues?.color?.id

    if (keyCorners) {
      let selectedPers = []
      if (keyCorners === '2pcs') {
        selectedPers = [408]
      } else if (keyCorners === '4pcs') {
        selectedPers = [411]
      }
      const idsBuhta = lodash.intersection(this.originIdsAvailable, selectedPers)
      idBuhta = idsBuhta[0]
    }
  } else if (modelValues.metallicAccessories?.id === 'frames') {
    if (keyFrames === 'both') {
      idBuhta = 738
    } else if (keyFrames === 'front') {
      idBuhta = 739
      idParam = 153
      idValue = 6097
    } else if (keyFrames === 'back') {
      idBuhta = 739
      idParam = 153
      idValue = 6098
    }
  } else if (modelValues.metallicAccessories?.id === 'label') {
    idBuhta = 737
    idWork = 1804

    value = keyEngravingMaking
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
    {
      idWork,
      value,
    },
  ])
}

/**
 * Выбор Металлического аксессуара
 */
function getMetallicAccessories(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  const values = []
  const personals = {
    corners: [408, 411],
    frames: [738, 739],
    label: [737],
  }

  for (const key in personals) {
    if (lodash.intersection(originIdsAvailable, personals[key]).length > 0) {
      values.push({
        id: key,
        tName: `personalizations.metallicAccessories.${key}`,
        img: `${cnstrAssetsUrl}/personalizations/metallicAccessories/${key}.webp`,
        // idBuhta: personals[key][0],
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'metallicAccessories',
      tName: 'personalizations.metallicAccessories.metallicAccessories',
      template: 'selectImageText',
      sort: 10,
      values,
    })

    if (oldModelValues.metallicAccessories) {
      modelValues.metallicAccessories = values.find((i) => i.id === oldModelValues.metallicAccessories.id) ?? values[0]
    } else {
      modelValues.metallicAccessories = values[0]
    }
  }
}

/**
 * Выбор 2/4 уголка
 */
function getCorners(params) {
  const { oldModelValues, items, modelValues, originIdsAvailable } = params
  if (modelValues.metallicAccessories?.id !== 'corners') {
    return
  }

  const values = []

  const personals = {
    '2pcs': [408],
    '4pcs': [411],
  }
  for (const key in personals) {
    if (lodash.intersection(originIdsAvailable, personals[key]).length > 0) {
      values.push({
        id: key,
        tName: `personalizations.metallicAccessories.${key}`,
        idBuhta: personals[key][0],
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'corners',
      tName: 'personalizations.metallicAccessories.corners',
      template: 'selectRadio',
      sort: 20,
      values,
    })

    if (oldModelValues.corners) {
      modelValues.corners = values.find((i) => i.id === oldModelValues.corners.id) ?? values[0]
    } else {
      modelValues.corners = values[0]
    }
  }
}

/**
 * Выбор цвета уголков
 */
function getColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params
  if (modelValues.metallicAccessories?.id !== 'corners') {
    return
  }

  let idBuhta
  const idParam = 56
  if (modelValues.corners.id === '2pcs') {
    idBuhta = 408
  } else if (modelValues.corners.id === '4pcs') {
    idBuhta = 411
  } else {
    return
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: colors[n.id] ?? n.hex,
        hex: colorsHex[n.id],
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.metallicAccessories.color',
      template: 'selectClassicColor',
      sort: 30,
      values,
    })

    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[0]
    } else {
      modelValues.color = values[0]
    }
  }
}

/**
 * Выбор расположения рамок
 */
function getFrames(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params
  if (modelValues.metallicAccessories?.id !== 'frames') {
    return
  }

  let values = []

  let idBuhta = 739
  const idParam = 153

  const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
  if (val?.values?.length) {
    values = val.values.map((n) => {
      let key = 'front'
      if (n.id === 6098) key = 'back'
      return {
        id: key,
        tName: `personalizations.metallicAccessories.${key}`,
        idBuhta: n.id,
      }
    })
  }
  idBuhta = 738
  if (originPersonalizations[idBuhta]) {
    values.push({
      id: 'both',
      tName: `personalizations.metallicAccessories.both`,
      idBuhta: 738,
    })
  }

  if (values.length > 0) {
    items.push({
      id: 'frames',
      tName: 'personalizations.metallicAccessories.frames',
      template: 'selectRadio',
      sort: 40,
      values,
    })

    if (oldModelValues.frames) {
      modelValues.frames = values.find((i) => i.id === oldModelValues.frames.id) ?? values[0]
    } else {
      modelValues.frames = values[0]
    }
  }
}

/**
 * Выбор флага гравировки
 */
function getEngravingMaking(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  if (modelValues.metallicAccessories?.id !== 'label') {
    return
  }

  let values = []
  const param = originPersonalizations[737]?.params?.find((item) => Number(item.idWork) === 1804)
  if (!param) {
    return
  }

  const checked = Number(param?.InOut) === 1
  values = [
    { id: 0, idWork: param.idWork, checked },
    { id: 1, idWork: param.idWork, checked: !checked },
  ]

  if (values.length > 0) {
    items.push({
      id: 'engravingMaking',
      tName: 'personalizations.metallicAccessories.engravingMaking',
      template: 'checkbox',
      sort: 50,
      values,
    })
    if (oldModelValues.engravingMaking) {
      modelValues.engravingMaking =
        values.find((i) => i.checked === oldModelValues.engravingMaking.checked) ?? values[0]
    } else {
      modelValues.engravingMaking = values[0]
    }
  }
}
