<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Задник_Контур"><rect class="bc-c-3" x="112.04" y="202.53" width="289.92" height="217.44"/><polygon class="bc-c-4" points="114.68 9.23 114.68 104.04 114.68 104.04 114.68 198.84 399.36 198.84 399.36 104.04 399.36 104.04 399.36 9.23 114.68 9.23"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:gray;stroke-width:0.75px;}.bc-c-3,.bc-c-4{stroke:#232728;stroke-miterlimit:2;}.bc-c-4{fill:#fff;stroke-width:0.75px;}</style>

