import lodash from 'lodash'
import { colors, colorsHex } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'
import { getDraggableAreaMm, getPosition } from '/src/utils/draggable'

export const metallic = {
  id: 'metallic',
  // tName:  'personalizations.metallic.metallic',
  template: 'modal',
  preview: 'cover',
  multiple: true,

  originPersonalizationsIds: [
    712, // Металлостикер до 4 см.кв.
    713, // Металлостикер до 9 см.кв.
    714, // Металлостикер до 25 см.кв.
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getMetallic(params)
  getColor(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  const keyMetallic = modelValues?.metallic?.id
  const idParam = 136
  const idValue = modelValues?.color?.id

  if (keyMetallic === '4cm') {
    idBuhta = 712
  } else if (keyMetallic === '9cm') {
    idBuhta = 713
  } else if (keyMetallic === '25cm') {
    idBuhta = 714
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
  ])
}

/**
 * Выбор размера металлостикера
 */
function getMetallic(params) {
  const { store, oldModelValues, items, modelValues, originIdsAvailable } = params

  const values = []

  const personals = {
    '4cm': [712],
    '9cm': [713],
    '25cm': [714],
  }
  for (const key in personals) {
    if (lodash.intersection(originIdsAvailable, personals[key]).length > 0) {
      let side = 0
      if (key === '4cm') {
        side = 20
      } else if (key === '9cm') {
        side = 30
      } else if (key === '25cm') {
        side = 50
      }
      values.push({
        id: key,
        tName: `personalizations.metallic.${key}`,
        idBuhta: personals[key][0],
        r: 0,
        w: side,
        h: side,
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'metallic',
      tName: 'personalizations.metallic.area',
      template: 'selectRadio',
      sort: 10,
      values,
    })

    if (oldModelValues.metallic) {
      modelValues.metallic = values.find((i) => i.id === oldModelValues.metallic.id) ?? values[0]
      const { r, x, y, w, h } = oldModelValues.metallic
      if (x === undefined || y === undefined) {
        const draggableArea = getDraggableAreaMm(store)

        const draggablePosition = getPosition({ w, h }, draggableArea)
        modelValues.metallic = { ...modelValues.metallic, ...draggablePosition }
      } else {
        modelValues.metallic = { ...modelValues.metallic, r, x, y, w, h }
      }
    } else {
      const draggableArea = getDraggableAreaMm(store)

      const draggablePosition = getPosition({ w: values[0].w, h: values[0].h }, draggableArea)
      modelValues.metallic = { ...values[0], ...draggablePosition }
    }
  }
}

/**
 * Выбор цвета металла
 */
function getColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let idBuhta

  const idParam = 136
  if (modelValues.metallic.id === '4cm') {
    idBuhta = 712
  } else if (modelValues.metallic.id === '9cm') {
    idBuhta = 713
  } else if (modelValues.metallic.id === '25cm') {
    idBuhta = 714
  } else {
    return
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: colors[n.id] ?? n.hex,
        hex: colorsHex[n.id] ?? n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.metallic.color',
      template: 'selectClassicColor',
      sort: 20,
      values,
    })

    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[0]
    } else {
      modelValues.color = values[0]
    }
  }
}
