<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M176.07,331.12l.18-.95c7.74-40.62,9.92-79.44,11-98.1.07-1.28.14-2.46.2-3.56-4.79-7.77-7.51-19-7.51-31.29,0-11.8,2.77-23.29,7.48-31.19a179.71,179.71,0,0,0-5.45-32.6l-2.84-10.24"/><g class="tss-c-4"><path class="tss-c-5" d="M176.27,331.32l.18-.95c.19-1,.38-2,.56-3"/><path class="tss-c-6" d="M177.71,323.52c6.72-38.08,8.72-73.65,9.7-91.25.07-1.28.14-2.46.2-3.56-4.79-7.77-7.51-19.05-7.51-31.29,0-11.8,2.77-23.29,7.48-31.19a179.71,179.71,0,0,0-5.45-32.6l-1.25-4.5"/><line class="tss-c-5" x1="180.36" y1="127.24" x2="179.29" y2="123.39"/></g><g class="tss-c-7"><path class="tss-c-8" d="M175.87,330.92l.18-.95c.19-1,.38-2,.56-3"/><path class="tss-c-9" d="M177.31,323.12C184,285,186,249.47,187,231.87c.07-1.28.14-2.46.2-3.56-4.79-7.77-7.51-19.05-7.51-31.29,0-11.8,2.77-23.29,7.48-31.19a179.71,179.71,0,0,0-5.45-32.6l-1.25-4.5"/><line class="tss-c-8" x1="179.96" y1="126.84" x2="178.89" y2="122.99"/></g><path class="tss-c-10" d="M181.41,331.11c8.6-45.09,10.35-87.46,11.34-104-4.51-6.5-7.6-17.22-7.6-29.94,0-12.5,3.22-23.39,7.6-29.94A189.09,189.09,0,0,0,187,132.12l-2.48-8.93"/><path class="tss-c-11" d="M181.16,331.11c8.6-45.09,10.35-87.46,11.34-104-4.51-6.5-7.6-17.22-7.6-29.94,0-12.5,3.22-23.39,7.6-29.94a189.09,189.09,0,0,0-5.74-35.16l-2.48-8.93"/></g><g id="Шов_Цвет"><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M176.07,331.12l.18-.95c.19-1,.38-2,.56-3"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M177.51,323.32c6.72-38.08,8.72-73.65,9.7-91.25.07-1.28.14-2.46.2-3.56-4.79-7.77-7.51-19-7.51-31.29,0-11.8,2.77-23.29,7.48-31.19a179.71,179.71,0,0,0-5.45-32.6l-1.25-4.5"/><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="180.16" y1="127.04" x2="179.09" y2="123.19"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-5,.tss-c-6{stroke:#000;}.tss-c-10,.tss-c-11,.tss-c-3{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-4{opacity:0.6;}.tss-c-12,.tss-c-13,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{stroke-linecap:round;stroke-width:1.14px;}.tss-c-13,.tss-c-6,.tss-c-9{stroke-dasharray:7.85 3.92;}.tss-c-7{opacity:0.7;}.tss-c-10,.tss-c-8,.tss-c-9{stroke:#fff;}.tss-c-11,.tss-c-12,.tss-c-13{stroke:#232728;}</style>

