import lodash from 'lodash'
import { colors, colorsHex } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const slice = {
  id: 'slice',
  // tName:  'personalizations.slice.slice',
  template: 'modal',
  preview: 'edge',

  originPersonalizationsIds: [
    328, // Золочение и серебрение обреза ежедневников
    682, // Золочение и серебрение обреза ежедневников и планингов

    371, // Окраска обреза блока

    396, // Печать по обрезу блока 8.5х13
    397, // Печать по обрезу блока 10х16, 10.5х16
    398, // Печать по обрезу блока 13х21, 15x21, 15.5x21
    399, // Печать по обрезу блока 20x26, 20.5x27

    600, // Печать обреза "Хамелеон" + золочение/серебрение
    601, // Печать обреза "Хамелеон" + печать верх/низ обреза
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getSlice(params)
  getSliceChameleon(params)
  getColor(params)
  getCorner(params)
  getUpload(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  let idParam
  let idValue

  const personals = {
    gilding: [328, 682],
    coloring: [371],
    print: [396, 397, 398, 399],
    chameleon: [600, 601],
  }

  const key = modelValues?.slice?.id
  const keyChameleon = modelValues?.sliceChameleon?.id
  const keyColor = modelValues?.color?.id
  const keyCorner = modelValues?.corner?.id
  if (key) {
    let selectedPers = []
    if (key === 'chameleon') {
      if (keyChameleon === 'gilding') {
        selectedPers = [600]
      } else if (keyChameleon === 'print') {
        selectedPers = [601]
      }
    } else {
      selectedPers = personals[key]
    }

    if (
      (key === 'chameleon' && keyChameleon && keyChameleon === 'gilding') ||
      (key === 'gilding' && originPersonalizations[328])
    ) {
      idParam = 39
      if (keyColor === 'gold') {
        if (keyCorner === 'square') {
          idValue = 1004
        } else if (keyCorner === 'rounded') {
          idValue = 1006
        }
      } else if (keyColor === 'silver') {
        if (keyCorner === 'square') {
          idValue = 1003
        } else if (keyCorner === 'rounded') {
          idValue = 1005
        }
      }
    }
    const idsBuhta = lodash.intersection(this.originIdsAvailable, selectedPers)
    idBuhta = idsBuhta[0]
    if (idBuhta === 682) {
      idParam = 124
      idValue = keyColor
    } else if (idBuhta === 371) {
      idParam = 45
      idValue = keyColor
    }
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
  ])
}

/**
 * Выбор обреза
 */
function getSlice(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  const values = []
  const personals = {
    gilding: [328, 682],
    coloring: [371],
    print: [396, 397, 398, 399],
    chameleon: [600, 601],
  }

  for (const key in personals) {
    if (lodash.intersection(originIdsAvailable, personals[key]).length > 0) {
      values.push({
        id: key,
        tName: `personalizations.slice.${key}`,
        img: `${cnstrAssetsUrl}/personalizations/slice/${key}.webp`,
      })
    }
  }
  if (values.length > 0) {
    items.push({
      id: 'slice',
      tName: 'personalizations.slice.slice',
      template: 'selectImageText',
      sort: 10,
      values,
    })

    if (oldModelValues.slice) {
      modelValues.slice = values.find((i) => i.id === oldModelValues.slice.id) ?? values[0]
    } else {
      modelValues.slice = values[0]
    }
  }
}

/**
 * Выбор типа окраса среза верха и низа для хамелеона
 */
function getSliceChameleon(params) {
  const { oldModelValues, items, modelValues, originIdsAvailable } = params

  if (modelValues.slice?.id !== 'chameleon') {
    return
  }
  const values = []
  const personals = {
    gilding: [600],
    print: [601],
  }

  for (const key in personals) {
    if (lodash.intersection(originIdsAvailable, personals[key]).length > 0) {
      values.push({
        id: key,
        tName: `personalizations.slice.${key}`,
      })
    }
  }
  if (values.length > 0) {
    items.push({
      id: 'sliceChameleon',
      tName: 'personalizations.slice.sliceChameleon',
      template: 'selectRadio',
      sort: 20,
      values,
    })

    if (oldModelValues.sliceChameleon) {
      modelValues.sliceChameleon = values.find((i) => i.id === oldModelValues.sliceChameleon.id) ?? values[0]
    } else {
      modelValues.sliceChameleon = values[0]
    }
  }
}

/**
 * Выбор цвета обреза
 */
function getColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let idBuhta
  let idParam

  if (modelValues.slice?.id === 'gilding') {
    if (originPersonalizations[328]) {
      idBuhta = 328
      idParam = 39
    } else if (originPersonalizations[682]) {
      idBuhta = 682
      idParam = 124
    }
  } else if (modelValues.slice?.id === 'coloring') {
    idBuhta = 371
    idParam = 45
  } else if (modelValues.slice?.id === 'chameleon' && modelValues.sliceChameleon?.id === 'gilding') {
    idBuhta = 600
    idParam = 39
  } else {
    return
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const param = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)

    if (param?.values?.length) {
      if (idBuhta === 328 || idBuhta === 600) {
        if (param.values.find((p) => p.id === 1004 || p.id === 1006)) {
          if (!values.find((k) => k.id === 'gold')) {
            values.push({
              id: 'gold',
              tName: `personalizations.slice.gold`,
              background: colors[32],
              hex: colorsHex[32],
            })
          }
        }

        if (param.values.find((p) => p.id === 1003 || p.id === 1005)) {
          if (!values.find((k) => k.id === 'silver')) {
            values.push({
              id: 'silver',
              tName: `personalizations.slice.silver`,
              background: colors[33],
              hex: colorsHex[33],
            })
          }
        }
      } else {
        values = param.values
          .filter((n) => n.id !== 4802) // индивидуальный цвет
          .map((n) => ({
            ...n,
            background: colors[n.id] ?? n.hex,
            hex: colorsHex[n.id] ?? n.hex,
          }))
      }
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.slice.color',
      template: 'selectClassicColor',
      sort: 30,
      values,
    })

    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[0]
    } else {
      modelValues.color = values[0]
    }
  }
}

/**
 * Выбор цвета обреза
 */
function getCorner(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let idBuhta
  let idParam

  if (modelValues.slice?.id === 'gilding' && originPersonalizations[328]) {
    idBuhta = 328
    idParam = 39
  } else if (
    modelValues.slice?.id === 'chameleon' &&
    modelValues.sliceChameleon?.id === 'gilding' &&
    originPersonalizations[600]
  ) {
    idBuhta = 600
    idParam = 39
  } else {
    return
  }

  const values = []
  if (originPersonalizations[idBuhta].params) {
    const param = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (param?.values?.length) {
      if (param.values.find((p) => p.id === 1003 || p.id === 1004)) {
        if (!values.find((k) => k.id === 'square')) {
          values.push({
            id: 'square',
            tName: `personalizations.slice.square`,
            background: colors[32],
          })
        }
      }

      if (param.values.find((p) => p.id === 1005 || p.id === 1006)) {
        if (!values.find((k) => k.id === 'rounded')) {
          values.push({
            id: 'rounded',
            tName: `personalizations.slice.rounded`,
          })
        }
      }
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'corner',
      tName: 'personalizations.slice.corner',
      template: 'selectRadio',
      sort: 40,
      values,
    })

    if (oldModelValues.corner) {
      modelValues.corner = values.find((i) => i.id === oldModelValues.corner.id) ?? values[0]
    } else {
      modelValues.corner = values[0]
    }
  }
}

/**
 * Выбор загрузки изображения печати по срезу
 */
function getUpload(params) {
  const { oldModelValues, items, modelValues } = params

  if (!(modelValues.slice.id === 'print' || modelValues.slice?.id === 'chameleon')) {
    return
  }

  if (oldModelValues.upload) {
    modelValues.upload = oldModelValues.upload
  } else {
    modelValues.upload = {
      url: '',
    }
  }
  const values = [modelValues.upload]
  items.push({
    id: 'upload',
    tName: 'personalizations.slice.upload',
    template: 'upload',
    sort: 50,
    values,
  })
}
