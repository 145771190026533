<template>
<svg data-svg-lvl-2="cover-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
<PreviewMaterial :params="params" />
<PreviewLayersCoverTopLaminateM2 :params="params" v-if="params.laminateGlow" />
<PreviewDraggable :fromModal="fromModal" :params="params" :active="active" :draggableActive="draggableActive" @draggableActiveChanged="setActive" @draggableChanged="$emit('draggableChanged', $event)"  />
<PreviewLayersCoverTopStitchingRubberS40 :params="params" v-if="params.stitching === 40" />
<PreviewLayersCoverTopStitchingRubberS55 :params="params" v-if="params.stitching === 55" />
<PreviewLayersCoverTopStitchingRubberS60 :params="params" v-if="params.stitching === 60" />
<component :params="params" :is="`PreviewLayersCoverTopSeamStitching${params.seamStitchingComponent}`" v-if="params.seamStitchingComponent" />
<PreviewLayersCoverTopStitchingLoopS55 :params="params" v-if="params.stitching === 55" />
<PreviewLayersCoverTopStitchingLoopS56 :params="params" v-if="params.stitching === 56" />
<PreviewLayersCoverTopStitchingLoopS57 :params="params" v-if="params.stitching === 57" />
<PreviewLayersCoverTopStitchingLoopS62 :params="params" v-if="params.stitching === 62" />
<PreviewLayersCoverTopSeamPerimeterM4 :params="params" v-if="params.isPerimeter"/>
<component :params="params" :is="`PreviewLayersCoverTopPerforation${params.perforationComponent}`" v-if="params.perforationComponent" />
<PreviewDraggable :fromModal="fromModal" :params="params" :active="active" :draggableActive="draggableActive" @draggableActiveChanged="setActive" @draggableChanged="$emit('draggableChanged', $event)" isUp />
<PreviewLayersCoverTopContourM4 :params="params" />
<component :params="params" :is="`PreviewLayersCoverTopCorners${params.cornersTopComponent}`" v-if="params.cornersTopComponent" />
<component :params="params" :is="`PreviewLayersCoverTopLabelM2`" v-if="params.label.enabled" />
<component :is="`PreviewLayersCoverTopFlapF${params.flapComponent}`" :params="params" v-if="params.flapComponent" />
<component :params="params" :is="`PreviewLayersCoverTopRubberR${params.rubberComponent}`" v-if="params.rubberComponent" />
<component :params="params" :is="`PreviewLayersCoverTopRubberNameplateR${params.rubberNameplateComponent}`" v-if="params.rubberNameplateComponent" />
<component :is="`PreviewLayersCoverTopLoopL${params.loopComponent}`" :params="params" v-if="params.loopComponent" />
<PreviewDraggableTeleportTarget :fromModal="fromModal" :params="params" />
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default { components: {
      PreviewDraggable: () => import('/src/components/preview/Draggable'),
      PreviewDraggableTeleportTarget: () => import('/src/components/preview/DraggableTeleportTarget'),
      PreviewMaterial: () => import('/src/components/preview/Material'),
     },props: { params: { type: Object, required: true, }, fromModal: { type: Boolean, default: () => false }, draggableActive: { type: Object, default: () => {} }, },  data() {
        return {
          active: '',
        }
      },    
      mounted() {
        document.body.addEventListener('mousedown', () => {
          this.active = ''
        })
      },
      methods: {
        setActive(draggable) {
          this.active = draggable.id.pers + draggable.id.idx
          this.$emit('draggableActiveChanged', { pers: draggable.id.pers, idx: Number(draggable.id.idx) })
        },
      },}
</script>