<template>
<svg data-svg-lvl-3="spread-back-flap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bf-lg" x1="452.54" y1="205.74" x2="513" y2="205.74" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.3"/><stop offset="0.14" stop-opacity="0.14"/><stop offset="1" stop-opacity="0"/></linearGradient></defs><g id="Хлястик_Контур"><path class="bf-c-3" d="M500.8,217.73c6.87-6.72,12.2-17.11,12.2-33.36,0-9.57-5.06-13.67-11.63-7.62-22.88,21.08-25.59,25-48.83,25v35.77S478.49,239.58,500.8,217.73Z"/><path class="bf-c-4" d="M507.58,173.94c3.29.32,5.42,4.22,5.42,10.43,0,16.25-5.33,26.64-12.2,33.36a68,68,0,0,1-46,19.84c5.65.07,27.61-1,46.88-19.84,6.86-6.72,12.19-17.11,12.19-33.36C513.9,177.61,511.38,173.59,507.58,173.94Z"/><path class="bf-c-5" d="M500.8,217.73c6.87-6.72,12.2-17.11,12.2-33.36,0-9.57-5.06-13.67-11.63-7.62-22.88,21.08-25.59,25-48.83,25v35.77S478.49,239.58,500.8,217.73Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bf-c-1{opacity:0.3;}.bf-c-2{fill:#64ff00;}.bf-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.bf-c-4{fill:#232728;}.bf-c-5{fill:url(#bf-lg);}</style>

