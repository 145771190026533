<template>
<svg data-svg-lvl-3="spread-block-spread" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="ls-cp"><polygon class="ls-c-1" points="256.74 86.61 93.1 86.61 66.88 112.84 66.88 352.73 256.74 352.73 446.61 352.73 446.61 112.46 420.76 86.61 256.74 86.61"/></clipPath></defs><g id="Разворот"><g class="ls-c-4"> <polygon :style="{ fill: params.slice.color }"  class="ls-c-1" points="256.74 86.61 93.1 86.61 66.88 112.84 66.88 352.73 256.74 352.73 446.61 352.73 446.61 112.46 420.76 86.61 256.74 86.61"/> <image width="1200" height="859" transform="translate(66.42 83.44) scale(0.32)" :href="params.blockImage"  v-show="params.blockImage" preserveAspectRatio="xMidYMid slice" /></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.ls-c-1{fill:none;}.ls-c-2{opacity:0.3;}.ls-c-3{fill:#ff931e;}.ls-c-4{clip-path:url(#ls-cp);}</style>

