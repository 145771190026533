<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M46.38,157.23l2,.91C80.1,172.32,122.69,184.57,175,194.55c54.73,10.44,105.26,15.52,127.87,17,10.18-6.48,25.2-10.17,41.57-10.17,15.77,0,31.09,3.76,41.45,10.12,7.82-.82,41.42-5.15,79.8-21.28l1.94-.82"/><path class="tss-c-4" d="M467.62,195.05c-38.27,16.08-72.69,21-83,21.82-8.79-5.92-23.42-10.27-40.21-10.27-17.08,0-31.47,4.18-40.2,10.27-20.74-1.25-73.28-6.3-130.18-17.16C129.82,191.27,83,179.33,46.38,163"/><path class="tss-c-5" d="M467.62,194.8c-38.27,16.08-72.69,21-83,21.82-8.79-5.92-23.42-10.27-40.21-10.27-17.08,0-31.47,4.18-40.2,10.27-20.74-1.25-73.28-6.3-130.18-17.16C129.82,191,83,179.08,46.38,162.71"/><g class="tss-c-6"><path class="tss-c-7" d="M46.58,157.43l2.05.91,1.6.72"/><path class="tss-c-8" d="M53.93,160.66c31.07,13.2,71.82,24.66,121.26,34.09,54.73,10.44,105.26,15.52,127.87,17,10.18-6.48,25.2-10.17,41.57-10.17,15.77,0,31.09,3.76,41.45,10.12,7.57-.8,39.32-4.88,76.17-19.79"/><path class="tss-c-7" d="M464.12,191.12l1.76-.73,1.94-.82"/></g><g class="tss-c-9"><path class="tss-c-10" d="M46.18,157l2,.91,1.6.72"/><path class="tss-c-11" d="M53.53,160.25c31.07,13.21,71.82,24.67,121.26,34.1,54.73,10.44,105.26,15.52,127.87,17,10.18-6.48,25.2-10.17,41.57-10.17,15.77,0,31.09,3.76,41.45,10.12,7.57-.8,39.32-4.88,76.17-19.79"/><path class="tss-c-10" d="M463.72,190.72l1.76-.73,1.94-.82"/></g></g><g id="Шов_Цвет"><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M46.38,157.23l2,.91,1.6.72"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M53.73,160.46c31.07,13.2,71.82,24.66,121.26,34.09,54.73,10.44,105.26,15.52,127.87,17,10.18-6.48,25.2-10.17,41.57-10.17,15.77,0,31.09,3.76,41.45,10.12,7.57-.8,39.32-4.88,76.17-19.79"/><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M463.92,190.92l1.76-.73,1.94-.82"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-4,.tss-c-5,.tss-c-7,.tss-c-8{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-7,.tss-c-8{stroke:#000;}.tss-c-3,.tss-c-4,.tss-c-5{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-10,.tss-c-11,.tss-c-4{stroke:#fff;}.tss-c-12,.tss-c-13,.tss-c-5{stroke:#232728;}.tss-c-6{opacity:0.6;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-7,.tss-c-8{stroke-linecap:round;stroke-width:1.14px;}.tss-c-11,.tss-c-13,.tss-c-8{stroke-dasharray:8.05 4.03;}.tss-c-9{opacity:0.7;}</style>

