<template>
<svg data-svg-lvl-3="cover-block-edge" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs>
      <mask id="le-m-si" x="0" y="0" width="500" height="430" fill="#ffffff"><path  d="M401.37,25.13A9.16,9.16,0,0,0,392.24,16H124.66a4.51,4.51,0,0,0-3.18,1.32l-8,8a1.06,1.06,0,0,0-.31.61h0a1.12,1.12,0,0,0,.4,1h0a.67.67,0,0,0,.14.09l.12.06.13,0a.75.75,0,0,0,.26,0h267a9.12,9.12,0,0,1,8.93,9.12V416.86a9.11,9.11,0,0,1-2.41,6.16h0l11-10.92h0l0,0h0a9.13,9.13,0,0,0,2.65-6.43Z"/></mask>
  <linearGradient id="lg-silver" x1="113.83" x2="398.05" y1="197.49" y2="197.49" gradientUnits="userSpaceOnUse" >
    <stop offset="0" stop-color="#989ba0" />
    <stop offset=".3" stop-color="#fff" /><stop offset=".83" stop-color="#3c3e45" /><stop offset="1" stop-color="#979a9f" />
  </linearGradient>
  <linearGradient id="lg-gold" x1="113.83" x2="398.05" y1="197.49" y2="197.49" gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#9c6b00" /><stop offset=".3" stop-color="#fbe6a6" />
    <stop offset=".83" stop-color="#6c4209" /><stop offset="1" stop-color="#efe9cd" />
  </linearGradient></defs><g id="Обрез"><path :style="{ fill: params.slice.color === '#9C6B00' ? 'url(#lg-gold)' : params.slice.color === '#989BA0' ? 'url(#lg-silver)' : params.slice.color }"  class="le-c-3" d="M401.37,25.13A9.16,9.16,0,0,0,392.24,16H124.66a4.51,4.51,0,0,0-3.18,1.32l-8,8a1.06,1.06,0,0,0-.31.61h0a1.12,1.12,0,0,0,.4,1h0a.67.67,0,0,0,.14.09l.12.06.13,0a.75.75,0,0,0,.26,0h267a9.12,9.12,0,0,1,8.93,9.12V416.86a9.11,9.11,0,0,1-2.41,6.16h0l11-10.92h0l0,0h0a9.13,9.13,0,0,0,2.65-6.43Z"/> <image v-if="params.slice.image" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.slice.image" mask="url(#le-m-si)" /></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.le-c-1{opacity:0.3;}.le-c-2{fill:#64ff00;}.le-c-3{fill:#f4e8ce;stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}</style>

