<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><line class="tss-c-3" x1="46.58" y1="207.2" x2="467.82" y2="207.2"/><g class="tss-c-4"><line class="tss-c-5" x1="46.38" y1="202" x2="467.62" y2="202"/><line class="tss-c-5" x1="46.38" y1="212" x2="467.62" y2="212"/></g><g class="tss-c-6"><line class="tss-c-7" x1="46.58" y1="202.2" x2="50.58" y2="202.2"/><line class="tss-c-8" x1="54.59" y1="202.2" x2="461.81" y2="202.2"/><line class="tss-c-7" x1="463.82" y1="202.2" x2="467.82" y2="202.2"/><line class="tss-c-7" x1="46.58" y1="212.2" x2="50.58" y2="212.2"/><line class="tss-c-8" x1="54.59" y1="212.2" x2="461.81" y2="212.2"/><line class="tss-c-7" x1="463.82" y1="212.2" x2="467.82" y2="212.2"/></g><g class="tss-c-9"><line class="tss-c-10" x1="46.18" y1="201.8" x2="50.18" y2="201.8"/><line class="tss-c-11" x1="54.19" y1="201.8" x2="461.41" y2="201.8"/><line class="tss-c-10" x1="463.42" y1="201.8" x2="467.42" y2="201.8"/><line class="tss-c-10" x1="46.18" y1="211.8" x2="50.18" y2="211.8"/><line class="tss-c-11" x1="54.19" y1="211.8" x2="461.41" y2="211.8"/><line class="tss-c-10" x1="463.42" y1="211.8" x2="467.42" y2="211.8"/></g><line class="tss-c-12" x1="46.38" y1="207" x2="467.62" y2="207"/></g><g id="Шов_Цвет"><line :style="{ stroke: params.seamColor }"  class="tss-c-13" x1="46.38" y1="202" x2="50.38" y2="202"/><line :style="{ stroke: params.seamColor }"  class="tss-c-14" x1="54.39" y1="202" x2="461.61" y2="202"/><line :style="{ stroke: params.seamColor }"  class="tss-c-13" x1="463.62" y1="202" x2="467.62" y2="202"/><line :style="{ stroke: params.seamColor }"  class="tss-c-13" x1="46.38" y1="212" x2="50.38" y2="212"/><line :style="{ stroke: params.seamColor }"  class="tss-c-14" x1="54.39" y1="212" x2="461.61" y2="212"/><line :style="{ stroke: params.seamColor }"  class="tss-c-13" x1="463.62" y1="212" x2="467.62" y2="212"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-14,.tss-c-3,.tss-c-5,.tss-c-7,.tss-c-8{fill:none;stroke-miterlimit:2;}.tss-c-10,.tss-c-11,.tss-c-3{stroke:#fff;}.tss-c-12,.tss-c-3{stroke-width:0.46px;}.tss-c-4{opacity:0.5;}.tss-c-5,.tss-c-7,.tss-c-8{stroke:#000;}.tss-c-10,.tss-c-11,.tss-c-13,.tss-c-14,.tss-c-5,.tss-c-7,.tss-c-8{stroke-linecap:round;}.tss-c-5{stroke-width:0.5px;}.tss-c-6{opacity:0.6;}.tss-c-10,.tss-c-11,.tss-c-13,.tss-c-14,.tss-c-7,.tss-c-8{stroke-width:1.14px;}.tss-c-11,.tss-c-14,.tss-c-8{stroke-dasharray:8.02 4.01;}.tss-c-9{opacity:0.7;}.tss-c-12,.tss-c-13,.tss-c-14{stroke:#232728;}</style>

