<template>
  <div class="item-wapper d-flex flex-wrap">
    <div class="item label" v-if="tLabel">{{ $t(tLabel) }}</div>
    <div class="item d-flex align-items-center" v-for="item in items" :key="item.id" @click="click(item)">
      <svg class="item-image" :class="{ disabled }" viewBox="2 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g :filter="disabled ? '' : 'url(#filter0_d_8403_6386)'">
          <circle class="bg" cx="9.5" cy="7.5" r="7.5" />
          <circle class="contour" cx="9.5" cy="7.5" r="7" />
          <circle class="checked" v-show="item.id === value.id" cx="9.5" cy="7.5" r="6" stroke-width="3" />
        </g>
        <defs>
          <filter
            id="filter0_d_8403_6386"
            x="0"
            y="0"
            width="19"
            height="19"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0196078 0 0 0 0 0.176471 0 0 0 0 0.168627 0 0 0 0.3 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8403_6386" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8403_6386" result="shape" />
          </filter>
        </defs>
      </svg>

      <div :class="['name', { disabled, small }]">{{ item.tName ? $t(item.tName) : item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /** @var items - массив объектов для отрисовки списка. Объект должен
     * содержать следующие поля
     * @property {number} id - ключ для списка
     * @property {string} name - наименования для надписи
     * @property {string} image - url изображения
     */
    items: {
      type: Array,
      required: true,
    },

    /** @var value - выбранный объект из items */
    value: {
      type: [Object],
      required: true,
    },
    /** @var disabled - признак неактивного элемента */
    disabled: {
      type: Boolean,
      default: () => false,
    },
    /** @var tLabel - метка */
    tLabel: {
      type: String,
      default: () => '',
    },
    small: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {}
  },
  methods: {
    click(item) {
      if (this.disabled) {
        return
      }
      if (item.id !== this.value.id) {
        this.$emit('input', item)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.item-wapper
  column-gap: 15px
  row-gap: 10px

.item

  position: relative
  cursor: pointer
  // margin-right: 15px

  &-image
    display: block
    position: relative
    width: 15px
    height: 19px
    padding-top: 2px
  .name
    transition: .3s
    font-size: 14px
    line-height: 21px
    margin-left: 8px
    &.disabled
      color: $stroke-color
    &.small
      font-size: 13px
      line-height: 19px
  &.label
    font-weight: bold
    margin-right: 13px
svg
  display: block
  transition: .3s
  circle
    transition: .3s
    &.bg
      fill: $white
    &.contour
      stroke: $stroke-color
    &.checked
      stroke: $alternative

  &.disabled
    opacity: 0.5
    circle.checked
      stroke: $stroke-color
</style>
