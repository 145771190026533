<template>
<svg data-svg-lvl-2="flyleaffront-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
<PreviewLayersFlyleaffrontBlockShadowDPH26 :params="params" />
<PreviewLayersFlyleaffrontBlockFlyleaffrontDPH26 :params="params" />
<PreviewLayersFlyleaffrontBlockContourDPH26 :params="params" />
<PreviewLayersFlyleaffrontBlockPocketR :params="params" v-if="params.flyleaffrontPocket" />
<PreviewDraggableInner :params="params" />
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default { components: {
      PreviewDraggableInner: () => import('/src/components/preview/DraggableInner'),
     },props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>