import lodash from 'lodash'
import { colors } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const loop = {
  id: 'loop',
  // tName:  'personalizations.loop.loop',
  template: 'modal',
  preview: 'cover',

  originPersonalizationsIds: [
    // 15мм
    594, // Петля-резинка 15 мм для ручки для ежедневника
    // 596, // Петля-резинка 15 мм для ручки для ежедневника из кожи

    // 20 мм
    307, // Петля-резинка 20 мм для ручки для ежедневника
    // 312, // Петля-резинка 20 мм для ручки для ежедневника из кожи

    // петля
    195, // Петля для ручки из синтетических материалов
    // 285, // Петля для ручки из кожи

    // двойная петля
    260, // Двойная петля для ручки для ежедневников из синтетический материалов
    // 286, // Двойная петля для ручки из кожи

    // петля-лейбл
    621, // Петля-лейбл из синтетических материалов
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }
  getLoop(params)
  getColor(params)
  getMaterial(params)
  getMaterial2(params)
  getThreadColor(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  let idParam
  let idValue
  let idParamAdd
  let idValueAdd
  let idParam2
  let idValue2

  const keyLoop = modelValues?.loop?.id
  const keyColor = modelValues?.color?.id
  const keyMaterial = modelValues?.material?.id
  const keyMaterial2 = modelValues?.material2?.id
  const keyThreadColor = modelValues?.threadColor?.id

  if (keyLoop === '15mm' || keyLoop === '20mm') {
    idValue = keyColor
    if (keyLoop === '15mm') {
      idBuhta = 594
      idParam = 86
    } else if (keyLoop === '20mm') {
      idBuhta = 307
      idParam = 29
    }
  } else if (keyLoop === 'pen' || keyLoop === 'penDouble' || keyLoop === 'penLabel') {
    idParam = 68
    idValue = keyMaterial
    idParamAdd = 88
    idValueAdd = keyThreadColor
    if (keyLoop === 'pen') {
      idBuhta = 195
    } else if (keyLoop === 'penDouble') {
      idBuhta = 260
      idParam = 145

      idParam2 = 146
      idValue2 = keyMaterial2
    } else if (keyLoop === 'penLabel') {
      idBuhta = 621
    }
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
    {
      idParam: idParamAdd,
      idValue: idValueAdd,
    },
    {
      idParam: idParam2,
      idValue: idValue2,
    },
  ])
}

/**
 * Выбор Петли для ручки
 */
function getLoop(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  const values = []
  const personals = {
    '15mm': [594],
    '20mm': [307],
    pen: [195],
    penDouble: [260],
    penLabel: [621],
  }

  for (const key in personals) {
    if (lodash.intersection(originIdsAvailable, personals[key]).length > 0) {
      values.push({
        id: key,
        tName: `personalizations.loop.${key}`,
        img: `${cnstrAssetsUrl}/personalizations/loop/${key}.webp`,
        idBuhta: personals[key][0],
      })
    }
  }
  if (values.length > 0) {
    items.push({
      id: 'loop',
      tName: 'personalizations.loop.loop',
      template: 'selectImageText',
      sort: 10,
      values,
    })

    if (oldModelValues.loop) {
      modelValues.loop = values.find((i) => i.id === oldModelValues.loop.id) ?? values[0]
    } else {
      modelValues.loop = values[0]
    }
  }
}

/**
 * Выбор цвета резинки
 */
function getColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  const idBuhta = modelValues.loop.idBuhta
  let idParam

  if (idBuhta === 594) {
    idParam = 86
  } else if (idBuhta === 307) {
    idParam = 29
  } else {
    return
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.loop.color',
      template: 'selectClassicColor',
      sort: 20,
      values,
    })

    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[0]
    } else {
      modelValues.color = values[0]
    }
  }
}

/**
 * Выбор материала
 */
function getMaterial(params) {
  const {
    store: {
      materialsTexturesKeys,
      materialsColorsKeys,
      selectedMaterials,
      CONST: { assetsUrl },
    },
    oldModelValues,
    items,
    modelValues,
    originPersonalizations,
  } = params

  let idBuhta
  let idParam = 68
  if (modelValues.loop.id === 'pen' || modelValues.loop.id === 'penDouble' || modelValues.loop.id === 'penLabel') {
    idBuhta = modelValues.loop.idBuhta
    if (modelValues.loop.id === 'penDouble') {
      idParam = 145
    }
  } else {
    return
  }
  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => {
        let textureRef = materialsTexturesKeys[n.textureId]
        let colorRef = materialsColorsKeys[n.colorId]
        let hint = ``
        if (textureRef && colorRef) {
          hint = `${textureRef.key} ${textureRef.name}<br>${colorRef.name} ${colorRef.key}`
        }
        return {
          ...n,
          imgOrigin: `${assetsUrl}/materials/${textureRef?.key}${colorRef?.key}.jpg`,
          img: `${assetsUrl}/materials/small/${textureRef?.key}${colorRef?.key}_mini.jpg`,
          textureRef,
          colorRef,
          hint,
        }
      })
      // сортировка материалов и перемещение наверх материала, который является материалом обложки
      values = lodash.sortBy(values, ['colorRef.sort', 'textureRef.key'])
      let matId = values.findIndex(
        (n) => n.colorId === selectedMaterials[0].color && n.textureId === selectedMaterials[0].texture,
      )
      if (matId > 0) {
        values.unshift(values[matId])
        values.splice(matId + 1, 1)
      }
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'material',
      tName: `personalizations.loop.material${modelValues.loop.id === 'penDouble' ? '1' : ''}`,
      template: 'selectImage',
      useFilter: true,
      sort: 30,
      values,
    })

    if (oldModelValues.material) {
      modelValues.material = values.find((i) => i.id === oldModelValues.material.id) ?? values[0]
    } else {
      modelValues.material = values[0]
    }
  }
}

/**
 * Выбор материала 2 (для двйной петли)
 */
function getMaterial2(params) {
  const {
    store: {
      materialsTexturesKeys,
      materialsColorsKeys,
      selectedMaterials,
      CONST: { assetsUrl },
    },
    oldModelValues,
    items,
    modelValues,
    originPersonalizations,
  } = params

  let idBuhta
  let idParam = 146
  if (modelValues.loop.id === 'penDouble') {
    idBuhta = modelValues.loop.idBuhta
  } else {
    return
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => {
        let textureRef = materialsTexturesKeys[n.textureId]
        let colorRef = materialsColorsKeys[n.colorId]
        let hint = ``
        if (textureRef && colorRef) {
          hint = `${textureRef.key} ${textureRef.name}<br>${colorRef.name} ${colorRef.key}`
        }
        return {
          ...n,
          imgOrigin: `${assetsUrl}/materials/${textureRef?.key}${colorRef?.key}.jpg`,
          img: `${assetsUrl}/materials/small/${textureRef?.key}${colorRef?.key}_mini.jpg`,
          textureRef,
          colorRef,
          hint,
        }
      })
      // сортировка материалов и перемещение наверх материала, который является материалом обложки
      values = lodash.sortBy(values, ['colorRef.sort', 'textureRef.key'])
      let matId = values.findIndex(
        (n) => n.colorId === selectedMaterials[0].color && n.textureId === selectedMaterials[0].texture,
      )
      if (matId > 0) {
        values.unshift(values[matId])
        values.splice(matId + 1, 1)
      }
    }
  }
  if (values.length > 0) {
    items.push({
      id: 'material2',
      tName: `personalizations.loop.material2`,
      template: 'selectImage',
      useFilter: true,
      sort: 40,
      values,
    })

    if (oldModelValues.material2) {
      modelValues.material2 = values.find((i) => i.id === oldModelValues.material2.id) ?? values[0]
    } else {
      modelValues.material2 = values[0]
    }
  }
}

/**
 * Выбор цвета нитки
 */
function getThreadColor(params) {
  const { store, oldModelValues, items, modelValues, originPersonalizations } = params

  let idBuhta
  const idParam = 88

  if (modelValues.loop.id === 'pen' || modelValues.loop.id === 'penDouble' || modelValues.loop.id === 'penLabel') {
    idBuhta = modelValues.loop.idBuhta
  } else {
    return
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => {
        const newItem = {
          ...n,
          background: colors[n.id] ?? n.hex,
        }
        // Дефолтная нитка берётся с цветом из материала threadColorConstr
        if (n.id === 1292) {
          newItem.hex = '#232728'
          if (store?.selectedMaterials) {
            const foundHex = val.values.find((m) => m.id === store.selectedMaterials[0]?.threadColorConstr)
            if (foundHex?.hex) {
              newItem.hex = foundHex?.hex
            }
          }
        }
        return newItem
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'threadColor',
      tName: 'personalizations.loop.threadColor',
      template: 'selectClassicColor',
      sort: 50,
      mod: 'aboveSelect',
      values,
    })

    // Дефолтная нитка берётся с цветом "в соответствии с материалом"
    let defaultIndex = values.findIndex((n) => n.id === 1292)
    if (defaultIndex < 0) {
      defaultIndex = 0
    }
    if (oldModelValues.threadColor) {
      modelValues.threadColor = values.find((i) => i.id === oldModelValues.threadColor.id) ?? values[defaultIndex]
    } else {
      modelValues.threadColor = values[defaultIndex]
    }
  }
}
