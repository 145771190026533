<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><g class="tss-c-3"><path class="tss-c-4" d="M334.38,123.19v3.52c.06,39.32.22,142.56-57.09,204.4"/><path class="tss-c-4" d="M263.29,331.15l3.52-3.56c57.79-58.44,57.63-161.91,57.57-200.86v-3.54"/></g><g class="tss-c-5"><path class="tss-c-6" d="M334.58,123.39v4"/><path class="tss-c-7" d="M334.58,131.48c0,41.2-1.79,135.4-53.07,195.3"/><path class="tss-c-6" d="M280.17,328.34c-.88,1-1.78,2-2.68,3"/><line class="tss-c-6" x1="263.49" y1="331.35" x2="266.31" y2="328.5"/><path class="tss-c-8" d="M269,325.7c54.63-57.59,55.59-156.19,55.55-196.36"/><path class="tss-c-6" d="M324.58,127.39v-4"/></g><g class="tss-c-9"><path class="tss-c-10" d="M334.18,123v4"/><path class="tss-c-11" d="M334.18,131.08c0,41.2-1.79,135.4-53.07,195.3"/><path class="tss-c-10" d="M279.77,327.94c-.88,1-1.78,2-2.68,3"/><line class="tss-c-10" x1="263.09" y1="330.95" x2="265.91" y2="328.1"/><path class="tss-c-12" d="M268.63,325.3c54.63-57.59,55.59-156.19,55.55-196.36"/><path class="tss-c-10" d="M324.18,127v-4"/></g><path class="tss-c-13" d="M270.61,331.11c61.05-61.74,59-170.66,59-207.89v0"/><path class="tss-c-14" d="M270.36,331.11c61.05-61.74,59-170.66,59-207.89v0"/></g><g id="Шов_Цвет"><path :style="{ stroke: params.seamColor }"  class="tss-c-15" d="M334.38,123.19v4"/><path :style="{ stroke: params.seamColor }"  class="tss-c-16" d="M334.38,131.28c0,41.2-1.79,135.4-53.07,195.3"/><path :style="{ stroke: params.seamColor }"  class="tss-c-15" d="M280,328.14c-.88,1-1.78,2-2.68,3"/><line :style="{ stroke: params.seamColor }"  class="tss-c-15" x1="263.29" y1="331.15" x2="266.11" y2="328.3"/><path :style="{ stroke: params.seamColor }"  class="tss-c-17" d="M268.83,325.5c54.63-57.59,55.59-156.19,55.55-196.36"/><path :style="{ stroke: params.seamColor }"  class="tss-c-15" d="M324.38,127.19v-4"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-3{opacity:0.5;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-14,.tss-c-15,.tss-c-16,.tss-c-17,.tss-c-4,.tss-c-6,.tss-c-7,.tss-c-8{fill:none;stroke-miterlimit:2;}.tss-c-4,.tss-c-6,.tss-c-7,.tss-c-8{stroke:#000;}.tss-c-13,.tss-c-14,.tss-c-4{stroke-width:0.46px;}.tss-c-5{opacity:0.6;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-15,.tss-c-16,.tss-c-17,.tss-c-6,.tss-c-7,.tss-c-8{stroke-linecap:round;stroke-width:1.14px;}.tss-c-11,.tss-c-16,.tss-c-7{stroke-dasharray:8.18 4.09;}.tss-c-12,.tss-c-17,.tss-c-8{stroke-dasharray:7.81 3.91;}.tss-c-9{opacity:0.7;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13{stroke:#fff;}.tss-c-14,.tss-c-15,.tss-c-16,.tss-c-17{stroke:#232728;}</style>

