<template>
<svg data-svg-lvl-3="flyleaffront-block-pocket" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lp-lg" x1="69.23" y1="147.85" x2="156.77" y2="147.85" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#333"/><stop offset="1" stop-color="#b2b2b2" stop-opacity="0.5"/></linearGradient><linearGradient id="lp-lg-2" x1="112.99" y1="130.62" x2="112.99" y2="176.3" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.05"/><stop offset="1" stop-opacity="0.3"/></linearGradient><clipPath id="lp-cp"><path class="lp-c-1" d="M69.23,118.67h87.55a0,0,0,0,1,0,0v54.75a3.62,3.62,0,0,1-3.62,3.62H72.85a3.62,3.62,0,0,1-3.62-3.62V118.67A0,0,0,0,1,69.23,118.67Z"/></clipPath></defs><g id="КонвертКонтур_Пластиковый_Карман" ><g class="lp-c-4"><path class="lp-c-5" d="M69.23,118.67h87.55a0,0,0,0,1,0,0v54.75a3.62,3.62,0,0,1-3.62,3.62H72.85a3.62,3.62,0,0,1-3.62-3.62V118.67A0,0,0,0,1,69.23,118.67Z"/><path class="lp-c-6" d="M69.23,130.06h87.55a0,0,0,0,1,0,0v43.36a3.62,3.62,0,0,1-3.62,3.62H72.85a3.62,3.62,0,0,1-3.62-3.62V130.06A0,0,0,0,1,69.23,130.06Z"/><path class="lp-c-7" d="M153.11,176.61H72.87a3.19,3.19,0,0,1-3.19-3.19V130.06h.58v43.36A2.61,2.61,0,0,0,72.87,176h80.24a2.61,2.61,0,0,0,2.6-2.61V130.06h.58v43.36A3.19,3.19,0,0,1,153.11,176.61Z"/></g><g class="lp-c-4"><g class="lp-c-8"><g class="lp-c-9"><image width="276" height="396" transform="translate(53.37 44.23) scale(0.43 0.43)" :href="`${$CONST.cnstrAssetsUrl}/others/pocket.webp`"/></g></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lp-c-1{fill:none;}.lp-c-2{opacity:0.3;}.lp-c-3{fill:#64ff00;}.lp-c-4{opacity:0.8;}.lp-c-5,.lp-c-9{opacity:0.5;}.lp-c-5{fill:url(#lp-lg);}.lp-c-6{fill:#828282;opacity:0.4;}.lp-c-7{fill:url(#lp-lg-2);}.lp-c-8{clip-path:url(#lp-cp);}</style>

