<template>
<svg data-svg-lvl-3="spread-block-inserts" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="li-cp"><rect class="li-c-1" x="256.74" y="86.62" width="189.86" height="266.12"/></clipPath></defs><g id="РекламныеЛисты"><g class="li-c-4"><image width="401" height="600" transform="translate(256.74 76.1) scale(0.47)"  :href="`${$CONST.cnstrAssetsUrl}/others/inserts.webp`"/></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.li-c-1{fill:none;}.li-c-2{opacity:0.3;}.li-c-3{fill:#64ff00;}.li-c-4{clip-path:url(#li-cp);}</style>

