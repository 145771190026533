import { getPrices } from '/src/utils/api/getPrices'

export default {
  namespaced: true,

  state() {
    return {
      discountsEdition: [],
      discounts: {
        price: 0,
      },
      product: 0,
      personalizations: [],
      quantity: 100,
    }
  },

  mutations: {
    CLEAR_REFERENCES(state) {
      state.discountsEdition = []
    },
    CLEAR_PRICES(state) {
      state.product = 0
      state.personalizations = []
    },
    CLEAR_QUANTITY: (state) => {
      state.quantity = 100
    },
    SET_REFERENCES(state, payload) {
      state.discountsEdition = payload.discountsEdition
    },
    SET_PRODUCT_PRICE(state, payload) {
      state.product = payload
    },
    SET_PERSONALIZATIONS_PRICE(state, payload) {
      state.personalizations = payload
    },
    SET_DISCOUNTS(state, payload) {
      state.discounts = payload
    },

    SET_QUANTITY: (state, payload) => {
      state.quantity = payload
    },
  },

  actions: {
    async GET_PRICES({ commit, rootGetters }, { api, CONST, quantity }) {
      /**
       * Получение цены продукта.
       * Может вызываться на втором или третьем шаге
       * На втором шаге material=0
       * На третьем шаге берется первый материал из массива
       * - Если выбран ламинат - дополнительно отправляется personalization
       * - Если выбран биматериал - дополнительно отправляется personalization(для кожи из personalizationSkin)
       *    и material берется 130(для кожи из 134)
       */
      const params = {
        model: rootGetters['control/SELECTED_MODEL'].id,
        block: rootGetters['control/SELECTED_BLOCK'].id,
        material: rootGetters['control/SELECTED_MATERIALS'][0].id ?? 0,
        quantity,
      }
      if (params.model && params.block) {
        if (rootGetters['control/SELECTED_TAB'].id === 2) {
          // Биматериалы

          if (rootGetters['control/SELECTED_MATERIALS'][0].type === 2) {
            // Кожа
            params.material = 134
            params.personalization = rootGetters['control/SELECTED_STITCHING'].personalizationSkin
          } else {
            // Не кожа
            params.material = 130
            params.personalization = rootGetters['control/SELECTED_STITCHING'].personalization
          }
        }

        if (rootGetters['control/SELECTED_TAB'].id === 3) {
          // Ламинат
          params.personalization = rootGetters['control/SELECTED_MATERIALS'][0].personalization
        }
      }

      const prices = await getPrices(api, {
        params,
        CONST,
        selectedPersonalizationsBuhta: rootGetters['control/SELECTED_PERSONALIZATIONS_BUHTA'],
      })

      commit('SET_PERSONALIZATIONS_PRICE', prices.pricePersonalizations)
      commit('SET_PRODUCT_PRICE', prices.price)
      commit('SET_DISCOUNTS', prices.discount)

      commit('SET_QUANTITY', quantity)
    },
  },

  getters: {
    DISCOUNTS_EDITION: (state) => state.discountsEdition,
    PRODUCT: (state) => state.product,
    PERSONALIZATIONS: (state) => state.personalizations,
    PRODUCT_TOTAL: (state) => {
      let personalizationsPrice = 0
      for (const persId in state.personalizations) {
        personalizationsPrice += state.personalizations[persId].reduce((ps, a) => ps + a * 100, 0) / 100
      }
      return state.product + personalizationsPrice
    },
    DISCOUNTS: (state) => state.discounts,
    PRODUCT_TOTAL_DISCOUNTS: (state) => state.discounts.price,
    QUANTITY: (state) => {
      return state.quantity
    },
  },
}
