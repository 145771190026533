import { mapActions } from 'vuex'
import currentStep from '/src/mixins/currentStep'
import api from '/src/mixins/api'

/** смена роута и вызов метода для смены состояния */
export default {
  mixins: [currentStep, api],

  methods: {
    ...mapActions({
      setProductConfig: 'control/SET_PRODUCT_CONFIG',
    }),
    async changeQuery(query) {
      if (!this.$lodash.isEqual(query, this.$route.query)) {
        await this.$router.replace({ query }, null, () =>
          console.log('Ошибка запуска метода $router.replace() в changeQuery(), query, error:', query),
        )
      }

      await this.setProductConfig({
        // init: false,
        step: this.currentStep,
        locale: this.$i18n.locale,
        api: this.api,
        CONST: this.$CONST,
        query,
      })
    },
  },
}
