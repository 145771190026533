<template>
<svg data-svg-lvl-3="cover-block-flyleaffront" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lff-m-f" x="0" y="0" width="500" height="430" fill="#ffffff"><polygon  points="31.19 42.3 31.19 192.73 31.19 192.73 31.19 343.15 482.87 343.15 482.87 192.73 482.87 192.73 482.87 42.3 31.19 42.3"/></mask></defs><g id="Форзац"> <polygon  :style="{ fill: params.flyleaffrontColor }"  class="lff-c-3" points="31.19 42.3 31.19 192.73 31.19 192.73 31.19 343.15 482.87 343.15 482.87 192.73 482.87 192.73 482.87 42.3 31.19 42.3"/> <image v-if="params.flyleaffrontImage" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.flyleaffrontImage" mask="url(#lff-m-f)" /> </g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lff-c-1{opacity:0.3;}.lff-c-2{fill:#64ff00;}.lff-c-3{fill:#f4e8ce;stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}</style>

