<template>
<svg data-svg-lvl-2="spread-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
<PreviewLayersSpreadBlockShadowR :params="params" v-if="!params.isFlap7" />
<PreviewLayersSpreadBlockShadowR_F7 :params="params" v-if="params.isFlap7" />
<PreviewLayersSpreadBlockSpreadR :params="params" v-if="!params.isFlap7" />
<PreviewLayersSpreadBlockSpreadR_F7 :params="params" v-if="params.isFlap7" />
<PreviewLayersSpreadBlockInsertsR :params="params" v-if="!params.isFlap7 && params.isInserts" />
<PreviewLayersSpreadBlockInsertsR_F7 :params="params" v-if="params.isFlap7 && params.isInserts" />
<PreviewLayersSpreadBlockContourR :params="params" v-if="!params.isFlap7" />
<PreviewLayersSpreadBlockContourR_F7 :params="params" v-if="params.isFlap7" />
<component :params="params" :is="`PreviewLayersSpreadBlockLasseRD`" v-if="params.isLasse" />
<component :params="params" :is="`PreviewLayersSpreadBlockLasseNameplate${params.lasseNameplateComponent}`" v-if="params.lasseNameplateComponent" />
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>