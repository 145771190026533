<template>
<svg data-svg-lvl-3="spread-back-corners" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bcr-lg" x1="888.65" y1="352.35" x2="888.65" y2="356.67" gradientTransform="matrix(-1, 0, 0, 1, 963.27, 0)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-2" x1="902.14" y1="10235.34" x2="897.71" y2="10235.34" gradientTransform="translate(963.27 10578.42) rotate(180)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-3" x1="888.65" y1="10926.17" x2="888.65" y2="10921.84" gradientTransform="translate(963.27 11016.08) rotate(180)" href="#bcr-lg"/><linearGradient id="bcr-lg-4" x1="902.14" y1="-334.38" x2="897.71" y2="-334.38" gradientTransform="matrix(-1, 0, 0, 1, 963.27, 437.66)" href="#bcr-lg-2"/></defs><g id="УголкиЗадник"><path class="bcr-c-3" d="M62.65,356.73H87.76l-4.66-4.66H65.63l-4.17,4.17A1.68,1.68,0,0,0,62.65,356.73Z"/><path class="bcr-c-4" d="M65.63,352.07V334.59L61,329.93v25.12a1.71,1.71,0,0,0,.49,1.19Z"/><path class="bcr-c-5" d="M61,329.93l4.66,4.66v17.48H83.1l4.66,4.66H62.65A1.68,1.68,0,0,1,61,355.05V329.93m0-.75a.76.76,0,0,0-.75.75v25.12a2.43,2.43,0,0,0,2.43,2.43H87.76a.76.76,0,0,0,.7-.47.74.74,0,0,0-.17-.81l-4.65-4.66a.77.77,0,0,0-.54-.22H66.38V334.59a.75.75,0,0,0-.22-.53L61.5,329.4a.75.75,0,0,0-.53-.22Z"/><line class="bcr-c-6" x1="65.98" y1="351.71" x2="61.11" y2="356.59"/><path class="bcr-c-7" d="M62.65,89.64H87.76L83.1,94.3H65.63l-4.17-4.17A1.68,1.68,0,0,1,62.65,89.64Z"/><path class="bcr-c-8" d="M65.63,94.3v17.47L61,116.43V91.31a1.67,1.67,0,0,1,.49-1.18Z"/><path class="bcr-c-5" d="M87.76,89.64,83.1,94.3H65.63v17.47L61,116.43V91.31a1.68,1.68,0,0,1,1.68-1.67H87.76m0-.75H62.65a2.43,2.43,0,0,0-2.43,2.42v25.12a.74.74,0,0,0,.46.69.74.74,0,0,0,.82-.16l4.66-4.66a.75.75,0,0,0,.22-.53V95.05H83.1a.77.77,0,0,0,.54-.22l4.65-4.66a.75.75,0,0,0-.53-1.28Z"/><line class="bcr-c-6" x1="65.98" y1="94.65" x2="61.11" y2="89.77"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bcr-c-1{opacity:0.3;}.bcr-c-2{fill:#64ff00;}.bcr-c-3{fill:url(#bcr-lg);}.bcr-c-4{fill:url(#bcr-lg-2);}.bcr-c-5{fill:#232728;}.bcr-c-6{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:0.4px;}.bcr-c-7{fill:url(#bcr-lg-3);}.bcr-c-8{fill:url(#bcr-lg-4);}</style>

