import lodash from 'lodash'
import { embossing } from '/src/utils/adapters/embossing'
import { printing } from '/src/utils/adapters/printing'
import { uvVarnish } from '/src/utils/adapters/uvVarnish'
import { sliceColoring } from '/src/utils/adapters/sliceColoring'
import { flap } from '/src/utils/adapters/flap'
import { rubber } from '/src/utils/adapters/rubber'
import { loop } from '/src/utils/adapters/loop'
import { seamPerimeter } from '/src/utils/adapters/seamPerimeter'
import { plastic } from '/src/utils/adapters/plastic'
import { perforation } from '/src/utils/adapters/perforation'
import { cutting } from '/src/utils/adapters/cutting'
import { cuttingPrint } from '/src/utils/adapters/cuttingPrint'
import { polymer } from '/src/utils/adapters/polymer'
import { metallic } from '/src/utils/adapters/metallic'
import { metallicAccessories } from '/src/utils/adapters/metallicAccessories'
import { nfc } from '/src/utils/adapters/nfc'
import { magnet } from '/src/utils/adapters/magnet'

import { lasse } from '/src/utils/adapters/lasse'
import { flyleafs } from '/src/utils/adapters/flyleafs'
import { inserts } from '/src/utils/adapters/inserts'
import { slice } from '/src/utils/adapters/slice'
import { envelope } from '/src/utils/adapters/envelope'
import { rounding } from '/src/utils/adapters/rounding'
// import { pocket } from '/src/utils/adapters/pocket'

import { spine } from '/src/utils/adapters/spine'
import { pocketMaterials } from '/src/utils/adapters/pocketMaterials'
import { additionalBlock } from '/src/utils/adapters/additionalBlock'
import { zipper } from '/src/utils/adapters/zipper'
import { button } from '/src/utils/adapters/button'
import { captal } from '/src/utils/adapters/captal'
import { stitchingRubber } from '/src/utils/adapters/stitchingRubber'
import { spring } from '/src/utils/adapters/spring'
import { spunbond } from '/src/utils/adapters/spunbond'

import { heatShrink } from '/src/utils/adapters/heatShrink'
import { packing } from '/src/utils/adapters/packing'

const persBindings = {
  25: embossing,
  5: printing,
  28: uvVarnish,
  23: sliceColoring,
  13: flap,
  18: rubber,
  12: loop,
  11: seamPerimeter,
  9: plastic,
  21: perforation,
  10: cutting,
  105: cuttingPrint,
  26: polymer,
  111: metallic,
  6: metallicAccessories,
  107: nfc,
  109: magnet,

  4: lasse,
  3: flyleafs, //  <--- pocket,
  7: inserts,
  16: slice,
  33: envelope,
  34: rounding,

  113: spine,
  115: pocketMaterials,
  114: additionalBlock,
  112: zipper,
  118: button,
  119: captal,
  121: stitchingRubber,
  120: spring,
  116: spunbond,

  14: heatShrink,
  24: packing,
}
const unnecessaryPersonalizationsGroups = [
  2, // Ламинат
  115, // Материал
]

const unnecessaryPersonalizations = [
  105, // Шелкография
  106, // Шелкография

  16, //  Шитье биматериалов
  171, // Шитье биматериалов
  172, // Шитье биматериалов
  173, // Шитье биматериалов
  174, // Шитье биматериалов
  175, // Шитье биматериалов
  176, // Шитье биматериалов
  420, // Шитье биматериалов
  567, // Шитье биматериалов

  697, // Ламинированная крышка бархатная
  708, // Окраска среза изделия деловой галантереи

  647, // Резинка для сшивки Футляр

  // Доп материалы
  627, // Выбор оборота материала для обложки модели Дуэт (M14)
  633, // Выбор внутренного материала для модели Флап-Лайт (M12)
  632, // Выбор внутреннего материала для обложки модели Флекс-Пен (M13)
]

export async function getPersonalizations(api, { model, block, stitching, materials }) {
  let data = []

  const response = await api('constructor-new/get-personalizations-groups', {
    model,
    block,
    stitching,
    materials,
  })
  if (!Array.isArray(response.result)) {
    throw new TypeError(`getPersonalizations: Ожидается массив в response.result`)
  }

  data = modifyItems(response.result, model, block, stitching, materials)
  return data
}

function modifyItems(groups) {
  const newGroups = []
  for (const group of groups) {
    const newPersonalizations = []
    for (const persGroup of group.persGroups) {
      if (persBindings[persGroup.id]) {
        const originPersonalizations = lodash.keyBy(persGroup.personalizations, (item) => item.id)
        const originIdsAvailable = getOriginIdsAvailable(
          persGroup.personalizations,
          persBindings[persGroup.id].originPersonalizationsIds,
          persGroup.name,
        )

        const required = persGroup.personalizations.findIndex((n) => n.required === true) >= 0
        if (originIdsAvailable.length > 0) {
          newPersonalizations.push({
            idNum: persGroup.id,
            name: persGroup.name,
            originIdsAvailable,
            originPersonalizations,
            required,
            ...persBindings[persGroup.id],
          })
        }
      } else if (!unnecessaryPersonalizationsGroups.includes(persGroup.id)) {
        console.warn(
          `getPersonalizations: новая необработанная групповая персоналка - ${persGroup.id}:${persGroup.name} группа - ${group.id}:${group.name} `,
          persGroup,
        )
      }
    }
    if (newPersonalizations.length > 0) {
      newGroups.push({
        id: group.id,
        name: group.name,
        hidden: newPersonalizations.findIndex((n) => n.hidden !== true) === -1,
        personalizations: newPersonalizations,
      })
    }
  }
  return newGroups
}

/**
 * Проверяет и возвращает список id совпадающих персонализаций
 * @param {Array} personalizations список персонализаций пришедших из БД
 * @param {Array} personalizationsIds список id требуемых персонализаций
 */
function getOriginIdsAvailable(personalizations, personalizationsIds, name) {
  const personalizationsIdsDB = lodash.map(personalizations, (item) => item.id)

  const idsIntersect = lodash.intersection(personalizationsIds, personalizationsIdsDB)

  if (idsIntersect.length === 0) {
    if (personalizationsIdsDB.length !== 1 || !unnecessaryPersonalizations.includes(personalizationsIdsDB[0])) {
      console.warn(
        `getPersonalizations: нет совпадающих персонализаций в группе '${name}'`,
        'personalizations',
        personalizations,
        'personalizationsIds',
        personalizationsIds,
      )
    }
  }
  const idsDifference = lodash.difference(personalizationsIdsDB, personalizationsIds)
  if (idsDifference.length > 0) {
    const idsDifferenceUnnecessary = lodash.difference(idsDifference, unnecessaryPersonalizations)
    if (idsDifferenceUnnecessary.length > 0) {
      console.warn(
        `getPersonalizations: есть новые необработанные персонализации в группе '${name}'`,
        'новые idsDifferenceUnnecessary',
        idsDifferenceUnnecessary,
        'personalizations',
        personalizations,
        'personalizationsIds',
        personalizationsIds,
      )
    }
  }

  return idsIntersect
}

// /** Шаблоны персонализаций верхнего уровня */
// const templates = {
//   /**
//    * Селектор True/false, который не вызывает диалоговое окно
//    * Заданы несколько персонализаций Бухты, по факту приходит одна из них
//    */
//   boolean: 'boolean',
//   /**
//    * Модальное диалоговое окно со вкладками и селекторами параметров
//    * Заданы несколько персонализаций Бухты
//    */
//   modal: 'modal',
// }

// /** Шаблоны персонализаций в модальном окне */
// const templatesModal = {
//   /**
//    * Селектор в форме таба
//    */
//   selectTab: 'selectTab',
//   /**
//    * Селектор в форме классического селекта
//    */
//   selectClassic: 'selectClassic',
//   /**
//    * Селектор в форме классического селекта с цветом
//    */
//   selectClassicColor: 'selectClassicColor',
//   /**
//    * Селектор в форме классического селекта с картинкой
//    */
//   selectClassicImage: 'selectClassicImage',
//   /**
//    * Селектор в форме картинок
//    */
//   selectImage: 'selectImage',
//   /**
//    * Селектор в форме картинок с подписями
//    */
//   selectImageText: 'selectImageText',
//   /**
//    * Чекбокс
//    */
//   checkbox: 'checkbox',
//   /**
//    * Форма загрузки картинки
//    */
//   upload: 'upload',
//   /**
//    * Селектор в форме радио кнопки
//    */
//   selectRadio: 'selectRadio',
// }

// /** Шаблоны персонализаций верхнего уровня */
//   boolean: 'boolean',
//   modal: 'modal',

// /** Шаблоны персонализаций в модальном окне */
//   selectTab: 'selectTab',
//   +  selectClassic: 'selectClassic',
//   + selectClassicColor: 'selectClassicColor',
//   selectClassicImage: 'selectClassicImage',
//   selectImage: 'selectImage',
//   + selectImageText: 'selectImageText',
//   checkbox: 'checkbox',
//   upload: 'upload',
