// import axios from 'axios'

export default {
  // mixins: [currentStep],

  methods: {
    async api(method, params = {}) {
      /** Автоматом передаются язык и тестовый режим
       * TODO: возможно в конце сделать чтоб тестовый режим выбирался по домену:
       * для боевого - всегда testMode=false
       * для тестового - всегда testMode=true
       * для локального - брался из app.$CONST.testMode
       */
      params.testMode = this.$CONST.testMode
      const id = Date.now()
      const key = this.$CONST.key
      const lang = this.$i18n.locale
      const url = `${this.$CONST.apiUrl}/${method}`
      try {
        const result = await this.$axios.post(
          url,
          { id, key, lang, params },
          {
            withCredentials: true,
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
          },
        )
        return result.data
      } catch (error) {
        return { id, error }
      }
    },
  },
}
