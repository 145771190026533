<template>
<svg data-svg-lvl-3="flyleafend-block-flyleafend" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Нахзац"><rect class="lfe-c-3" x="257.26" y="93.63" width="74.41" height="259.1" transform="translate(588.93 446.36) rotate(-180)"/><path class="lfe-c-3" d="M257.26,86.62V348.09H79.39a12,12,0,0,1-12-12V98.62a12,12,0,0,1,12-12Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lfe-c-1{opacity:0.3;}.lfe-c-2{fill:#64ff00;}.lfe-c-3{fill:#f4e8ce;}</style>

