<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M390.1,316.84c-49.71-.46-90.17-40.92-90.2-90.18a90.25,90.25,0,0,1,90.24-90.29"/><g class="tss-c-4"><path class="tss-c-5" d="M390.3,317c-1.34,0-2.68-.05-4-.12"/><path class="tss-c-6" d="M382.38,316.63c-46-4.42-82.25-43.15-82.28-89.77a90.27,90.27,0,0,1,84.28-90.1"/><path class="tss-c-5" d="M386.34,136.66c1.33-.06,2.67-.09,4-.09"/></g><g class="tss-c-7"><path class="tss-c-8" d="M389.9,316.64c-1.34,0-2.68-.05-4-.12"/><path class="tss-c-9" d="M382,316.23c-46-4.42-82.25-43.15-82.28-89.77A90.27,90.27,0,0,1,384,136.36"/><path class="tss-c-8" d="M385.94,136.26c1.33-.06,2.66-.09,4-.09"/></g><path class="tss-c-10" d="M390.39,311.84c-46.82-.43-85.21-38.26-85.24-85.18a85.25,85.25,0,0,1,85.24-85.29"/><path class="tss-c-11" d="M390.14,311.84c-46.82-.43-85.21-38.26-85.24-85.18a85.25,85.25,0,0,1,85.24-85.29"/></g><g id="Шов_Цвет"><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M390.1,316.84c-1.34,0-2.68,0-4-.12"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M382.18,316.43c-46-4.42-82.25-43.15-82.28-89.77a90.27,90.27,0,0,1,84.28-90.1"/><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M386.14,136.46c1.33-.06,2.66-.09,4-.09"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-5,.tss-c-6{stroke:#000;}.tss-c-10,.tss-c-11,.tss-c-3{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-4{opacity:0.6;}.tss-c-12,.tss-c-13,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{stroke-linecap:round;stroke-width:1.14px;}.tss-c-13,.tss-c-6,.tss-c-9{stroke-dasharray:7.86 3.93;}.tss-c-7{opacity:0.7;}.tss-c-10,.tss-c-8,.tss-c-9{stroke:#fff;}.tss-c-11,.tss-c-12,.tss-c-13{stroke:#232728;}</style>

