<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="bc-cp"><rect class="bc-c-1" x="63.47" y="63.07" width="386.96" height="141.42"/></clipPath><clipPath id="bc-cp-2"><rect class="bc-c-1" x="63.44" y="39.07" width="386.93" height="386.93"/></clipPath><linearGradient id="bc-lg" x1="-10522.52" y1="-9928.82" x2="-10519.52" y2="-9928.82" gradientTransform="matrix(1, 0, 0, -1, 10778.02, -9689.87)" gradientUnits="userSpaceOnUse"><stop offset="0"/><stop offset="1" stop-opacity="0"/></linearGradient></defs><g id="Задник_Контур"><rect class="bc-c-1" x="61.41" y="60.67" width="391.19" height="287.69"/><rect class="bc-c-4" x="63.47" y="63.07" width="386.96" height="141.42"/><g class="bc-c-5"><rect class="bc-c-4" x="63.44" y="39.07" width="386.93" height="386.93"/><g class="bc-c-6"><image width="1024" height="1024" transform="translate(63.44 39.07) scale(0.38)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/spread/back/contour/PH1Z_1.webp`"/></g><rect class="bc-c-7" x="63.44" y="39.07" width="386.93" height="386.93"/></g><rect class="bc-c-7" x="63.47" y="63.07" width="386.96" height="141.42"/><rect class="bc-c-8" x="255.5" y="43.36" width="3" height="391.19" transform="translate(18.05 495.95) rotate(-90)"/><line class="bc-c-7" x1="452.59" y1="240.45" x2="61.41" y2="240.45"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1,.bc-c-4{fill:gray;}.bc-c-1,.bc-c-7{stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.bc-c-2,.bc-c-8{opacity:0.3;}.bc-c-3{fill:#64ff00;}.bc-c-5{clip-path:url(#bc-cp);}.bc-c-6{clip-path:url(#bc-cp-2);}.bc-c-7{fill:none;}.bc-c-8{fill:url(#bc-lg);}</style>

