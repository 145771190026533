<template>
<svg data-svg-lvl-3="edge-block-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Контур"><rect class="lc-c-3" x="44.59" y="226.23" width="377.32" height="1.39"/><rect class="lc-c-3" x="44.84" y="224.83" width="377.32" height="1.39"/><rect class="lc-c-3" x="44.59" y="223.44" width="377.32" height="1.39"/><rect class="lc-c-3" x="44.74" y="222.05" width="377.32" height="1.39"/><rect class="lc-c-3" x="44.82" y="220.66" width="377.32" height="1.39"/><rect class="lc-c-3" x="44.59" y="219.27" width="377.32" height="1.39"/><rect class="lc-c-3" x="44.7" y="217.88" width="377.32" height="1.39"/><rect class="lc-c-3" x="44.59" y="216.49" width="377.32" height="1.39"/><rect class="lc-c-3" x="44.59" y="215.1" width="377.32" height="1.39"/><rect class="lc-c-3" x="44.68" y="213.71" width="377.32" height="1.39"/><polygon class="lc-c-4" points="421.91 227.62 421.91 226.22 422.15 226.22 422.15 224.83 421.91 224.83 421.91 223.44 422.05 223.44 422.05 222.05 422.13 222.05 422.13 220.66 421.91 220.66 421.91 219.27 422.01 219.27 422.01 217.88 421.91 217.88 421.91 216.49 421.91 216.49 421.91 215.1 422 215.1 422 213.71 422.11 213.71 44.8 213.71 44.68 213.71 44.68 215.1 44.59 215.1 44.59 216.49 44.59 216.49 44.59 217.88 44.7 217.88 44.7 219.27 44.59 219.27 44.59 220.66 44.82 220.66 44.82 222.05 44.74 222.05 44.74 223.44 44.59 223.44 44.59 224.83 44.84 224.83 44.84 226.22 44.59 226.22 44.59 227.62 421.91 227.62"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lc-c-1{opacity:0.3;}.lc-c-2{fill:#64ff00;}.lc-c-3,.lc-c-4{fill:none;stroke-miterlimit:2;}.lc-c-3{stroke:#707d7f;stroke-width:0.2px;}.lc-c-4{stroke:#232728;stroke-width:0.5px;}</style>

