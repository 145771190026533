<template>
<svg data-svg-lvl-3="cover-back-corners" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bcr-lg" x1="455.84" y1="15756.86" x2="455.84" y2="15752.72" gradientTransform="matrix(1, 0, 0, -1, 0, 16037.72)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-2" x1="464.02" y1="15764.85" x2="467.99" y2="15764.85" gradientTransform="matrix(1, 0, 0, -1, 0, 16037.72)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#e0ce94" />
  <stop offset="0.31" stop-color="#b38e40" />
  <stop offset="0.32" stop-color="#b49043" />
  <stop offset="0.39" stop-color="#ba9953" />
  <stop offset="0.48" stop-color="#be9e5c" />
  <stop offset="0.59" stop-color="#bfa05f" />
  <stop offset="0.93" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#83858a" />
  <stop offset=".18" stop-color="#e0e0e0" />
  <stop offset=".31" stop-color="#83858a" />
  <stop offset=".31" stop-color="#84868b" />
  <stop offset=".36" stop-color="#9fa2a8" />
  <stop offset=".4" stop-color="#b4b7be" />
  <stop offset=".45" stop-color="#c4c7ce" />
  <stop offset=".51" stop-color="#cdd0d8" />
  <stop offset=".59" stop-color="#d0d3db" />
  <stop offset=".93" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-3" x1="58.16" y1="281.01" x2="58.16" y2="285.08" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" href="#bcr-lg"/><linearGradient id="bcr-lg-4" x1="4714.6" y1="15764.85" x2="4710.63" y2="15764.85" gradientTransform="translate(4760.68 16037.72) rotate(180)" href="#bcr-lg-2"/></defs><g id="УголкиЗадник"><path class="bcr-c-3" d="M466.56,285.09h-22.5l4.17-4.17h15.65l3.74,3.73A1.51,1.51,0,0,1,466.56,285.09Z"/><path class="bcr-c-4" d="M463.88,280.92V265.26l4.18-4.17v22.5a1.51,1.51,0,0,1-.44,1.06Z"/><path class="bcr-c-5" d="M468.06,261.09v22.5a1.5,1.5,0,0,1-1.5,1.5h-22.5l4.17-4.17h15.65V265.26l4.18-4.17m-.81-.81-4.17,4.18-.34.33v15h-15l-.34.34-4.17,4.17.81,2h22.5a2.64,2.64,0,0,0,2.64-2.64v-22.5l-1.95-.81Z"/><path class="bcr-c-6" d="M47.44,285.09h22.5l-4.17-4.17H50.12l-3.74,3.73A1.51,1.51,0,0,0,47.44,285.09Z"/><path class="bcr-c-7" d="M50.12,280.92V265.26l-4.18-4.17v22.5a1.51,1.51,0,0,0,.44,1.06Z"/><path class="bcr-c-5" d="M45.94,261.09l4.18,4.17v15.66H65.77l4.17,4.17H47.44a1.5,1.5,0,0,1-1.5-1.5v-22.5m.81-.81-2,.81v22.5a2.64,2.64,0,0,0,2.64,2.64h22.5l.81-2-4.17-4.17-.34-.34h-15v-15l-.34-.33-4.17-4.18Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bcr-c-1{opacity:0.3;}.bcr-c-2{fill:#64ff00;}.bcr-c-3{fill:url(#bcr-lg);}.bcr-c-4{fill:url(#bcr-lg-2);}.bcr-c-5{fill:#232728;}.bcr-c-6{fill:url(#bcr-lg-3);}.bcr-c-7{fill:url(#bcr-lg-4);}</style>

