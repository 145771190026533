<template>
<svg data-svg-lvl-3="flyleaffront-block-flyleaffront" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Форзац"><path class="lff-c-3" d="M78.88,154.91h166a0,0,0,0,1,0,0V286.67a0,0,0,0,1,0,0h-166a12,12,0,0,1-12-12V166.91A12,12,0,0,1,78.88,154.91Z"/><path class="lff-c-3" d="M256.74,282H434.61a12,12,0,0,0,12-12V159.84a12,12,0,0,0-12-12H256.74Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lff-c-1{opacity:0.3;}.lff-c-2{fill:#64ff00;}.lff-c-3{fill:#f4e8ce;}</style>

