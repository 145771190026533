<template>
  <div class="modal-wrapper-top">
    <transition name="fade">
      <div class="modal-wrapper" v-if="value">
        <div class="background"></div>
      </div>
    </transition>
    <transition name="popup">
      <div class="modal-wrapper" :style="style" @click="close" v-if="value">
        <div
          class="modal-inner"
          :style="{ 'max-width': bp.smAndDown ? '100%' : width }"
          v-focus
          tabindex="0"
          @keyup.esc="close"
          @click="close"
        >
          <div class="close" v-if="!hideClose" @click="close">
            <IconsIconClose />
          </div>
          <div class="content" @click.stop>
            <div class="title" v-if="header">{{ header }}</div>
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    header: {
      type: String,
      default: () => '',
    },
    hideClose: {
      type: Boolean,
      default: () => false,
    },
    width: {
      type: String,
      default: () => '100%',
    },
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
  },

  computed: {
    ...mapGetters({
      bp: 'window/BP',
      windowWidth: 'window/WIDTH',
    }),

    style() {
      let style = {}
      if (this.bp.smAndDown) {
        style = {
          alignItems: 'flex-start',
          top: '70px',
        }
      }
      return style
    },
  },
}
</script>

<style lang="sass" scoped>
.modal-wrapper
  display: flex
  justify-content: center
  align-items: center
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 30
.background
  background-color: rgba(0, 0, 0, .5)
  position: fixed
  cursor: pointer
  width: 100%
  height: 100%
  top: 0
  bottom: 0
  left: 0
  right: 0
.modal-inner
  display: flex
  position: absolute
  z-index: 1
  background-color: $white
  box-shadow: 0px 4px 15px rgba(0, 52, 49, 0.4)
  padding: 20px 20px
  transition: ease .3s
  height: fit-content
  border-radius: 15px
  max-width: 100%
  max-height: calc(100% - 110px)
  @media (max-width: 779px)
    border-radius: 0
    width: 100%
    // height: 100%

  .close
    position: absolute
    top: -40px
    right: 0px
    cursor: pointer
    width: 30px
    height: 30px
    &::v-deep path
      fill:  $white
    @media (max-width: 779px)
      top: -60px
      width: 50px
      height: 50px

  .content
    display: flex
    flex-direction: column
    overflow: hidden
    @media (max-width: 1199px)
      width:100%
      border-radius: 0px
      display: flex
      justify-content: center
    .title
      font-family: 'Eurostile'
      font-weight: 500
      font-size: 30px
      line-height: 32px
      text-align: center
      margin: 10px 0 20px 0


.fade-enter-active, .fade-leave-active
  transition: all .2s

.fade-enter, .fade-leave-to
  opacity: 0

.popup-enter-active, .popup-leave-active
  transition: all .2s

.popup-enter, .popup-leave-to
  transform: translateY(50px)
  opacity: 0
</style>
