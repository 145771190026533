<template>
<svg data-svg-lvl-3="cover-top-stitchingRubber" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="sr-cp"><path class="sr-c-1" d="M316.47,136.47s-3.61,60.85-3.61,85.42,3.61,74.64,3.61,74.64a7.82,7.82,0,0,1-7.82,7.82H192.59a7.82,7.82,0,0,1-7.82-7.82s3.6-50.4,3.6-74.64-3.6-85.42-3.6-85.42H162.56v184h176v-184Z"/></clipPath><clipPath id="sr-cp-2"><rect class="sr-c-1" x="47.75" y="52.34" width="418.5" height="343.59"/></clipPath><linearGradient id="sr-lg" x1="162.56" y1="228.49" x2="338.56" y2="228.49" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.3"/><stop offset="0.2" stop-opacity="0"/><stop offset="0.79" stop-opacity="0"/><stop offset="1" stop-opacity="0.3"/></linearGradient><linearGradient id="sr-lg-2" x1="250.56" y1="320.02" x2="250.56" y2="151.76" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.3"/><stop offset="0.14" stop-opacity="0.14"/><stop offset="1" stop-opacity="0"/></linearGradient></defs><g id="Сшивка_Резинка_Текстура"><g class="sr-c-4"><g class="sr-c-5"><image width="514" height="422" transform="translate(47.75 52.34) scale(0.81)" :href="`${$CONST.cnstrAssetsUrl}/others/black-rubber.webp`"/></g></g></g><g id="Сшивка_Резинка_Контур"><path class="sr-c-6" d="M316.47,136.47s-3.61,60.85-3.61,85.42,3.61,74.64,3.61,74.64a7.82,7.82,0,0,1-7.82,7.82H192.59a7.82,7.82,0,0,1-7.82-7.82s3.6-50.4,3.6-74.64-3.6-85.42-3.6-85.42H162.56v184h176v-184Z"/><path class="sr-c-7" d="M316.47,136.47s-3.61,60.85-3.61,85.42,3.61,74.64,3.61,74.64a7.82,7.82,0,0,1-7.82,7.82H192.59a7.82,7.82,0,0,1-7.82-7.82s3.6-50.4,3.6-74.64-3.6-85.42-3.6-85.42H162.56v184h176v-184Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.sr-c-1{fill:none;}.sr-c-2{opacity:0.3;}.sr-c-3{fill:#64ff00;}.sr-c-4{clip-path:url(#sr-cp);}.sr-c-5{clip-path:url(#sr-cp-2);}.sr-c-6{fill:url(#sr-lg);}.sr-c-7{fill:url(#sr-lg-2);}</style>

