<template>
<svg data-svg-lvl-3="flyleaffront-block-pocket" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lp-lg" x1="72.88" y1="243.61" x2="184.05" y2="243.61" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#333"/><stop offset="1" stop-color="#b2b2b2" stop-opacity="0.5"/></linearGradient><linearGradient id="lp-lg-2" x1="128.45" y1="221.73" x2="128.45" y2="279.75" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.05"/><stop offset="1" stop-opacity="0.3"/></linearGradient><clipPath id="lp-cp"><path class="lp-c-1" d="M72.88,206.56H184a0,0,0,0,1,0,0v69.52a4.59,4.59,0,0,1-4.59,4.59h-102a4.59,4.59,0,0,1-4.59-4.59V206.56A0,0,0,0,1,72.88,206.56Z"/></clipPath></defs><g id="КонвертКонтур_Пластиковый_Карман" ><g class="lp-c-4"><path class="lp-c-5" d="M72.88,206.56H184a0,0,0,0,1,0,0v69.52a4.59,4.59,0,0,1-4.59,4.59h-102a4.59,4.59,0,0,1-4.59-4.59V206.56A0,0,0,0,1,72.88,206.56Z"/><path class="lp-c-6" d="M72.88,221H184a0,0,0,0,1,0,0v55.06a4.59,4.59,0,0,1-4.59,4.59h-102a4.59,4.59,0,0,1-4.59-4.59V221a0,0,0,0,1,0,0Z"/><path class="lp-c-7" d="M179.39,280.14H77.5a4.05,4.05,0,0,1-4-4.05V221h.73v55.07a3.31,3.31,0,0,0,3.31,3.31H179.39a3.31,3.31,0,0,0,3.31-3.31V221h.74v55.07A4.06,4.06,0,0,1,179.39,280.14Z"/></g><g class="lp-c-4"><g class="lp-c-8"><g class="lp-c-9"><image width="276" height="396" transform="translate(52.74 112.03) scale(0.55 0.55)" :href="`${$CONST.cnstrAssetsUrl}/others/pocket.webp`"/></g></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lp-c-1{fill:none;}.lp-c-2{opacity:0.3;}.lp-c-3{fill:#64ff00;}.lp-c-4{opacity:0.8;}.lp-c-5,.lp-c-9{opacity:0.5;}.lp-c-5{fill:url(#lp-lg);}.lp-c-6{fill:#828282;opacity:0.4;}.lp-c-7{fill:url(#lp-lg-2);}.lp-c-8{clip-path:url(#lp-cp);}</style>

