<template>
  <div class="preloader-layout" :style="layout">
    <div class="preloader" :style="preload">
      <div class="preloader-background">
        <div style="opacity: 0; visiblity: hidden">загружается...</div>
        <svg class="preloader-icon" xmlns:svg="www.w3.org/2000/svg" viewbox="0 0 40 40">
          <circle class="c1" cx="20" cy="20" r="3"></circle>
          <circle class="c2" cx="20" cy="20" r="8"></circle>
          <circle class="c3" cx="20" cy="20" r="13"></circle>
          <circle class="c4" cx="20" cy="20" r="18"></circle>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /** @property {boolean} fullscreen прелодер на весь экран или на родителя */
    fullscreen: {
      type: Boolean,
      default: false,
    },
    /** @property {boolean} fullscreen прелодер на весь экран или на родителя */
    inner: {
      type: Boolean,
      default: false,
    },
    /** @property {string} minHeight минимальная высота для встроенного прелодера */
    minHeight: {
      type: String,
      default: '300px',
    },
    /** @property {string} bg цвет подложки для встроенного прелодера */
    bg: {
      type: String,
      default: '#000000',
    },
  },
  data() {
    return {}
  },
  computed: {
    layout() {
      return {
        position: this.inner ? 'absolute' : 'relative',
        minHeight: this.inner ? 'auto' : this.minHeight,
      }
    },
    preload() {
      return {
        position: this.fullscreen ? 'fixed' : 'absolute',
        background: this.bg + '44',
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.preloader-layout
  top: 0
  left: 0
  right: 0
  bottom: 0
  min-height: 300px
  width: 100%
  z-index: 15
.preloader
  top: 0
  left: 0
  right: 0
  bottom: 0
  // background: rgba(200, 200, 200, 0.5)

  .preloader-background
    position: absolute
    height: 40px
    width: 40px
    margin: auto
    top: 0
    bottom: 0
    left: 0
    right: 0

    .preloader-icon
      width: 40px
      height: 40px
      circle
        fill: none
        stroke-width: 2px
        stroke-linecap: round
      .c1
        stroke: $contrast-dark
        stroke-dasharray: 1 11 7 0
        animation: c1 2.0s linear infinite

      .c2
        stroke: $alternative
        stroke-dasharray: 2 30 18 0
        animation: c2 1.4s linear infinite

      .c3
        stroke: $contrast-dark
        stroke-dasharray: 32 48 2 0
        animation: c3 1.7s linear infinite

      .c4
        stroke: $alternative
        stroke-dasharray: 2 69 44 0
        animation: c4 1.1s linear infinite

@keyframes c1
  0%
    stroke-dashoffset: 0

  100%
    stroke-dashoffset: 19


@keyframes c2
  0%
    stroke-dashoffset: 0

  100%
    stroke-dashoffset: 50


@keyframes c3
  0%
    stroke-dashoffset: 0

  100%
    stroke-dashoffset: 82


@keyframes c4
  0%
    stroke-dashoffset: 0

  100%
    stroke-dashoffset: 114
</style>
