<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="tc-rg" cx="376.11" cy="42.99" r="439.96" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.2"/><stop offset="0.44" stop-color="#e5e5e5" stop-opacity="0"/></radialGradient><linearGradient id="tc-lg" x1="109.91" y1="223.55" x2="116.42" y2="223.55" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.2"/><stop offset="0.29" stop-opacity="0.15"/><stop offset="0.57" stop-opacity="0.15"/><stop offset="0.75" stop-opacity="0.08"/><stop offset="1" stop-opacity="0"/></linearGradient><linearGradient id="tc-ng_2" x1="109.97" y1="220.75" x2="104.74" y2="220.75" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#989ba0"/><stop offset="0.3" stop-color="#fff"/><stop offset="0.83" stop-color="#3c3e45"/><stop offset="1" stop-color="#979a9f"/></linearGradient></defs><g id="Крышки_Контур"><path class="tc-c-3" d="M390.14,36.35A9.13,9.13,0,0,0,381,27.22H115.82c-1.59,0-2.29-1-2.91-2s-2.82-4.13-2.82-4.13l-.18,398.81c.85,0,2.28,3.08,3,4.07a4.84,4.84,0,0,0,3.51,2H381a9.16,9.16,0,0,0,9.13-9.13Z"/><path class="tc-c-4" d="M390.14,36.35A9.13,9.13,0,0,0,381,27.22H115.82c-1.59,0-2.29-1-2.91-2s-2.82-4.13-2.82-4.13l-.18,398.81c.85,0,2.28,3.08,3,4.07a4.84,4.84,0,0,0,3.51,2H381a9.16,9.16,0,0,0,9.13-9.13Z"/><line class="tc-c-5" x1="111.39" y1="22.96" x2="111.73" y2="422.05"/><line class="tc-c-5" x1="112.68" y1="24.86" x2="113.03" y2="424.13"/><line class="tc-c-5" x1="114.02" y1="26.61" x2="114.37" y2="425.33"/><path class="tc-c-6" d="M390.14,36.35A9.13,9.13,0,0,0,381,27.22H115.82c-1.59,0-2.29-1-2.91-2s-2.82-4.13-2.82-4.13h-5.77c-.06,0-.16-.15-.16-.15v398.8s.06.16.18.16h5.57c.85,0,2.28,3.08,3,4.07a4.84,4.84,0,0,0,3.51,2H381a9.16,9.16,0,0,0,9.13-9.13Z"/><path class="tc-c-7" d="M103.48,420V20.87s.19.55.54.55h4a2,2,0,0,1,2,2V418.58a2,2,0,0,1-2,2h-3.77C103.6,420.62,103.48,420,103.48,420Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#64ff00;}.tc-c-3{fill:url(#tc-rg);}.tc-c-4{fill:url(#tc-lg);}.tc-c-5,.tc-c-6{fill:none;}.tc-c-5,.tc-c-6,.tc-c-7{stroke:#232728;stroke-miterlimit:2;}.tc-c-5{stroke-width:0.45px;}.tc-c-6,.tc-c-7{stroke-width:1.14px;}.tc-c-7{fill:url(#tc-ng_2);}</style>

