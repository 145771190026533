<template>
<svg data-svg-lvl-3="spread-block-lasseNameplate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lln-ng_2" x1="289.24" y1="364.14" x2="267.49" y2="390.39" gradientUnits="userSpaceOnUse">
  <template v-if="params.lasse.nameplateColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset=".3" stop-color="#fbe6a6" />
  <stop offset=".83" stop-color="#6c4209" />
  <stop offset="1" stop-color="#efe9cd" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset="0.3" stop-color="#fff" />
  <stop offset="0.83" stop-color="#3c3e45" />
  <stop offset="1" stop-color="#979a9f" />
</template>
</linearGradient></defs><g id="Шильда_Контур"><path class="lln-c-3" d="M289.94,364.86a1.41,1.41,0,0,0-1.49-1.31l-6.68.41.16,2.6.29,0a.47.47,0,0,1,.48.45l.06,1.15a.5.5,0,0,1-.48.53l-8.29.4a.49.49,0,0,1-.52-.47l-.05-1.13a.5.5,0,0,1,.48-.53l.28,0-.14-2.48-7.44.46a1.4,1.4,0,0,0-1.31,1.49l1.45,23.37a1.39,1.39,0,0,0,1.48,1.31l21.85-1.35a1.41,1.41,0,0,0,1.31-1.49Z"/><path class="lln-c-4" d="M277.56,377.13l-.27-4.72-5.84.33.14,2.36,3.15-.18.62,10.92,10.29-.58-.49-8.55Zm5.27,5.92-4.92.28-.21-3.64,4.93-.28Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lln-c-1{opacity:0.3;}.lln-c-2{fill:#64ff00;}.lln-c-3{stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;fill-rule:evenodd;fill:url(#lln-ng_2);}.lln-c-4{opacity:0.5;}</style>

