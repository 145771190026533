<template>
<svg data-svg-lvl-3="cover-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Задник_Контур"><path class="bc-c-3" d="M464.58,129.33H49.43v181a7.6,7.6,0,0,0,7.6,7.6H457a7.6,7.6,0,0,0,7.6-7.6Z"/><path class="bc-c-4" d="M162.57,16.18H343.33a7.6,7.6,0,0,1,7.6,7.6V423.24a7.6,7.6,0,0,1-7.6,7.6H162.57a0,0,0,0,1,0,0V16.18A0,0,0,0,1,162.57,16.18Z" transform="translate(480.26 -33.24) rotate(90)"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1,.bc-c-4{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

