<template>
<svg data-svg-lvl-3="edge-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tc-ng_2" x1="227.76" y1="195.28" x2="239.43" y2="195.28" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#989ba0"/><stop offset="0.3" stop-color="#fff"/><stop offset="0.83" stop-color="#3c3e45"/><stop offset="1" stop-color="#979a9f"/></linearGradient></defs><g id="Крышки_Контур"><path class="tc-c-3" d="M239.45,197.62a.77.77,0,0,1-.76.77l-7.91,0s-3.3-.11-3.3-3,3.21-3.23,3.21-3.23l8,0a.79.79,0,0,1,.79.8h0a.79.79,0,0,1-.79.8h-8.09a1.57,1.57,0,0,0-1.49,1.6,1.44,1.44,0,0,0,1.35,1.47l8.26,0a.76.76,0,0,1,.76.76Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#64ff00;}.tc-c-3{stroke:#232728;stroke-miterlimit:10;stroke-width:0.55px;fill:url(#tc-ng_2);}</style>

