<template>
<svg data-svg-lvl-3="flyleafend-block-shadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lsh-m" x="30" y="52" width="455" height="341" maskUnits="userSpaceOnUse"><rect class="lsh-c-1" x="61.49" y="90.54" width="195.77" height="266.11" transform="translate(318.75 447.19) rotate(-180)"/></mask></defs><g id="Тень_Блока"><g class="lsh-c-4"><g class="lsh-c-5"><image width="455" height="341" transform="translate(30 52)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/flyleafend/block/shadow/DHL_1.webp`"/></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lsh-c-1{fill:#fff;}.lsh-c-2{opacity:0.3;}.lsh-c-3{fill:#64ff00;}.lsh-c-4{mask:url(#lsh-m);}.lsh-c-5{opacity:0.5;}</style>

