import lodash from 'lodash'
import { colors } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const seamPerimeter = {
  id: 'seamPerimeter',
  // tName:  'personalizations.seamPerimeter.seamPerimeter',
  template: 'modal',
  preview: 'cover',

  originPersonalizationsIds: [
    123, // Прошивка по периметру
    585, // Прошивка по периметру (дефолтная для изделий которые не бывают без прошивки)
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getColor(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  const idParam = 88
  const idValue = modelValues?.color?.id

  if (idValue) {
    if (this.originPersonalizations[585]) {
      idBuhta = 585
    } else if (this.originPersonalizations[123]) {
      idBuhta = 123
    } else return []
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
  ])
}

/**
 * Выбор цвета нитки
 */
function getColor(params) {
  const { store, oldModelValues, items, modelValues, originPersonalizations } = params

  let idBuhta
  const idParam = 88

  if (originPersonalizations[585]) {
    idBuhta = 585
  } else if (originPersonalizations[123]) {
    idBuhta = 123
  } else {
    return
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)

    if (val?.values?.length) {
      values = val.values.map((n) => {
        const newItem = {
          ...n,
          background: colors[n.id] ?? n.hex,
        }
        // Дефолтная нитка берётся с цветом из материала threadColorConstr
        if (n.id === 1292) {
          newItem.hex = '#000000'
          if (store?.selectedMaterials) {
            const foundHex = val.values.find((m) => m.id === store?.selectedMaterials[0]?.threadColorConstr)
            if (foundHex?.hex) {
              newItem.hex = foundHex?.hex
            }
          }
        }
        return newItem
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.seamPerimeter.color',
      template: 'selectClassicColor',
      sort: 10,
      values,
    })

    // Дефолтная нитка берётся с цветом "в соответствии с материалом"
    let defaultIndex = values.findIndex((n) => n.id === 1292)
    if (defaultIndex < 0) {
      defaultIndex = 0
    }
    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[defaultIndex]
    } else {
      modelValues.color = values[defaultIndex]
    }
  }
}
