<template>
<svg data-svg-lvl-3="edge-top-slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышка_Срез"><path :style="{ fill: params.sliceColor }"  class="tsl-c-3" d="M456.49,220.74c1.13.36,7,2.52,7,8.58v24.84c0,5.69-6.07,6.74-6.89,6.85H45.89a1.55,1.55,0,1,0,0,3.09h411c3.36-.4,9.7-3,9.7-9.93V229.32c0-7.16-6.2-10.73-9.48-11.62l-.4,0H54.33c-.82-.12-6.9-1.16-6.9-6.85V186c0-6.06,5.89-8.22,7-8.58h410.6a1.55,1.55,0,0,0,0-3.09H54.22l-.4.05c-3.28.89-9.48,4.46-9.48,11.62V210.8c0,6.94,6.35,9.53,9.7,9.93H456.49Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsl-c-1{opacity:0.3;}.tsl-c-2{fill:#64ff00;}.tsl-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

