<template>
<svg data-svg-lvl-3="spread-back-frames" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="bfr-rg" cx="-891.7" cy="60.9" fx="-891.7" fy="60.9" r="308.65" gradientTransform="translate(-859.49) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#686a6d"/><stop offset=".18" stop-color="#bcc3cd"/><stop offset=".41" stop-color="#bdc0c8"/><stop offset=".61" stop-color="#80828a"/><stop offset=".8" stop-color="#505256"/><stop offset="1" stop-color="#747578"/></radialGradient></defs><g id="УголкиЗадник"><image width="663" height="50" transform="translate(66.88 90.08) scale(.24)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/spread/back/frames/M2T_1.webp`"/><path class="bfr-c-3" d="M224.82,356.62v-4.18c0-1.19-.97-2.16-2.16-2.16H70.44c-1.74,0-3.16-1.41-3.16-3.16V99.05c0-1.74,1.41-3.16,3.16-3.16h151.21c1.19,0,2.16-.97,2.16-2.16v-4.19c0-.08-.07-.15-.15-.15H67.05c-3.46,0-6.26,2.8-6.26,6.26v254.86c0,3.46,2.8,6.26,6.26,6.26h157.62c.08,0,.15-.07.15-.15Z"/><image width="686" height="1117" transform="translate(60.64 89.12) scale(.24)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/spread/back/frames/M2T_2.webp`"/><path class="bfr-c-2" d="M223.66,89.4c.08,0,.15.07.15.15v4.19c0,1.19-.97,2.16-2.16,2.16H70.44c-1.74,0-3.16,1.41-3.16,3.16v248.07c0,1.74,1.41,3.16,3.16,3.16h152.22c1.19,0,2.16.97,2.16,2.16v4.19c0,.08-.07.15-.15.15H67.05c-3.46,0-6.26-2.8-6.26-6.26V95.66c0-3.46,2.8-6.26,6.26-6.26h156.61M223.66,88.26H67.05c-4.08,0-7.4,3.32-7.4,7.4v254.86c0,4.08,3.32,7.4,7.4,7.4h157.62c.71,0,1.29-.58,1.29-1.29v-4.19c0-1.82-1.48-3.3-3.3-3.3H70.44c-1.11,0-2.02-.9-2.02-2.02V99.05c0-1.11.9-2.02,2.02-2.02h151.21c1.82,0,3.3-1.48,3.3-3.3v-4.19c0-.71-.58-1.29-1.29-1.29h0Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bfr-c-1{opacity:.3;}.bfr-c-2{fill:#232728;}.bfr-c-2,.bfr-c-3,.bfr-c-4{stroke-width:0px;}.bfr-c-3{fill:url(#bfr-rg);}.bfr-c-4{fill:#64ff00;}</style>

