import { render, staticRenderFns } from "./Rm.vue?vue&type=template&id=74b6cf8d&scoped=true"
import script from "./Rm.vue?vue&type=script&lang=js"
export * from "./Rm.vue?vue&type=script&lang=js"
import style0 from "./Rm.vue?vue&type=style&index=0&id=74b6cf8d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74b6cf8d",
  null
  
)

export default component.exports