<template>
<svg data-svg-lvl-3="cover-top-rubber" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tr-lg" x1="397.9" y1="219.36" x2="401.92" y2="224.12" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.4"/><stop offset="0.01" stop-opacity="0.39"/><stop offset="1" stop-opacity="0"/></linearGradient><linearGradient id="tr-lg-2" x1="-4826.52" y1="-4852.53" x2="-4819.68" y2="-4852.53" gradientTransform="matrix(0, -1, -1, 0, -4601.58, -4601.58)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0"/><stop offset="0.68" stop-color="#fff" stop-opacity="0.4"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="tr-lg-3" x1="399.57" y1="209.46" x2="404.25" y2="214.77" gradientUnits="userSpaceOnUse"><stop offset="0.13" stop-opacity="0.3"/><stop offset="0.43" stop-opacity="0"/><stop offset="1" stop-opacity="0.2"/></linearGradient></defs><g id="РезинкаТестура"><path :style="{ fill: params.rubber.color }"  class="tr-c-3" d="M393.73,224.07a3.43,3.43,0,0,1-2.28.87H113.27c-1.9,0-2.82-1.54-2.82-3.43s.92-3.42,2.82-3.42H390.14l20-17.9c1.8-1.57,3.88.06,3.88,2.12,0,2.57-.73,4.35-3.88,7.17Z"/></g><g id="РезинкаКонтур"><path class="tr-c-4" d="M391.45,224.94h-1.31v9.61l20-17.32v-7.75l-16.42,14.59A3.43,3.43,0,0,1,391.45,224.94Z"/><path class="tr-c-5" d="M390.14,218.09H113.27c-1.9,0-2.82,1.53-2.82,3.42s.92,3.43,2.82,3.43H391.45"/><path class="tr-c-6" d="M393.73,224.07a3.43,3.43,0,0,1-2.28.87H113.27c-1.9,0-2.82-1.54-2.82-3.43s.92-3.42,2.82-3.42H390.14l20-17.9c1.8-1.57,3.88.06,3.88,2.12,0,2.57-.73,4.35-3.88,7.17Z"/><path class="tr-c-7" d="M390.14,218.09l20-17.9c1.8-1.57,3.88.06,3.88,2.12,0,2.57-.73,4.35-3.88,7.17l-16.42,14.59a3.43,3.43,0,0,1-2.28.87"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tr-c-1{opacity:0.3;}.tr-c-2{fill:#64ff00;}.tr-c-3{fill:#999;}.tr-c-4{fill:url(#tr-lg);}.tr-c-5,.tr-c-7{opacity:0.6;}.tr-c-5{fill:url(#tr-lg-2);}.tr-c-6{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}.tr-c-7{fill:url(#tr-lg-3);}</style>

