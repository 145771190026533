<template>
<svg data-svg-lvl-3="cover-top-triangle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="tt-cp"><path class="tt-c-1" d="M113.83,423.4H387.17V30.18H114.33c-.5,0-.5-.53-.5-.53Z"/></clipPath></defs><g id="Крышка_Треугольники"><path class="tt-c-4" d="M113.83,423.4H387.17V30.18H114.33c-.5,0-.5-.53-.5-.53Z"/><g class="tt-c-5"><image width="298" height="394" transform="translate(107.8 27.61) scale(1.01)" :href="`${$CONST.cnstrAssetsUrl}/others/H11V_3.webp`"/></g><path class="tt-c-6" d="M113.83,423.4H387.17V30.18H114.33c-.5,0-.5-.53-.5-.53Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tt-c-1,.tt-c-4{fill:#fff;}.tt-c-1,.tt-c-6{stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}.tt-c-2{opacity:0.3;}.tt-c-3{fill:#64ff00;}.tt-c-5{clip-path:url(#tt-cp);}.tt-c-6{fill:none;}</style>

