<template>
  <div class="ui-hint">
    <div @mouseenter.stop="setOffsets" ref="innerRef">
      <slot></slot>
    </div>
    <div
      class="popup"
      @click.stop
      :style="{ top: popupTop, left: popupLeft, width: popupWidth + 'px' }"
      v-if="hintHtml"
    >
      <div class="popup__text" v-html="hintHtml"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      popupTop: '0px',
      popupLeft: '0px',
    }
  },
  props: {
    hintHtml: {
      type: String,
      default: () => null,
    },
    scrollerRef: {
      type: Object,
      default: () => null,
    },
    popupWidth: {
      type: Number,
      default: () => 160,
    },
    topCorrection: {
      type: Number,
      default: () => 0,
    },
    leftCorrection: {
      type: Number,
      default: () => 0,
    },
  },

  mounted() {
    window.addEventListener('scroll', this.setOffsets)
    let scrollerRef = this.scrollerRef?.scrollerRef
    if (scrollerRef) {
      scrollerRef.addEventListener('scroll', this.setOffsets)
    }
  },

  destroyed() {
    window.removeEventListener('scroll', this.setOffsets)
  },

  methods: {
    setOffsets() {
      let target = this.$refs.innerRef
      var rect = target.getBoundingClientRect()
      this.popupTop = `${rect.bottom + 13 + this.topCorrection}px`
      this.popupLeft = `${rect.left - this.popupWidth / 2 + rect.width / 2 + this.leftCorrection}px`
    },
  },
}
</script>

<style lang="sass" scoped>



.ui-hint
  &:hover .popup
    display: block
  .popup
    display: none
    position: fixed
    z-index: 1
    &::before
      content: ''
      position: absolute
      top: -13px
      left: calc( 50% - 7px )
      width: 14px
      height: 15px
      position: absolute
      background-image: url(https://assets.cnstr.adjutant.ru/images/others/pointer-pers.webp)
    &__text
      color: $black
      font-size: 13px
      line-height: 19px
      text-align: center
      overflow: hidden
      padding: 5px 10px
      background: $white
      border: 1px solid $stroke-color
      box-shadow: 0px 4px 4px rgba(5, 45, 43, 0.3)
      border-radius: 5px
</style>
