import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'
import { getDraggableAreaMm, getPosition } from '/src/utils/draggable'

export const cutting = {
  id: 'cutting',
  // tName:  'personalizations.cutting.cutting',
  template: 'modal',
  preview: 'cover',

  originPersonalizationsIds: [
    373, // Стандартная фигурная вырубка крышки
    583, // Индивидуальная фигурная вырубка крышки
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getCuttung(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  let idParam
  const idValue = modelValues?.cutting?.id

  if (idValue) {
    if (idValue === 'individual') {
      idBuhta = 583
    } else {
      idBuhta = 373
      idParam = 81
    }
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
  ])
}

/**
 *  Выбор вырубки
 */
function getCuttung(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originPersonalizations,
    originIdsAvailable,
    store,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  let idBuhta = 373
  const idParam = 81

  if (originIdsAvailable.length === 0) {
    return
  }
  let values = []

  if (originPersonalizations[idBuhta]?.params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => {
        let w = 50
        let h = 50
        if (sizes[n.id]) {
          w = sizes[n.id].w
          h = sizes[n.id].h
        }
        return {
          ...n,
          img: `${cnstrAssetsUrl}/personalizations/cutting/${n.id}.webp`,
          r: 0,
          w,
          h,
        }
      })
    }
  }

  idBuhta = 583
  if (originPersonalizations[idBuhta]) {
    values.push({
      id: 'individual',
      tName: 'personalizations.cutting.individual',
      img: `${cnstrAssetsUrl}/personalizations/cutting/individual.webp`,
      r: 0,
      w: sizes.I.w,
      h: sizes.I.h,
    })
  }
  if (values.length > 0) {
    items.push({
      id: 'cutting',
      tName: 'personalizations.cutting.cutting',
      template: 'selectClassicImage',
      sort: 10,
      values,
    })

    if (oldModelValues.cutting) {
      modelValues.cutting = values.find((i) => i.id === oldModelValues.cutting.id) ?? values[0]
      const { r, x, y, w, h } = oldModelValues.cutting
      if (x === undefined || y === undefined) {
        const draggableArea = getDraggableAreaMm(store)
        const draggablePosition = getPosition({ w, h }, draggableArea)
        modelValues.cutting = { ...modelValues.cutting, ...draggablePosition }
      } else {
        modelValues.cutting = { ...modelValues.cutting, r, x, y, w, h }
      }
    } else {
      const draggableArea = getDraggableAreaMm(store)
      const draggablePosition = getPosition({ w: values[0].w, h: values[0].h }, draggableArea)
      modelValues.cutting = { ...values[0], ...draggablePosition }
    }
  }
}

/**
 * Размеры вырубки в см.
 * Вычисляется из расчета 500px = 306см
 * Xcm = Ypx * 306 / 500 =  Ypx * .612
 */
const sizes = {
  1144: { w: 126, h: 126 },
  1145: { w: 86, h: 86 },
  1146: { w: 91, h: 91 },
  1147: { w: 76, h: 76 },
  1148: { w: 92, h: 92 },
  1149: { w: 69, h: 69 },
  I: { w: 125, h: 125 },
}

// const sizesReal = {
//   1144: { w: 126, h: 122 },
//   1145: { w: 86, h: 86 },
//   1146: { w: 44, h: 91 },
//   1147: { w: 76, h: 76 },
//   1148: { w: 84, h: 92 },
//   1149: { w: 69, h: 59 },
//   I: { w: 69, h: 125 },
// }
