import { render, staticRenderFns } from "./ImageActive.vue?vue&type=template&id=b504f594&scoped=true"
var script = {}
import style0 from "./ImageActive.vue?vue&type=style&index=0&id=b504f594&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b504f594",
  null
  
)

export default component.exports