<template>
  <div class="cntnr" ref="scrollerRef">
    <div class="item-wrapper d-flex flex-wrap">
      <UiHint
        v-for="item in items"
        :key="item.id"
        :hintHtml="item.hint || null"
        :topCorrection="-6"
        :popupWidth="120"
        :scrollerRef="$refs"
      >
        <div class="item d-flex" @click="click(item)">
          <div class="item__link" :class="{ _checked: item.id === value.id }">
            <img class="item__img" :src="item.img" />
            <div class="item__text" v-if="showNames">
              {{ item.tName ? $t(item.tName) : item.name }}
            </div>
          </div>
          <div class="check-icon" v-if="!showNames && item.id === value.id">
            <ImagesImageActive />
          </div>
        </div>
      </UiHint>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    /** @var items - массив объектов для отрисовки списка. Объект должен
     * содержать следующие поля
     * @property {number} id - ключ для списка
     * @property {string} name - наименования для надписи
     */
    items: {
      type: Array,
      required: true,
    },

    /** @var value - выбранный объект из items */
    value: {
      type: Object,
      required: true,
    },
    /** @var tLabel - перевод метки */
    tLabel: {
      type: String,
      default: () => '',
    },
    /** @var tLabel - токазывать ли текстовые надписи */
    showNames: {
      type: Boolean,
      default: () => false,
    },
  },

  methods: {
    click(item) {
      if (item.id !== this.value.id) {
        this.$emit('input', item)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
// .item-wrapper
//   padding-bottom: 40px
.item
  position: relative
  cursor: pointer
  &__link
    display: block
    // background-color: #eee

    margin: 5px
    &._checked
      .item__img
        border: 2px solid $contrast-regular
        // border-color: $contrast-regular
      .item__text
        color: $contrast-regular
    &:hover
      .item__img
        filter: drop-shadow(0px 4px 15px rgba(0, 52, 49, 0.4))
      .item__text
        color: $contrast-regular
  &__img
    // border: 2px solid transparent
    border-radius: 10px
    width: 70px
    height: 70px
    margin: auto
    display: block
    filter: drop-shadow(0px 2px 2px rgba(5, 45, 43, 0.15))


  &__text
    margin-top: 5px
    text-align: center
    color: $dark-text
    font-size: 13px
    width: 122px
  // &__link:hover &__text
  //   color: $contrast-regular
  .check-icon
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0px
    margin: auto
    width: 30px
    height: 30px
</style>
