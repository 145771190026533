import lodash from 'lodash'
import { getMaterials } from '/src/utils/api/getMaterials'

export default {
  namespaced: true,

  state() {
    return {
      tabs: [
        { id: 1, tName: 'materials.materials', key: 'materials' },
        { id: 2, tName: 'materials.stitchings', key: 'stitchings' },
        { id: 3, tName: 'materials.laminates', key: 'laminates' },
      ],

      modelsWithAdditionalMaterials: {
        back: [14, 21],
        internal: [5, 12, 13, 15, 22, 24, 25, 27],
        spine: [7],
        pocket: [21],
        backPocket: [21],
      },

      types: [],
      textures: [],
      colors: [],

      request: { locale: '', product: 0, classId: 0, model: 0, block: 0 },
      materials: [],
      stitchings: [],
      laminates: [],
    }
  },

  mutations: {
    CLEAR_REFERENCES(state) {
      state.textures = []
      state.colors = []
    },
    SET_REFERENCES(state, payload) {
      state.types = payload.materialTypes
      state.textures = payload.materialTextures
      state.colors = payload.materialColors
    },

    SET_REQUEST(state, payload) {
      state.request = payload
    },
    CLEAR_ITEMS(state) {
      state.request = { locale: '', query: {} }
      state.materials = []
      state.stitchings = []
      state.laminates = []
      // { locale: '', product: 0, classId: 0, model: 0, block: 0 }
    },
    SET_ITEMS(state, payload) {
      state.materials = payload.materials
      state.stitchings = payload.stitchings
      state.laminates = payload.laminates
    },
  },

  actions: {
    async GET_ITEMS({ commit, state, getters }, { api, locale, product, classId, model, block, CONST }) {
      if (
        locale !== state.request.locale ||
        product !== state.request.product ||
        classId !== state.request.classId ||
        model !== state.request.model ||
        block !== state.request.block
      ) {
        if (state.request.locale !== '') commit('CLEAR_ITEMS')

        const data = await getMaterials(api, {
          product,
          class: classId,
          model,
          block,
          getters,
          CONST,
        })

        commit('SET_REQUEST', { locale, product, classId, model, block })
        commit('SET_ITEMS', data)
      }
    },
  },

  getters: {
    TYPES: (state) => state.types,
    TYPES_KEYS: (state) => lodash.keyBy(state.types, (item) => item.id),
    TEXTURES: (state) => state.textures,
    TEXTURES_KEYS: (state) => lodash.keyBy(state.textures, (item) => item.id),
    COLORS: (state) => state.colors,
    COLORS_KEYS: (state) => lodash.keyBy(state.colors, (item) => item.id),
    COLORS_NAMES: (state) => lodash.keyBy(state.colors, (item) => item.name),

    TABS_AVAILABLE: (state) => state.tabs.filter((item) => state[item.key].length > 0),
    TABS_AVAILABLE_KEYS: (state, getters) => lodash.keyBy(getters.TABS_AVAILABLE, (item) => item.id),

    MATERIALS: (state) => state.materials,
    MATERIALS_KEYS: (state) => lodash.keyBy(state.materials, (item) => item.id),
    STITCHINGS: (state) => state.stitchings,
    STITCHINGS_KEYS: (state) => lodash.keyBy(state.stitchings, (item) => item.id),
    LAMINATES: (state) => state.laminates,
    LAMINATES_KEYS: (state) => lodash.keyBy(state.laminates, (item) => item.id),

    MODELS_WITH_ADDITIONAL_MATERIALS: (state) => state.modelsWithAdditionalMaterials,
    MATERIAL_TABS,
    MATERIALS_FILTERED_BY_TAB,
  },
}

function MATERIAL_TABS(state, getters, _rootState, rootGetters) {
  const materialTabs = []
  const modelsWithAdditionalMaterials = getters.MODELS_WITH_ADDITIONAL_MATERIALS
  const selectedTab = rootGetters['control/SELECTED_TAB']
  const selectedStitching = rootGetters['control/SELECTED_STITCHING']
  const selectedMaterials = rootGetters['control/SELECTED_MATERIALS']
  const selectedModel = rootGetters['control/SELECTED_MODEL']

  if (selectedTab.id === 2 && selectedStitching.quantity) {
    let id = 1
    for (let i = 0; i < selectedStitching.quantity; i++) {
      materialTabs.push({
        id,
        key: `m${id}`,
        img: selectedMaterials[id - 1]?.imageMini ?? '',
      })
      id++
    }
    if (selectedStitching.haveAdditionalMaterial) {
      // haveAdditionalMaterial есть например у Хайтек Софт (H1V)
      materialTabs.push({
        id,
        key: `internal`,
        img: selectedMaterials[id - 1]?.imageMini ?? '',
      })
      id++
    }
  } else {
    materialTabs.push({
      id: 1,
      key: 'material',
      img: selectedMaterials[0]?.imageMini ?? '',
    })
  }

  for (const key in modelsWithAdditionalMaterials) {
    if (modelsWithAdditionalMaterials[key].includes(selectedModel.cover)) {
      const id = materialTabs.length + 1
      materialTabs.push({
        id,
        key,
        img: selectedMaterials[id - 1]?.imageMini ?? '',
      })
    }
  }
  // Костыль: Материал оборота для H2Z
  if (selectedModel.sku === 'H2Z') {
    const id = materialTabs.length + 1
    materialTabs.push({
      id,
      key: `back`,
      img: selectedMaterials[id - 1]?.imageMini ?? '',
    })
  }
  // Костыль: Внутренний материал для H2L
  if (selectedModel.sku === 'H2L') {
    const id = materialTabs.length + 1
    materialTabs.push({
      id,
      key: `internal`,
      img: selectedMaterials[id - 1]?.imageMini ?? '',
    })
  }
  return materialTabs
}

function MATERIALS_FILTERED_BY_TAB(state, getters, _rootState, rootGetters) {
  let materialsFilteredByTab = []
  let materialTabs = getters.MATERIAL_TABS
  const selectedModel = rootGetters['control/SELECTED_MODEL']
  const selectedMaterials = rootGetters['control/SELECTED_MATERIALS']
  const selectedTab = rootGetters['control/SELECTED_TAB']

  for (let tab of materialTabs) {
    /** Для некоторых моделей, у которых есть доп материал, этот доп материал
     * - должен быть не дороже основного
     * - не может быть текстурным 1-не рельеф 15-рельеф
     */
    let addMaterialStartIndex = materialTabs.length - 1
    if (selectedModel.cover === 21) {
      addMaterialStartIndex = materialTabs.length - 3
    }

    // нужно ли проверять priceOrder
    let needToCheckPrice = false
    if (selectedTab.id === 1 && selectedMaterials[addMaterialStartIndex].id && tab.id >= addMaterialStartIndex + 1) {
      if (
        selectedModel.cover === 5 ||
        selectedModel.cover === 13 ||
        selectedModel.cover === 15 ||
        selectedModel.cover === 21 ||
        selectedModel.cover === 22 ||
        selectedModel.cover === 24 ||
        selectedModel.cover === 27
      ) {
        needToCheckPrice = true
      }
    }

    // нужно ли проверять type
    let needToCheckType = false
    if (selectedMaterials[addMaterialStartIndex].id && tab.id >= addMaterialStartIndex + 1) {
      if (
        selectedModel.cover === 12 ||
        selectedModel.cover === 13 ||
        (selectedModel.class !== 6 && selectedModel.cover === 15) ||
        selectedModel.cover === 21 ||
        selectedModel.cover === 22 ||
        selectedModel.cover === 25 ||
        selectedModel.cover === 27
      ) {
        needToCheckType = true
      }
    }

    materialsFilteredByTab.push(
      getters.MATERIALS.filter((item) => {
        if (
          // должен быть не дороже основного
          (needToCheckPrice && item.priceOrder < selectedMaterials[0].priceOrder) ||
          // тип материала не может быть рельефным (1-не рельеф 15-рельеф)
          (needToCheckType && item.type === 15) ||
          // Для сшивок бумага не может быть
          (tab.id === 2 && item.type === 3)
        ) {
          return false
        }
        return true
      }),
    )
  }

  return materialsFilteredByTab
}
