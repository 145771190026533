<template>
<svg data-svg-lvl-3="cover-top-laminate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Ламинат_Блик"> <image transform="translate(0 4)" :href="params.laminateGlow" /></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tlm-c-1{opacity:0.3;}.tlm-c-2{fill:#64ff00;}</style>

