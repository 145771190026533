<template>
<svg data-svg-lvl-3="edge-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tc-lg" x1="283.46" y1="191.19" x2="383.46" y2="191.19" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#232728"/><stop offset="1" stop-color="#232728" stop-opacity="0"/></linearGradient></defs><g id="Крышки_Контур"><line class="tc-c-3" x1="283.71" y1="189.71" x2="383.21" y2="192.66"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#64ff00;}.tc-c-3{fill:none;stroke-linecap:round;stroke-miterlimit:2;stroke-width:0.5px;stroke:url(#tc-lg);}</style>

