<template>
<svg data-svg-lvl-3="spread-block-lasseNameplate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="lln-rg" cx="280.11" cy="379.68" r="14.11" gradientTransform="translate(602.56 935.47) rotate(175.72) scale(1 1.52)" gradientUnits="userSpaceOnUse"><stop offset="0.6" stop-opacity="0"/><stop offset="0.85" stop-opacity="0.05"/><stop offset="0.93" stop-color="#fff" stop-opacity="0.15"/><stop offset="1" stop-opacity="0.1"/></radialGradient><radialGradient id="lln-rg-2" cx="277.86" cy="383.51" r="14" gradientTransform="matrix(1, -0.06, 0.1, 1.52, -36.67, -181.71)" gradientUnits="userSpaceOnUse"><stop offset="0.85" stop-color="#fff" stop-opacity="0"/><stop offset="0.93" stop-color="#fff" stop-opacity="0.3"/><stop offset="1" stop-color="#fff" stop-opacity="0.1"/></radialGradient></defs><g id="Шильда_Контур"><path class="lln-c-3" d="M291.4,380.83c-.53-8.24-4.55-15-9.64-17.15l.16,2.7h.3a.46.46,0,0,1,.49.44l.06,1.16a.51.51,0,0,1-.49.53l-8.29.39a.48.48,0,0,1-.51-.46l-.05-1.13a.51.51,0,0,1,.48-.54h.3l-.14-2.71c-4.91,2.7-8.13,10-7.58,18.42.67,10.32,6.8,18.32,13.68,17.87S292.08,391.15,291.4,380.83Z"/><path class="lln-c-4" d="M291.4,380.83c-.53-8.24-4.55-15-9.64-17.15l.16,2.7h.3a.46.46,0,0,1,.49.44l.06,1.16a.51.51,0,0,1-.49.53l-8.29.39a.48.48,0,0,1-.51-.46l-.05-1.13a.51.51,0,0,1,.48-.54h.3l-.14-2.71c-4.91,2.7-8.13,10-7.58,18.42.67,10.32,6.8,18.32,13.68,17.87S292.08,391.15,291.4,380.83Z"/><path class="lln-c-5" d="M291.4,380.83c-.53-8.24-4.55-15-9.64-17.15l.16,2.7h.3a.46.46,0,0,1,.49.44l.06,1.16a.51.51,0,0,1-.49.53l-8.29.39a.48.48,0,0,1-.51-.46l-.05-1.13a.51.51,0,0,1,.48-.54h.3l-.14-2.71c-4.91,2.7-8.13,10-7.58,18.42.67,10.32,6.8,18.32,13.68,17.87S292.08,391.15,291.4,380.83Z"/><path class="lln-c-6" d="M277.45,379.94l-.34-6-7.45.42.17,3,4-.23.78,13.92,13.12-.74-.61-10.91Zm6.73,7.54-6.29.36-.26-4.64,6.29-.35Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lln-c-1{opacity:0.3;}.lln-c-2{fill:#64ff00;}.lln-c-3{fill:#277fff;}.lln-c-3,.lln-c-4,.lln-c-5{stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.lln-c-4{fill:url(#lln-rg);}.lln-c-5{fill:url(#lln-rg-2);}.lln-c-6{fill:#ffb62b;}</style>

