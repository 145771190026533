<template>
<svg data-svg-lvl-3="flyleafend-block-pocket" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lp-lg" x1="272.67" y1="295.53" x2="352.19" y2="295.53" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#333"/><stop offset="1" stop-color="#b2b2b2" stop-opacity="0.5"/></linearGradient><linearGradient id="lp-lg-2" x1="312.42" y1="279.88" x2="312.42" y2="321.38" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.05"/><stop offset="1" stop-opacity="0.3"/></linearGradient><clipPath id="lp-cp"><path class="lp-c-1" d="M272.67,269h79.52a0,0,0,0,1,0,0v49.73A3.29,3.29,0,0,1,348.9,322H276a3.29,3.29,0,0,1-3.29-3.29V269A0,0,0,0,1,272.67,269Z"/></clipPath></defs><g id="КонвертКонтур_Пластиковый_Карман" ><g class="lp-c-4"><path class="lp-c-5" d="M272.67,269h79.52a0,0,0,0,1,0,0v49.73A3.29,3.29,0,0,1,348.9,322H276a3.29,3.29,0,0,1-3.29-3.29V269A0,0,0,0,1,272.67,269Z"/><path class="lp-c-6" d="M272.67,279.37h79.52a0,0,0,0,1,0,0v39.38A3.29,3.29,0,0,1,348.9,322H276a3.29,3.29,0,0,1-3.29-3.29V279.37A0,0,0,0,1,272.67,279.37Z"/><path class="lp-c-7" d="M348.86,321.66H276a2.9,2.9,0,0,1-2.89-2.89v-39.4h.53v39.4a2.36,2.36,0,0,0,2.36,2.36h72.89a2.37,2.37,0,0,0,2.37-2.36v-39.4h.52v39.4A2.89,2.89,0,0,1,348.86,321.66Z"/></g><g class="lp-c-4"><g class="lp-c-8"><g class="lp-c-9"><image width="276" height="396" transform="translate(258.26 201.41) scale(0.39 0.39)" :href="`${$CONST.cnstrAssetsUrl}/others/pocket.webp`"/></g></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lp-c-1{fill:none;}.lp-c-2{opacity:0.3;}.lp-c-3{fill:#64ff00;}.lp-c-4{opacity:0.8;}.lp-c-5,.lp-c-9{opacity:0.5;}.lp-c-5{fill:url(#lp-lg);}.lp-c-6{fill:#828282;opacity:0.4;}.lp-c-7{fill:url(#lp-lg-2);}.lp-c-8{clip-path:url(#lp-cp);}</style>

