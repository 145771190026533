<template>
<svg data-svg-lvl-3="cover-top-corners" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tcr-lg" x1="369.3" y1="15713.43" x2="369.3" y2="15706.2" gradientTransform="matrix(1, 0, 0, -1, 0, 16037.72)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="tcr-lg-2" x1="383.59" y1="15727.38" x2="390.52" y2="15727.38" href="#tcr-lg"/><linearGradient id="tcr-lg-3" x1="369.3" y1="122.66" x2="369.3" y2="129.89" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.58" stop-color="#fbe6a6" />
  <stop offset="0.65" stop-color="#f9e4a4" />
  <stop offset="0.7" stop-color="#f2db9c" />
  <stop offset="0.73" stop-color="#e5cd8e" />
  <stop offset="0.76" stop-color="#d4b97b" />
  <stop offset="0.79" stop-color="#bd9f62" />
  <stop offset="0.82" stop-color="#a17f43" />
  <stop offset="0.84" stop-color="#815a20" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".58" stop-color="#edf1fa" />
  <stop offset=".65" stop-color="#eaeef7" />
  <stop offset=".69" stop-color="#e2e6ef" />
  <stop offset=".73" stop-color="#d4d8e1" />
  <stop offset=".76" stop-color="#c0c4cc" />
  <stop offset=".79" stop-color="#a7aab2" />
  <stop offset=".81" stop-color="#888a92" />
  <stop offset=".84" stop-color="#63666d" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="tcr-lg-4" x1="390.41" y1="144.02" x2="383.48" y2="144.02" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.32" stop-color="#d8b056" />
  <stop offset="0.37" stop-color="#e5c373" />
  <stop offset="0.41" stop-color="#efd38a" />
  <stop offset="0.46" stop-color="#f6de9a" />
  <stop offset="0.52" stop-color="#fae4a3" />
  <stop offset="0.59" stop-color="#fbe6a6" />
  <stop offset="1" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".32" stop-color="#a1a4aa" />
  <stop offset=".37" stop-color="#bcc0c7" />
  <stop offset=".41" stop-color="#d2d5dd" />
  <stop offset=".46" stop-color="#e1e5ed" />
  <stop offset=".52" stop-color="#eaeef7" />
  <stop offset=".59" stop-color="#edf1fa" />
  <stop offset="1" stop-color="#3c3e45" />
</template>
</linearGradient></defs><g id="УголкиКрышка"><line class="tcr-c-3" x1="357.88" y1="131.95" x2="383.35" y2="131.95"/><line class="tcr-c-4" x1="357.71" y1="131.77" x2="383.35" y2="131.77"/><line class="tcr-c-5" x1="357.54" y1="131.59" x2="383.35" y2="131.59"/><line class="tcr-c-6" x1="357.37" y1="131.41" x2="383.35" y2="131.41"/><line class="tcr-c-7" x1="357.2" y1="131.23" x2="383.35" y2="131.23"/><line class="tcr-c-8" x1="357.03" y1="131.05" x2="383.35" y2="131.05"/><line class="tcr-c-9" x1="356.86" y1="130.87" x2="383.35" y2="130.87"/><line class="tcr-c-10" x1="356.69" y1="130.69" x2="383.35" y2="130.69"/><line class="tcr-c-11" x1="356.52" y1="130.51" x2="383.35" y2="130.51"/><line class="tcr-c-12" x1="356.35" y1="130.33" x2="383.35" y2="130.33"/><line class="tcr-c-13" x1="356.18" y1="130.15" x2="383.35" y2="130.15"/><line class="tcr-c-14" x1="356.01" y1="129.97" x2="383.35" y2="129.97"/><path class="tcr-c-15" d="M388,331.68H348.73l7.28-7.29h27.34l6.53,6.52A2.61,2.61,0,0,1,388,331.68Z"/><path class="tcr-c-16" d="M383.35,324.39V297.05l7.29-7.29v39.3a2.62,2.62,0,0,1-.76,1.85Z"/><path class="tcr-c-17" d="M390.64,289.76v39.3a2.62,2.62,0,0,1-2.62,2.62H348.73l7.28-7.29h27.34V297.05l7.29-7.29m-.8-.8-7.29,7.29-.34.33v26.67H355.54l-.33.33-7.29,7.29.81,1.95H388a3.77,3.77,0,0,0,3.77-3.76v-39.3l-2-.8Z"/><path class="tcr-c-18" d="M388,122.68H348.73L356,130h27.34l6.53-6.53A2.64,2.64,0,0,0,388,122.68Z"/><path class="tcr-c-19" d="M383.35,130V157.3l7.29,7.29V125.3a2.64,2.64,0,0,0-.76-1.86Z"/><path class="tcr-c-17" d="M388,122.68a2.62,2.62,0,0,1,2.62,2.62v39.29l-7.29-7.29V130H356l-7.28-7.29H388m0-1.15H348.73l-.81,2,7.29,7.29.33.34h26.67v26.67l.34.33,7.29,7.29,2-.81V125.3a3.77,3.77,0,0,0-3.77-3.77Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tcr-c-1{opacity:0.3;}.tcr-c-2{fill:#64ff00;}.tcr-c-10,.tcr-c-11,.tcr-c-12,.tcr-c-13,.tcr-c-14,.tcr-c-3,.tcr-c-4,.tcr-c-5,.tcr-c-6,.tcr-c-7,.tcr-c-8,.tcr-c-9{fill:none;stroke:#000;stroke-miterlimit:2;stroke-width:0.5px;}.tcr-c-3{opacity:0;}.tcr-c-4{opacity:0.05;}.tcr-c-5{opacity:0.09;}.tcr-c-6{opacity:0.14;}.tcr-c-7{opacity:0.18;}.tcr-c-8{opacity:0.23;}.tcr-c-9{opacity:0.27;}.tcr-c-10{opacity:0.32;}.tcr-c-11{opacity:0.36;}.tcr-c-12{opacity:0.41;}.tcr-c-13{opacity:0.45;}.tcr-c-14{opacity:0.5;}.tcr-c-15{fill:url(#tcr-lg);}.tcr-c-16{fill:url(#tcr-lg-2);}.tcr-c-17{fill:#232728;}.tcr-c-18{fill:url(#tcr-lg-3);}.tcr-c-19{fill:url(#tcr-lg-4);}</style>

