<template>
<svg data-svg-lvl-3="flyleafend-block-flyleafend" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lfe-m-f" x="0" y="0" width="500" height="430" fill="#ffffff"><polygon  points="67.57 72.08 67.57 203.7 67.57 203.7 67.57 240.45 446.32 240.45 446.32 203.7 446.32 203.7 446.32 72.08 67.57 72.08"/></mask></defs><g id="Нахзац"> <polygon  :style="{ fill: params.flyleafendColor }"  class="lfe-c-3" points="67.57 72.08 67.57 203.7 67.57 203.7 67.57 240.45 446.32 240.45 446.32 203.7 446.32 203.7 446.32 72.08 67.57 72.08"/> <image v-if="params.flyleafendImage" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.flyleafendImage" mask="url(#lfe-m-f)" /> </g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lfe-c-1{opacity:0.3;}.lfe-c-2{fill:#64ff00;}.lfe-c-3{fill:#f4e8ce;}</style>

