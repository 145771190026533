<template>
<svg data-svg-lvl-3="spread-block-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Контур"><path class="lc-c-3" d="M420.76,86.62H93.1L66.88,112.84V340.73a12,12,0,0,0,12,12H434.61a12,12,0,0,0,12-12V112.46Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lc-c-1{opacity:0.3;}.lc-c-2{fill:#ff931e;}.lc-c-3{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}</style>

