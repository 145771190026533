<template>
<svg data-svg-lvl-3="cover-top-stitchingRubber" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="sr-cp"><rect class="sr-c-1" x="170.03" y="234.21" width="58.87" height="191.78"/></clipPath><linearGradient id="sr-lg" x1="170.03" y1="330.1" x2="228.89" y2="330.1" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.5"/><stop offset="0.14" stop-opacity="0.23"/><stop offset="1" stop-opacity="0"/></linearGradient><linearGradient id="sr-lg-2" x1="6241.12" y1="330.1" x2="6299.98" y2="330.1" gradientTransform="matrix(1, 0, 0, -1, -6071.09, 660.19)" href="#sr-lg"/><linearGradient id="sr-lg-3" x1="-4168.01" y1="-3875.54" x2="-4109.15" y2="-3875.54" gradientTransform="matrix(1, 0, 0, -1, 4338.1, -3478.98)" href="#sr-lg"/></defs><g id="Сшивка_Резинка_Текстура"><g class="sr-c-4"><image width="514" height="422" transform="translate(0 3.91)" :href="`${$CONST.cnstrAssetsUrl}/others/black-rubber.webp`"/></g></g><g id="Сшивка_Резинка_Контур"><rect class="sr-c-5" x="170.03" y="234.2" width="58.87" height="191.79"/><rect class="sr-c-6" x="170.03" y="234.2" width="58.87" height="191.79" transform="translate(398.92 660.19) rotate(-180)"/><rect class="sr-c-7" x="170.08" y="367.07" width="58.87" height="58.98" transform="translate(-197.04 596.08) rotate(-90)"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.sr-c-1{fill:none;}.sr-c-2{opacity:0.3;}.sr-c-3{fill:#64ff00;}.sr-c-4{clip-path:url(#sr-cp);}.sr-c-5{fill:url(#sr-lg);}.sr-c-6{fill:url(#sr-lg-2);}.sr-c-7{fill:url(#sr-lg-3);}</style>

