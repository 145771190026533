<template>
<svg data-svg-lvl-3="edge-block-captal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
    <defs>
        
    </defs>
    <g id="Каптал">
        <path :style="{ fill: params.captalColor }"  class="lcp-c-3" d="M47.59,220.17c0-16.06,2-19.39,2-19.39h-.27a10.7,10.7,0,0,0-7.17,10.12v21.93c0,7.4,7.16,8.39,7.17,8.39h1S47.59,236.22,47.59,220.17Z" />
    </g>
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>
            .lcp-c-3 {
                fill: #fff;
                stroke: #232728;
                stroke-miterlimit: 2;
                stroke-width: 0.25px;
            }
        </style>

