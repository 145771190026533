import lodash from 'lodash'
import { getBlocks } from '/src/utils/api/getBlocks'

export default {
  namespaced: true,

  state() {
    return {
      types: [],
      lines: [],
      formats: [],

      request: { locale: '', product: 0, classId: 0, model: 0 },
      items: [],
    }
  },

  mutations: {
    CLEAR_REFERENCES(state) {
      state.types = []
      state.lines = []
      state.formats = []
    },
    SET_REFERENCES(state, payload) {
      state.types = payload.blockTypes
      state.lines = payload.blockLines
      state.formats = payload.blockFormats
    },

    SET_REQUEST(state, payload) {
      state.request = payload
    },
    CLEAR_ITEMS(state) {
      state.request = { locale: '', query: {} }
      state.items = []
    },
    SET_ITEMS(state, payload) {
      state.items = payload
    },
  },

  actions: {
    async GET_ITEMS({ commit, state, getters }, { api, locale, product, classId, model, CONST }) {
      if (
        locale !== state.request.locale ||
        product !== state.request.product ||
        classId !== state.request.classId ||
        model !== state.request.model
      ) {
        if (state.request.locale !== '') commit('CLEAR_ITEMS')
        const data = await getBlocks(api, {
          product,
          classId,
          model,
          getters,
          CONST,
        })
        commit('SET_REQUEST', { locale, product, classId, model })
        commit('SET_ITEMS', data)
      }
    },
  },

  getters: {
    TYPES: (state) => state.types,
    TYPES_KEYS: (state) => lodash.keyBy(state.types, (item) => item.id),
    LINES: (state) => state.lines,
    LINES_KEYS: (state) => lodash.keyBy(state.lines, (item) => item.id),
    FORMATS: (state) => state.formats,
    FORMATS_KEYS: (state) => lodash.keyBy(state.formats, (item) => item.id),

    ITEMS: (state) => state.items,
    ITEMS_KEYS: (state) => lodash.keyBy(state.items, (item) => item.id),
  },
}
