<template>
<svg data-svg-lvl-3="flyleaffront-block-flyleaffront" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lff-m-f" x="0" y="0" width="500" height="430" fill="#ffffff"><polygon  points="256.74 86.61 256.74 348.09 446.61 348.09 446.61 112.46 420.76 86.61 256.74 86.61"/></mask></defs><g id="Форзац"> <polygon  :style="{ fill: params.flyleaffrontColor }"  class="lff-c-3" points="256.74 86.61 256.74 348.09 446.61 348.09 446.61 112.46 420.76 86.61 256.74 86.61"/> <image v-if="params.flyleaffrontImage" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.flyleaffrontImage" mask="url(#lff-m-f)" /> </g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lff-c-1{opacity:0.3;}.lff-c-2{fill:#ff931e;}.lff-c-3{fill:#fff;}</style>

