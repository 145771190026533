import lodash from 'lodash'

export async function getReferences(api, { orderId, dog101, dog100, CONST }) {
  const data = {}
  const response = await api('constructor-new/get-references', {})
  const fields = [
    'products',
    'blockFormats',
    'blockLines',
    'blockTypes',
    'modelClasses',
    'modelCovers',
    'modelSprings',
    'materialColors',
    'materialTextures',
    'materialTypes',
    'discountsEdition',
  ]
  for (const field of fields) {
    if (!response?.result[field]) {
      throw new Error(`getReferences: Не найдено поле response.result.${field}`)
    }
    if (field === 'products') {
      data[field] = modifyProducts(response.result[field], orderId, dog101, dog100, CONST)
    } else if (field === 'modelSprings') {
      data[field] = modifyModelSprings(response.result[field])
    } else if (field === 'materialColors') {
      data[field] = modifyMaterialColors(response.result[field])
    } else if (field === 'discountsEdition') {
      data[field] = modifyDiscountsEdition(response.result[field])
    } else {
      data[field] = response.result[field]
    }
  }
  if (response.seo) {
    data.seo = response.seo
  } else {
    throw new Error(`dataReferences: Не найдено поле response.seo`)
  }
  return data
}

function modifyProducts(products, orderId, dog101, dog100, { cnstrAssetsUrl }) {
  return lodash.sortBy(products, ['sort']).map((item) => {
    const query = { orderId, product: item.id }
    if (dog101 > 0) {
      query.dog101 = dog101
    }
    if (dog100 > 0) {
      query.dog100 = dog100
    }
    query.step = 'model'
    return {
      ...item,
      image: `${cnstrAssetsUrl}/products/${item.id}.webp`,
      imageHover: `${cnstrAssetsUrl}/products/${item.id}-hov.webp`,
      route: {
        query,
      },
    }
  })
}

function modifyModelSprings(modelSprings) {
  return modelSprings.map((item) => ({
    ...item,
    sort: item.id === 4 ? 10 : item.id,
  }))
}
function modifyMaterialColors(materialColors) {
  return materialColors.map((item) => ({
    ...item,
    key: item.key.length === 1 ? item.key + item.key : item.key,
  }))
}

function modifyDiscountsEdition(discountsEdition) {
  let newDiscountsEdition = lodash.orderBy(discountsEdition, ['quantity'])
  newDiscountsEdition = lodash.map(newDiscountsEdition, (item, index, arr) => {
    if (arr[index + 1]) {
      item.quantityNext = arr[index + 1].quantity
    }
    return item
  })
  return newDiscountsEdition
}
