<template>
  <div>
    <UiOptionsContent
      class="options-content"
      v-if="bp.xlAndUp"
      :optionsData="optionsData"
      @input="$emit('input', false)"
    />
    <UiModal v-else :value="true" @input="$emit('input', false)" width="560px" hideClose>
      <div class="opt" slot="body">
        <UiOptionsContent :optionsData="optionsData" @input="$emit('input', false)" />
      </div>
    </UiModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    /** @var optionsData - объект для отрисовки параметров материала или блока.
     * Объект должен содержать следующие поля
     * @property {string} header - заголовок
     * @property {object} params - список параметров в формате ключ-значения
     * @property {string} description - описание(можно в html)
     * @property {string} availability - наличие
     * @property {string} delivery - ближайшая поставка
     */
    optionsData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      bp: 'window/BP',
    }),
  },
}
</script>

<style lang="sass" scoped>
.options-content
  margin-left: 20px
.opt
  display: flex
  height: 100%
</style>
