<template>
<svg data-svg-lvl-3="edge-top-slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышка_Срез"><path :style="{ fill: params.sliceColor }"  class="tsl-c-3" d="M465,193.77H285.25a1.55,1.55,0,0,0,0,3.09H465c10.67,0,16.54,9.56,16.54,23.14,0,13.32-6.29,21.16-16.7,21.16H54.33c-.82-.11-6.9-1.16-6.9-6.85V209.47c0-6.06,5.89-8.22,7-8.58h410.6a1.55,1.55,0,0,0,0-3.09H54.22l-.4,0c-3.28.89-9.48,4.46-9.48,11.62v24.84c0,6.94,6.35,9.53,9.7,9.93h411c11.95,0,19.58-8.36,19.58-24.25C484.63,203.85,477.58,193.77,465,193.77Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsl-c-1{opacity:0.3;}.tsl-c-2{fill:#64ff00;}.tsl-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

