import lodash from 'lodash'

export async function getMaterials(api, { product, classId, model, block, getters, CONST }) {
  const response = await api('constructor-new/get-materials', {
    product,
    class: classId,
    model,
    block,
  })

  if (typeof response?.result !== 'object') {
    throw new TypeError(`getMaterials: Ожидается объект в response.result`)
  }
  const data = modifyItems(response.result, getters, CONST, product)

  return data
}

function modifyItems(items, getters, CONST, product) {
  const newItems = {
    materials: [],
    stitchings: [],
    laminates: [],
  }
  if (items.materials !== undefined && Array.isArray(items.materials)) {
    newItems.materials = items.materials.map((item) => {
      const textureRef = getters.TEXTURES_KEYS[item.texture]
      const colorRef = getters.COLORS_KEYS[item.color]
      const typeUnited = item.type === 15 ? 1 : item.type
      const imageMini = `${CONST.assetsUrl}/materials/small/${textureRef?.key}${colorRef?.key}_mini.jpg`
      let image = `${CONST.assetsUrl}/materials/${textureRef?.key}${colorRef?.key}.jpg`
      if (textureRef?.id === 103) {
        image = `${CONST.assetsUrl}/materials/butterfly.jpg`
      }
      return {
        ...item,
        name: `${textureRef?.key} ${textureRef?.name}`,
        subname: `${colorRef?.name} ${colorRef?.key}`,
        imageMini,
        image,
        typeUnited,
        typeRef: getters.TYPES_KEYS[typeUnited],
        textureRef,
        colorRef,
      }
    })

    // сортировка цвет - цена - текстура
    newItems.materials = lodash.sortBy(newItems.materials, ['colorRef.sort', 'priceOrder', 'textureRef.key'])
  }

  if (items.stitchings !== undefined && Array.isArray(items.stitchings)) {
    newItems.stitchings = items.stitchings.map((item) => {
      return {
        ...item,
        group: item.personalization,
        image: `${CONST.cnstrAssetsUrl}/stitchings/${product === 3 ? 'plannings/' : ''}${item.id}.webp`,
      }
    })
  }

  if (items.laminates !== undefined && Array.isArray(items.laminates)) {
    newItems.laminates = items.laminates.map((item) => {
      return {
        ...item,
        image: `${CONST.cnstrAssetsUrl}/laminates-materials/${product === 3 ? 'P' : ''}${item.key}.webp`,
        typeRef: getters.TYPES_KEYS[item.type],
        colorRef: getters.COLORS_KEYS[item.color],
      }
    })
  }

  return newItems
}
