<template>
<svg data-svg-lvl-3="cover-back-corners" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bcr-lg" x1="475.32" y1="10813.31" x2="475.32" y2="10809.17" gradientTransform="matrix(1, 0, 0, -1, 0, 11200.73)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.58" stop-color="#fbe6a6" />
  <stop offset="0.65" stop-color="#f9e4a4" />
  <stop offset="0.7" stop-color="#f2db9c" />
  <stop offset="0.73" stop-color="#e5cd8e" />
  <stop offset="0.76" stop-color="#d4b97b" />
  <stop offset="0.79" stop-color="#bd9f62" />
  <stop offset="0.82" stop-color="#a17f43" />
  <stop offset="0.84" stop-color="#815a20" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".58" stop-color="#edf1fa" />
  <stop offset=".65" stop-color="#eaeef7" />
  <stop offset=".69" stop-color="#e2e6ef" />
  <stop offset=".73" stop-color="#d4d8e1" />
  <stop offset=".76" stop-color="#c0c4cc" />
  <stop offset=".79" stop-color="#a7aab2" />
  <stop offset=".81" stop-color="#888a92" />
  <stop offset=".84" stop-color="#63666d" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-2" x1="483.5" y1="10821.3" x2="487.47" y2="10821.3" gradientTransform="matrix(1, 0, 0, -1, 0, 11200.73)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.32" stop-color="#d8b056" />
  <stop offset="0.37" stop-color="#e5c373" />
  <stop offset="0.41" stop-color="#efd38a" />
  <stop offset="0.46" stop-color="#f6de9a" />
  <stop offset="0.52" stop-color="#fae4a3" />
  <stop offset="0.59" stop-color="#fbe6a6" />
  <stop offset="1" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".32" stop-color="#a1a4aa" />
  <stop offset=".37" stop-color="#bcc0c7" />
  <stop offset=".41" stop-color="#d2d5dd" />
  <stop offset=".46" stop-color="#e1e5ed" />
  <stop offset=".52" stop-color="#eaeef7" />
  <stop offset=".59" stop-color="#edf1fa" />
  <stop offset="1" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-3" x1="-1017.98" y1="10813.31" x2="-1017.98" y2="10809.17" gradientTransform="translate(-979.32 11200.73) rotate(180)" href="#bcr-lg"/><linearGradient id="bcr-lg-4" x1="-1005.9" y1="10821.3" x2="-1009.87" y2="10821.3" gradientTransform="translate(-979.32 11200.73) rotate(180)" href="#bcr-lg-2"/></defs><g id="УголкиЗадник"><path class="bcr-c-3" d="M486,391.65h-22.5l4.17-4.17h15.66l3.73,3.73A1.51,1.51,0,0,1,486,391.65Z"/><path class="bcr-c-4" d="M483.37,387.48V371.82l4.17-4.17v22.5a1.51,1.51,0,0,1-.44,1.06Z"/><path class="bcr-c-5" d="M487.54,367.65v22.5a1.5,1.5,0,0,1-1.5,1.5h-22.5l4.17-4.17h15.66V371.82l4.17-4.17m-.81-.81L482.56,371l-.33.33v15h-15l-.33.33-4.18,4.17.81,2H486a2.64,2.64,0,0,0,2.64-2.64v-22.5l-1.95-.81Z"/><path class="bcr-c-6" d="M27.94,391.65h22.5l-4.17-4.17H30.61l-3.73,3.73A1.49,1.49,0,0,0,27.94,391.65Z"/><path class="bcr-c-7" d="M30.61,387.48V371.82l-4.17-4.17v22.5a1.51,1.51,0,0,0,.44,1.06Z"/><path class="bcr-c-5" d="M26.44,367.65l4.17,4.17v15.66H46.27l4.17,4.17H27.94a1.5,1.5,0,0,1-1.5-1.5v-22.5m.81-.81-1.95.81v22.5a2.64,2.64,0,0,0,2.64,2.64h22.5l.81-2-4.18-4.17-.33-.33h-15v-15l-.33-.33-4.17-4.18Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bcr-c-1{opacity:0.3;}.bcr-c-2{fill:#64ff00;}.bcr-c-3{fill:url(#bcr-lg);}.bcr-c-4{fill:url(#bcr-lg-2);}.bcr-c-5{fill:#232728;}.bcr-c-6{fill:url(#bcr-lg-3);}.bcr-c-7{fill:url(#bcr-lg-4);}</style>

