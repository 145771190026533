<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="bc-cp"><path class="bc-c-1" d="M257,90.13H73.46a12,12,0,0,0-12,12V344.24a12,12,0,0,0,12,12H257Z"/></clipPath></defs><g id="Задник_Контур"><g class="bc-c-4"><rect class="bc-c-5" x="56.75" y="75.39" width="204.91" height="295.58"/><g class="bc-c-6"><image width="276" height="396" transform="translate(56.33 75.41) scale(0.74 0.75)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/spread/back/contour/H11V_1.webp`"/></g></g><path class="bc-c-1" d="M257,90.13H73.46a12,12,0,0,0-12,12V344.24a12,12,0,0,0,12,12H257Z"/><path class="bc-c-7" d="M440.54,90.13H257V356.24H440.54a12,12,0,0,0,12-12V102.13A12,12,0,0,0,440.54,90.13Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{fill:none;stroke-miterlimit:10;stroke-width:0.75px;}.bc-c-1,.bc-c-5{stroke:#262626;}.bc-c-2{opacity:0.3;}.bc-c-3{fill:#64ff00;}.bc-c-4{clip-path:url(#bc-cp);}.bc-c-5{fill:#b3b3b3;stroke-miterlimit:2;stroke-width:1.14px;opacity:0.2;}.bc-c-6{opacity:0.5;}.bc-c-7{fill:#727272;stroke:#232728;stroke-linejoin:round;stroke-width:0.75px;}</style>

