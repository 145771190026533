// import lodash from '/src/plugins/lodash'

import { getPersById } from '/src/utils/getPersById'
import { getReferences } from '/src/utils/api/getReferences'

const rootCfg = { root: true }

export default {
  namespaced: true,

  state() {
    return {
      showPreloader: false,
      previews: ['cover', 'spread', 'flyleaffront', 'flyleafend', 'edge'],
      preview: 'cover',
      showMobilePreview: false,
      request: { locale: '', orderId: 0, dog101: 0, dog100: 0 },
      pending: false,
      CONST: null,

      selectedProduct: 0,
      selectedClass: 0,
      selectedModel: 0,
      selectedBlock: 0,
      selectedTab: 0,
      selectedStitching: 0,
      selectedMaterials: [0, 0, 0, 0],
      selectedPersonalizations: {},
      selectedPersonalizationsBuhta: [],
    }
  },

  mutations: {
    SET_SHOW_PRELOADER(state, payload) {
      state.showPreloader = payload
    },
    SET_PREVIEW(state, payload) {
      state.preview = payload
    },
    SET_SHOW_MOBILE_PREVIEW(state, payload) {
      state.showMobilePreview = payload
    },
    SET_REQUEST: (state, payload) => {
      state.request = payload
    },
    SET_PENDING: (state, payload) => {
      state.pending = payload
    },
    SET_CONST: (state, payload) => {
      state.CONST = payload
    },
    SET_SELECTED_PRODUCT: (state, payload) => {
      state.selectedProduct = payload
    },
    SET_SELECTED_CLASS: (state, payload) => {
      state.selectedClass = payload
    },
    SET_SELECTED_MODEL: (state, payload) => {
      state.selectedModel = payload
    },
    SET_SELECTED_BLOCK: (state, payload) => {
      state.selectedBlock = payload
    },
    SET_SELECTED_TAB: (state, payload) => {
      state.selectedTab = payload
    },
    SET_SELECTED_STITCHING: (state, payload) => {
      state.selectedStitching = payload
    },
    SET_SELECTED_MATERIALS: (state, payload) => {
      const selectedMaterials = [0, 0, 0, 0]
      for (const index in payload) {
        selectedMaterials[index] = payload[index]
      }
      state.selectedMaterials = selectedMaterials
    },
    SET_SELECTED_PERSONALIZATIONS: (state, payload) => {
      state.selectedPersonalizations = payload
    },
    SET_SELECTED_PERSONALIZATIONS_BUHTA: (state, payload) => {
      state.selectedPersonalizationsBuhta = payload
    },

    CLEAR_REQUEST(state) {
      state.request = { locale: '', dog101: 0, dog100: 0 }
    },
    CLEAR_SELECTED_PRODUCT(state) {
      state.selectedProduct = 0
    },
    CLEAR_SELECTED_CLASS(state) {
      state.selectedClass = 0
    },
    CLEAR_SELECTED_MODEL(state) {
      state.selectedModel = 0
    },
    CLEAR_SELECTED_BLOCK(state) {
      state.selectedBlock = 0
    },
    CLEAR_SELECTED_TAB(state) {
      state.selectedTab = 0
    },
    CLEAR_SELECTED_STITCHING(state) {
      state.selectedStitching = 0
    },
    CLEAR_SELECTED_MATERIALS(state) {
      state.selectedMaterials = [0, 0, 0, 0]
    },
    CLEAR_SELECTED_PERSONALIZATIONS(state) {
      state.selectedPersonalizations = {}
    },
    CLEAR_SELECTED_PERSONALIZATIONS_BUHTA(state) {
      state.selectedPersonalizationsBuhta = {}
    },
  },

  actions: {
    SET_SHOW_PRELOADER({ commit }, payload) {
      commit('SET_SHOW_PRELOADER', payload)
    },

    SET_PREVIEW({ commit }, payload) {
      commit('SET_PREVIEW', payload)
    },

    SET_SHOW_MOBILE_PREVIEW({ commit }, payload) {
      commit('SET_SHOW_MOBILE_PREVIEW', payload)
    },

    async SET_PRODUCT_CONFIG(
      { commit, state, dispatch, rootGetters },
      {
        // init,
        locale,
        api,
        CONST,
        query: {
          step,
          orderId,
          dog101,
          dog100,
          product,
          class: classId,
          model,
          block,
          quantity,
          tab,
          stitching,
          materials,
          personalizations,
          // laminates
          // stitchingParams
        },
      },
    ) {
      commit('SET_PENDING', true)

      // Преобразование id в Number()
      if (orderId === String(Number(orderId))) {
        orderId = Number(orderId)
      }
      if (dog101 === String(Number(dog101))) {
        dog101 = Number(dog101)
      }
      if (dog100 === String(Number(dog100))) {
        dog100 = Number(dog100)
      }
      if (product === String(Number(product))) {
        product = Number(product)
      }
      if (classId === String(Number(classId))) {
        classId = Number(classId)
      }
      if (model === String(Number(model))) {
        model = Number(model)
      }
      if (block === String(Number(block))) {
        block = Number(block)
      }
      if (quantity === String(Number(quantity))) {
        quantity = Number(quantity)
      }
      if (tab === String(Number(tab))) {
        tab = Number(tab)
      }
      if (stitching === String(Number(stitching))) {
        stitching = Number(stitching)
      }
      if (materials) {
        if (materials[0] === String(Number(materials[0]))) {
          materials[0] = Number(materials[0])
        }
        if (materials[1] === String(Number(materials[1]))) {
          materials[1] = Number(materials[1])
        }
        if (materials[2] === String(Number(materials[2]))) {
          materials[2] = Number(materials[2])
        }
        if (materials[3] === String(Number(materials[3]))) {
          materials[3] = Number(materials[3])
        }
      }

      // console.log('SET_PRODUCT_CONFIG() -----> ', {
      //   step,
      //   locale,

      //   orderId,
      //   dog101,
      //   dog100,
      //   product,
      //   classId,
      //   model,
      //   block,
      //   quantity,
      //   tab,
      //   stitching,
      //   materials,
      //   personalizations,
      // })

      // error: product есть в query, но его нет в массиве с изделиями
      if (orderId === undefined) {
        throw new Error(`Не найден ключ заказа orderId == ${orderId}`)
      }
      // Загрузка справочников + продукты
      await dispatch('GET_REFERENCES', { api, CONST, locale, orderId, dog101, dog100 })

      // exit: если программа работает на сервере
      // if (!this.$CONST.client) {
      //   commit('SET_PENDING', false)
      //   return
      // }

      if (!state.CONST) {
        commit('SET_CONST', CONST)
      }
      // очистка всех моделей

      if (product === undefined && state.selectedProduct > 0) commit('CLEAR_SELECTED_PRODUCT')
      if (classId === undefined && state.selectedClass > 0) commit('CLEAR_SELECTED_CLASS')
      if (model === undefined && state.selectedModel > 0) commit('CLEAR_SELECTED_MODEL')
      if (block === undefined && state.selectedBlock > 0) commit('CLEAR_SELECTED_BLOCK')

      if (tab === undefined && state.selectedTab > 0) commit('CLEAR_SELECTED_TAB')
      if (stitching === undefined && state.selectedStitching > 0) commit('CLEAR_SELECTED_STITCHING')
      if (materials === undefined && state.selectedMaterials.length > 0) commit('CLEAR_SELECTED_MATERIALS')
      if (
        personalizations === undefined &&
        state.selectedPersonalizations &&
        Object.keys(state.selectedPersonalizations).length > 0
      ) {
        commit('CLEAR_SELECTED_PERSONALIZATIONS')
        commit('CLEAR_SELECTED_PERSONALIZATIONS_BUHTA')
      }

      // очистка
      // exit: если программа запущена на первом шаге
      if (!['model', 'block', 'materials', 'personalizations', 'orders'].includes(step)) {
        commit('SET_PENDING', false)
        return
      }
      // error: product обязан быть в query
      if (!(product > 0)) {
        throw new Error(`query.product должен быть >0. Текущий: ${product}`)
      }

      const selectedProduct = rootGetters['products/ITEMS_KEYS'][product]

      // error: product есть в query, но его нет в массиве с изделиями
      if (selectedProduct === undefined) {
        throw new Error(`Не найден продукт c id == ${product}`)
      }

      // сохранение объекта продукта в выбранном
      if (selectedProduct.id !== state.selectedProduct) {
        commit('SET_SELECTED_PRODUCT', product)
      }

      // Загрузка моделей
      await dispatch('models/GET_ITEMS', { api, CONST, locale, orderId, product }, rootCfg)

      // Если класс не определён, то программа остановится. Без ошибки для шага
      // выбора класса модели, или с ошибкой для последующих шагов, либо с ошибкой
      // для шага  выбора класса модели и при этоб заданы id следующий шагов
      if (classId === undefined) {
        if (
          ['block', 'materials', 'personalizations', 'orders'].includes(step) ||
          (step === 'model' &&
            (model !== undefined ||
              block !== undefined ||
              tab !== undefined ||
              materials !== undefined ||
              personalizations !== undefined))
        ) {
          throw new Error(`query.class должен быть >0. Текущий: ${classId}`)
        } else {
          commit('SET_PENDING', false)
          return
        }
      }
      const selectedClass = rootGetters['models/CLASSES_AVAILABLE_KEYS'][classId]

      // error: class есть в query, но его нет в массиве с доступными классами
      if (selectedClass === undefined) {
        throw new Error(`Не найден класс модели c id == ${classId}`)
      }
      // сохранение объекта класса модели в выбранном
      if (selectedClass.id !== state.selectedClass) {
        commit('SET_SELECTED_CLASS', classId)
      }

      // Если модель не определёна, то программа остановится. Без ошибки для шага
      // выбора модели+блока, или с ошибкой для последующих шагов, либо с ошибкой
      // для шага  выбора модели+блока и при этоб заданы id следующий шагов
      if (model === undefined) {
        if (
          ['materials', 'personalizations', 'orders'].includes(step) ||
          (step === 'block' &&
            (block !== undefined || tab !== undefined || materials !== undefined || personalizations !== undefined))
        ) {
          throw new Error(`query.model должен быть >0. Текущий: ${model}`)
        } else {
          commit('SET_PENDING', false)
          return
        }
      }
      const selectedModel = rootGetters['models/ITEMS_AVAILABLE_KEYS'][model]
      // error: model есть в query, но его нет в массиве с доступными моделями
      if (selectedModel === undefined) {
        throw new Error(`Не найдена модель c id == ${model}`)
      }
      // сохранение объекта модели в выбранном
      if (selectedModel.id !== state.selectedModel) {
        commit('SET_SELECTED_MODEL', model)
      }

      // Загрузка блоков
      await dispatch('blocks/GET_ITEMS', { api, CONST, locale, product, classId, model }, rootCfg)

      // Если блок не определён, то программа остановится. Без ошибки для шага
      // выбора модели+блока, или с ошибкой для последующих шагов, либо с ошибкой
      // для шага  выбора модели+блока и при этоб заданы id следующий шагов
      if (block === undefined) {
        if (
          ['materials', 'personalizations', 'orders'].includes(step) ||
          (step === 'block' && (tab !== undefined || materials !== undefined || personalizations !== undefined))
        ) {
          throw new Error(`query.block должен быть >0. Текущий: ${block}`)
        } else {
          commit('SET_PENDING', false)
          return
        }
      }

      const selectedBlock = rootGetters['blocks/ITEMS_KEYS'][block]

      // error: block есть в query, но его нет в массиве с блоками
      // if (init && selectedBlock === undefined) {
      //   throw new Error(`Не найден блок c id == ${block}`)
      // }
      // сохранение объекта блока в выбранном
      // if (selectedBlock === undefined) {
      //   commit('CLEAR_SELECTED_BLOCK')
      // } else

      if (selectedBlock.id !== state.selectedBlock) {
        commit('SET_SELECTED_BLOCK', block)
      }

      /** Исключение для Модерн Дуэт (M14), чтобы получить доп блок нужно загрузить 
        персоналки с дефолтными матералами */
      if (state.selectedModel === 52) {
        await dispatch(
          'personalizations/GET_ITEMS',
          {
            api,
            CONST,
            locale,
            product,
            classId,
            model,
            block,
            tab,
            stitching,
            materials: materials ?? [505], // дефолтные матералы
          },
          rootCfg,
        )
        commit('SET_SELECTED_PERSONALIZATIONS', personalizations)
      }

      if (!['materials', 'personalizations', 'orders'].includes(step) && materials === undefined) {
        await dispatch('prices/GET_PRICES', { api, CONST, step, quantity }, rootCfg)
        commit('SET_PENDING', false)
        return
      }

      // quantity

      // Загрузка материалов
      // if (init) {
      await dispatch('materials/GET_ITEMS', { api, CONST, locale, product, classId, model, block }, rootCfg)
      // }

      // Если таб(простые обложки/биматериалы/ламинат) не определён, то программа
      // остановится. Без ошибки для шага выбора материала, или с ошибкой для
      // последующих шагов, либо с ошибкой для шага выбора материала и при этом
      // заданы id следующий шагов
      if (tab === undefined) {
        if (
          ['personalizations', 'orders'].includes(step) ||
          (step === 'materials' && materials !== undefined) /* || personalizations !== undefined */ // todo обработать персоналки для М14
        ) {
          throw new Error(`query.tab должен быть >0. Текущий: ${tab}`)
        } else {
          // запрос цены изделия
          await dispatch('prices/GET_PRICES', { api, CONST, step, quantity }, rootCfg)
          commit('SET_PENDING', false)
          return
        }
      }

      const selectedTab = rootGetters['materials/TABS_AVAILABLE_KEYS'][tab]
      // error: material.tab есть в query, но его нет в массиве с табами
      if (selectedTab === undefined) {
        throw new Error(`Не найден таб c id == ${tab}`)
      }

      if (selectedTab.id !== state.selectedTab) {
        commit('SET_SELECTED_TAB', tab)
      }
      // -------------------------------------------------------------------------------------------------------------
      // Если сшивка(только для биматериалов) не определёна, то программа
      // остановится. Без ошибки для шага выбора материала, или с ошибкой для
      // последующих шагов, либо с ошибкой для шага выбора материала и при этом
      // заданы id следующий шагов
      if (tab === 2) {
        if (stitching === undefined) {
          if (
            ['personalizations', 'orders'].includes(step) ||
            (step === 'materials' && (materials !== undefined || personalizations !== undefined))
          ) {
            throw new Error(`query.stitching должен быть >0. Текущий: ${stitching}`)
          } else {
            commit('SET_PENDING', false)
            return
          }
        }
        const selectedStitching = rootGetters['materials/STITCHINGS_KEYS'][stitching]
        // error: material.stitching есть в query, но его нет в массиве со сшивками
        if (selectedStitching === undefined) {
          throw new Error(`Не найдена сшивка c id == ${stitching}`)
        }

        if (selectedStitching.id !== state.selectedStitching) {
          commit('SET_SELECTED_STITCHING', stitching)
        }
      }

      // -------------------------------------------------------------------------------------------------------------
      // Если материал не определён, то программа остановится. Без ошибки для
      // шага выбора материала, или с ошибкой для последующих шагов, либо с
      // ошибкой для шага выбора материала и при этом заданы id следующий шагов
      if (materials === undefined || !Array.isArray(materials) || materials.length === 0) {
        if (['personalizations', 'orders'].includes(step) || (step === 'materials' && personalizations !== undefined)) {
          // Список персоналок - нужен чтоб дефолтные персоналки не вызывали ошибку
          const personalizationsAvailableKeys = Object.keys(personalizations)
          // Доп блок для Модерн Дуэт (M14)
          if (
            state.selectedModel === 52 &&
            personalizationsAvailableKeys.length === 1 &&
            personalizationsAvailableKeys[0] === 'additionalBlock'
          ) {
            commit('SET_PENDING', false)
            return
          }
          throw new Error(`query.materials[0] должен быть >0. Текущий: ${materials}`)
        } else {
          // if (state.materials[0].id > 0) commit('CLEAR_SELECTED_MATERIALS')
          commit('SET_PENDING', false)
          return
        }
      }

      const selectedMaterials = []
      for (let i = 0; i <= 3; i++) {
        if (materials[i]) {
          const name = tab === 3 ? 'LAMINATES' : 'MATERIALS'
          const currentSelectedMaterial = rootGetters[`materials/${name}_KEYS`][materials[i]]
          // error: material есть в query, но его нет в массиве с материалами
          if (currentSelectedMaterial === undefined) {
            throw new Error(`Не найден материал c id == ${materials[0]}`)
          }
          selectedMaterials.push(materials[i])
        } else {
          selectedMaterials.push(0)
        }
      }

      if (
        selectedMaterials[0].id !== state.selectedMaterials[0] ||
        selectedMaterials[1].id !== state.selectedMaterials[1] ||
        selectedMaterials[2].id !== state.selectedMaterials[2] ||
        selectedMaterials[3].id !== state.selectedMaterials[3]
      ) {
        commit('SET_SELECTED_MATERIALS', selectedMaterials)
      }

      // -------------------------------------------------------------------------------------------------------------
      // персоналки

      // Если персонализации не определены, то программа остановится. Без ошибки для шага
      // выбора персонализаций, или с ошибкой для последующих шагов

      // Загрузка персонализаций
      await dispatch(
        'personalizations/GET_ITEMS',
        { api, CONST, locale, product, classId, model, block, tab, stitching, materials },
        rootCfg,
      )

      commit('SET_SELECTED_PERSONALIZATIONS', personalizations)
      // Загрузка справочников + продукты
      dispatch('GET_SELECTED_PERSONALIZATIONS_BUHTA')

      // запрос цены изделия
      await dispatch('prices/GET_PRICES', { api, CONST, step, quantity }, rootCfg)
      commit('SET_PENDING', false)
    },

    async GET_REFERENCES({ commit, state }, { api, CONST, locale, orderId, dog101, dog100 }) {
      if (locale === state.request.locale && orderId === state.request.orderId) {
        return
      }
      if (state.request.locale !== '') {
        commit('CLEAR_REQUEST')
        commit('products/CLEAR_REFERENCES', null, rootCfg)
        commit('models/CLEAR_REFERENCES', null, rootCfg)
        commit('blocks/CLEAR_REFERENCES', null, rootCfg)
        commit('materials/CLEAR_REFERENCES', null, rootCfg)
        commit('prices/CLEAR_REFERENCES', null, rootCfg)
      }

      // if (api !== 9999999) return
      const data = await getReferences(api, {
        orderId,
        dog101,
        dog100,
        CONST,
      })

      // вместо  if (response?.result) {
      // todo !!! сделать полную проверку всех полей

      commit('SET_REQUEST', { locale, orderId, dog101, dog100 })

      commit('products/SET_REFERENCES', data, rootCfg)
      commit('models/SET_REFERENCES', data, rootCfg)
      commit('blocks/SET_REFERENCES', data, rootCfg)
      commit('materials/SET_REFERENCES', data, rootCfg)
      commit('prices/SET_REFERENCES', data, rootCfg)
    },

    GET_SELECTED_PERSONALIZATIONS_BUHTA({ /* , state, dispatch, */ commit, rootGetters, getters }) {
      const personalizations = rootGetters['personalizations/PERSONALIZATIONS']
      // let selectedPersonalizationsBuhta = []
      const selectedPersonalizationsBuhta = {}
      for (const persId in getters.SELECTED_PERSONALIZATIONS) {
        if (selectedPersonalizationsBuhta[persId] === undefined) {
          selectedPersonalizationsBuhta[persId] = []
        }
        for (const pers of getters.SELECTED_PERSONALIZATIONS[persId]) {
          const foundPers = getPersById(persId, personalizations)

          const persBuhta = foundPers.getItemsBuhta({
            modelValues: pers,
            selectedMaterials: getters.SELECTED_MATERIALS,
            // originPersonalizations: foundPers.originPersonalizations,
          })
          // selectedPersonalizationsBuhta =
          //   selectedPersonalizationsBuhta.concat(persBuhta)
          selectedPersonalizationsBuhta[persId].push(persBuhta)
        }
      }

      commit('SET_SELECTED_PERSONALIZATIONS_BUHTA', selectedPersonalizationsBuhta)
    },
  },

  getters: {
    SHOW_PRELOADER: (state) => state.showPreloader,
    PENDING: (state) => state.pending,
    PREVIEWS: (state) => state.previews,
    PREVIEW: (state) => state.preview,
    SHOW_MOBILE_PREVIEW: (state) => state.showMobilePreview,
    SELECTED_PRODUCT: (state, getters, rootState, rootGetters) => {
      return rootGetters['products/ITEMS_KEYS'][state.selectedProduct] || {}
    },
    SELECTED_CLASS: (state, getters, rootState, rootGetters) => {
      return rootGetters['models/CLASSES_AVAILABLE_KEYS'][state.selectedClass] || {}
    },
    SELECTED_MODEL: (state, getters, rootState, rootGetters) => {
      return rootGetters['models/ITEMS_AVAILABLE_KEYS'][state.selectedModel] || {}
    },
    SELECTED_BLOCK: (state, getters, rootState, rootGetters) => {
      return rootGetters['blocks/ITEMS_KEYS'][state.selectedBlock] || {}
    },
    SELECTED_TAB: (state, getters, rootState, rootGetters) => {
      return rootGetters['materials/TABS_AVAILABLE_KEYS'][state.selectedTab] || {}
    },
    SELECTED_STITCHING: (state, getters, rootState, rootGetters) => {
      return rootGetters['materials/STITCHINGS_KEYS'][state.selectedStitching] || {}
    },
    SELECTED_MATERIALS: (state, getters, rootState, rootGetters) => {
      return state.selectedMaterials.map(
        (id) => rootGetters['materials/MATERIALS_KEYS'][id] || rootGetters['materials/LAMINATES_KEYS'][id] || {},
      )
    },
    SELECTED_PERSONALIZATIONS: (state, getters, rootState, rootGetters) => {
      const newSelectedPersonalizations = {}
      for (const idGroup in state.selectedPersonalizations) {
        const foundPers = getPersById(idGroup, rootGetters['personalizations/PERSONALIZATIONS'])
        if (foundPers) {
          newSelectedPersonalizations[idGroup] = []
          for (const idPers in state.selectedPersonalizations[idGroup]) {
            const model = state.selectedPersonalizations[idGroup][idPers]
            const newpers = foundPers.getPersConfig(
              {
                CONST: state.CONST,
                blocksItemsKeys: rootGetters['blocks/ITEMS_KEYS'],
                materialsTexturesKeys: rootGetters['materials/TEXTURES_KEYS'],
                materialsColorsKeys: rootGetters['materials/COLORS_KEYS'],
                selectedProduct: getters.SELECTED_PRODUCT,
                selectedClass: getters.SELECTED_CLASS,
                selectedModel: getters.SELECTED_MODEL,
                selectedBlock: getters.SELECTED_BLOCK,
                selectedTab: getters.SELECTED_TAB,
                selectedStitching: getters.SELECTED_STITCHING,
                selectedMaterials: getters.SELECTED_MATERIALS,
              },
              model,
            )
            newSelectedPersonalizations[idGroup][idPers] = newpers.modelValues
          }
        }
      }

      return newSelectedPersonalizations
    },
    SELECTED_PERSONALIZATIONS_BUHTA: (state) => {
      return state.selectedPersonalizationsBuhta || {}
    },
  },
}
