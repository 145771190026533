<template>
<svg data-svg-lvl-3="spread-back-loop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bl-lg" x1="446.97" y1="-540.3" x2="465.58" y2="-540.3" gradientTransform="matrix(1, 0, 0, -1, 0, -370.54)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.3"/><stop offset="0.48" stop-color="#231f20" stop-opacity="0"/><stop offset="0.75" stop-opacity="0"/><stop offset="1" stop-color="#231f20" stop-opacity="0.3"/></linearGradient><linearGradient id="bl-lg-2" x1="446.97" y1="-540.3" x2="465.58" y2="-540.3" gradientTransform="matrix(1, 0, 0, -1, 0, -370.54)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0"/><stop offset="0.54" stop-color="#fff" stop-opacity="0.2"/><stop offset="0.69" stop-color="#fff" stop-opacity="0.2"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient></defs><g id="Петли_Контур"><path class="bl-c-3" d="M447,155c3.78-1.31,5.8-2.59,9.72-3,4.84-.55,8.81.22,8.89,1.34V185h0c.08,1.12-3.72,2.62-8.6,2.74-4.23.09-5.8-.63-10-1h0"/><path class="bl-c-4" d="M447,155c3.78-1.31,5.8-2.59,9.72-3,4.84-.55,8.81.22,8.89,1.34V185h0c.08,1.12-3.72,2.62-8.6,2.74-4.23.09-5.8-.63-10-1h0"/><path class="bl-c-5" d="M447,155c3.78-1.31,5.8-2.59,9.72-3,4.84-.55,8.81.22,8.89,1.34V185h0c.08,1.12-3.72,2.62-8.6,2.74-4.23.09-5.8-.63-10-1h0"/><path class="bl-c-6" d="M447,155c3.78-1.31,5.8-2.59,9.72-3,4.84-.55,8.81.22,8.89,1.34V185h0c.08,1.12-3.72,2.62-8.6,2.74-4.23.09-5.8-.63-10-1h0"/><path class="bl-c-7" d="M465.58,184.65c-.08-1.12-4.05-1.89-8.89-1.33-3.92.45-5.94,1.72-9.72,3,4.21.4,5.78,1.12,10,1C461.86,187.27,465.66,185.77,465.58,184.65Z"/><path class="bl-c-7" d="M465.58,184.65c-.08-1.12-4.05-1.89-8.89-1.33-3.92.45-5.94,1.72-9.72,3,4.21.4,5.78,1.12,10,1C461.86,187.27,465.66,185.77,465.58,184.65Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bl-c-1{opacity:0.3;}.bl-c-2{fill:#64ff00;}.bl-c-3{fill:gray;}.bl-c-4{fill:none;stroke-width:0.75px;}.bl-c-4,.bl-c-5,.bl-c-6,.bl-c-7{stroke:#232728;stroke-miterlimit:2;}.bl-c-5,.bl-c-6{stroke-width:0.75px;}.bl-c-5{fill:url(#bl-lg);}.bl-c-6{fill:url(#bl-lg-2);}.bl-c-7{fill:#262626;stroke-width:0.46px;}</style>

