<template>
<svg data-svg-lvl-3="cover-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Задник_Контур"><path class="bc-c-3" d="M357.47,16H124.66a14.72,14.72,0,0,0-4.26.76l-10.25,4h-5.64a.55.55,0,0,0-.35.19v398.8a.46.46,0,0,1,.35-.21h5.64c.68,0,9.09-3.46,10.29-3.89a15.36,15.36,0,0,1,4.22-.88c1.13,0,265.48,0,265.48,0h2.1a9.13,9.13,0,0,0,9.13-9.13V59.89Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#ff931e;}.bc-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

