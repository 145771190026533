/**
 * Формирует выбранные персоналки бухты для корзины
 * @param {*} originPersonalizations объект с персоналками бухты
 * @param {*} idBuhta id персоналки в бухте
 * @param {*} configs Массив с парами персоналок id/значения:
 * idParam/idValue - для списков выбора
 * idWork/value - для работ
 * idParamInt/value - для целочисленных параметров(107/Длина, 108/Высота)
 */
export function getItemsBuhtaByConfig(
  originPersonalizations,
  idBuhta,
  configs
) {
  const itemsBuhta = []
  const params = []
  if (!originPersonalizations[idBuhta]) {
    return itemsBuhta
  }

  if (Array.isArray(configs) && configs.length > 0) {
    for (const { idParam, idValue, idWork, idParamInt, value } of configs) {
      if (idWork) {
        const foundParam = originPersonalizations[idBuhta]?.params?.find(
          (i) => Number(i.idWork) === idWork
        )
        if (foundParam) {
          params.push({
            idWork: foundParam.idWork,
            nameWork: foundParam.nameWork,
            value: value || false,
          })
        }
      } else if (idParamInt && value) {
        const foundParam = originPersonalizations[idBuhta]?.params?.find(
          (i) => Number(i.id) === idParamInt
        )
        if (foundParam) {
          params.push({
            id: foundParam.id,
            name: foundParam.name,
            value: value || 0,
          })
        }
      } else if (idParam && idValue) {
        const foundParam = originPersonalizations[idBuhta]?.params?.find(
          (i) => i.id === idParam
        )
        if (foundParam) {
          const foundValue = foundParam?.values?.find((i) => i.id === idValue)
          if (foundValue) {
            params.push({
              id: foundParam.id,
              name: foundParam.name,
              value: {
                id: foundValue.id,
                name: foundValue.name,
              },
            })
          }
        }
      }
    }
  }

  itemsBuhta.push({
    id: idBuhta,
    name: originPersonalizations[idBuhta].name,
    params,
  })
  return itemsBuhta
}
