<template>
<svg data-svg-lvl-3="edge-top-slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышка_Срез"><path :style="{ fill: params.sliceColor }"  class="tsl-c-3" d="M55.77,200.78H468a1.24,1.24,0,0,0,1.23-1.24v-3.39a1.24,1.24,0,0,0-1.23-1.24H55.77S42,196.7,42,211.37v19.45c0,15.44,13.74,16.27,13.74,16.27H468a1.24,1.24,0,0,0,1.23-1.23v-3.4a1.24,1.24,0,0,0-1.23-1.24H55.77s-8.34-1-8.34-8.39V210.9C47.43,203,55.77,200.78,55.77,200.78Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsl-c-1{opacity:0.3;}.tsl-c-2{fill:#64ff00;}.tsl-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

