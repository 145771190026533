<template>
  <div class="filters-el" :class="{ 'align-left': alignLeft }">
    <div class="select-filters">
      <div class="select-filters__item" v-for="(item, idx) in items" :key="idx" :class="item.model">
        <UiFilterSelect
          :alignment="item.alignment || 'left'"
          :regular="item.test"
          v-if="item.model !== 'sorts' && item.type == 'select'"
          :value="value[item.model]"
          @input="input(value, item, $event)"
          :items="item.items"
          :item-text="item.itemText"
          :placeholderTranslation="item.placeholderTranslation"
          :disabled="disabledItems ? disabledItems[item.model] : false"
        />

        <UiFilterCheckbox
          v-if="hasCheckbox && item.type == 'checkbox'"
          :key="item.model"
          :id="item.model"
          :value="value[item.model]"
          @input="input(value, item, $event)"
          :placeholderTranslation="item.placeholderTranslation"
          :disabled="disabledItems ? disabledItems[item.model] : false"
        />
      </div>
    </div>
    <div class="adaptive-filters" :class="{ _show: isShowFilters }">
      <div class="filters-btn">
        <UiButton @click="openFilters" btnSize="lg" btnColor="hover-color" btnFontColor="black">
          <div class="filters-el-btn-inner d-flex">
            <IconsIconFilters />
            <span>{{ $t(`ui.filters`) }}</span>
            <span v-if="filtersCheckedCount">&nbsp;({{ filtersCheckedCount }})</span>
          </div>
        </UiButton>
      </div>
      <div class="adaptive-filters__overlay" @click="closeFilters"></div>
      <div class="adaptive-filters__wrapper">
        <div class="adaptive-filters__inner">
          <div class="adaptive-filters__content" :class="{ move: isShowRight }">
            <div class="adaptive-filters__left">
              <div class="adaptive-filters__title d-flex .align-items-center">
                <div class="adaptive-filters__close" @click="closeFilters">
                  <IconsIconClose />
                </div>
                {{ $t(`ui.filters`) }}
              </div>
              <div class="scroller">
                <div class="adaptive-filters__items">
                  <div
                    v-for="(item, idx) in items"
                    :key="item.model"
                    :class="[
                      'adaptive-filters__item-wrapper',
                      { _checked: value[item.model].id || value[item.model] === true },
                    ]"
                  >
                    <div v-if="item.type == 'select'">
                      <div
                        class="adaptive-filters__item"
                        v-if="value[item.model].id"
                        @click="$emit('input', { ...value, [item.model]: {} })"
                      >
                        <span>{{ value[item.model][item.itemText] }}</span>
                        <div class="close">
                          <IconsIconClose />
                        </div>
                      </div>
                      <div
                        class="adaptive-filters__item"
                        v-else
                        @click="showRight(idx, item)"
                        :class="{ disabled: disabledItems ? disabledItems[item.model] : false }"
                      >
                        <span>{{ $t(item.placeholderTranslation) }}</span>
                        <div class="caret">
                          <IconsIconCaret />
                        </div>
                      </div>
                    </div>
                    <div v-if="item.type == 'checkbox'">
                      <div
                        class="adaptive-filters__item"
                        @click="checkboxClick(item)"
                        :class="{ disabled: disabledItems ? disabledItems[item.model] : false }"
                      >
                        <span>{{ $t(item.placeholderTranslation) }}</span>
                        <div class="close" v-if="value[item.model]">
                          <IconsIconClose />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="adaptive-filters__right">
              <div class="adaptive-filters__title d-flex .align-items-center">
                <div class="adaptive-filters__back" @click="isShowRight = false">
                  <div class="_svg" @click="isShowRight = false">
                    <IconsIconArrow />
                  </div>
                </div>
                {{ $t(submenu.placeholderTranslation) }}
              </div>
              <div class="scroller">
                <div class="adaptive-filters__items">
                  <div
                    class="adaptive-filters__item"
                    v-for="(item, idx) in submenu.items"
                    :key="`right_${idx}`"
                    :class="{ disabled: item.disabled }"
                    @click="filterClick(item, submenu.model)"
                  >
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /** @var alignLeft - по умолчанию фильтры выровнены по центру */
    alignLeft: {
      type: Boolean,
      default: false,
    },
    /** @var items - список значений */
    items: {
      type: Array,
      required: true,
    },
    /** @var value - выбранное значение */
    value: {
      type: Object,
      required: true,
    },
    disabledItems: {
      type: Object,
      default: () => ({}),
    },
    hasCheckbox: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showBlockFiltersMobile: false,
      isShowFilters: false,
      isShowRight: false,
      submenu: [],
    }
  },
  computed: {
    filtersCheckedCount() {
      return this.$lodash.filter(this.value, (o) => o.id > 0 || o === true).length
    },
  },
  methods: {
    filterClick(item, model) {
      if (item.disabled) {
        return
      }
      this.$emit('input', { ...this.value, [model]: item })
      this.isShowRight = this.isShowFilters = false
    },
    checkboxClick(item) {
      if (!this.disabledItems[item.model]) {
        this.$emit('input', {
          ...this.value,
          [item.model]: !this.value[item.model],
        })
        if (!this.value[item.model]) this.isShowFilters = false
      }
    },
    showRight(idx, item) {
      if (!this.disabledItems[item.model]) {
        this.$emit('input', { ...this.value, [idx]: item })
        this.submenu = item
        this.isShowRight = true
      }
    },
    openFilters() {
      this.isShowFilters = true
    },
    closeFilters() {
      this.isShowFilters = false
    },
    input(value, item, $event) {
      this.$emit('input', { ...value, [item.model]: $event })
    },
  },
}
</script>

<style lang="sass" scoped>
.filters-el
  &.align-left .select-filters
      justify-content: flex-start

  .select-filters
    display: flex
    @media (max-width: 1199px)
      display: none
    &__item
      margin-right: 10px
      @media (max-width: 1199px)
        display: none
      &:last-child
        margin-right: 0

  &-btn-inner::v-deep svg
      width: 24px
      height: 24px
      *
        fill: $icon2-color
  &-btn-inner :nth-child(2)
    margin-left: 8px

.adaptive-filters
  display: none
  @media (max-width: 1199px)
    display: flex
  &__wrapper
    position: fixed
    top: 0
    left: 0
    height: 100vh
    z-index: 1000
    transform: translateX(-100%)
    transition: transform .3s ease
    width: 100%
    @media (min-width: 780px)
      width: 315px
  &__overlay
    width: 100%
    height: 100%
    // +overlay(black, .6)
    position: relative
    &::before
      content: ''
      display: inline-block
      top: 0
      left: 0
      right: 0
      bottom: 0
      width: 100%
      height: 100%
      position: absolute
      z-index: 0
      background: black
      background: rgba(black, .6)
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    z-index: 100
    display: none

  &__inner
    height: 100%
    box-shadow: 0px 4px 15px rgba(0, 52, 49, 0.4)
    overflow: hidden
    background-color: $white
  &__content
    width: 200%
    display: flex
    transition: ease .3s
    &.move
      transform: translateX(-50%)
  &__title
    font-size: 16px
    line-height: 32px
    margin: 15px 0
    padding: 17px 22px
    &::v-deep path
        fill: $icon2-color
  &__close, &__back
    margin-right: 16px
    width: 32px
    height: 32px
    cursor: pointer
    &::v-deep path
      fill: $icon2-color
    &:hover::v-deep path
      fill: $dark-text
    ._svg
      transform:  rotate(270deg)
      width: 32px
      height: 32px


  &__left,&__right
    width: 50%

  &__items
    border-top: 1px solid $stroke-color
    padding-top: 15px
  &__item-wrapper
    border-left: 3px solid transparent
  &__item
    cursor: pointer
    // margin: 0 15px
    padding: 0 10px 0 13px
    font-size: 16px
    line-height: 66px
    display: flex
    justify-content: space-between
    align-items: center
    &:hover
      background-color: $hover-color

    span
      line-height: 66px
    &.disabled
      color: $light-text-dark-stroke
    .close
      // text-shadow: none
      // padding-top: 16px
      // line-height: 43px
      width: 32px
      height: 32px
      &::v-deep path
        fill: $icon2-color
      &:hover::v-deep path
        fill: $dark-text

    .caret
      width: 32px
      height: 32px
      margin-right: -7px
      &::v-deep
        svg
          transform: rotate(90deg)
          path
            fill: $icon2-color
.scroller
  height: calc(100vh - 96px)
._checked
  background-color: $hover-color
  border-left: 3px solid $contrast-regular
  .adaptive-filters__item
    border-top-color: transparent
  + div .adaptive-filters__item
    border-top-color: transparent

._show .adaptive-filters
  &__overlay
    display: block
  &__wrapper
    transform: translateX(0px)


.regular_select::v-deep
  .select .input
    font-size: 14px
    transition: ease 0.3s
    position: relative
    display: flex
    align-items: center
    border: none
    border-radius: 5px
    background: $light-back-color
    padding: 5px 10px 6px
</style>
