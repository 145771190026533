<template>
<svg data-svg-lvl-3="edge-top-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="tbt-rg" cx="8077.61" cy="4154.89" r="11.26" gradientTransform="translate(-3848.01 8267.28) rotate(-90)" gradientUnits="userSpaceOnUse"> <template v-if="params.buttonColor === '#A31A30'">
  <!-- Бордовый -->
  <stop offset="0" stop-color="#debac0" />
  <stop offset=".4" stop-color="#a31a30" />
  <stop offset=".78" stop-color="#990019" />
  <stop offset=".83" stop-color="#9a071f" />
  <stop offset=".89" stop-color="#9d1c32" />
  <stop offset=".97" stop-color="#a33e50" />
  <stop offset="1" stop-color="#a54b5b" />
</template>
<template v-else-if="params.buttonColor === '#191919'">
  <!-- Черный -->
  <stop offset="0" stop-color="#dedede" />
  <stop offset=".4" stop-color="#000" />
  <stop offset=".78" stop-color="#191919" />
  <stop offset=".81" stop-color="#202020" />
  <stop offset=".85" stop-color="#353535" />
  <stop offset=".91" stop-color="#585858" />
  <stop offset=".97" stop-color="#878787" />
  <stop offset="1" stop-color="#a5a5a5" />
</template>
<template v-else-if="params.buttonColor === '#1E2F87'">
  <!-- Синий -->
  <stop offset="0" stop-color="#bac0de" />
  <stop offset=".4" stop-color="#1e2f87" />
  <stop offset=".78" stop-color="#1e2f87" />
  <stop offset=".84" stop-color="#25368c" />
  <stop offset=".94" stop-color="#3a4a9a" />
  <stop offset="1" stop-color="#4b5aa5" />
</template>

<template v-else-if="params.buttonColor === '#808080'">
  <!--  Серый -->
  <stop offset="0" stop-color="#dedede" />
  <stop offset=".4" stop-color="gray" />
  <stop offset=".78" stop-color="#666" />
  <stop offset=".83" stop-color="#6d6d6d" />
  <stop offset=".91" stop-color="#828282" />
  <stop offset="1" stop-color="#a4a4a4" />
  <stop offset="1" stop-color="#a5a5a5" />
</template>
<template v-else-if="params.buttonColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#fbffff" />
  <stop offset=".47" stop-color="#c3a22a" />
  <stop offset=".7" stop-color="#9c6b00" />
  <stop offset=".83" stop-color="#6c4209" />
  <stop offset="1" stop-color="#efd691" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#fff" />
  <stop offset=".78" stop-color="#363636" />
  <stop offset=".81" stop-color="#3d3d3d" />
  <stop offset=".86" stop-color="#525252" />
  <stop offset=".92" stop-color="#757575" />
  <stop offset=".99" stop-color="#a4a4a4" />
  <stop offset="1" stop-color="#ababab" />
</template> </radialGradient><radialGradient id="tbt-rg-2" cx="8075.57" cy="4164.48" r="8.63" gradientTransform="translate(-3848.01 8267.28) rotate(-90)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff"/><stop offset="0.78" stop-color="#fff" stop-opacity="0"/></radialGradient></defs><g id="Кнопки_Градиент"><path class="tbt-c-3" d="M309.9,189c4.36,0,7.89,1.45,7.89,3.51,0,1.3-3.53,1.29-7.89,1.29s-7.9.07-7.9-1.49C302,190.06,305.54,189,309.9,189Z"/></g><g id="Кнопки_Контур"><path class="tbt-c-4" d="M309.9,189c4.36,0,7.89,1.45,7.89,3.51,0,1.3-3.53,1.29-7.89,1.29s-7.9.07-7.9-1.49C302,190.06,305.54,189,309.9,189Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tbt-c-1{opacity:0.3;}.tbt-c-2{fill:#64ff00;}.tbt-c-3,.tbt-c-4{stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}.tbt-c-3{fill:url(#tbt-rg);}.tbt-c-4{opacity:0.8;fill:url(#tbt-rg-2);}</style>

