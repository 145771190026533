import { render, staticRenderFns } from "./L15.vue?vue&type=template&id=36e0b356&scoped=true"
import script from "./L15.vue?vue&type=script&lang=js"
export * from "./L15.vue?vue&type=script&lang=js"
import style0 from "./L15.vue?vue&type=style&index=0&id=36e0b356&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e0b356",
  null
  
)

export default component.exports