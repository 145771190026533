<template>
<svg data-svg-lvl-3="cover-top-corners" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tcr-lg" x1="455.84" y1="15756.86" x2="455.84" y2="15752.72" gradientTransform="matrix(1, 0, 0, -1, 0, 16037.72)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.58" stop-color="#fbe6a6" />
  <stop offset="0.65" stop-color="#f9e4a4" />
  <stop offset="0.7" stop-color="#f2db9c" />
  <stop offset="0.73" stop-color="#e5cd8e" />
  <stop offset="0.76" stop-color="#d4b97b" />
  <stop offset="0.79" stop-color="#bd9f62" />
  <stop offset="0.82" stop-color="#a17f43" />
  <stop offset="0.84" stop-color="#815a20" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".58" stop-color="#edf1fa" />
  <stop offset=".65" stop-color="#eaeef7" />
  <stop offset=".69" stop-color="#e2e6ef" />
  <stop offset=".73" stop-color="#d4d8e1" />
  <stop offset=".76" stop-color="#c0c4cc" />
  <stop offset=".79" stop-color="#a7aab2" />
  <stop offset=".81" stop-color="#888a92" />
  <stop offset=".84" stop-color="#63666d" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="tcr-lg-2" x1="464.02" y1="15764.85" x2="467.99" y2="15764.85" gradientTransform="matrix(1, 0, 0, -1, 0, 16037.72)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.32" stop-color="#d8b056" />
  <stop offset="0.37" stop-color="#e5c373" />
  <stop offset="0.41" stop-color="#efd38a" />
  <stop offset="0.46" stop-color="#f6de9a" />
  <stop offset="0.52" stop-color="#fae4a3" />
  <stop offset="0.59" stop-color="#fbe6a6" />
  <stop offset="1" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".32" stop-color="#a1a4aa" />
  <stop offset=".37" stop-color="#bcc0c7" />
  <stop offset=".41" stop-color="#d2d5dd" />
  <stop offset=".46" stop-color="#e1e5ed" />
  <stop offset=".52" stop-color="#eaeef7" />
  <stop offset=".59" stop-color="#edf1fa" />
  <stop offset="1" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="tcr-lg-3" x1="2406.51" y1="15756.86" x2="2406.51" y2="15752.72" gradientTransform="translate(2464.68 16037.72) rotate(180)" href="#tcr-lg"/><linearGradient id="tcr-lg-4" x1="2418.6" y1="15764.85" x2="2414.63" y2="15764.85" gradientTransform="translate(2464.68 16037.72) rotate(180)" href="#tcr-lg-2"/></defs><g id="УголкиКрышка"><path class="tcr-c-3" d="M466.56,285.09h-22.5l4.17-4.17h15.65l3.74,3.73A1.51,1.51,0,0,1,466.56,285.09Z"/><path class="tcr-c-4" d="M463.88,280.92V265.26l4.18-4.17v22.5a1.51,1.51,0,0,1-.44,1.06Z"/><path class="tcr-c-5" d="M468.06,261.09v22.5a1.5,1.5,0,0,1-1.5,1.5h-22.5l4.17-4.17h15.65V265.26l4.18-4.17m-.81-.81-4.17,4.18-.34.33v15h-15l-.34.34-4.17,4.17.81,2h22.5a2.64,2.64,0,0,0,2.64-2.64v-22.5l-1.95-.81Z"/><path class="tcr-c-6" d="M47.44,285.09h22.5l-4.17-4.17H50.12l-3.74,3.73A1.51,1.51,0,0,0,47.44,285.09Z"/><path class="tcr-c-7" d="M50.12,280.92V265.26l-4.18-4.17v22.5a1.51,1.51,0,0,0,.44,1.06Z"/><path class="tcr-c-5" d="M45.94,261.09l4.18,4.17v15.66H65.77l4.17,4.17H47.44a1.5,1.5,0,0,1-1.5-1.5v-22.5m.81-.81-2,.81v22.5a2.64,2.64,0,0,0,2.64,2.64h22.5l.81-2-4.17-4.17-.34-.34h-15v-15l-.34-.33-4.17-4.18Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tcr-c-1{opacity:0.3;}.tcr-c-2{fill:#64ff00;}.tcr-c-3{fill:url(#tcr-lg);}.tcr-c-4{fill:url(#tcr-lg-2);}.tcr-c-5{fill:#232728;}.tcr-c-6{fill:url(#tcr-lg-3);}.tcr-c-7{fill:url(#tcr-lg-4);}</style>

