<template>
<svg data-svg-lvl-3="cover-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bc-lg" x1="257" y1="320.41" x2="257" y2="355.66" gradientTransform="translate(38.39 475.62) rotate(-90)" gradientUnits="userSpaceOnUse"><stop offset="0.58" stop-opacity="0.25"/><stop offset="1" stop-opacity="0.05"/></linearGradient></defs><g id="Задник_Контур"><rect class="bc-c-3" x="27" y="46.12" width="460" height="345"/><rect class="bc-c-4" x="84.5" y="-11.38" width="345" height="460" transform="translate(475.62 -38.39) rotate(90)"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}.bc-c-4{fill:url(#bc-lg);}</style>

