<template>
<svg data-svg-lvl-3="spread-block-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Контур"><rect class="lc-c-3" x="66.88" y="147.83" width="379.73" height="138.91" rx="12"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lc-c-1{opacity:0.3;}.lc-c-2{fill:#64ff00;}.lc-c-3{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}</style>

