<template>
<svg data-svg-lvl-3="edge-top-flap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="tf-rg" cx="8517.7" cy="2368.25" r="11.26" gradientTransform="translate(-1936.21 8703.47) rotate(-90)" gradientUnits="userSpaceOnUse"> <template v-if="params.flap.buttonColor === '#A31A30'">
  <!-- Бордовый -->
  <stop offset="0" stop-color="#debac0" />
  <stop offset=".4" stop-color="#a31a30" />
  <stop offset=".78" stop-color="#990019" />
  <stop offset=".83" stop-color="#9a071f" />
  <stop offset=".89" stop-color="#9d1c32" />
  <stop offset=".97" stop-color="#a33e50" />
  <stop offset="1" stop-color="#a54b5b" />
</template>
<template v-else-if="params.flap.buttonColor === '#191919'">
  <!-- Черный -->
  <stop offset="0" stop-color="#dedede" />
  <stop offset=".4" stop-color="#000" />
  <stop offset=".78" stop-color="#191919" />
  <stop offset=".81" stop-color="#202020" />
  <stop offset=".85" stop-color="#353535" />
  <stop offset=".91" stop-color="#585858" />
  <stop offset=".97" stop-color="#878787" />
  <stop offset="1" stop-color="#a5a5a5" />
</template>
<template v-else-if="params.flap.buttonColor === '#1E2F87'">
  <!-- Синий -->
  <stop offset="0" stop-color="#bac0de" />
  <stop offset=".4" stop-color="#1e2f87" />
  <stop offset=".78" stop-color="#1e2f87" />
  <stop offset=".84" stop-color="#25368c" />
  <stop offset=".94" stop-color="#3a4a9a" />
  <stop offset="1" stop-color="#4b5aa5" />
</template>

<template v-else-if="params.flap.buttonColor === '#808080'">
  <!--  Серый -->
  <stop offset="0" stop-color="#dedede" />
  <stop offset=".4" stop-color="gray" />
  <stop offset=".78" stop-color="#666" />
  <stop offset=".83" stop-color="#6d6d6d" />
  <stop offset=".91" stop-color="#828282" />
  <stop offset="1" stop-color="#a4a4a4" />
  <stop offset="1" stop-color="#a5a5a5" />
</template>
<template v-else-if="params.flap.buttonColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#fbffff" />
  <stop offset=".47" stop-color="#c3a22a" />
  <stop offset=".7" stop-color="#9c6b00" />
  <stop offset=".83" stop-color="#6c4209" />
  <stop offset="1" stop-color="#efd691" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#fff" />
  <stop offset=".78" stop-color="#363636" />
  <stop offset=".81" stop-color="#3d3d3d" />
  <stop offset=".86" stop-color="#525252" />
  <stop offset=".92" stop-color="#757575" />
  <stop offset=".99" stop-color="#a4a4a4" />
  <stop offset="1" stop-color="#ababab" />
</template> </radialGradient><radialGradient id="tf-rg-2" cx="8515.67" cy="2377.84" r="8.63" gradientTransform="translate(-1936.21 8703.47) rotate(-90)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff"/><stop offset="0.78" stop-color="#fff" stop-opacity="0"/></radialGradient></defs><g id="Хлястик_Контур"><path :style="{ fill: params.flap.sliceColor }"  class="tf-c-3" d="M106.74,194.93c-6,0-9.54-.22-11.51-2.18a4.86,4.86,0,0,1-1.39-3.58c0-6.51,9-7.41,18.63-7.41,2.08,0,7.85.31,17.4.83,37.3,2,135.6,7.28,274.26,7.41h55.59a2,2,0,0,1,0,4H404.13c-138.77-.13-237.15-5.41-274.47-7.42-9.16-.49-15.22-.82-17.19-.82-9.71,0-14.63,1.15-14.63,3.41,0,.54.15.68.21.74,1.06,1.06,5.79,1,11.26,1h14.44a2,2,0,0,1,0,4h-17Z"/><path class="tf-c-3" d="M106.74,194.93c-6,0-9.54-.22-11.51-2.18a4.86,4.86,0,0,1-1.39-3.58c0-6.51,9-7.41,18.63-7.41,2.08,0,7.85.31,17.4.83,37.76,2,138,7.41,279.42,7.41a2,2,0,0,1,0,4c-141.49,0-241.85-5.39-279.63-7.42-9.16-.49-15.22-.82-17.19-.82-9.71,0-14.63,1.15-14.63,3.41,0,.54.15.68.21.74,1.06,1.06,5.79,1,11.26,1h14.44a2,2,0,0,1,0,4h-17Z"/></g><g id="Кнопки_Градиент"><path class="tf-c-4" d="M435.06,185.08c4.36,0,7.9,1.44,7.9,3.5,0,1.3-3.54,1.29-7.9,1.29s-7.89.07-7.89-1.49C427.17,186.16,430.7,185.08,435.06,185.08Z"/></g><g id="Кнопки_Контур"><path class="tf-c-5" d="M435.06,185.08c4.36,0,7.9,1.44,7.9,3.5,0,1.3-3.54,1.29-7.9,1.29s-7.89.07-7.89-1.49C427.17,186.16,430.7,185.08,435.06,185.08Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tf-c-1{opacity:0.3;}.tf-c-2{fill:#64ff00;}.tf-c-3{fill:gray;stroke-width:1.14px;}.tf-c-3,.tf-c-4,.tf-c-5{stroke:#232728;stroke-miterlimit:2;}.tf-c-4,.tf-c-5{stroke-width:1.14px;}.tf-c-4{fill:url(#tf-rg);}.tf-c-5{opacity:0.8;fill:url(#tf-rg-2);}</style>

