<template>
<svg data-svg-lvl-3="edge-block-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Контур"><rect class="lc-c-3" x="54.96" y="232.74" width="417.97" height="1.81"/><rect class="lc-c-3" x="55.27" y="230.94" width="417.97" height="1.81"/><rect class="lc-c-3" x="54.96" y="229.13" width="417.97" height="1.81"/><rect class="lc-c-3" x="55.15" y="227.33" width="417.97" height="1.81"/><rect class="lc-c-3" x="55.25" y="225.52" width="417.97" height="1.81"/><rect class="lc-c-3" x="54.96" y="223.71" width="417.97" height="1.81"/><rect class="lc-c-3" x="55.09" y="221.91" width="417.97" height="1.81"/><rect class="lc-c-3" x="54.96" y="220.1" width="417.97" height="1.81"/><rect class="lc-c-3" x="54.96" y="218.3" width="417.97" height="1.81"/><rect class="lc-c-3" x="55.07" y="216.49" width="417.97" height="1.81"/><rect class="lc-c-3" x="55.22" y="214.69" width="417.97" height="1.81"/><rect class="lc-c-3" x="54.73" y="207.46" width="417.97" height="1.81"/><rect class="lc-c-3" x="54.96" y="209.27" width="417.97" height="1.81"/><rect class="lc-c-3" x="54.96" y="211.07" width="417.97" height="1.81"/><rect class="lc-c-3" x="54.96" y="212.88" width="417.97" height="1.81"/><polyline class="lc-c-4" points="54.73 207.46 54.73 209.27 54.96 209.27 54.96 214.69 55.22 214.69 55.22 216.49 55.07 216.49 55.07 218.3 54.96 218.3 54.96 220.1 54.96 220.1 54.96 221.91 55.09 221.91 55.09 223.71 54.96 223.71 54.96 225.52 55.25 225.52 55.25 227.32 55.15 227.32 55.15 229.13 54.96 229.13 54.96 230.94 55.27 230.94 55.27 232.74 54.96 232.74 54.96 234.55"/><polyline class="lc-c-4" points="472.71 207.46 472.71 209.27 472.94 209.27 472.94 214.69 473.2 214.69 473.2 216.49 473.04 216.49 473.04 218.3 472.94 218.3 472.94 220.1 472.94 220.1 472.94 221.91 473.07 221.91 473.07 223.71 472.94 223.71 472.94 225.52 473.22 225.52 473.22 227.32 473.12 227.32 473.12 229.13 472.94 229.13 472.94 230.94 473.25 230.94 473.25 232.74 472.94 232.74 472.94 234.55"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lc-c-1{opacity:0.3;}.lc-c-2{fill:#64ff00;}.lc-c-3,.lc-c-4{fill:none;stroke-miterlimit:2;}.lc-c-3{stroke:#707d7f;stroke-width:0.2px;}.lc-c-4{stroke:#232728;stroke-width:0.5px;}</style>

