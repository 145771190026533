import lodash from 'lodash'
import { colors, colorsHex } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const flap = {
  id: 'flap',
  // tName:  'personalizations.flap.flap',
  template: 'modal',
  preview: 'cover',

  originPersonalizationsIds: [
    405, // Хлястик  №1 (на кнопке)
    406, // Хлястик  №2 (с петлей-резинкой  для ручки)
    308, // Хлястик №4 (на магнитной кнопке с петлей для ручки)
    582, // Хлястик №6 (на скрытой магнитной кнопке).
    630, // Клапан на магнитах с карманом и петлёй для ручки
    631, // Обрезной уголок с хлястиком на механической кнопке
    661, // Органайзер
    657, // Хлястик № 8 (на скрытом магните с окраской среза)
    688, // Карман "Тройка"
    710, // Хлястик № 10 (на магнитной кнопке с окрасом среза)
    711, // Хлястик №9 (на механической кнопке с прорезями  под ручку)
    722, // Хлястик №11 (на механической кнопке)
    735, // Карман "Тройка" Хайтек Хард/Дуо Флекс

    // 558, // Хлястик №5 (на магнитной кнопке)
    // 613, // Хлястик  №1 (на кнопке) КОЖА
    // 614, // Хлястик  №2 (с петлей-резинкой  для ручки) КОЖА
    // 616, // Хлястик №4 (на магнитной кнопке с петлей для ручки) КОЖА
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getFlap(params)
  getPosition(params)
  getMaterial(params)
  getRubberColor(params)
  getThreadColor(params)
  getButtonColor(params)
  getSliceColor(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  let idParam
  let idValue
  let idParamAdd
  let idValueAdd
  let idParamAdd2
  let idValueAdd2
  let idParamAdd3
  let idValueAdd3

  const keyRubberColor = modelValues?.rubberColor?.id
  const keySliceColor = modelValues?.sliceColor?.id
  const keymaterialTop = modelValues?.materialTop?.id
  const keymaterialBottom = modelValues?.materialBottom?.id
  const keyThreadColor = modelValues?.threadColor?.id
  const keyMaterialPocket = modelValues?.materialPocket?.id
  const keyButtonColor = modelValues?.buttonColor?.id
  const keyMaterial = modelValues?.material?.id
  const keyPosition = modelValues?.position?.id

  const keyFlap = modelValues?.flap?.id
  if (keyFlap === 'flap1') {
    idBuhta = 405
    idParam = 68
    idValue = keyMaterial
  } else if (keyFlap === 'flap2') {
    idBuhta = 406
    idParam = 68
    idValue = keyMaterial
    idParamAdd = 29
    idValueAdd = keyRubberColor
  } else if (keyFlap === 'flap4') {
    idBuhta = 308
    idParam = 68
    idValue = keyMaterial
  } else if (keyFlap === 'flap6') {
    idBuhta = 582
    idParam = 68
    idValue = keyMaterial
  } else if (keyFlap === 'flap7') {
    idBuhta = 631
    idParam = 68
    idValue = keyMaterial
    idParamAdd = 97
    idValueAdd = keyButtonColor
    idParamAdd2 = 123
    idValueAdd2 = keyThreadColor
  } else if (keyFlap === 'flap8') {
    idBuhta = 657
    idParam = 68
    idValue = keyMaterial
    idParamAdd = 100
    idValueAdd = keySliceColor
  } else if (keyFlap === 'flap9') {
    idBuhta = 711
    idParam = 68
    idValue = keyMaterial
    idParamAdd = 97
    idValueAdd = keyButtonColor
  } else if (keyFlap === 'flap10') {
    idBuhta = 710
    idParam = 68
    idValue = keyMaterial
    idParamAdd = 100
    idValueAdd = keySliceColor
  } else if (keyFlap === 'flap11') {
    idBuhta = 722
    idParam = 68
    idValue = keyMaterial
    idParamAdd = 97
    idValueAdd = keyButtonColor
    idParamAdd2 = 123
    idValueAdd2 = keyThreadColor
  } else if (keyFlap === 'magnet') {
    idBuhta = 630
    idParam = 68
    idValue = keyMaterial
    idParamAdd = 98
    idValueAdd = keyMaterialPocket
    idParamAdd2 = 88
    idValueAdd2 = keyThreadColor
  } else if (keyFlap === 'organizer') {
    idBuhta = 661
    idParam = 68
    idValue = keyMaterial
    idParamAdd = 97
    idValueAdd = keyButtonColor
    idParamAdd2 = 88
    idValueAdd2 = keyThreadColor
  } else if (keyFlap === 'trinity') {
    idBuhta = 688
    idParam = 126
    idValue = keymaterialBottom
    idParamAdd = 127
    idValueAdd = keymaterialTop
    idParamAdd2 = 88
    idValueAdd2 = keyThreadColor
  } else if (keyFlap === 'trinityH') {
    idBuhta = 735
    idParam = 126
    idValue = keymaterialBottom
    idParamAdd = 127
    idValueAdd = keymaterialTop
    idParamAdd2 = 88
    idValueAdd2 = keyThreadColor
    idParamAdd3 = 148
    idValueAdd3 = keyPosition
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
    {
      idParam: idParamAdd,
      idValue: idValueAdd,
    },
    {
      idParam: idParamAdd2,
      idValue: idValueAdd2,
    },
    {
      idParam: idParamAdd3,
      idValue: idValueAdd3,
    },
  ])
}

/**
 * Выбор типа тиснения(Блинтовое тиснение/Тиснение фольгой/Тиснение цветной фольгой)
 */
function getFlap(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originPersonalizations,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  const persKeyAndId = {
    flap1: 405,
    flap2: 406,
    flap4: 308,
    flap6: 582,
    flap7: 631,
    flap8: 657,
    flap9: 711,
    flap10: 710,
    flap11: 722,
    magnet: 630,
    organizer: 661,
    trinity: 688,
    trinityH: 735,
  }
  const values = []
  for (const k in persKeyAndId) {
    if (originPersonalizations[persKeyAndId[k]]) {
      values.push({
        id: k,
        idBuhta: persKeyAndId[k],
        tName: `personalizations.flap.${k}`,
        tDescription: `personalizations.flap.description.${k}`,
        img: `${cnstrAssetsUrl}/personalizations/flap/${persKeyAndId[k]}.webp`,
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'flap',
      tName: 'personalizations.flap.flap',
      template: 'selectImageText',
      sort: 10,
      values,
    })

    if (oldModelValues.flap) {
      modelValues.flap = values.find((i) => i.id === oldModelValues.flap.id) ?? values[0]
    } else {
      modelValues.flap = values[0]
    }
  }
}

/**
 * Выбор места установки
 */
function getPosition(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params
  if (modelValues.flap.id !== 'trinityH') {
    return
  }

  let values = []
  let idBuhta = 735
  let idParam = 148
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'position',
      tName: 'personalizations.flap.position',
      template: 'selectRadio',
      sort: 20,
      values,
    })

    if (oldModelValues.position) {
      modelValues.position = values.find((i) => i.id === oldModelValues.position.id) ?? values[0]
    } else {
      modelValues.position = values[0]
    }
  }
}

/**
 * Выбор материала
 */
function getMaterial(params) {
  const {
    store: {
      materialsTexturesKeys,
      materialsColorsKeys,
      selectedMaterials,
      CONST: { assetsUrl },
    },
    oldModelValues,
    items,
    modelValues,
    originPersonalizations,
  } = params

  const paramsKeyAndId = {
    material: 68,
    materialBottom: 126,
    materialTop: 127,
    materialPocket: 98,
  }
  const idBuhta = modelValues.flap.idBuhta

  for (const nameParam in paramsKeyAndId) {
    const idParam = paramsKeyAndId[nameParam]

    let values = []
    if (originPersonalizations[idBuhta].params) {
      const val = originPersonalizations[idBuhta].params.find((n) => n.id === idParam)
      if (val?.values?.length) {
        values = val.values.map((n) => {
          let textureRef = materialsTexturesKeys[n.textureId]
          let colorRef = materialsColorsKeys[n.colorId]
          let hint = ``
          if (textureRef && colorRef) {
            hint = `${textureRef.key} ${textureRef.name}<br>${colorRef.name} ${colorRef.key}`
          }
          return {
            ...n,
            imgOrigin: `${assetsUrl}/materials/${textureRef?.key}${colorRef?.key}.jpg`,
            img: `${assetsUrl}/materials/small/${textureRef?.key}${colorRef?.key}_mini.jpg`,
            textureRef,
            colorRef,
            hint,
          }
        })
        // сортировка материалов и перемещение наверх материал, который является материалом обложки
        values = lodash.sortBy(values, ['colorRef.sort', 'textureRef.key'])
        let matId = values.findIndex(
          (n) => n.colorId === selectedMaterials[0].color && n.textureId === selectedMaterials[0].texture,
        )
        if (matId > 0) {
          values.unshift(values[matId])
          values.splice(matId + 1, 1)
        }
      }
    }

    if (values.length > 0) {
      items.push({
        id: nameParam,
        tName: `personalizations.flap.${nameParam}`,
        template: 'selectImage',
        useFilter: true,
        sort: nameParam === 'materialBottom' || nameParam === 'materialPocket' ? 40 : 30,
        values,
      })

      if (oldModelValues[nameParam]) {
        modelValues[nameParam] = values.find((i) => i.id === oldModelValues[nameParam].id) ?? values[0]
      } else {
        modelValues[nameParam] = values[0]
      }
    }
  }
}

/**
 * Выбор цвета резинки
 */
function getRubberColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params
  if (modelValues.flap.id !== 'flap2') {
    return
  }

  const idBuhta = 406
  const idParam = 29

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'rubberColor',
      tName: 'personalizations.flap.rubber20Color',
      template: 'selectClassicColor',
      mod: 'aboveSelect',
      sort: 50,
      values,
    })

    if (oldModelValues.rubberColor) {
      modelValues.rubberColor = values.find((i) => i.id === oldModelValues.rubberColor.id) ?? values[0]
    } else {
      modelValues.rubberColor = values[0]
    }
  }
}

/**
 * Выбор цвета нитки
 */
function getThreadColor(params) {
  const { store, oldModelValues, items, modelValues, originPersonalizations } = params

  let idBuhta
  let idParam = 88
  let mod = ''
  if (modelValues.flap.id === 'magnet') {
    idBuhta = 630
    mod = 'aboveSelect'
  } else if (modelValues.flap.id === 'flap7') {
    idBuhta = 631
    idParam = 123
  } else if (modelValues.flap.id === 'flap11') {
    idBuhta = 722
    idParam = 123
  } else if (modelValues.flap.id === 'organizer') {
    idBuhta = 661
  } else if (modelValues.flap.id === 'trinity') {
    idBuhta = 688
    mod = 'aboveSelect'
  } else if (modelValues.flap.id === 'trinityH') {
    idBuhta = 735
    mod = 'aboveSelect'
  } else {
    return
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => {
        const newItem = {
          ...n,
          background: colors[n.id] ?? n.hex,
        }
        // Дефолтная нитка берётся с цветом из материала threadColorConstr
        if (n.id === 1292) {
          newItem.hex = '#232728'
          if (store?.selectedMaterials) {
            let threadColorConstr = store.selectedMaterials[0]?.threadColorConstr
            if (modelValues.flap.id === 'flap7') {
              threadColorConstr = modelValues?.material?.threadColorConstr
            }
            const foundHex = val.values.find((m) => m.id === threadColorConstr)
            if (foundHex?.hex) {
              newItem.hex = foundHex?.hex
            }
          }
        }
        return newItem
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'threadColor',
      tName: 'personalizations.flap.threadColor',
      template: 'selectClassicColor',
      mod,
      sort: 60,
      values,
    })

    // Дефолтная нитка берётся с цветом "в соответствии с материалом"
    let defaultIndex = values.findIndex((n) => n.id === 1292)
    if (defaultIndex < 0) {
      defaultIndex = 0
    }
    if (oldModelValues.threadColor) {
      modelValues.threadColor = values.find((i) => i.id === oldModelValues.threadColor.id) ?? values[defaultIndex]
    } else {
      modelValues.threadColor = values[defaultIndex]
    }
  }
}

/**
 * Выбор цвета кнопки
 */
function getButtonColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let idBuhta
  const idParam = 97

  if (modelValues.flap.id === 'organizer') {
    idBuhta = 661
  } else if (modelValues.flap.id === 'flap7') {
    idBuhta = 631
  } else if (modelValues.flap.id === 'flap9') {
    idBuhta = 711
  } else if (modelValues.flap.id === 'flap11') {
    idBuhta = 722
  } else {
    return
  }
  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: colors[n.id] ?? n.hex,
        hex: colorsHex[n.id] ?? n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'buttonColor',
      tName: 'personalizations.flap.buttonColor',
      template: 'selectClassicColor',
      mod: modelValues.flap.id === 'organizer' ? 'aboveSelect' : '',
      sort: 70,
      values,
    })

    // Дефолтный цвет кнопки - серебро
    let defaultIndex = values.findIndex((n) => n.hex === '#989BA0')
    if (defaultIndex < 0) {
      defaultIndex = 0
    }
    if (oldModelValues.buttonColor) {
      modelValues.buttonColor = values.find((i) => i.id === oldModelValues.buttonColor.id) ?? values[defaultIndex]
    } else {
      modelValues.buttonColor = values[defaultIndex]
    }
  }
}

/**
 * Выбор цвета среза
 */
function getSliceColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let idBuhta
  const idParam = 100

  if (modelValues.flap.id === 'flap8') {
    idBuhta = 657
  } else if (modelValues.flap.id === 'flap10') {
    idBuhta = 710
  } else {
    return
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'sliceColor',
      tName: 'personalizations.flap.sliceColor',
      template: 'selectClassicColor',
      mod: 'aboveSelect',
      sort: 80,
      values,
    })

    if (oldModelValues.sliceColor) {
      modelValues.sliceColor = values.find((i) => i.id === oldModelValues.sliceColor.id) ?? values[0]
    } else {
      modelValues.sliceColor = values[0]
    }
  }
}
