<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bc-lg" x1="61.46" y1="222.76" x2="452.54" y2="222.76" gradientUnits="userSpaceOnUse"><stop offset="0.47" stop-color="#727272"/><stop offset="0.48" stop-color="#545454"/><stop offset="0.48" stop-color="#525252"/><stop offset="0.49" stop-color="#484848"/><stop offset="0.5" stop-color="#454545"/><stop offset="0.51" stop-color="#494949"/><stop offset="0.52" stop-color="#545454"/><stop offset="0.53" stop-color="#727272"/></linearGradient></defs><g id="Задник_Контур"><path class="bc-c-3" d="M440.54,151.32h-174A3.18,3.18,0,0,0,264,152.6a6,6,0,0,1-4.82,2.44h-4.94a6,6,0,0,1-4.82-2.43,3.16,3.16,0,0,0-2.54-1.29H73.46a12,12,0,0,0-12,12V278.24a12,12,0,0,0,12,12H247.34a2.16,2.16,0,0,1,1.82,1.08,6,6,0,0,0,5.11,2.88h4.94a6,6,0,0,0,5.13-2.9,2.09,2.09,0,0,1,1.78-1.06H440.54a12,12,0,0,0,12-12V163.32A12,12,0,0,0,440.54,151.32Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{stroke:#232728;stroke-linejoin:round;stroke-width:0.75px;fill:url(#bc-lg);}</style>

