<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="tc-rg" cx="378.31" cy="36.82" r="465.41" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.2"/><stop offset="0.44" stop-color="#e5e5e5" stop-opacity="0"/></radialGradient><linearGradient id="tc-lg" x1="113.12" y1="226.65" x2="229.42" y2="226.65" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.4"/><stop offset="0.13" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="tc-lg-2" x1="113.12" y1="226.65" x2="137.42" y2="226.65" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.3"/><stop offset="0.13" stop-opacity="0"/></linearGradient></defs><g id="Крышки_Контур"><path class="tc-c-3" d="M346.28,27.25h0V27H113.13V425.73H381a9.16,9.16,0,0,0,9.13-9.14V71.12Z"/><rect class="tc-c-4" x="113.12" y="27.26" width="116.29" height="398.77"/><rect class="tc-c-5" x="113.12" y="27.26" width="24.29" height="398.77"/><path class="tc-c-6" d="M346.28,27.25h-232A1.09,1.09,0,0,1,113.15,26l0,0v399a1.1,1.1,0,0,0,1.1,1.1H381a9.16,9.16,0,0,0,9.13-9.13V71.12Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#ff931e;}.tc-c-3{fill:url(#tc-rg);}.tc-c-4{fill:url(#tc-lg);}.tc-c-5{fill:url(#tc-lg-2);}.tc-c-6{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

