<template>
<svg data-svg-lvl-3="cover-back-frames" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="bfr-rg" cx="444.58" cy="-26.97" fx="444.58" fy="-26.97" r="461.25" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#686a6d"/><stop offset=".18" stop-color="#bcc3cd"/><stop offset=".41" stop-color="#bdc0c8"/><stop offset=".61" stop-color="#80828a"/><stop offset=".8" stop-color="#505256"/><stop offset="1" stop-color="#747578"/></radialGradient></defs><g id="УголкиЗадник"><image width="996" height="53" transform="translate(157.92 22) scale(.24)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/back/frames/M2_1.webp`"/><path class="bfr-c-3" d="M156.74,414.96v-6.25c0-1.78,1.44-3.23,3.23-3.23h227.47c2.61,0,4.72-2.11,4.72-4.72V30.04c0-2.61-2.11-4.72-4.72-4.72h-225.97c-1.78,0-3.23-1.44-3.23-3.23v-6.25c0-.12.1-.23.23-.23h234.05c5.16,0,9.35,4.19,9.35,9.35v380.86c0,5.16-4.19,9.35-9.35,9.35h-235.55c-.12,0-.23-.1-.23-.23Z"/><image width="1023" height="1667" transform="translate(156.48 15.52) scale(.24)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/back/frames/M2_2.webp`"/><path class="bfr-c-2" d="M392.51,15.62c5.16,0,9.35,4.19,9.35,9.35v380.86c0,5.16-4.19,9.35-9.35,9.35h-235.55c-.12,0-.23-.1-.23-.23v-6.25c0-1.78,1.44-3.23,3.23-3.23h227.47c2.61,0,4.72-2.11,4.72-4.72V30.04c0-2.61-2.11-4.72-4.72-4.72h-225.97c-1.78,0-3.23-1.44-3.23-3.23v-6.25c0-.12.1-.23.23-.23h234.05M392.51,14.48h-234.05c-.75,0-1.37.61-1.37,1.37v6.25c0,2.41,1.96,4.37,4.37,4.37h225.97c1.97,0,3.58,1.6,3.58,3.58v370.72c0,1.97-1.6,3.58-3.58,3.58h-227.47c-2.41,0-4.37,1.96-4.37,4.37v6.25c0,.75.61,1.37,1.37,1.37h235.55c5.79,0,10.49-4.71,10.49-10.49V24.97c0-5.78-4.71-10.49-10.49-10.49h0Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bfr-c-1{opacity:.3;}.bfr-c-2{fill:#232728;}.bfr-c-2,.bfr-c-3,.bfr-c-4{stroke-width:0px;}.bfr-c-3{fill:url(#bfr-rg);}.bfr-c-4{fill:#64ff00;}</style>

