<template>
<svg data-svg-lvl-3="edge-top-flap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Хлястик_Контур"><path :style="{ fill: params.flap.sliceColor }"  class="tf-c-3" d="M468.42,250.8H437.24a1.86,1.86,0,1,1,0-3.71h31.43c1.87,0,4.23-4.72,4.23-26.59s-2.92-25.59-4.23-25.59H343a1.85,1.85,0,1,1,0-3.7H468.14c4.58,0,8.46,4.79,8.46,29.16S473.33,250.8,468.42,250.8Z"/><rect class="tf-c-3" x="390.68" y="189.47" width="20.84" height="5.44"/><line class="tf-c-4" x1="393.44" y1="189.47" x2="393.44" y2="191.47"/><line class="tf-c-4" x1="393.44" y1="192.91" x2="393.44" y2="194.91"/><line class="tf-c-4" x1="408.85" y1="189.47" x2="408.85" y2="191.47"/><line class="tf-c-4" x1="408.85" y1="192.91" x2="408.85" y2="194.91"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tf-c-1{opacity:0.3;}.tf-c-2{fill:#64ff00;}.tf-c-3{fill:gray;stroke-width:1.14px;}.tf-c-3,.tf-c-4{stroke:#232728;stroke-miterlimit:2;}.tf-c-4{fill:none;stroke-linecap:round;stroke-width:0.75px;}</style>

