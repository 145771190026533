<template>
<svg data-svg-lvl-3="edge-top-flap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Хлястик_Контур"><path :style="{ fill: params.flap.sliceColor }"  class="tf-c-3" d="M468.14,191.21H264.3a1.85,1.85,0,0,0,0,3.7H468.67c1.31,0,16.62,1.14,16.62,23S470.54,239,468.67,239l-5.69.05v2.16h5a1.24,1.24,0,0,1,1.23,1.24v.25c4.48-.1,19.81-1.84,19.81-24.91C489,193.43,472.72,191.21,468.14,191.21Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tf-c-1{opacity:0.3;}.tf-c-2{fill:#64ff00;}.tf-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

