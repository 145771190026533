<template>
<svg data-svg-lvl-3="flyleafend-block-plastic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lpl-lg" x1="486.28" y1="90.79" x2="486.28" y2="355.97" gradientTransform="matrix(-1, 0, 0, 1, 894.02, 0)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4d4d4d" stop-opacity="0.3"/><stop offset="0.59" stop-color="#4d4d4d" stop-opacity="0.32"/><stop offset="0.82" stop-color="#4d4d4d" stop-opacity="0.41"/><stop offset="0.98" stop-color="#4d4d4d" stop-opacity="0.57"/><stop offset="1" stop-color="#4d4d4d" stop-opacity="0.6"/></linearGradient><clipPath id="lpl-cp"><path class="lpl-c-1" d="M440.54,90.13a12,12,0,0,1,12,12V344.24a12,12,0,0,1-12,12h-77.6V90.13Z"/></clipPath><linearGradient id="lpl-lg-2" x1="-3609.03" y1="223.18" x2="-3606.04" y2="223.18" gradientTransform="translate(3972.02)" gradientUnits="userSpaceOnUse"><stop offset="0"/><stop offset="1" stop-opacity="0"/></linearGradient></defs><g id="Комбинация_ЗадникМаска"><path class="lpl-c-4" d="M440.54,90.13a12,12,0,0,1,12,12V344.24a12,12,0,0,1-12,12h-77.6V90.13Z"/></g><g id="Комбинация_Задник"><path class="lpl-c-5" d="M440.54,90.13a12,12,0,0,1,12,12V344.24a12,12,0,0,1-12,12h-77.6V90.13Z"/><g class="lpl-c-6"><image width="514" height="422" transform="matrix(-1, 0, 0, 1, 514, 4)" :href="`${$CONST.cnstrAssetsUrl}/others/93BB-Ice-print.webp`"/></g><rect class="lpl-c-7" x="362.94" y="90.13" width="3" height="266.11"/><path class="lpl-c-8" d="M440.54,90.13a12,12,0,0,1,12,12V344.24a12,12,0,0,1-12,12h-77.6V90.13Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lpl-c-1,.lpl-c-8{fill:none;}.lpl-c-2{opacity:0.3;}.lpl-c-3{fill:#64ff00;}.lpl-c-4{fill:#fff;}.lpl-c-5{fill:url(#lpl-lg);}.lpl-c-6{clip-path:url(#lpl-cp);}.lpl-c-7{opacity:0.4;fill:url(#lpl-lg-2);}.lpl-c-8{stroke:#232728;stroke-linejoin:round;stroke-width:0.75px;}</style>

