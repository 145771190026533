<template>
<svg data-svg-lvl-3="flyleafend-block-envelope" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="len-rg" cx="275" cy="122.44" r="288.8" gradientTransform="translate(718.73 446.6) rotate(180)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.1"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></radialGradient></defs><g id="КонвертЦвет"><polygon :style="{ fill: params.envelopeColor }"  class="len-c-3" points="270.79 335.59 270.79 111.02 268.06 111.02 265.71 100.02 270.79 100.02 447.94 100.02 447.94 346.58 270.79 346.58 265.71 346.58 268.06 335.59 270.79 335.59"/></g><g id="КонвертКонтур_Пластиковый_Карман" ><rect class="len-c-4" x="270.79" y="100.02" width="177.15" height="246.56" transform="translate(718.73 446.6) rotate(-180)"/><polygon class="len-c-5" points="265.71 346.57 268.06 335.59 270.79 335.58 270.79 111.02 268.06 111.02 265.71 100.02 265.71 341.21 265.71 346.57"/><polygon class="len-c-6" points="265.71 346.57 270.79 346.57 270.79 100.02 265.71 100.02 265.71 341.21 265.71 346.57"/><polygon class="len-c-7" points="270.79 100.02 268.06 111.02 270.79 111.02 270.79 100.02"/><polygon class="len-c-8" points="268.06 111.02 270.79 100.02 265.71 100.02 268.06 111.02"/><polygon class="len-c-7" points="270.79 346.57 268.06 335.58 270.79 335.58 270.79 346.57"/><polygon class="len-c-8" points="268.06 335.58 270.79 346.57 265.71 346.57 268.06 335.58"/><rect class="len-c-6" x="270.79" y="100.02" width="177.15" height="246.56" transform="translate(718.73 446.6) rotate(-180)"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.len-c-1{opacity:0.3;}.len-c-2{fill:#64ff00;}.len-c-3{fill:#cce1e2;}.len-c-4{fill:url(#len-rg);}.len-c-5{fill:#727272;}.len-c-6{fill:none;stroke-linejoin:round;stroke-width:0.75px;}.len-c-6,.len-c-7,.len-c-8{stroke:#232728;}.len-c-7{fill-opacity:0.4;}.len-c-7,.len-c-8{stroke-miterlimit:1;stroke-width:0.4px;}.len-c-8{fill-opacity:0.2;}</style>

