<template>
<svg data-svg-lvl-3="cover-top-stitchingRubber" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="sr-cp"><path class="sr-c-1" d="M154.35,381.8a9.77,9.77,0,0,0,9.77,9.78H338.88a9.78,9.78,0,0,0,9.78-9.78V226.59H154.35Zm24.2-132.63a5.52,5.52,0,0,1,5.52-5.52H318.93a5.53,5.53,0,0,1,5.53,5.52V369a5.54,5.54,0,0,1-5.53,5.53H184.07a5.53,5.53,0,0,1-5.52-5.53Z"/></clipPath><clipPath id="sr-cp-2"><rect class="sr-c-1" x="42.05" y="56.79" width="418.5" height="343.59"/></clipPath><linearGradient id="sr-lg" x1="154.35" y1="309.08" x2="348.66" y2="309.08" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.5"/><stop offset="0.25" stop-opacity="0"/><stop offset="0.75" stop-opacity="0"/><stop offset="1" stop-opacity="0.5"/></linearGradient><linearGradient id="sr-lg-2" x1="251.5" y1="391.58" x2="251.5" y2="226.89" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.5"/><stop offset="0.14" stop-opacity="0.23"/><stop offset="1" stop-opacity="0"/></linearGradient></defs><g id="Сшивка_Резинка_Текстура"><g class="sr-c-4"><g class="sr-c-5"><image width="514" height="422" transform="translate(42.05 56.79) scale(0.81)" :href="`${$CONST.cnstrAssetsUrl}/others/black-rubber.webp`"/></g></g></g><g id="Сшивка_Резинка_Контур"><path class="sr-c-6" d="M154.35,381.8a9.77,9.77,0,0,0,9.77,9.78H338.88a9.78,9.78,0,0,0,9.78-9.78V226.59H154.35Zm24.2-132.63a5.52,5.52,0,0,1,5.52-5.52H318.93a5.53,5.53,0,0,1,5.53,5.52V369a5.54,5.54,0,0,1-5.53,5.53H184.07a5.53,5.53,0,0,1-5.52-5.53Z"/><path class="sr-c-7" d="M154.35,381.8a9.77,9.77,0,0,0,9.77,9.78H338.88a9.78,9.78,0,0,0,9.78-9.78V226.59H154.35Zm24.2-132.63a5.52,5.52,0,0,1,5.52-5.52H318.93a5.53,5.53,0,0,1,5.53,5.52V369a5.54,5.54,0,0,1-5.53,5.53H184.07a5.53,5.53,0,0,1-5.52-5.53Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.sr-c-1{fill:none;}.sr-c-2{opacity:0.3;}.sr-c-3{fill:#64ff00;}.sr-c-4{clip-path:url(#sr-cp);}.sr-c-5{clip-path:url(#sr-cp-2);}.sr-c-6{fill:url(#sr-lg);}.sr-c-7{fill:url(#sr-lg-2);}</style>

