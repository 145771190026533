<template>
<svg data-svg-lvl-3="cover-top-stitchingLoop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="sl-lg" x1="262.9" y1="220.92" x2="245.84" y2="178.52" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0"/><stop offset="0.12" stop-color="#fff" stop-opacity="0.2"/><stop offset="0.28" stop-color="#fff" stop-opacity="0.19"/><stop offset="0.34" stop-color="#fff" stop-opacity="0.17"/><stop offset="0.38" stop-color="#fff" stop-opacity="0.12"/><stop offset="0.39" stop-color="#fff" stop-opacity="0.1"/><stop offset="0.86" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="sl-lg-2" x1="262.9" y1="169.37" x2="245.84" y2="126.96" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.15"/><stop offset="0.08" stop-opacity="0"/></linearGradient></defs><g id="Петля_Контур"><polygon class="sl-c-3" points="301.61 155.13 207.11 192.74 207.11 244.27 301.61 206.67 301.61 155.13"/><polygon class="sl-c-4" points="301.61 27.23 301.61 206.67 207.11 244.27 207.11 27.23 301.61 27.23"/><polygon class="sl-c-4" points="301.61 155.13 207.11 192.74 207.11 244.27 301.61 206.67 301.61 155.13"/><polygon class="sl-c-5" points="301.61 103.58 207.11 141.18 207.11 192.72 301.61 155.11 301.61 103.58"/><image width="131" height="235" transform="translate(188.67 27.5)" :href="`${$CONST.cnstrAssetsUrl}/others/S62.webp`"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.sl-c-1{opacity:0.3;}.sl-c-2{fill:#64ff00;}.sl-c-3{fill:url(#sl-lg);}.sl-c-4{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}.sl-c-5{fill:url(#sl-lg-2);}</style>

