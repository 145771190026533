<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Задник_Контур"><rect class="bc-c-3" x="61.46" y="90.13" width="195.54" height="266.11"/><rect class="bc-c-3" x="257" y="90.13" width="195.54" height="266.11"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:#727272;stroke:#232728;stroke-linejoin:round;stroke-width:0.75px;}</style>

