<template>
  <div
    :class="['ac-checkbox', 'd-flex', 'align-items-center', { checked: value, disabled: disabled }]"
    @click="$emit('input', !value)"
  >
    <div>{{ $t(placeholderTranslation) }}</div>
    <div class="close" v-if="value">
      <IconsIconClose />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    /** @var value - выбранное значение */
    value: {
      type: Boolean,
      default: false,
    },

    /** @var placeholderTranslation - назнание перевода для плейсхолдера */
    placeholderTranslation: {
      type: String,
      default: 'ui.chooseValue',
    },
    id: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showList: false,
      height: 0,
    }
  },
}
</script>

<style lang="sass" scoped>
.ac-checkbox
  display: block
  border-radius: 5px
  background: $light-back-color
  padding: 0 10px
  cursor: pointer
  position: relative
  gap: 3px
  height: 30px
  &:hover
    background: $hover-color
  &.disabled
    opacity: 0.6
    cursor: default
    background: $light-back-color
  input
    display: none
  .close
    width: 24px
    height: 24px
    &::v-deep path
      fill: $white
  &.checked
    padding: 0 5px 0 10px
    background: $contrast-regular
    color: white
    &:hover
      background: $contrast-light
</style>
