<template>
  <div class="ui-hint">
    <div
      class="slot"
      @mouseenter.stop="slotEnter"
      @mouseleave.stop="slotMouseleave"
      @click.stop="slotClick"
      ref="innerRef"
    >
      <slot></slot>
    </div>
    <div
      class="popup"
      :class="{ show: showHint, left: isLeft, [`variant${variant}`]: true }"
      @click.stop
      :style="{ bottom: popupBottom + 'px', top: popupTop + 'px', left: popupLeft + 'px', width: popupWidth + 'px' }"
    >
      <div class="popup__text" v-if="hintHtml" v-html="hintHtml"></div>
    </div>
    <UiModal v-model="showModalHint" :width="popupWidth + 40 + 'px'">
      <div slot="body" class="popup__text-mobile" v-html="hintHtml"></div>
    </UiModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      popupBottom: 'unset',
      popupTop: 'unset',
      popupLeft: 0,
      showHint: false,
      showModalHint: false,
      isLeft: false,
    }
  },

  computed: {
    ...mapGetters({
      bp: 'window/BP',
      windowWidth: 'window/WIDTH',
    }),
  },

  watch: {
    windowWidth() {
      this.setOffsets()
    },
  },

  props: {
    hintHtml: {
      type: String,
      default: () => null,
    },
    scrollerRef: {
      type: Object,
      default: () => null,
    },
    popupWidth: {
      type: Number,
      default: () => 240,
    },
    topCorrection: {
      type: Number,
      default: () => 0,
    },
    leftCorrection: {
      type: Number,
      default: () => 0,
    },
    variant: {
      type: Number,
      default: () => 1,
    },
  },

  mounted() {
    window.addEventListener('scroll', this.setOffsets)
    let scrollerRef = this.scrollerRef?.scrollerRef
    if (scrollerRef) {
      scrollerRef.addEventListener('scroll', this.setOffsets)
    }
  },

  destroyed() {
    window.removeEventListener('scroll', this.setOffsets)
  },

  methods: {
    slotEnter() {
      if (this.bp.mdAndUp) {
        this.setOffsets()
        this.showHint = true
        this.$emit('showHint', true)
      }
    },

    slotMouseleave() {
      if (this.bp.mdAndUp) {
        this.showHint = false
        this.$emit('showHint', false)
      }
    },

    slotClick() {
      if (this.bp.smAndDown) {
        this.setOffsets()
        this.showModalHint = true
      }
    },

    setOffsets() {
      let target = this.$refs.innerRef
      var rect = target.getBoundingClientRect()
      this.isLeft = rect.left + this.popupWidth + 10 > this.windowWidth

      if (this.variant === 1) {
        this.popupTop = 'unset'
        this.popupBottom = Math.round(window.innerHeight - rect.top + 13 + this.topCorrection)
        if (!this.isLeft) {
          this.popupLeft = Math.round(rect.left - 27 + this.leftCorrection)
        } else {
          this.popupLeft = Math.round(rect.left - this.popupWidth + 32 + 10 + this.leftCorrection)
        }
      } else if (this.variant === 2) {
        this.popupBottom = 'unset'
        this.popupTop = Math.round(rect.bottom + 12 + this.topCorrection)
        if (!this.isLeft) {
          this.popupLeft = Math.round(rect.left + rect.width / 2 - 12 + this.leftCorrection)
        } else {
          this.popupLeft = Math.round(rect.left - this.popupWidth + 13 + this.leftCorrection)
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>

.ui-hint
  .popup
    display: none
    position: fixed
    z-index: 5
    &.variant1

      &::after
        overflow: hidden
        border: 9px solid transparent
        border-top: 12px solid rgba(0, 0, 0, 0.7)
        border-bottom: 0px solid transparent
        content: ''
        position: absolute
        left: 30px
      &.left::after
        left: calc(100% - 40px)
    &.show
      display: block

    &__text
      background-color: rgba(0, 0, 0, 0.7)
      border-radius: 20px
      padding: 20px 10px
      color: $white
      font-size: 13px
      line-height: 19px
      &::v-deep p
        margin: 10px 0 0 0
        color: $white
        &:first-child
          margin: 0
    &__text-mobile
      font-size: 13px
      line-height: 19px
      color: $black
    &.variant2
      &::before
        content: ""
        position: absolute
        left: 20px
        top: -12px
        width: 35px
        height: 12px
        background-image:  url(https://assets.cnstr.adjutant.ru/images/others/pointer-right.webp)
      &.left::before
        left: calc(100% - 55px)
        background-image: url(https://assets.cnstr.adjutant.ru/images/others/pointer-left.webp)
</style>
