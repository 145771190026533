<template>
<svg data-svg-lvl-3="flyleafend-block-flyleafend" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lfe-m-f" x="0" y="0" width="500" height="430" fill="#ffffff"><path  d="M341,93.86H258.08V92.59c0-4.35-7.7-6-12.4-6H68.21V348.09H245.48c6.82,0,12.4,2.08,12.4,4.64h83.18c4.73-17.06,22.6-84.48,22.6-129.23C363.66,178.47,345.56,110.41,341,93.86Z"/></mask></defs><g id="Нахзац"> <path  :style="{ fill: params.flyleafendColor }"  class="lfe-c-3" d="M341,93.86H258.08V92.59c0-4.35-7.7-6-12.4-6H68.21V348.09H245.48c6.82,0,12.4,2.08,12.4,4.64h83.18c4.73-17.06,22.6-84.48,22.6-129.23C363.66,178.47,345.56,110.41,341,93.86Z"/> <image v-if="params.flyleafendImage" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.flyleafendImage" mask="url(#lfe-m-f)" /> </g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lfe-c-1{opacity:0.3;}.lfe-c-2{fill:#64ff00;}.lfe-c-3{fill:#f4e8ce;}</style>

