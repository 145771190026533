<template>
<svg data-svg-lvl-3="cover-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Задник_Контур"><path class="bc-c-3" d="M390.14,16H124.66A14.56,14.56,0,0,0,116,19.6c-3.32,2.95-2.87,5.43-2.84,5.56V413.67a1.1,1.1,0,0,0,1.1,1.1h278a9.13,9.13,0,0,0,9.13-9.13V24.39C401.37,20.81,398.13,16,390.14,16Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

