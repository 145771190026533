/** Выбор активной площади для перемещаемых персоналок */
function getDraggableArea(props) {
  let draggableArea

  if (props.selectedBlock.format === 25) {
    // mini
    draggableArea = { x: 114, y: 124, w: 275, h: 206 }
  } else if (props.selectedProduct.id === 3) {
    // Планинги
    draggableArea = { x: 47, y: 130, w: 420, h: 154 }
  } else {
    // Модерн/Хайтек
    draggableArea = { x: 114, y: 28, w: 275, h: 397 }
    if (props.selectedModel?.cover === 4 || props.selectedModel?.cover === 12 || props.selectedModel?.cover === 24) {
      // M4/M12/M24
      draggableArea.w = 287
    } else if (props.selectedModel?.cover === 13 || props.selectedModel?.cover === 27) {
      // M13/M27
      draggableArea.x = 94
      draggableArea.w = 295
    } else if (props.selectedModel?.cover === 22 || props.selectedModel?.cover === 23) {
      // M22/M23
      draggableArea.x = 111
      draggableArea.w = 279
    }
  }
  return draggableArea
}

/** Выбор активной площади для перемещаемых персоналок для форзаца с обратной стороны */
function getDraggableAreaInner(props) {
  let draggableArea

  if (props.selectedBlock.format === 25) {
    // mini
    draggableArea = { x: 61, y: 151, w: 195, h: 140 }
  } else if (props.selectedProduct.id === 3) {
    // Планинги
    draggableArea = { x: 61, y: 60, w: 392, h: 133 }
  } else {
    // Модерн/Хайтек
    draggableArea = { x: 61, y: 89, w: 195, h: 268 }
    if (props.selectedModel?.cover === 17) {
      // H17R
      draggableArea = { x: 26, y: 106, w: 168, h: 229 }
    }
  }
  return draggableArea
}

/** Выбор активной площади для перемещаемых персоналок в мм */
function getDraggableAreaMm(props) {
  let draggableArea = getDraggableArea(props)
  const mmPix = getPixIn1Mm(props.selectedBlock, draggableArea.w)

  draggableArea = {
    x: Math.round(draggableArea.x / mmPix),
    y: Math.round(draggableArea.y / mmPix),

    w: Math.round(draggableArea.w / mmPix),
    h: Math.round(draggableArea.h / mmPix),
  }
  return draggableArea
}

/** пикселей в 1 мм */
function getPixIn1Mm(selectedBlock, w) {
  let mmPix = 1
  if (selectedBlock?.options?.blockSize) {
    const sizes = selectedBlock.options.blockSize.match(/([\d.]+)[хХxX]([\d.]+)/isu)
    if (sizes) {
      if (selectedBlock.format === 25) {
        mmPix = w / (sizes[2] * 10)
      } else {
        mmPix = w / (sizes[1] * 10)
      }
    }
  }
  return mmPix
}

/** Выбор центра изображения для перемещаемых персоналок */
function getPosition(obj, area, posX = 'center', posY = 'middle') {
  const position = {
    // default center Middle
    x: Math.round((area.w - obj.w) / 2),
    y: Math.round((area.h - obj.h) / 2),
    w: obj.w,
    h: obj.h,
  }
  switch (posX) {
    case 'left':
      position.x = 0
      break
    case 'right':
      position.x = Math.round(area.w - obj.w)
      break
    case 'center':
    default:
      position.x = Math.round((area.w - obj.w) / 2)
      break
  }

  switch (posY) {
    case 'top':
      position.y = 0
      break
    case 'bottom':
      position.y = Math.round(area.h - obj.h)
      break
    case 'middle':
    default:
      position.y = Math.round((area.h - obj.h) / 2)
      break
  }

  return position
}
export { getDraggableArea, getDraggableAreaInner, getDraggableAreaMm, getPixIn1Mm, getPosition }
