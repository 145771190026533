<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M310.33,363.47A161.76,161.76,0,0,1,278,338.55a158.13,158.13,0,0,1-.23-224.21,159.64,159.64,0,0,1,32.52-25.11"/><g class="tss-c-4"><path class="tss-c-5" d="M310.53,363.67c-1.15-.68-2.3-1.37-3.43-2.07"/><path class="tss-c-6" d="M303.78,359.48a161.8,161.8,0,0,1-25.54-20.73A158.13,158.13,0,0,1,278,114.54a160.2,160.2,0,0,1,27.41-22"/><path class="tss-c-5" d="M307.1,91.49c1.13-.7,2.28-1.39,3.43-2.06"/></g><g class="tss-c-7"><path class="tss-c-8" d="M310.13,363.27c-1.15-.68-2.3-1.37-3.43-2.07"/><path class="tss-c-9" d="M303.38,359.08a161.8,161.8,0,0,1-25.54-20.73,158.13,158.13,0,0,1-.23-224.21,160.2,160.2,0,0,1,27.41-22"/><path class="tss-c-8" d="M306.7,91.09c1.13-.7,2.28-1.39,3.43-2.06"/></g><path class="tss-c-10" d="M309.75,357.08C266,330,236.6,281.8,236.57,226.69A153.69,153.69,0,0,1,309.76,95.6"/><path class="tss-c-11" d="M310.33,357.59c-44.25-27-74-75.45-74-130.9a153.73,153.73,0,0,1,74-131.59"/></g><g id="Шов_Цвет"><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M310.33,363.47c-1.15-.68-2.3-1.37-3.43-2.07"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M303.58,359.28A161.8,161.8,0,0,1,278,338.55a158.13,158.13,0,0,1-.23-224.21,160.2,160.2,0,0,1,27.41-22"/><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M306.9,91.29c1.13-.7,2.28-1.39,3.43-2.06"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-5,.tss-c-6{stroke:#000;}.tss-c-10,.tss-c-11,.tss-c-3{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-4{opacity:0.6;}.tss-c-12,.tss-c-13,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{stroke-linecap:round;stroke-width:1.14px;}.tss-c-13,.tss-c-6,.tss-c-9{stroke-dasharray:7.89 3.94;}.tss-c-7{opacity:0.7;}.tss-c-10,.tss-c-8,.tss-c-9{stroke:#fff;}.tss-c-11,.tss-c-12,.tss-c-13{stroke:#232728;}</style>

