<template>
  <div class="ac-form-group">
    <div
      class="select"
      :class="{ open: showList, disabled: disabled }"
      tabindex="0"
      @blur="hideList"
      v-click-outside="hideList"
    >
      <div
        class="d-flex align-items-center input"
        :class="{ selected: value[itemText] }"
        @click="toggleSelect(value[itemText])"
      >
        <span>{{ value[itemText] ? value[itemText] : $t(placeholderTranslation) }}</span>
        <div v-if="!value[itemText]" class="caret">
          <IconsIconCaret />
        </div>
        <div v-else class="close">
          <IconsIconClose />
        </div>
      </div>
      <transition name="list">
        <div class="select-list" v-if="showList" :class="{ _right0: alignment === 'right' }">
          <div class="select-list-wrap">
            <div class="select-list-wrap2">
              <div class="scroller" v-if="items.length" :style="`height: ${height}px;`">
                <div class="select-list-inner" ref="select">
                  <div
                    class="select-list-item"
                    v-for="item in items"
                    :key="item[itemValue]"
                    @click="setItem(item)"
                    :class="{ active: item[itemValue] == value[itemValue], disabled: item.disabled }"
                  >
                    {{ item[itemText] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    /** @var scrollHeight - длина списка для отрисовки скролла */
    scrollHeight: {
      type: Number,
      default: 245,
    },
    /** @var items - список значений */
    items: {
      type: Array,
      required: true,
    },
    /** @var value - выбранное значение */
    value: {
      type: Object,
      default: () => ({}),
    },
    /** @var itemText - название поля, из которого берутся наименования для списка */
    itemText: {
      type: String,
      default: 'text',
    },
    /** @var itemValue - название поля, из которого берутся ключи для списка */
    itemValue: {
      type: String,
      default: 'id',
    },
    /** @var placeholderTranslation - название перевода для плейсхолдера */
    placeholderTranslation: {
      type: String,
      default: 'ui.chooseValue',
    },
    alignment: {
      type: String,
      default: 'left',
    },
  },

  data() {
    return {
      showList: false,
      height: 0,
    }
  },

  watch: {
    items() {
      this.setScrollHeight()
    },
  },

  methods: {
    /**
     * При выборе элемента списка генерирует событие, если выбран новый элемент
     */
    setItem(item) {
      if (item.disabled) {
        return
      }
      if (item[this.itemValue] !== this.value[this.itemValue]) {
        this.$emit('input', item)
      }
      this.showList = false
    },
    toggleSelect(value) {
      if (value) {
        this.$emit('input', {})
      } else {
        this.disabled ? (this.showList = false) : (this.showList = !this.showList)
        this.setScrollHeight()
      }
    },
    setScrollHeight() {
      if (this.showList) {
        setTimeout(() => {
          const innerHeight = this.$refs.select.scrollHeight
          if (innerHeight < this.scrollHeight) {
            this.$refs.select.style = `height: ${innerHeight}px`
            this.height = innerHeight
          } else {
            this.height = this.scrollHeight
          }
        }, 200)
      }
    },
    hideList() {
      window.setTimeout(() => {
        this.showList = false
      }, 200)
    },
  },
}
</script>

<style lang="sass" scoped>
.ac-form-group
  position: relative
  margin-bottom: 0

.form-item
  position: relative
.select
  cursor: pointer
  overflow: hidden

  &.disabled
    opacity: 0.6
    cursor: default
  .input
    transition: .3s
    position: relative
    border: none
    border-radius: 5px
    background: $light-back-color
    padding: 0 5px 0 10px
    height: 30px
    gap: 3px
    &:hover
      background: $hover-color
    &.selected
      background: $contrast-regular
      span
        color: $white
    span
      display: inline-block
      // padding-right: 3px
      transition: .3s
      width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .close, .caret
      transition: .3s
      height: 24px
      width: 24px

    .close
      &::v-deep
        svg
          width: 24px
          path
            fill: $white
    .caret
      flex-shrink: 0
      width: 24px
      height: 24px
      ::v-deep svg
        transform: scaleY(-1)
        // transform: rotate(0deg)
        *
          fill: $icon2-color

  &-list
    width: 290px
    position: absolute
    // position: fixed
    z-index: 999
    // top: 35px
    // left: 0
    // min-width: 100%
    z-index: 2
    box-shadow: 0px 4px 15px 0px rgba(0, 52, 49, 0.30)
    border-radius: 15px
    overflow: hidden
    &._right0
      right: 0
    &-wrap
      padding: 10px 0
      background: $light-back-color
    &-wrap2
      overflow: hidden
      height: 100%
    &-inner
      max-height: 100%
      border-top: none
      border: none
    &-item
      padding: 9.5px 16px
      cursor: pointer
      transition: .3s
      font-size: 14px
      white-space: nowrap
      max-width: 100%
      overflow: hidden
      text-overflow: ellipsis
      &:hover
        background-color: $hover-color
      &.active
        color: $alternative
      &.disabled
        color: $gray-400
        cursor: default
    &-load
      display: flex
      justify-content: center
      margin-bottom: 7px

  // открытие селекта
  &.open

    .input
      border-color: $alternative
    .caret::v-deep svg
      transform: scaleY(1)
      // transform: rotate(0deg)


  .list-enter-active, .list-leave-active
    transition: .3s

  .list-enter-to, .list-leave
    opacity: 1

  .list-enter, .list-leave-to
    opacity: 0
</style>
