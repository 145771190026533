<template>
<svg data-svg-lvl-3="edge-top-base" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tb-ng_6" x1="1.71" y1="219.8" x2="19.3" y2="219.8" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#989ba0"/><stop offset="0.3" stop-color="#fff"/><stop offset="0.83" stop-color="#3c3e45"/><stop offset="1" stop-color="#979a9f"/></linearGradient></defs><g id="Крышки_Подложка"><path class="tb-c-3" d="M18.09,226.53c-10.64,0-16.8-.25-16.8-6.62,0-6.68,6.71-6.85,16.8-6.85a1.25,1.25,0,1,1,0,2.5c-11.91,0-14.3.93-14.3,4.35S6.07,224,18.09,224a1.25,1.25,0,1,1,0,2.5Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tb-c-1{opacity:0.3;}.tb-c-2{fill:#64ff00;}.tb-c-3{stroke:#232728;stroke-miterlimit:10;fill:url(#tb-ng_6);}</style>

