<template>
  <div class="input-error">
    <UiInputNumber :value="value" :min="min" :max="max" @input="input($event)"
      @change="change($event)" @tempInput="tempInput($event)"
      @errorMin="errorMsg = $tc(errorMsgMin, min)" @errorMax="errorMsg = $tc(errorMsgMax, max)" />
    <div class="msg" v-if="showError && errorMsg">{{ errorMsg }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: () => 0,
    },
    min: {
      type: Number,
      default: () => 0,
    },
    max: {
      type: Number,
      default: () => 100000,
    },
    showError: {
      type: Boolean,
      default: () => true,
    },
    errorMsgMax: {
      type: String,
      default: () => 'ui.inputErrorMax',
    },
    errorMsgMin: {
      type: String,
      default: () => 'ui.inputErrorMin',
    },
  },
  data() {
    return {
      errorMsg: '',
    }
  },

  methods: {
    input($event) {
      this.errorMsg = ''
      this.$emit('input', $event)
    },
    change($event) {
      this.errorMsg = ''
      this.$emit('change', $event)
    },
    tempInput($event) {
      if ($event > 0) {
        this.$emit('tempInput', $event)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.input-error
  display: flex
  .msg
    color: $error
    font-size: 14px
    padding-left: 10px
    line-height: 28px
</style>
