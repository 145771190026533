import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const packing = {
  id: 'packing',
  tName: 'personalizations.packing.packing',
  template: 'boolean',

  originPersonalizationsIds: [
    170, // Упаковка в коробку подарочную/подарочную VIP
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getPacking(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  if (modelValues?.packing?.checked) {
    const originPersonalizations = this.originPersonalizations
    const idBuhta = 170

    return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [])
  } else return []
}

/**
 * Упаковка в коробку
 */

function getPacking(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let values = []

  if (originPersonalizations[170]) {
    values = [
      { id: 0, checked: true },
      { id: 1, checked: false },
    ]

    if (values.length > 0) {
      items.push({
        id: 'packing',
        tName: 'personalizations.packing.packing',
        template: 'checkbox',
        sort: 10,
        values,
      })
      if (oldModelValues.packing) {
        modelValues.packing = values.find((i) => i.checked === oldModelValues.packing.checked) ?? values[0]
      } else {
        modelValues.packing = values[0]
      }
    }
  }
}
