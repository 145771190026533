<template>
<svg data-svg-lvl-3="edge-block-captal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
  <defs>
    
  </defs>

  <g id="Каптал">
    <path :style="{ fill: params.captalColor }"  class="lcp-c-3" d="M52.84,220.17c0-16.05,4.17-19.39,4.17-19.39h-1.23s-8.34,2.25-8.34,10.13v21.92c0,7.41,8.33,8.4,8.34,8.4h2.01s-4.94-5-4.94-21.06Z" />
  </g>
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>
      .lcp-c-3 {
        fill: #fff;
        stroke: #232728;
        stroke-miterlimit: 2;
        stroke-width: .25px;
      }
    </style>

