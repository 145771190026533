<template>
<svg data-svg-lvl-3="cover-back-material" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="bm-m-m" x="0" y="0" width="500" height="430" fill="#ffffff" ><path   d="M162.57,16.18H343.33a7.6,7.6,0,0,1,7.6,7.6V423.24a7.6,7.6,0,0,1-7.6,7.6H162.57a0,0,0,0,1,0,0V16.18A0,0,0,0,1,162.57,16.18Z" transform="translate(480.26 -33.24) rotate(90)"/></mask></defs><g id="Задник_Материал"><path class="bm-c-3" d="M162.57,16.18H343.33a7.6,7.6,0,0,1,7.6,7.6V423.24a7.6,7.6,0,0,1-7.6,7.6H162.57a0,0,0,0,1,0,0V16.18A0,0,0,0,1,162.57,16.18Z" transform="translate(480.26 -33.24) rotate(90)"/> <image v-if="params.bottomCoverMaterial" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.bottomCoverMaterial" :mask="`url(#bm-m-m)`" /> </g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bm-c-1{opacity:0.3;}.bm-c-2{fill:#64ff00;}.bm-c-3{fill:#cce1e2;}</style>

