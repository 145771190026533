<template>
<svg data-svg-lvl-3="spread-back-corners" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bcr-lg" x1="-17884.71" y1="1874.97" x2="-17884.71" y2="1871.57" gradientTransform="translate(-17763.06 2291.93) rotate(180)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-2" x1="-17874.78" y1="1881.56" x2="-17878.04" y2="1881.56" gradientTransform="translate(-17763.06 2291.93) rotate(180)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-3" x1="-282.18" y1="1874.97" x2="-282.18" y2="1871.57" gradientTransform="matrix(1, 0, 0, -1, 674.53, 2291.93)" href="#bcr-lg"/><linearGradient id="bcr-lg-4" x1="-272.26" y1="1881.56" x2="-275.52" y2="1881.56" gradientTransform="matrix(1, 0, 0, -1, 674.53, 2291.93)" href="#bcr-lg-2"/></defs><g id="УголкиЗадник"><path class="bcr-c-3" d="M112.84,420.39h18.48L127.89,417H115L112,420A1.19,1.19,0,0,0,112.84,420.39Z"/><path class="bcr-c-4" d="M115,417V404.11l-3.43-3.42v18.47a1.23,1.23,0,0,0,.36.87Z"/><path class="bcr-c-5" d="M111.61,400.69l3.43,3.42V417h12.85l3.43,3.42H112.84a1.23,1.23,0,0,1-1.23-1.23V400.69m0-.75a1,1,0,0,0-.29.05.75.75,0,0,0-.46.7v18.47a2,2,0,0,0,2,2h18.48a.76.76,0,0,0,.53-1.29l-3.43-3.42a.75.75,0,0,0-.53-.22h-12.1V404.11a.77.77,0,0,0-.22-.53l-3.43-3.42a.71.71,0,0,0-.53-.22Z"/><path class="bcr-c-6" d="M401.15,420.39H382.67L386.1,417H399L402,420A1.19,1.19,0,0,1,401.15,420.39Z"/><path class="bcr-c-7" d="M399,417V404.11l3.43-3.42v18.47a1.23,1.23,0,0,1-.36.87Z"/><path class="bcr-c-5" d="M402.38,400.69v18.47a1.23,1.23,0,0,1-1.23,1.23H382.67L386.1,417H399V404.11l3.43-3.42m0-.75a.73.73,0,0,0-.53.22l-3.43,3.42a.77.77,0,0,0-.22.53v12.11H386.1a.75.75,0,0,0-.53.22l-3.43,3.42a.76.76,0,0,0,.53,1.29h18.48a2,2,0,0,0,2-2V400.69a.75.75,0,0,0-.46-.7.93.93,0,0,0-.29-.05Z"/><line class="bcr-c-8" x1="115.25" y1="416.75" x2="111.75" y2="420.25"/><line class="bcr-c-8" x1="398.74" y1="416.75" x2="402.24" y2="420.25"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bcr-c-1{opacity:0.3;}.bcr-c-2{fill:#64ff00;}.bcr-c-3{fill:url(#bcr-lg);}.bcr-c-4{fill:url(#bcr-lg-2);}.bcr-c-5{fill:#232728;}.bcr-c-6{fill:url(#bcr-lg-3);}.bcr-c-7{fill:url(#bcr-lg-4);}.bcr-c-8{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:0.4px;}</style>

