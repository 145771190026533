<template>
<svg data-svg-lvl-3="cover-back-corners" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bcr-lg" x1="380.77" y1="317.46" x2="380.77" y2="323.23" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-2" x1="398.75" y1="16074.29" x2="392.85" y2="16074.29" gradientTransform="matrix(1, 0, 0, -1, 0, 16379.41)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-3" x1="380.62" y1="114.54" x2="380.62" y2="120.7" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-4" x1="398.6" y1="132.74" x2="392.7" y2="132.74" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#e0ce94" />
  <stop offset="0.31" stop-color="#b38e40" />
  <stop offset="0.32" stop-color="#b49043" />
  <stop offset="0.39" stop-color="#ba9953" />
  <stop offset="0.48" stop-color="#be9e5c" />
  <stop offset="0.59" stop-color="#bfa05f" />
  <stop offset="0.93" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#83858a" />
  <stop offset=".18" stop-color="#e0e0e0" />
  <stop offset=".31" stop-color="#83858a" />
  <stop offset=".31" stop-color="#84868b" />
  <stop offset=".36" stop-color="#9fa2a8" />
  <stop offset=".4" stop-color="#b4b7be" />
  <stop offset=".45" stop-color="#c4c7ce" />
  <stop offset=".51" stop-color="#cdd0d8" />
  <stop offset=".59" stop-color="#d0d3db" />
  <stop offset=".93" stop-color="#3c3e45" />
</template>
</linearGradient></defs><g id="УголкиЗадник"><path class="bcr-c-3" d="M396.72,323.3H363.25l6.21-6.21h23.28l5.56,5.56A2.25,2.25,0,0,1,396.72,323.3Z"/><path class="bcr-c-4" d="M392.74,317.09V293.8l6.21-6.2v33.47a2.26,2.26,0,0,1-.65,1.58Z"/><path class="bcr-c-5" d="M399,287.6v33.47a2.23,2.23,0,0,1-2.23,2.23H363.25l6.21-6.21h23.28V293.8l6.21-6.2m-.81-.81-6.2,6.21-.34.33V316H369l-.33.33-6.21,6.21.81,1.95h33.47a3.37,3.37,0,0,0,3.37-3.37V287.6l-1.95-.81Z"/><line class="bcr-c-6" x1="392.74" y1="317.09" x2="398.3" y2="322.65"/><path class="bcr-c-7" d="M396.57,114.56H363.09l6.21,6.21h23.29l5.56-5.56A2.26,2.26,0,0,0,396.57,114.56Z"/><path class="bcr-c-8" d="M392.59,120.77v23.29l6.21,6.2V116.79a2.25,2.25,0,0,0-.65-1.58Z"/><path class="bcr-c-5" d="M396.57,114.56a2.23,2.23,0,0,1,2.23,2.23v33.47l-6.21-6.2V120.77H369.3l-6.21-6.21h33.48m0-1.14H363.09l-.8,2,6.21,6.21.33.33h22.62v22.62l.33.33,6.21,6.21,1.95-.81V116.79a3.37,3.37,0,0,0-3.37-3.37Z"/><line class="bcr-c-6" x1="392.59" y1="120.77" x2="398.15" y2="115.21"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bcr-c-1{opacity:0.3;}.bcr-c-2{fill:#64ff00;}.bcr-c-3{fill:url(#bcr-lg);}.bcr-c-4{fill:url(#bcr-lg-2);}.bcr-c-5{fill:#232728;}.bcr-c-6{fill:#f4e8ce;stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}.bcr-c-7{fill:url(#bcr-lg-3);}.bcr-c-8{fill:url(#bcr-lg-4);}</style>

