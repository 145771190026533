<template>
<svg data-svg-lvl-3="cover-block-edge" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs>
      <mask id="le-m-si" x="0" y="0" width="500" height="430" fill="#ffffff"><path  d="M398.05,28.44a9.12,9.12,0,0,0-9.13-9.13h-264a2.09,2.09,0,0,0-1.48.61L114,29.32a.5.5,0,0,0,.35.86H378a9.13,9.13,0,0,1,9.13,9.13V396.84h0v17.43a9.13,9.13,0,0,1-2.65,6.43h0L395.2,410h0a9.1,9.1,0,0,0,2.85-6.62Z"/><path  d="M408.39,8.15h-262A9.09,9.09,0,0,0,140,10.82L134.81,16H392.24a9.26,9.26,0,0,1,1.05.07h5.32a4.54,4.54,0,0,0,3.19-1.32Z"/></mask>
  <linearGradient id="lg-silver" x1="113.83" x2="398.05" y1="197.49" y2="197.49" gradientUnits="userSpaceOnUse" >
    <stop offset="0" stop-color="#989ba0" />
    <stop offset=".3" stop-color="#fff" /><stop offset=".83" stop-color="#3c3e45" /><stop offset="1" stop-color="#979a9f" />
  </linearGradient>
  <linearGradient id="lg-gold" x1="113.83" x2="398.05" y1="197.49" y2="197.49" gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#9c6b00" /><stop offset=".3" stop-color="#fbe6a6" />
    <stop offset=".83" stop-color="#6c4209" /><stop offset="1" stop-color="#efe9cd" />
  </linearGradient></defs><g id="Обрез"><path :style="{ fill: params.slice.color === '#9C6B00' ? 'url(#lg-gold)' : params.slice.color === '#989BA0' ? 'url(#lg-silver)' : params.slice.color }"  class="le-c-3" d="M398.05,28.44a9.12,9.12,0,0,0-9.13-9.13h-264a2.09,2.09,0,0,0-1.48.61L114,29.32a.5.5,0,0,0,.35.86H378a9.13,9.13,0,0,1,9.13,9.13V396.84h0v17.43a9.13,9.13,0,0,1-2.65,6.43h0L395.2,410h0a9.1,9.1,0,0,0,2.85-6.62Z"/><path :style="{ fill: params. slice.colorAdditional  === '#9C6B00' ? 'url(#lg-gold)' : params. slice.colorAdditional  === '#989BA0' ? 'url(#lg-silver)' : params. slice.colorAdditional  }"  class="le-c-4" d="M408.39,8.15h-262A9.09,9.09,0,0,0,140,10.82L134.81,16H392.24a9.26,9.26,0,0,1,1.05.07h5.32a4.54,4.54,0,0,0,3.19-1.32Z"/> <image v-if="params.slice.image" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.slice.image" mask="url(#le-m-si)" /></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.le-c-1{opacity:0.3;}.le-c-2{fill:#64ff00;}.le-c-3{fill:#fff;}.le-c-3,.le-c-4{stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}.le-c-4{fill:#f4e8ce;}</style>

