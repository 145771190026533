<template>
<svg data-svg-lvl-3="edge-top-slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышка_Срез"><path :style="{ fill: params.sliceColor }"  class="tsl-c-3" d="M33.7,231.72V210.28a9.5,9.5,0,0,1,9.5-9.5H468a1.24,1.24,0,0,0,1.23-1.24v-3.39a1.25,1.25,0,0,0-1.24-1.24H41.84a14,14,0,0,0-14,14v24.18a14,14,0,0,0,14,14H468a1.24,1.24,0,0,0,1.23-1.23v-3.4a1.24,1.24,0,0,0-1.23-1.24H43.2A9.5,9.5,0,0,1,33.7,231.72Z"/><path class="tsl-c-3" d="M469.15,247.09H437.07a1.28,1.28,0,0,0-1.28,1.28v1.45a1.24,1.24,0,0,0,1.23,1.23l32.72,1.64a5.07,5.07,0,0,0,5.32-5.06V191.27a2.23,2.23,0,0,0-2.23-2.23H355.31a1.29,1.29,0,0,0-1.29,1.29v3.3a1.29,1.29,0,0,0,1.28,1.28H469.15a0,0,0,0,1,0,.05V247A0,0,0,0,1,469.15,247.09Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsl-c-1{opacity:0.3;}.tsl-c-2{fill:#64ff00;}.tsl-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

