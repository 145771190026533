<template>
<svg data-svg-lvl-3="flyleafend-block-flyleafend" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Нахзац"><path class="lfe-c-3" d="M257.26,86.62V348.09H79.39a12,12,0,0,1-12-12V98.62a12,12,0,0,1,12-12Z"/><path class="lfe-c-3" d="M281.1,93.63h166a0,0,0,0,1,0,0v259.1a0,0,0,0,1,0,0h-166a12,12,0,0,1-12-12V105.63A12,12,0,0,1,281.1,93.63Z" transform="translate(716.22 446.36) rotate(-180)"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lfe-c-1{opacity:0.3;}.lfe-c-2{fill:#64ff00;}.lfe-c-3{fill:#f4e8ce;}</style>

