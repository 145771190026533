<template>
<svg data-svg-lvl-3="flyleaffront-block-shadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="lsh-rg" cx="209.24" cy="119.88" r="205.35" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#d9d9d9"/><stop offset="0.78" stop-color="#737373"/><stop offset="0.85" stop-color="#7b7b7b"/><stop offset="0.96" stop-color="#909090"/><stop offset="1" stop-color="#999"/></radialGradient><radialGradient id="lsh-rg-2" cx="209.58" cy="118.83" r="14.96" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff"/><stop offset="0.78" stop-color="#363636"/><stop offset="0.81" stop-color="#3e3e3e"/><stop offset="0.86" stop-color="#535353"/><stop offset="0.92" stop-color="#757575"/><stop offset="0.99" stop-color="#a4a4a4"/><stop offset="1" stop-color="#ababab"/></radialGradient><radialGradient id="lsh-rg-3" cx="209.58" cy="313.56" r="14.96" href="#lsh-rg-2"/><radialGradient id="lsh-rg-4" cx="201.44" cy="127.32" r="6.84" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff"/><stop offset="0.78" stop-color="#fff" stop-opacity="0"/></radialGradient><radialGradient id="lsh-rg-5" cx="201.44" cy="322.05" r="6.84" href="#lsh-rg-4"/><mask id="lsh-m" x="26.88" y="82" width="371.18" height="284" maskUnits="userSpaceOnUse"><path class="lsh-c-1" d="M210.38,107.13H26.88V334.87H364.44V107.13Z"/></mask></defs><g id="Тень_Блока"><path class="lsh-c-4" d="M210.38,115.32a9.5,9.5,0,0,0-15.46,7.4v195h0c0,.14,0,.29,0,.44a9.5,9.5,0,0,0,15.46,7.4Z"/><circle class="lsh-c-5" cx="204.43" cy="122.72" r="5.33"/><circle class="lsh-c-6" cx="204.43" cy="317.45" r="5.33"/><circle class="lsh-c-7" cx="204.43" cy="122.72" r="5.33"/><circle class="lsh-c-8" cx="204.43" cy="317.45" r="5.33"/><g class="lsh-c-9"><g class="lsh-c-10"><image width="226" height="284" transform="translate(172.05 82)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/flyleaffront/block/shadow/DH17_1.webp`"/></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lsh-c-1{fill:#fff;}.lsh-c-2{opacity:0.3;}.lsh-c-3{fill:#64ff00;}.lsh-c-4,.lsh-c-5,.lsh-c-6,.lsh-c-7,.lsh-c-8{stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.lsh-c-4{fill:url(#lsh-rg);}.lsh-c-5{fill:url(#lsh-rg-2);}.lsh-c-6{fill:url(#lsh-rg-3);}.lsh-c-7,.lsh-c-8{opacity:0.8;}.lsh-c-7{fill:url(#lsh-rg-4);}.lsh-c-8{fill:url(#lsh-rg-5);}.lsh-c-9{mask:url(#lsh-m);}.lsh-c-10{opacity:0.4;}</style>

