<template>
<svg data-svg-lvl-3="flyleafend-block-flyleafend" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Нахзац"><rect class="lfe-c-3" x="66.88" y="147.84" width="189.86" height="134.19"/><rect class="lfe-c-3" x="268.58" y="154.91" width="178.03" height="131.76" transform="translate(715.18 441.59) rotate(-180)"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lfe-c-1{opacity:0.3;}.lfe-c-2{fill:#64ff00;}.lfe-c-3{fill:#f4e8ce;}</style>

