<template>
  <div class="upload">
    <div class="item" @click="open">
      <div class="item__image" :class="{ short, loading }">
        <IconsIconArrowCircle />
      </div>
      <div class="item__name" v-if="!short && value.url !== ''">{{ value.url }}</div>
      <div class="item__filename" v-else-if="!short">{{ tLabel ? $t(tLabel) : label }}</div>
      <div class="item__close" v-if="!short && value.url !== ''" @click="$emit('input', { ...value, url: '' })">
        <IconsIconClose />
      </div>
    </div>
    <input id="file" ref="fileInput" type="file" hidden="hidden" @input="upload" />
  </div>
</template>

<script>
import api from '/src/mixins/api'
import { uploadImage } from '/src/utils/api/uploadImage'

export default {
  mixins: [api],
  props: {
    value: {
      type: Object,
      required: true,
    },
    /** @var label - имя метки */
    label: {
      type: String,
      default: () => 'Загрузить изображение',
    },
    /** @var tLabel - перевод метки */
    tLabel: {
      type: String,
      default: () => '',
    },
    width: {
      type: Number,
      default: () => 500,
    },
    height: {
      type: Number,
      default: () => 430,
    },
    short: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      file: '',
      loading: false,
    }
  },
  methods: {
    open() {
      if (!this.loading) {
        this.$refs.fileInput.click()
      }
    },

    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },

    async upload() {
      if (!(this.$refs.fileInput?.files?.length > 0)) {
        return
      }
      this.file = this.$refs.fileInput.files[0]
      this.loading = true
      this.$emit('loading', true)

      let type = this.file.type
      if (type === 'image/svg+xml') {
        type = 'image/svg'
      }
      const imgUrl = await uploadImage(this.api, {
        type,
        data: await this.toBase64(this.file),
        width: this.width,
        height: this.height,
      })
      this.$emit('input', {
        ...this.value,
        url: imgUrl,
      })
      this.loading = false
      this.$emit('loading', false)
    },
  },
}
</script>

<style lang="sass" scoped>
.upload
  display: flex
  align-items: center
  .item
    display: flex
    align-items: center
    cursor: pointer
    column-gap:  8px
    &__image
      width: 24px
      height: 24px

      &.short
        width: 30px
        height: 30px
      ::v-deep path
        fill: $icon2-color
      &.loading::v-deep path
        animation: loading .2s infinite alternate ease-in-out
    &__name, &__filename
      transition: ease .2s
      font-size: 14px
    &:hover
      // color: $contrast-regular
      .item__image::v-deep svg path
        fill: $black
    &__close
      width: 24px
      height: 24px
      cursor: pointer
      &::v-deep svg
        width: 100%
        height: 100%
        path
          fill: $icon2-color
      &:hover::v-deep svg path
        fill: $dark-text

@keyframes loading
  0%
    // color: $stroke-color
    fill: $icon2-color
  100%
    // color: $contrast-regular
    fill: $black
</style>
