<template>
<svg data-svg-lvl-3="cover-back-plastic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bpl-lg" x1="268.26" y1="215.39" x2="270.25" y2="215.39" gradientUnits="userSpaceOnUse"><stop offset="0"/><stop offset="1" stop-opacity="0"/></linearGradient><clipPath id="bpl-cp"><path class="bpl-c-1" d="M392.24,16h-124V414.77h124a9.15,9.15,0,0,0,9.13-9.13V25.13A9.13,9.13,0,0,0,392.24,16Z"/></clipPath><linearGradient id="bpl-lg-2" x1="392.75" y1="21.86" x2="272.75" y2="414.53" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#b2b2b2" stop-opacity="0.1"/><stop offset="0.5" stop-color="#b2b2b2" stop-opacity="0.11"/><stop offset="0.69" stop-color="#b2b2b2" stop-opacity="0.15"/><stop offset="0.82" stop-color="#b2b2b2" stop-opacity="0.22"/><stop offset="0.93" stop-color="#b2b2b2" stop-opacity="0.31"/><stop offset="1" stop-color="#b2b2b2" stop-opacity="0.4"/></linearGradient></defs><g id="Комбинация_ЗадникМаска"><path class="bpl-c-9" d="M392.24,16h-124V414.77h124a9.15,9.15,0,0,0,9.13-9.13V25.13A9.13,9.13,0,0,0,392.24,16Z" /></g><g id="Комбинация_Задник"><rect class="bpl-c-4" x="268.23" y="16" width="2" height="398.77"/><g class="bpl-c-5"><g class="bpl-c-6"><image width="514" height="422" transform="translate(0 4)" :href="`${$CONST.cnstrAssetsUrl}/others/93BB-Ice-print.webp`"/></g></g><path class="bpl-c-7" d="M392.24,16h-124V414.77h124a9.15,9.15,0,0,0,9.13-9.13V25.13A9.13,9.13,0,0,0,392.24,16Z"/><line class="bpl-c-8" x1="268.23" y1="16" x2="268.23" y2="414.77"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bpl-c-1{fill:none;}.bpl-c-2{opacity:0.3;}.bpl-c-3{fill:#64ff00;}.bpl-c-4{opacity:0.4;fill:url(#bpl-lg);}.bpl-c-5{opacity:0.7;}.bpl-c-6{clip-path:url(#bpl-cp);}.bpl-c-7{fill:url(#bpl-lg-2);}.bpl-c-8{fill:#fff;stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}.bpl-c-9 {fill: #fff;}</style>

