import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'
import { getDraggableAreaMm, getPosition } from '/src/utils/draggable'

export const polymer = {
  id: 'polymer',
  // tName:  'personalizations.polymer.polymer',
  template: 'modal',
  preview: 'cover',
  multiple: true,

  originPersonalizationsIds: [
    676, // Полимерная наклейка до 4 см.кв.
    677, // Полимерная наклейка до 9 см.кв.
    679, // Полимерная наклейка до 25 см.кв.
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getPolymer(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  const keyPolymer = modelValues?.polymer?.id

  if (keyPolymer === '4cm') {
    idBuhta = 676
  } else if (keyPolymer === '9cm') {
    idBuhta = 677
  } else if (keyPolymer === '25cm') {
    idBuhta = 679
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [])
}

/**
 * Выбор размера полимерной наклейки
 */
function getPolymer(params) {
  const { store, oldModelValues, items, modelValues, originIdsAvailable } = params

  const values = []

  const personals = {
    '4cm': [676],
    '9cm': [677],
    '25cm': [679],
  }
  for (const key in personals) {
    if (lodash.intersection(originIdsAvailable, personals[key]).length > 0) {
      let side = 0
      if (key === '4cm') {
        side = 20
      } else if (key === '9cm') {
        side = 30
      } else if (key === '25cm') {
        side = 50
      }
      values.push({
        id: key,
        tName: `personalizations.polymer.${key}`,
        idBuhta: personals[key][0],
        r: 0,
        w: side,
        h: side,
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'polymer',
      tName: 'personalizations.polymer.area',
      template: 'selectRadio',
      sort: 10,
      values,
    })

    if (oldModelValues.polymer) {
      modelValues.polymer = values.find((i) => i.id === oldModelValues.polymer.id) ?? values[0]
      const { r, x, y, w, h } = oldModelValues.polymer
      if (x === undefined || y === undefined) {
        const draggableArea = getDraggableAreaMm(store)
        const draggablePosition = getPosition({ w, h }, draggableArea)
        modelValues.polymer = { ...modelValues.polymer, ...draggablePosition }
      } else {
        modelValues.polymer = { ...modelValues.polymer, r, x, y, w, h }
      }
    } else {
      const draggableArea = getDraggableAreaMm(store)
      const draggablePosition = getPosition({ w: values[0].w, h: values[0].h }, draggableArea)
      modelValues.polymer = { ...values[0], ...draggablePosition }
    }
  }
}
