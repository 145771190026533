// import lodash from 'lodash'

export async function getModels(api, { product, getters, CONST }) {
  let data = []

  const response = await api('constructor-new/get-models', { product })
  if (!Array.isArray(response.result)) {
    throw new TypeError(`getModels: Ожидается массив в response.result`)
  }

  data = modifyItems(response.result, product, getters, CONST)

  return data
}

function modifyItems(items, product, getters, { cnstrAssetsUrl }) {
  const newItems = []
  for (const item of items) {
    if (!getters.CLASSES_KEYS[item.class]?.key) {
      continue
    }
    const spring = item.spring ? item.spring : 1
    const extra = {
      hint: '',
      spring,
      classRef: getters.CLASSES_KEYS[item.class],
      coverRef: getters.COVERS_KEYS[item.cover],
      springRef: getters.SPRINGS_KEYS[spring],
    }

    if (Array.isArray(item.hint)) {
      extra.hint = item.hint.map((item) => item).join('</p><p>')
      if (extra.hint) extra.hint = `<p>${extra.hint}</p>`
    }

    const group = []
    const sku =
      (product === 3 ? 'P' : '') +
      getters.CLASSES_KEYS[item.class].key +
      item.cover +
      (getters.SPRINGS_KEYS[item.spring]?.key || '')
    if (item.corner0) {
      const corner = item.class === 1 && (item.cover === 1 || item.cover === 2) ? 'R' : ''
      extra.imageSprite = {
        suffix: `.webp`,
        prefix: `${cnstrAssetsUrl}/models/${sku}${corner}/`,
        frames: 16,
      }
      group.push({
        id: item.corner0,
        corner: 0,
        sku: `${sku}${corner}`,
        ...item,
        ...extra,
      })
    }
    if (item.corner1) {
      const corner = item.class === 1 && (item.cover === 1 || item.cover === 2) ? 'D' : ''
      extra.imageSprite = {
        suffix: `.webp`,
        prefix: `${cnstrAssetsUrl}/models/${sku}${corner}/`,
        frames: 16,
      }
      group.push({
        id: item.corner1,
        corner: 1,
        sku: `${sku}${corner}`,
        ...item,
        ...extra,
      })
    }
    newItems.push(group)
  }

  return newItems
}
