<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tc-lg" x1="111.57" y1="226.23" x2="229.9" y2="226.23" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.3"/><stop offset="0.08" stop-color="#fff" stop-opacity="0"/></linearGradient></defs><g id="Крышки_Контур"><rect class="tc-c-3" x="111.57" y="27.23" width="118.33" height="398.01"/><image width="279" height="400" transform="translate(111.3 26.77)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/top/contour/H1V_1.webp`"/><image width="279" height="400" transform="translate(111.3 26.77)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/top/contour/H1V_2.webp`"/><rect class="tc-c-4" x="111.57" y="27.23" width="278.57" height="398.77"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#64ff00;}.tc-c-3{fill:url(#tc-lg);}.tc-c-4{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

