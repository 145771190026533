<template>
<svg data-svg-lvl-3="edge-top-base" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышки_Подложка"><path class="tb-c-3" d="M63.45,240.81a24.89,24.89,0,0,1-17.78,7.59c-14.21,0-25.78-12.29-25.78-27.4s11.57-27.4,25.78-27.4A24.84,24.84,0,0,1,63,200.73h3.39a27.49,27.49,0,0,0-20.72-9.6c-15.58,0-28.25,13.4-28.25,29.87s12.67,29.87,28.25,29.87a27.53,27.53,0,0,0,21.12-10.06Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tb-c-1{opacity:0.3;}.tb-c-2{fill:#64ff00;}.tb-c-3{fill:#232728;}</style>

