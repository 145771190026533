<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M46.38,149.6,229.8,228.1a72.53,72.53,0,0,0,27.2,5.37c5.07,0,17.28-1.12,27.19-5.37l183.43-78.5"/><g class="tss-c-4"><line class="tss-c-5" x1="46.58" y1="149.8" x2="50.26" y2="151.37"/><path class="tss-c-6" d="M53.93,152.94,230,228.3a72.53,72.53,0,0,0,27.2,5.37c5.07,0,17.28-1.12,27.19-5.37l177.92-76.15"/><line class="tss-c-5" x1="464.14" y1="151.37" x2="467.82" y2="149.8"/></g><g class="tss-c-7"><line class="tss-c-8" x1="46.18" y1="149.4" x2="49.86" y2="150.97"/><path class="tss-c-9" d="M53.53,152.54,229.6,227.9a72.53,72.53,0,0,0,27.2,5.37c5.07,0,17.28-1.12,27.19-5.37l177.92-76.15"/><line class="tss-c-8" x1="463.74" y1="150.97" x2="467.42" y2="149.4"/></g><path class="tss-c-10" d="M46.38,155.29,227.83,233A76.9,76.9,0,0,0,257,238.72c5.42,0,18.33-1.13,29.16-5.77l181.46-77.67"/><path class="tss-c-11" d="M46.38,155,227.83,232.7A76.9,76.9,0,0,0,257,238.47c5.42,0,18.33-1.13,29.16-5.77L467.62,155"/></g><g id="Шов_Цвет"><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="46.38" y1="149.6" x2="50.06" y2="151.17"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M53.73,152.74,229.8,228.1a72.53,72.53,0,0,0,27.2,5.37c5.07,0,17.28-1.12,27.19-5.37L462.11,152"/><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="463.94" y1="151.17" x2="467.62" y2="149.6"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-5,.tss-c-6{stroke:#000;}.tss-c-10,.tss-c-11,.tss-c-3{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-4{opacity:0.6;}.tss-c-12,.tss-c-13,.tss-c-5,.tss-c-6,.tss-c-8,.tss-c-9{stroke-linecap:round;stroke-width:1.14px;}.tss-c-13,.tss-c-6,.tss-c-9{stroke-dasharray:7.98 3.99;}.tss-c-7{opacity:0.7;}.tss-c-10,.tss-c-8,.tss-c-9{stroke:#fff;}.tss-c-11,.tss-c-12,.tss-c-13{stroke:#232728;}</style>

