import lodash from 'lodash'
import { getModels } from '/src/utils/api/getModels'

export default {
  namespaced: true,

  state() {
    return {
      classes: [],
      covers: [],
      springs: [],

      request: { locale: '', orderId: 0, product: 0 },
      items: [],
    }
  },

  mutations: {
    CLEAR_REFERENCES(state) {
      state.classes = []
      state.covers = []
      state.springs = []
    },
    SET_REFERENCES(state, payload) {
      state.classes = payload.modelClasses
      state.covers = payload.modelCovers
      state.springs = payload.modelSprings
    },

    SET_REQUEST(state, payload) {
      state.request = payload
    },
    CLEAR_ITEMS(state) {
      state.request = { locale: '', orderId: 0, product: 0 }
      state.items = []
    },
    SET_ITEMS(state, payload) {
      state.items = payload
    },
  },

  actions: {
    async GET_ITEMS({ commit, state, getters }, { api, CONST, locale, orderId, product }) {
      if (locale === state.request.locale && orderId === state.request.orderId && product === state.request.product) {
        return
      }
      if (state.request.locale !== '') commit('CLEAR_ITEMS')

      const data = await getModels(api, {
        product,
        getters,
        CONST,
      })

      commit('SET_REQUEST', { locale, orderId, product })
      commit('SET_ITEMS', data)
    },
  },

  getters: {
    CLASSES: (state) => state.classes,
    CLASSES_KEYS: (state) => lodash.keyBy(state.classes, (item) => item.id),
    COVERS: (state) => state.covers,
    COVERS_KEYS: (state) => lodash.keyBy(state.covers, (item) => item.id),
    SPRINGS: (state) => state.springs,
    SPRINGS_KEYS: (state) => lodash.keyBy(state.springs, (item) => item.id),

    CLASSES_AVAILABLE,
    CLASSES_AVAILABLE_KEYS: (state, getters) => lodash.keyBy(getters.CLASSES_AVAILABLE, (item) => item.id),

    ITEMS: (state) => state.items,
    ITEMS_AVAILABLE,
    ITEMS_AVAILABLE_KEYS: (state, getters) => lodash.keyBy(getters.ITEMS_AVAILABLE.flat(), (item) => item.id),
  },
}

function CLASSES_AVAILABLE(state, getters, rootState) {
  const selectedProduct = rootState.control.selectedProduct
  if (selectedProduct === 0 || state.items.length === 0) {
    return []
  }
  const classIds = [...new Set(state.items.map((arr) => arr.map((item) => item.class)).flat())]
  return state.classes
    .filter((item) => classIds.includes(item.id))
    .map((item) => {
      // Какая модель будет отображаться на шаге 2.1
      let model = 'M2'
      if (item.id === 1) {
        model = 'C1R'
      } else if (item.id === 3) {
        model = 'M2'
      } else if (item.id === 6) {
        if (selectedProduct === 7) {
          model = 'H2V'
        } else {
          model = 'H2L'
        }
      }

      const query = {
        orderId: state.request.orderId,
        product: selectedProduct,
        class: item.id,
      }
      const { dog101, dog100 } = rootState.control.request
      if (dog101 > 0) {
        query.dog101 = dog101
      }
      if (dog100 > 0) {
        query.dog100 = dog100
      }
      query.step = 'block'
      return {
        ...item,
        imageSprite: {
          frames: 16,
          prefix: `https://assets.cnstr.adjutant.ru/images/models/${model}/`,
          suffix: '.webp',
        },
        route: {
          query,
        },
      }
    })
}

function ITEMS_AVAILABLE(state, getters, rootState) {
  const selectedClass = rootState.control.selectedClass
  if (selectedClass === 0 || state.items.length === 0) {
    return []
  }
  return state.items.filter((arr) => arr.filter((item) => item.class === selectedClass).length > 0)
}
