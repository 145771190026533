import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const additionalBlock = {
  id: 'additionalBlock',
  // tName: 'personalizations.additionalBlock.additionalBlock',
  template: 'modal',
  preview: 'cover',
  hidden: true,

  originPersonalizationsIds: [
    624, // Block15.5x21
    625, // Block10x16
    626, // Block15.5x21 BiColor
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getAdditionalBlock(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  let idParam
  if (this.originPersonalizations[624]) {
    idBuhta = 624
    idParam = 93
  } else if (this.originPersonalizations[625]) {
    idBuhta = 625
    idParam = 94
  } else if (this.originPersonalizations[626]) {
    idBuhta = 626
    idParam = 95
  } else return []
  const idValue = modelValues?.additionalBlock?.id

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
  ])
}

/**
 * Выбор Дополнительного блока  M14
 */
function getAdditionalBlock(params) {
  const {
    store: { selectedBlock },
    oldModelValues,
    items,
    modelValues,
    originPersonalizations,
    originIdsAvailable,
  } = params

  if (originIdsAvailable.length === 0) {
    return
  }

  const idBuhta = originIdsAvailable[0]
  const idsParams = [93, 94, 95]

  let values = []

  const param = originPersonalizations[idBuhta]?.params.find((n) => idsParams.includes(n.id))

  if (param?.values?.length) {
    values = param.values
  }

  if (values.length > 0) {
    items.push({
      id: 'additionalBlock',
      tName: 'personalizations.additionalBlock.additionalBlock',
      template: 'selectClassic',
      sort: 10,
      values,
    })

    // Дефолтный блок берётся тот же что и основной
    let defaultIndex = values.findIndex((n) => n.blockId === selectedBlock.id)
    if (defaultIndex < 0) {
      defaultIndex = 0
    }

    if (oldModelValues.additionalBlock) {
      modelValues.additionalBlock =
        values.find((i) => i.blockId === oldModelValues.additionalBlock.blockId) ?? values[defaultIndex]
    } else {
      modelValues.additionalBlock = values[defaultIndex]
    }
  }
}
