<template>
<svg data-svg-lvl-2="spread-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
<PreviewLayersSpreadBlockShadowDPH :params="params" />
<PreviewLayersSpreadBlockSpreadDPH :params="params" />
<PreviewLayersSpreadBlockInsertsDPH :params="params" v-if="params.isInserts"/>
<PreviewLayersSpreadBlockContourDPH :params="params" />
<component :params="params" :is="`PreviewLayersSpreadBlockLasseRD`" v-if="params.isLasse" />
<component :params="params" :is="`PreviewLayersSpreadBlockLasseNameplate${params.lasseNameplateComponent}`" v-if="params.lasseNameplateComponent" />
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>