<template>
<svg data-svg-lvl-3="cover-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="bc-rg" cx="127.19" cy="26.32" r="13.56" gradientTransform="translate(0.29 11.71) rotate(1.25) scale(1 0.45)" gradientUnits="userSpaceOnUse"><stop offset="0.04" stop-color="#a3a3a3"/><stop offset="0.83" stop-color="#a3a3a3"/><stop offset="0.99" stop-color="#232728"/></radialGradient></defs><g id="Задник_Контур"><path class="bc-c-3" d="M392.24,16H123.7a10.58,10.58,0,0,0-7.47,3.1l-2.06,2.06a3.5,3.5,0,0,0-1,2.49c0,1.73,0,352,0,388.12a3,3,0,0,0,3,3H392.24a9.13,9.13,0,0,0,9.13-9.13V25.13A9.16,9.16,0,0,0,392.24,16Z"/><path class="bc-c-4" d="M114.54,25.88c.35-2,4.22-4,10-4.53l1.27-1.28c-6,.27-10.83,2.2-12.07,4.76A3.67,3.67,0,0,0,114.54,25.88Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}.bc-c-4{fill:url(#bc-rg);}</style>

