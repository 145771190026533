<template>
<svg data-svg-lvl-3="spread-back-corners" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bcr-lg" x1="-1407.35" y1="286.24" x2="-1407.35" y2="290.56" gradientTransform="matrix(-1, 0, 0, 1, -1332.73, 0)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-2" x1="-1393.86" y1="10301.44" x2="-1398.29" y2="10301.44" gradientTransform="translate(-1332.73 10578.42) rotate(180)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-3" x1="-1407.35" y1="10865.04" x2="-1407.35" y2="10860.72" gradientTransform="translate(-1332.73 11016.08) rotate(180)" href="#bcr-lg"/><linearGradient id="bcr-lg-4" x1="-1393.86" y1="-273.26" x2="-1398.29" y2="-273.26" gradientTransform="matrix(-1, 0, 0, 1, -1332.73, 437.66)" href="#bcr-lg-2"/></defs><g id="УголкиЗадник"><path class="bcr-c-3" d="M62.65,290.62H87.76L83.1,286H65.63l-4.17,4.17A1.68,1.68,0,0,0,62.65,290.62Z"/><path class="bcr-c-4" d="M65.63,286V268.48L61,263.82v25.12a1.71,1.71,0,0,0,.49,1.19Z"/><path class="bcr-c-5" d="M61,263.82l4.66,4.66V286H83.1l4.66,4.66H62.65A1.68,1.68,0,0,1,61,288.94V263.82m0-.75a.76.76,0,0,0-.75.75v25.12a2.43,2.43,0,0,0,2.43,2.43H87.76a.75.75,0,0,0,.7-.47.74.74,0,0,0-.17-.81l-4.65-4.66a.77.77,0,0,0-.54-.22H66.38V268.48a.75.75,0,0,0-.22-.53l-4.66-4.66a.75.75,0,0,0-.53-.22Z"/><line class="bcr-c-6" x1="65.98" y1="285.6" x2="61.11" y2="290.48"/><path class="bcr-c-7" d="M62.65,150.76H87.76l-4.66,4.66H65.63l-4.17-4.17A1.68,1.68,0,0,1,62.65,150.76Z"/><path class="bcr-c-8" d="M65.63,155.42v17.47L61,177.55V152.43a1.67,1.67,0,0,1,.49-1.18Z"/><path class="bcr-c-5" d="M87.76,150.76l-4.66,4.66H65.63v17.47L61,177.55V152.43a1.68,1.68,0,0,1,1.68-1.67H87.76m0-.75H62.65a2.43,2.43,0,0,0-2.43,2.42v25.12a.75.75,0,0,0,.46.7,1,1,0,0,0,.29.05.71.71,0,0,0,.53-.22l4.66-4.66a.75.75,0,0,0,.22-.53V156.17H83.1a.77.77,0,0,0,.54-.22l4.65-4.66a.75.75,0,0,0-.53-1.28Z"/><line class="bcr-c-6" x1="65.98" y1="155.77" x2="61.11" y2="150.9"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bcr-c-1{opacity:0.3;}.bcr-c-2{fill:#64ff00;}.bcr-c-3{fill:url(#bcr-lg);}.bcr-c-4{fill:url(#bcr-lg-2);}.bcr-c-5{fill:#232728;}.bcr-c-6{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:0.4px;}.bcr-c-7{fill:url(#bcr-lg-3);}.bcr-c-8{fill:url(#bcr-lg-4);}</style>

