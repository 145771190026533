<template>
<svg data-svg-lvl-3="spread-block-lasseNameplate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lln-ng_2" x1="284.31" y1="364.89" x2="273.05" y2="400.01" gradientUnits="userSpaceOnUse">
  <template v-if="params.lasse.nameplateColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset=".3" stop-color="#fbe6a6" />
  <stop offset=".83" stop-color="#6c4209" />
  <stop offset="1" stop-color="#efe9cd" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset="0.3" stop-color="#fff" />
  <stop offset="0.83" stop-color="#3c3e45" />
  <stop offset="1" stop-color="#979a9f" />
</template>
</linearGradient></defs><g id="Шильда_Контур"><path class="lln-c-3" d="M283.14,363.59l-1.39.08.17,2.71h.27a.46.46,0,0,1,.49.44l.05,1.16a.5.5,0,0,1-.48.53l-8.29.39a.48.48,0,0,1-.51-.46l-.06-1.13a.52.52,0,0,1,.49-.54h.29L274,364.1l-1.48.09a2,2,0,0,0-1.89,2.11l1.81,32a2,2,0,0,0,2.11,1.89l10.6-.6a2,2,0,0,0,1.88-2.11l-1.8-32A2,2,0,0,0,283.14,363.59Z"/><path class="lln-c-4" d="M277.85,381.29l-.22-3.84-4.75.27.11,1.92,2.57-.15.5,8.88,8.36-.47-.39-7Zm4.29,4.81-4,.22-.17-2.95,4-.23Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lln-c-1{opacity:0.3;}.lln-c-2{fill:#64ff00;}.lln-c-3{stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;fill:url(#lln-ng_2);}.lln-c-4{opacity:0.5;}</style>

