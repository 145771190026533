import lodash from 'lodash'
import { colors, colorsHex } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const zipper = {
  id: 'zipper',
  // tName:  'personalizations.zipper.zipper',
  template: 'modal',
  preview: 'cover',

  originPersonalizationsIds: [
    684, // Выбор цвета молнии
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getColor(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  const idBuhta = 684
  const idParam = 125
  const idValue = modelValues?.color?.id

  const items = getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
  ])

  const idBuhta2 = 716
  const idParam2 = 138
  const idValue2 = modelValues?.buttonColor?.id

  return items.concat(
    getItemsBuhtaByConfig(originPersonalizations, idBuhta2, [
      {
        idParam: idParam2,
        idValue: idValue2,
      },
    ]),
  )
}

/**
 * Выбор цвета молнии
 */
function getColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  const idBuhta = 684
  const idParam = 125

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: colors[n.id] ?? n.hex,
        hex: colorsHex[n.id] ?? n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.zipper.color',
      template: 'selectClassicColor',
      sort: 10,
      values,
    })

    // Дефолтный цвет молнии - серебро
    let defaultIndex = values.findIndex((n) => n.hex === '#989BA0')
    if (defaultIndex < 0) {
      defaultIndex = 0
    }
    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[defaultIndex]
    } else {
      modelValues.color = values[defaultIndex]
    }
  }
}
