<template>
<svg data-svg-lvl-3="cover-top-seamPerimeter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Прошивка_по_периметру_Контур" ><g class="tsp-c-3"><path class="tsp-c-4" d="M459.6,129.33V269a7.5,7.5,0,0,1-7.5,7.5l-390.21.2a7.5,7.5,0,0,1-7.51-7.5V129.33"/></g><g class="tsp-c-5"><line class="tsp-c-6" x1="459.8" y1="129.53" x2="459.8" y2="133.53"/><path class="tsp-c-7" d="M459.8,137.52V269.15a7.5,7.5,0,0,1-7.5,7.5l-390.21.2a7.5,7.5,0,0,1-7.51-7.5V135.52"/><line class="tsp-c-6" x1="54.58" y1="133.53" x2="54.58" y2="129.53"/></g><g class="tsp-c-8"><line class="tsp-c-9" x1="459.4" y1="129.13" x2="459.4" y2="133.13"/><path class="tsp-c-10" d="M459.4,137.12V268.75a7.5,7.5,0,0,1-7.5,7.5l-390.21.2a7.5,7.5,0,0,1-7.51-7.5V135.12"/><line class="tsp-c-9" x1="54.18" y1="133.13" x2="54.18" y2="129.13"/></g></g><g id="Прошивка_по_периметру_Цвет" ><line :style="{ stroke: params.seamColor }"  class="tsp-c-11" x1="459.6" y1="129.33" x2="459.6" y2="133.33"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-12" d="M459.6,137.32V269a7.5,7.5,0,0,1-7.5,7.5l-390.21.2a7.5,7.5,0,0,1-7.51-7.5V135.32"/><line :style="{ stroke: params.seamColor }"  class="tsp-c-11" x1="54.38" y1="133.33" x2="54.38" y2="129.33"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsp-c-1{opacity:0.3;}.tsp-c-2{fill:#64ff00;}.tsp-c-3{opacity:0.5;}.tsp-c-10,.tsp-c-11,.tsp-c-12,.tsp-c-4,.tsp-c-6,.tsp-c-7,.tsp-c-9{fill:none;stroke-linecap:round;stroke-miterlimit:2;}.tsp-c-4,.tsp-c-6,.tsp-c-7{stroke:#000;}.tsp-c-4{stroke-width:0.5px;}.tsp-c-5{opacity:0.6;}.tsp-c-10,.tsp-c-11,.tsp-c-12,.tsp-c-6,.tsp-c-7,.tsp-c-9{stroke-width:1.14px;}.tsp-c-10,.tsp-c-12,.tsp-c-7{stroke-dasharray:7.97 3.98;}.tsp-c-8{opacity:0.7;}.tsp-c-10,.tsp-c-9{stroke:#fff;}.tsp-c-11,.tsp-c-12{stroke:#fc620d;}</style>

