<template>
<svg data-svg-lvl-3="spread-block-shadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lsh-m" x="23.05" y="82" width="375" height="284" maskUnits="userSpaceOnUse"><path class="lsh-c-1" d="M210.38,107.13H26.88V334.87H364.44V107.13Z"/></mask></defs><g id="Тень_Блока"><g class="lsh-c-4"><g class="lsh-c-5"><image width="375" height="284" transform="translate(23.05 82)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/spread/block/shadow/DH17_1.webp`"/></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lsh-c-1{fill:#fff;}.lsh-c-2{opacity:0.3;}.lsh-c-3{fill:#64ff00;}.lsh-c-4{mask:url(#lsh-m);}.lsh-c-5{opacity:0.4;}</style>

