<template>
<svg data-svg-lvl-2="edge-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
<PreviewLayersEdgeBlockEdgeRDPV26 :params="params" />
<PreviewLayersEdgeBlockContourRDPV26 :params="params" />
<component :params="params" :is="`PreviewLayersEdgeBlockCaptal${params.captalComponent}`" v-if="params.captalComponent" />
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>