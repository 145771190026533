<template>
<svg data-svg-lvl-3="cover-back-corners" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bcr-lg" x1="383.72" y1="409.54" x2="383.72" y2="415.31" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-2" x1="401.7" y1="15982.21" x2="395.8" y2="15982.21" gradientTransform="matrix(1, 0, 0, -1, 0, 16379.41)" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-3" x1="383.72" y1="15.13" x2="383.72" y2="21.29" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#fbe6a6" />
  <stop offset="0.31" stop-color="#d4a94c" />
  <stop offset="0.61" stop-color="#e1bd69" />
  <stop offset="0.86" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".18" stop-color="#fff" />
  <stop offset=".31" stop-color="#989ba0" />
  <stop offset=".61" stop-color="#babdc4" />
  <stop offset=".86" stop-color="#3c3e45" />
</template>
</linearGradient><linearGradient id="bcr-lg-4" x1="401.7" y1="33.33" x2="395.8" y2="33.33" gradientUnits="userSpaceOnUse">
<template v-if="params.cornersColor === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.18" stop-color="#e0ce94" />
  <stop offset="0.31" stop-color="#b38e40" />
  <stop offset="0.32" stop-color="#b49043" />
  <stop offset="0.39" stop-color="#ba9953" />
  <stop offset="0.48" stop-color="#be9e5c" />
  <stop offset="0.59" stop-color="#bfa05f" />
  <stop offset="0.93" stop-color="#6c4209" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#83858a" />
  <stop offset=".18" stop-color="#e0e0e0" />
  <stop offset=".31" stop-color="#83858a" />
  <stop offset=".31" stop-color="#84868b" />
  <stop offset=".36" stop-color="#9fa2a8" />
  <stop offset=".4" stop-color="#b4b7be" />
  <stop offset=".45" stop-color="#c4c7ce" />
  <stop offset=".51" stop-color="#cdd0d8" />
  <stop offset=".59" stop-color="#d0d3db" />
  <stop offset=".93" stop-color="#3c3e45" />
</template>
</linearGradient></defs><g id="УголкиЗадник"><path class="bcr-c-3" d="M399.67,415.38H366.2l6.21-6.21h23.28l5.56,5.56A2.23,2.23,0,0,1,399.67,415.38Z"/><path class="bcr-c-4" d="M395.69,409.17V385.88l6.21-6.2v33.47a2.26,2.26,0,0,1-.65,1.58Z"/><path class="bcr-c-5" d="M401.9,379.68v33.47a2.23,2.23,0,0,1-2.23,2.23H366.2l6.21-6.21h23.28V385.88l6.21-6.2m-.8-.81-6.21,6.21-.34.33V408H371.93l-.33.33-6.21,6.21.81,1.95h33.47a3.37,3.37,0,0,0,3.37-3.37V379.68l-1.94-.81Z"/><line class="bcr-c-6" x1="395.69" y1="409.17" x2="401.25" y2="414.73"/><path class="bcr-c-7" d="M399.67,15.15H366.2l6.21,6.21h23.28l5.56-5.56A2.23,2.23,0,0,0,399.67,15.15Z"/><path class="bcr-c-8" d="M395.69,21.36V44.64l6.21,6.21V17.38a2.23,2.23,0,0,0-.65-1.58Z"/><path class="bcr-c-5" d="M399.67,15.15a2.23,2.23,0,0,1,2.23,2.23V50.85l-6.21-6.21V21.36H372.41l-6.21-6.21h33.47m0-1.15H366.2L365.39,16l6.21,6.21.33.34h22.62V45.11l.34.34,6.21,6.21,1.94-.81V17.38A3.38,3.38,0,0,0,399.67,14Z"/><line class="bcr-c-6" x1="395.69" y1="21.36" x2="401.25" y2="15.8"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bcr-c-1{opacity:0.3;}.bcr-c-2{fill:#64ff00;}.bcr-c-3{fill:url(#bcr-lg);}.bcr-c-4{fill:url(#bcr-lg-2);}.bcr-c-5{fill:#232728;}.bcr-c-6{fill:#f4e8ce;stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}.bcr-c-7{fill:url(#bcr-lg-3);}.bcr-c-8{fill:url(#bcr-lg-4);}</style>

