<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="tc-rg" cx="47.39" cy="130.61" r="438.04" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.2"/><stop offset="0.65" stop-color="#e5e5e5" stop-opacity="0"/></radialGradient><linearGradient id="tc-lg" x1="1450.09" y1="-9455.94" x2="1568.41" y2="-9455.94" gradientTransform="translate(-1252.25 9644.43)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.25"/><stop offset="0.13" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="tc-lg-2" x1="46.38" y1="206.99" x2="467.61" y2="206.99" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.4"/><stop offset="0.01" stop-color="#fff" stop-opacity="0"/></linearGradient></defs><g id="Крышки_Контур"><path class="tc-c-3" d="M467.61,269.65l0-140.32H46.38V269.65a15,15,0,0,0,15,15H452.61A15,15,0,0,0,467.61,269.65Z"/><rect class="tc-c-4" x="197.84" y="-22.12" width="118.33" height="421.23" transform="translate(445.5 -68.51) rotate(90)"/><path class="tc-c-5" d="M467.61,269.65l0-140.32H46.38V269.65a15,15,0,0,0,15,15H452.61A15,15,0,0,0,467.61,269.65Z"/><path class="tc-c-6" d="M467.62,269.65V129.33H46.38V269.65a15,15,0,0,0,15,15H452.62A15,15,0,0,0,467.62,269.65Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#64ff00;}.tc-c-3{fill:url(#tc-rg);}.tc-c-4{fill:url(#tc-lg);}.tc-c-5{fill:url(#tc-lg-2);}.tc-c-6{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

