<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M110.29,28.91S112.5,32,113.11,33s1.32,2,2.91,2H375.14a7.61,7.61,0,0,1,7.6,7.6V411a7.6,7.6,0,0,1-7.6,7.59H116c-1.59,0-2.29-1-2.91-2s-2.82-4.12-2.82-4.12"/><path class="tss-c-4" d="M110.09,28.71s2.21,3.12,2.82,4.12,1.32,2,2.91,2H374.94a7.61,7.61,0,0,1,7.6,7.6V410.81a7.6,7.6,0,0,1-7.6,7.59H115.82c-1.59,0-2.29-1-2.91-2s-2.82-4.12-2.82-4.12"/><path class="tss-c-5" d="M110.09,33.74s2.21,3.13,2.82,4.12,1.32,2,2.91,2l259.12,0a2.61,2.61,0,0,1,2.6,2.6V410.81a2.6,2.6,0,0,1-2.6,2.59H115.82c-1.59,0-2.29-1-2.91-2s-2.82-4.12-2.82-4.12"/><g class="tss-c-6"><path class="tss-c-7" d="M110.29,33.94s1.4,2,2.28,3.29"/><path class="tss-c-8" d="M115.31,40a3.17,3.17,0,0,0,.71.07l259.12,0a2.61,2.61,0,0,1,2.6,2.6V411a2.6,2.6,0,0,1-2.6,2.59H116a2.8,2.8,0,0,1-2.34-1.16"/><path class="tss-c-7" d="M112.57,410.78c-.88-1.31-2.28-3.29-2.28-3.29"/></g><g class="tss-c-9"><path class="tss-c-10" d="M109.89,33.54s1.4,2,2.28,3.29"/><path class="tss-c-11" d="M114.91,39.58a3.17,3.17,0,0,0,.71.07l259.12,0a2.61,2.61,0,0,1,2.6,2.6V410.61a2.6,2.6,0,0,1-2.6,2.59H115.62a2.8,2.8,0,0,1-2.34-1.16"/><path class="tss-c-10" d="M112.17,410.38c-.88-1.31-2.28-3.29-2.28-3.29"/></g></g><g id="Шов_Цвет"><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M110.09,33.74s1.4,2,2.28,3.29"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M115.11,39.78a3.17,3.17,0,0,0,.71.07l259.12,0a2.61,2.61,0,0,1,2.6,2.6V410.81a2.6,2.6,0,0,1-2.6,2.59H115.82a2.8,2.8,0,0,1-2.34-1.16"/><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M112.37,410.58c-.88-1.31-2.28-3.29-2.28-3.29"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-4,.tss-c-5,.tss-c-7,.tss-c-8{fill:none;stroke-miterlimit:2;}.tss-c-10,.tss-c-11,.tss-c-3{stroke:#fff;}.tss-c-3,.tss-c-4,.tss-c-5{stroke-width:0.46px;}.tss-c-12,.tss-c-13,.tss-c-4{stroke:#232728;}.tss-c-5,.tss-c-7,.tss-c-8{stroke:#000;}.tss-c-5{opacity:0.5;}.tss-c-6{opacity:0.6;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-7,.tss-c-8{stroke-linecap:round;stroke-width:1.14px;}.tss-c-11,.tss-c-13,.tss-c-8{stroke-dasharray:8 4;}.tss-c-9{opacity:0.7;}</style>

