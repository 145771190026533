<template>
<svg data-svg-lvl-3="cover-top-triangle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="tt-cp"><path class="tt-c-1" d="M464.57,268.59V129.33H49.43V268.59a13,13,0,0,0,13,13H451.57A13,13,0,0,0,464.57,268.59Z"/></clipPath></defs><g id="Крышка_Треугольники"><g class="tt-c-4"><image width="298" height="600" transform="translate(467.46 93.52) rotate(90) scale(0.7)" :href="`${$CONST.cnstrAssetsUrl}/others/H11V.webp`"/></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tt-c-1{fill:none;}.tt-c-2{opacity:0.3;}.tt-c-3{fill:#64ff00;}.tt-c-4{clip-path:url(#tt-cp);}</style>

