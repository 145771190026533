<template>
<svg data-svg-lvl-3="cover-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Задник_Контур"><path class="bc-c-3" d="M392.24,16H124.66a4.51,4.51,0,0,0-3.18,1.32l-8,8a1.09,1.09,0,0,0-.33.78c0,.56,0,387.55,0,387.55a1.11,1.11,0,0,0,1.1,1.1h278a9.13,9.13,0,0,0,9.13-9.13V25.13A9.16,9.16,0,0,0,392.24,16Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

