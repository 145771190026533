<template>
<svg data-svg-lvl-3="edge-top-base" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышки_Подложка"><path class="tb-c-3" d="M59.13,232.67a14.68,14.68,0,0,1-10.48,4.48c-8.38,0-15.19-7.25-15.19-16.15s6.81-16.15,15.19-16.15a14.66,14.66,0,0,1,10.21,4.2h2a16.21,16.21,0,0,0-12.21-5.65C39.47,203.4,32,211.29,32,221s7.47,17.6,16.65,17.6a16.21,16.21,0,0,0,12.44-5.93Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tb-c-1{opacity:0.3;}.tb-c-2{fill:#64ff00;}.tb-c-3{fill:#232728;}</style>

