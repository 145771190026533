import { render, staticRenderFns } from "./F1.vue?vue&type=template&id=24893a7e&scoped=true"
import script from "./F1.vue?vue&type=script&lang=js"
export * from "./F1.vue?vue&type=script&lang=js"
import style0 from "./F1.vue?vue&type=style&index=0&id=24893a7e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24893a7e",
  null
  
)

export default component.exports