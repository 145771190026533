import { getPrices } from '/src/utils/api/getPrices'

import lodash from 'lodash'

export default {
  namespaced: true,

  state() {
    return {
      constructors: [],
      busy: false,
    }
  },

  mutations: {
    SET_CONSTRUCTORS: (state, constructors) => {
      localStorage.setItem('constructors', JSON.stringify(constructors))
      state.constructors = constructors
    },
  },

  actions: {
    /** Извлекает список изделий конструктора из localStorage в vuex store */
    async INIT_CONSTRUCTORS({ state, commit, dispatch }, { api, CONST }) {
      state.busy = true
      const ordersLS = JSON.parse(localStorage.getItem('constructors')) ?? []
      const orders = []
      for (const item of ordersLS) {
        // todo удалить после 01.01.2025(изменилось количество превьюшек)
        if ('img' in item.info) {
          item.info.images = [item.info.img]
        }
        const order = await dispatch('GET_CONSTRUCTORS_PRICES', {
          quantity: item.query.quantity,
          item,
          api,
          CONST,
        })
        orders.push(order)
      }
      commit('SET_CONSTRUCTORS', orders)
      state.busy = false
    },

    /** Сохраняет изделие в конструктор */
    async ADD_UPDATE_CONSTRUCTORS({ commit, state }, order) {
      const orders = [...state.constructors]
      const idx = orders.findIndex((n) => n.query.orderId === order.query.orderId)
      // Новый заказ помещается наверх массива, старый остаётся на своём месте
      if (idx >= 0) {
        orders[idx] = order
      } else {
        orders.unshift(order)
      }
      commit('SET_CONSTRUCTORS', orders)
    },

    /** Удаляет изделие конструктора */
    DELETE_ITEM({ commit, state }, idx) {
      const orders = state.constructors.filter((item, index) => {
        return index !== idx
      })

      commit('SET_CONSTRUCTORS', orders)
    },

    /** Удаляет все изделия конструктора */
    CLEAR_CONSTRUCTORS({ commit }) {
      commit('SET_CONSTRUCTORS', [])
    },

    /** Изменяет количество изделий конструктора */
    async CHANGE_CONSTRUCTORS_QUANTITY({ commit, dispatch, state }, { quantity, item, api, CONST }) {
      state.busy = true
      const order = await dispatch('GET_CONSTRUCTORS_PRICES', {
        quantity,
        item,
        api,
        CONST,
      })
      const orders = [...state.constructors]
      const idx = orders.findIndex((n) => n.query.orderId === order.query.orderId)
      if (idx >= 0) {
        // item.info.quantity = quantity
        order.query.quantity = quantity
        order.info.items_in_cart = quantity
        orders[idx] = order

        commit('SET_CONSTRUCTORS', orders)
        state.busy = false
      }
    },

    /** Актуализирует цены на изделие конструктора */
    async GET_CONSTRUCTORS_PRICES(_emp, { quantity, item: oldItem, api, CONST }) {
      const item = lodash.cloneDeep(oldItem)
      const params = {
        model: item.query.model,
        block: item.query.block,
        material: item.info.forPrice.material,
        personalization: item.info.forPrice.personalization,
        quantity,
      }

      const prices = await getPrices(api, {
        params,
        CONST,
        selectedPersonalizationsBuhta: item.selectedPersonalizationsBuhta,
      })

      let persPriceSumm = 0
      for (const idGroup in prices.pricePersonalizations) {
        for (const idPers in prices.pricePersonalizations[idGroup]) {
          const found = item.info.personalizations.find((n) => n.id === `${idGroup}${idPers}`)
          found.price = prices.pricePersonalizations[idGroup][idPers]
          persPriceSumm += found.price
        }
      }

      item.info.discounts = prices.discount
      item.info.prices = {
        oneProduct: prices.price,
        one: prices.price + persPriceSumm,
        edition: (prices.price + persPriceSumm) * quantity,
        oneDiscount: prices.discount.price,
        editionDiscount: prices.discount.price * quantity,
      }
      return item
    },
  },

  getters: {
    CONSTRUCTORS: (state) => state.constructors,
    BUSY: (state) => state.busy,
  },
}
