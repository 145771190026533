<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tc-lg" x1="46.38" y1="206.99" x2="164.71" y2="206.99" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.3"/><stop offset="0.08" stop-color="#fff" stop-opacity="0"/></linearGradient></defs><g id="Крышки_Контур"><image width="422" height="156" transform="translate(46.3 128.77)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/top/contour/PH1Z_1.webp`"/><rect class="tc-c-3" x="46.38" y="129.33" width="118.33" height="155.32"/><image width="422" height="156" transform="translate(46.3 128.77)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/top/contour/PH1Z_2.webp`"/><rect class="tc-c-4" x="46.38" y="129.33" width="421.23" height="155.32"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#64ff00;}.tc-c-3{fill:url(#tc-lg);}.tc-c-4{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

