import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const pocketMaterials = {
  id: 'pocketMaterials',
  // tName:  'personalizations.pocketMaterials.pocketMaterials',
  template: 'modal',
  preview: 'cover',
  hidden: true,

  originPersonalizationsIds: [
    640, // Выбор материала КАРМАНЫ
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig() {
  return {
    modelValues: {},
    items: [],
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ selectedMaterials }) {
  const originPersonalizations = this.originPersonalizations
  const idBuhta = 640
  const idParam = 104
  let idValue
  const idParamAdd = 105
  let idValueAdd
  // добавление материала Карманы - Лицо
  const color = selectedMaterials[2]?.color
  const texture = selectedMaterials[2]?.texture
  if (color && texture) {
    const foundParam = originPersonalizations[idBuhta]?.params?.find((i) => i.id === idParamAdd)
    const foundValue = foundParam?.values?.find((i) => i.colorId === color && i.textureId === texture)
    if (foundValue?.id) {
      idValue = foundValue.id
    }
  }
  // добавление материала Карманы - Оборот
  const colorAdd = selectedMaterials[3]?.color
  const textureAdd = selectedMaterials[3]?.texture
  if (colorAdd && textureAdd) {
    const foundParam = originPersonalizations[idBuhta]?.params?.find((i) => i.id === idParamAdd)
    const foundValue = foundParam?.values?.find((i) => i.colorId === colorAdd && i.textureId === textureAdd)
    if (foundValue?.id) {
      idValueAdd = foundValue.id
    }
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
    {
      idParam: idParamAdd,
      idValue: idValueAdd,
    },
  ])
}
