<template>
  <input
    type="number"
    :value="value"
    :min="min"
    :max="max"
    :placeholder="placeholder"
    @input="input($event)"
    @change="change($event)"
  />
</template>

<script>
import lodash from 'lodash'

export default {
  props: {
    id: {
      type: [Number, String],
      default: Math.round(Date.now() * Math.random()),
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    value: {
      type: [Number, String],
      default: () => '',
    },
    min: {
      type: Number,
      default: () => 0,
    },
    max: {
      type: Number,
      default: () => 100000,
    },
    wait: {
      type: Number,
      default: () => 2,
    },
  },
  data() {
    return {
      debounceInput: () => true,
      needToEmitNewValue: false,
    }
  },
  created() {
    this.debounceInput = lodash.debounce(this.emitNewValue, this.wait * 1000, {
      leading: false,
    })
  },
  methods: {
    input($event) {
      this.needToEmitNewValue = true
      const value = Number($event.target.value)
      this.errorMsg = ''
      if (value < this.min) this.$emit('errorMin')
      if (value > this.max) this.$emit('errorMax')
      this.$emit('tempInput', value)
      this.debounceInput($event)
    },

    change($event) {
      this.emitNewValue($event)
    },

    emitNewValue($event) {
      if (this.needToEmitNewValue === false) {
        return
      }
      let value = Number($event.target.value)
      if (value < this.min) value = this.min
      if (value > this.max) value = this.max
      $event.target.value = value
      this.$emit('input', value)
      this.needToEmitNewValue = false
    },
  },
}
</script>

<style lang="sass" scoped>
input
  border: 1px solid $stroke-color
  padding: 3.5px 10px
  font-size: 14px
  transition: ease 0.3s
  position: relative
  display: flex
  align-items: center
  -moz-appearance: textfield
  background-color: $white
</style>
