<template>
<svg data-svg-lvl-3="edge-block-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Контур"><rect class="lc-c-3" x="53.61" y="210.76" width="319.04" height="1.39"/><rect class="lc-c-3" x="53.79" y="230.23" width="319.04" height="1.39"/><rect class="lc-c-3" x="54.03" y="228.83" width="319.04" height="1.39"/><rect class="lc-c-3" x="53.79" y="227.44" width="319.04" height="1.39"/><rect class="lc-c-3" x="53.93" y="226.05" width="319.04" height="1.39"/><rect class="lc-c-3" x="54.01" y="224.66" width="319.04" height="1.39"/><rect class="lc-c-3" x="53.79" y="223.27" width="319.04" height="1.39"/><rect class="lc-c-3" x="53.89" y="221.88" width="319.04" height="1.39"/><rect class="lc-c-3" x="53.79" y="220.49" width="319.04" height="1.39"/><rect class="lc-c-3" x="53.79" y="219.1" width="319.04" height="1.39"/><rect class="lc-c-3" x="53.87" y="217.71" width="319.04" height="1.39"/><rect class="lc-c-3" x="53.99" y="216.32" width="319.04" height="1.39"/><rect class="lc-c-3" x="53.79" y="212.15" width="319.04" height="1.39"/><rect class="lc-c-3" x="53.79" y="213.54" width="319.04" height="1.39"/><rect class="lc-c-3" x="53.79" y="214.93" width="319.04" height="1.39"/><polyline class="lc-c-4" points="372.65 210.75 372.65 212.15 372.82 212.15 372.82 216.32 373.02 216.32 373.02 217.71 372.9 217.71 372.9 219.1 372.82 219.1 372.82 220.49 372.82 220.49 372.82 221.88 372.93 221.88 372.93 223.27 372.82 223.27 372.82 224.66 373.04 224.66 373.04 226.05 372.96 226.05 372.96 227.44 372.82 227.44 372.82 228.83 373.06 228.83 373.06 230.22 372.82 230.22 372.82 231.62"/><polyline class="lc-c-4" points="53.61 210.75 53.61 212.15 53.78 212.15 53.78 216.32 53.99 216.32 53.99 217.71 53.87 217.71 53.87 219.1 53.78 219.1 53.78 220.49 53.78 220.49 53.78 221.88 53.89 221.88 53.89 223.27 53.78 223.27 53.78 224.66 54.01 224.66 54.01 226.05 53.93 226.05 53.93 227.44 53.78 227.44 53.78 228.83 54.03 228.83 54.03 230.22 53.78 230.22 53.78 231.62"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lc-c-1{opacity:0.3;}.lc-c-2{fill:#64ff00;}.lc-c-3,.lc-c-4{fill:none;stroke-miterlimit:2;}.lc-c-3{stroke:#707d7f;stroke-width:0.2px;}.lc-c-4{stroke:#232728;stroke-width:0.5px;}</style>

