<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="tc-rg" cx="378.31" cy="37.08" r="465.41" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.2"/><stop offset="0.44" stop-color="#e5e5e5" stop-opacity="0"/></radialGradient><linearGradient id="tc-lg" x1="113.12" y1="226.61" x2="229.42" y2="226.61" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.4"/><stop offset="0.13" stop-color="#fff" stop-opacity="0"/></linearGradient></defs><g id="Крышки_Контур"><path class="tc-c-3" d="M381,27.22H113.13V426H381a9.16,9.16,0,0,0,9.13-9.13V36.35A9.16,9.16,0,0,0,381,27.22Z"/><path class="tc-c-4" d="M113.12,27.22H229.42a0,0,0,0,1,0,0V426a0,0,0,0,1,0,0H116.67a3.55,3.55,0,0,1-3.55-3.55V27.22A0,0,0,0,1,113.12,27.22Z"/><path class="tc-c-5" d="M390.14,36.35A9.13,9.13,0,0,0,381,27.22H116.6a3.57,3.57,0,0,1-3.47-3.63V422.44a3.55,3.55,0,0,0,3.55,3.55H381a9.16,9.16,0,0,0,9.13-9.13Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#64ff00;}.tc-c-3{fill:url(#tc-rg);}.tc-c-4{fill:url(#tc-lg);}.tc-c-5{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

