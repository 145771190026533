<template>
<svg data-svg-lvl-3="cover-block-shadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lsh-m" x="11" y="93" width="493" height="231.16" maskUnits="userSpaceOnUse"><rect class="lsh-c-1" x="42.66" y="129.44" width="428.78" height="194.72"/></mask></defs><g id="Тень_Блока"><g class="lsh-c-4"><g class="lsh-c-2"><image width="493" height="223" transform="translate(11 93)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/block/shadow/DPV_1.webp`"/></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lsh-c-1{fill:#fff;}.lsh-c-2{opacity:0.3;}.lsh-c-3{fill:#64ff00;}.lsh-c-4{mask:url(#lsh-m);}</style>

