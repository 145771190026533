<template>
<svg data-svg-lvl-3="cover-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Задник_Контур"><path class="bc-c-3" d="M357.5,16H124.66a4.47,4.47,0,0,0-3.18,1.32l-8,8a1.1,1.1,0,0,0-.33.79c0,.56,0,387.54,0,387.54a1.1,1.1,0,0,0,1.1,1.1h278a9.13,9.13,0,0,0,9.13-9.13V59.89Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#ff931e;}.bc-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

