/** определение текущего шага из url */
export default {
  computed: {
    currentStep() {
      let currentStep = this.$route?.query?.step
      if (!['product', 'model', 'block', 'materials', 'personalizations', 'orders', 'dev'].includes(currentStep)) {
        currentStep = 'product'
      }

      return currentStep
    },
  },
}
