<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="bc-cp"><path class="bc-c-1" d="M450.43,204.49h-387V68.57a5.5,5.5,0,0,1,5.5-5.5h376a5.5,5.5,0,0,1,5.5,5.5Z"/></clipPath><clipPath id="bc-cp-2"><rect class="bc-c-1" x="63.44" y="34.67" width="386.93" height="386.93"/></clipPath><linearGradient id="bc-lg" x1="-11044.52" y1="-10450.82" x2="-11041.52" y2="-10450.82" gradientTransform="matrix(1, 0, 0, -1, 11300.02, -10211.87)" gradientUnits="userSpaceOnUse"><stop offset="0"/><stop offset="1" stop-opacity="0"/></linearGradient></defs><g id="Задник_Контур"><rect class="bc-c-1" x="61.41" y="60.67" width="391.19" height="287.69" rx="7.5"/><path class="bc-c-4" d="M450.43,204.49h-387V68.57a5.5,5.5,0,0,1,5.5-5.5h376a5.5,5.5,0,0,1,5.5,5.5Z"/><g class="bc-c-5"><rect class="bc-c-4" x="63.44" y="34.67" width="386.93" height="386.93"/><g class="bc-c-6"><image width="1024" height="1024" transform="translate(63.44 39.07) scale(0.38)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/spread/back/contour/PH2L_1.webp`"/></g><rect class="bc-c-7" x="63.44" y="34.67" width="386.93" height="386.93"/></g><path class="bc-c-7" d="M450.43,204.49h-387V68.57a5.5,5.5,0,0,1,5.5-5.5h376a5.5,5.5,0,0,1,5.5,5.5Z"/><rect class="bc-c-8" x="255.5" y="43.36" width="3" height="391.19" transform="translate(18.05 495.95) rotate(-90)"/><line class="bc-c-7" x1="452.59" y1="240.45" x2="61.41" y2="240.45"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1,.bc-c-4{fill:gray;}.bc-c-1,.bc-c-7{stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.bc-c-2,.bc-c-8{opacity:0.3;}.bc-c-3{fill:#64ff00;}.bc-c-5{clip-path:url(#bc-cp);}.bc-c-6{clip-path:url(#bc-cp-2);}.bc-c-7{fill:none;}.bc-c-8{fill:url(#bc-lg);}</style>

