<template>
<svg data-svg-lvl-3="spread-block-spread" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="ls-cp"><polygon class="ls-c-1" points="446.61 147.83 256.74 147.83 66.88 147.83 66.88 286.74 256.74 286.74 446.61 286.74 446.61 147.83"/></clipPath></defs><g id="Разворот"><g class="ls-c-4"> <polygon :style="{ fill: params.slice.color }"  class="ls-c-1" points="446.61 147.83 256.74 147.83 66.88 147.83 66.88 286.74 256.74 286.74 446.61 286.74 446.61 147.83"/> <image width="1200" height="440" transform="translate(65.93 147.23) scale(0.32)" :href="params.blockImage"  v-show="params.blockImage" preserveAspectRatio="xMidYMid slice" /></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.ls-c-1{fill:none;}.ls-c-2{opacity:0.3;}.ls-c-3{fill:#64ff00;}.ls-c-4{clip-path:url(#ls-cp);}</style>

