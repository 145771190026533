<template>
<svg data-svg-lvl-3="flyleaffront-block-pocket" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lp-lg" x1="69.36" y1="318.73" x2="156.9" y2="318.73" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#333"/><stop offset="1" stop-color="#b2b2b2" stop-opacity="0.5"/></linearGradient><linearGradient id="lp-lg-2" x1="113.12" y1="301.5" x2="113.12" y2="347.19" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.05"/><stop offset="1" stop-opacity="0.3"/></linearGradient><clipPath id="lp-cp"><path class="lp-c-1" d="M69.36,289.55H156.9a0,0,0,0,1,0,0V344.3a3.62,3.62,0,0,1-3.62,3.62H73a3.62,3.62,0,0,1-3.62-3.62V289.55A0,0,0,0,1,69.36,289.55Z"/></clipPath></defs><g id="КонвертКонтур_Пластиковый_Карман" ><g class="lp-c-4"><path class="lp-c-5" d="M69.36,289.55H156.9a0,0,0,0,1,0,0V344.3a3.62,3.62,0,0,1-3.62,3.62H73a3.62,3.62,0,0,1-3.62-3.62V289.55A0,0,0,0,1,69.36,289.55Z"/><path class="lp-c-6" d="M69.36,300.94H156.9a0,0,0,0,1,0,0V344.3a3.62,3.62,0,0,1-3.62,3.62H73a3.62,3.62,0,0,1-3.62-3.62V300.94A0,0,0,0,1,69.36,300.94Z"/><path class="lp-c-7" d="M153.24,347.49H73a3.19,3.19,0,0,1-3.19-3.18V300.94h.58v43.37a2.6,2.6,0,0,0,2.61,2.6h80.24a2.6,2.6,0,0,0,2.6-2.6V300.94h.58v43.37A3.19,3.19,0,0,1,153.24,347.49Z"/></g><g class="lp-c-4"><g class="lp-c-8"><g class="lp-c-9"><image width="276" height="396" transform="translate(53.5 215.12) scale(0.43 0.43)" :href="`${$CONST.cnstrAssetsUrl}/others/pocket.webp`"/></g></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lp-c-1{fill:none;}.lp-c-2{opacity:0.3;}.lp-c-3{fill:#64ff00;}.lp-c-4{opacity:0.8;}.lp-c-5,.lp-c-9{opacity:0.5;}.lp-c-5{fill:url(#lp-lg);}.lp-c-6{fill:#828282;opacity:0.4;}.lp-c-7{fill:url(#lp-lg-2);}.lp-c-8{clip-path:url(#lp-cp);}</style>

