<template>
<svg data-svg-lvl-5="cover-top-draggableUpOrDown-draggable-printing" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.11 76.47" preserveAspectRatio="xMidYMid slice" :x="draggable.x" :y="draggable.y" :width="draggable.w" :height="draggable.h"><defs><linearGradient :id="`tpr-ng_1_${draggable.id.pers}_${draggable.id.idx}`" x1="38.55" y1="2.58" x2="38.55" y2="75.19" gradientUnits="userSpaceOnUse">
<template v-if="!draggable.gradient">
  <stop :stop-color="draggable.color" />
</template>
<template v-else-if="draggable.color === '#840808'">
  <!-- Красный -->
  <stop offset="0" stop-color="#840808" />
  <stop offset=".3" stop-color="#ff1711" />
  <stop offset=".83" stop-color="#6a0404" />
  <stop offset="1" stop-color="#ff6d66" />
</template>
<template v-else-if="draggable.color === '#048B1B'">
  <!-- Зелёный -->
  <stop offset="0" stop-color="#048b1b" />
  <stop offset=".3" stop-color="#4cf664" />
  <stop offset=".64" stop-color="#113a17" />
  <stop offset="1" stop-color="#4cf664" />
</template>
<template v-else-if="draggable.color === '#1A1D97'">
  <!-- Синий -->
  <stop offset=".03" stop-color="#1a1d97" />
  <stop offset=".3" stop-color="#9bc7ff" />
  <stop offset=".64" stop-color="#1f2986" />
  <stop offset="1" stop-color="#818feb" />
</template>
<template v-else-if="draggable.color === '#9C6B00'">
  <!--  Золото -->
  <stop offset="0" stop-color="#9c6b00" />
  <stop offset="0.3" stop-color="#fbe6a6" />
  <stop offset="0.83" stop-color="#6c4209" />
  <stop offset="1" stop-color="#efe9cd" />
</template>
<template v-else>
  <!-- Серебро -->
  <stop offset="0" stop-color="#989ba0" />
  <stop offset=".3" stop-color="#fff" />
  <stop offset=".83" stop-color="#3c3e45" />
  <stop offset="1" stop-color="#979a9f" />
</template>
  </linearGradient></defs><g id="Тиснение_Цвет" :style="{ opacity: draggable.opacity }" ><path    :style="`fill: url(#tpr-ng_1_${draggable.id.pers}_${draggable.id.idx});`" d="M33.76,27.81l0-25.71H2V15H19.14v59.4h56V27.81Zm26.8,33.7H33.74V41.72H60.56Z"/></g><g id="Тиснение_Свет_Тень" ><g class="tpr-c-2"><polygon points="33.24 42.22 33.24 62.01 60.06 62.01 60.06 61.51 33.74 61.51 33.74 42.22 33.24 42.22"/><polygon points="33.76 27.82 33.74 2.1 1.97 2.1 1.97 2.6 33.24 2.6 33.26 28.32 74.64 28.32 74.64 74.37 75.14 74.37 75.14 27.82 33.76 27.82"/></g><g class="tpr-c-2"><polygon class="tpr-c-3" points="19.63 73.87 19.64 14.46 2.47 14.46 2.47 2.1 1.97 2.1 1.97 14.96 19.14 14.96 19.13 74.37 75.14 74.37 75.14 73.87 19.63 73.87"/><polygon class="tpr-c-3" points="61.06 61.01 61.06 41.22 34.24 41.22 34.24 41.72 60.56 41.72 60.56 61.01 61.06 61.01"/></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, draggable: { type: Object, required: true }, }, data() { return {} },}
</script>

<style scoped>.tpr-c-1{fill:url(#tpr-ng_1);}.tpr-c-2{opacity:0.5;}.tpr-c-3{fill:#fff;}</style>

