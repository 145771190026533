<template>
<svg data-svg-lvl-3="cover-top-spine" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="ts-lg" x1="96.76" y1="73" x2="113.12" y2="73" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#919191"/><stop offset="0.21" stop-color="#c2c2c2"/><stop offset="0.45" stop-color="#919191"/><stop offset="0.8" stop-color="#525252"/><stop offset="1" stop-color="#919191"/></linearGradient><linearGradient id="ts-lg-2" x1="98" y1="201.99" x2="111.89" y2="201.99" href="#ts-lg"/><clipPath id="ts-cp"><rect class="ts-c-1" x="96.17" y="55.66" width="30.17" height="41.5"/></clipPath><clipPath id="ts-cp-2"><rect class="ts-c-1" x="82.53" y="41.31" width="52.15" height="74.1"/></clipPath><linearGradient id="ts-lg-3" x1="96.21" y1="76.41" x2="126.37" y2="76.41" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.1"/><stop offset="0.11" stop-opacity="0.2"/><stop offset="0.24" stop-opacity="0.2"/><stop offset="0.35" stop-opacity="0.09"/><stop offset="1" stop-opacity="0"/></linearGradient></defs><g id="Спайн_Подложка"><path class="ts-c-4" d="M99.24,25.25h12.12s1.76,19.21,1.76,95.51H96.76C96.76,37,99.24,25.25,99.24,25.25Z"/><path class="ts-c-5" d="M98,120.76v122c0,20,4.16,40.52,4.16,40.52h5.57a222.67,222.67,0,0,0,4.16-40.52V120.76Z"/><path class="ts-c-6" d="M98.8,28.94,97.65,50.56S92.2,55.78,92.2,68.15s1.61,27.4,2.66,31.2a6.83,6.83,0,0,1-1.52,6.38s-4-25.21-4-37.2S98.8,28.94,98.8,28.94Z"/></g><g id="Спайн_Материал"><g class="ts-c-7"><g class="ts-c-8"><image width="57" height="81" transform="translate(82.53 41.31) scale(0.91)"   v-show="params.spineImage" :href="params.spineImage"/></g></g></g><g id="Спайн_Контур"><rect class="ts-c-9" x="96.17" y="55.66" width="30.17" height="41.5"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.ts-c-1{fill:none;}.ts-c-2{opacity:0.3;}.ts-c-3{fill:#64ff00;}.ts-c-4,.ts-c-5,.ts-c-6,.ts-c-9{stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}.ts-c-4{fill:url(#ts-lg);}.ts-c-5{fill:url(#ts-lg-2);}.ts-c-6{fill:#727272;}.ts-c-7{clip-path:url(#ts-cp);}.ts-c-8{clip-path:url(#ts-cp-2);}.ts-c-9{fill:url(#ts-lg-3);}</style>

