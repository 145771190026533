<template>
<svg data-svg-lvl-3="flyleaffront-block-flyleaffront" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lff-m-f" x="0" y="0" width="500" height="430" fill="#ffffff"><path  d="M420.77,86.62H269.14c-4.7,0-12.4,1.62-12.4,6v1.27H85.86l-19,19V352.73H256.94c0-2.56,5.57-4.64,12.39-4.64H446.61V112.46Z"/></mask></defs><g id="Форзац"> <path  :style="{ fill: params.flyleaffrontColor }"  class="lff-c-3" d="M420.77,86.62H269.14c-4.7,0-12.4,1.62-12.4,6v1.27H85.86l-19,19V352.73H256.94c0-2.56,5.57-4.64,12.39-4.64H446.61V112.46Z"/> <image v-if="params.flyleaffrontImage" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.flyleaffrontImage" mask="url(#lff-m-f)" /> </g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lff-c-1{opacity:0.3;}.lff-c-2{fill:#ff931e;}.lff-c-3{fill:#f4e8ce;}</style>

