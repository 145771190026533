export default {
  namespaced: true,

  state() {
    return {
      offsetTop: 0,
      width: 1920,
    }
  },
  mutations: {
    SET_OFFSET_TOP(state, payload) {
      state.offsetTop = payload
    },
    SET_WIDTH(state, payload) {
      state.width = payload
    },
  },

  actions: {
    SET_OFFSET_TOP({ commit }) {
      commit('SET_OFFSET_TOP', window.scrollY)
    },
    SET_WIDTH({ commit }) {
      commit('SET_WIDTH', window.innerWidth)
    },
  },

  getters: {
    OFFSET_TOP: (state) => state.offsetTop,
    WIDTH: (state) => state.width,
    /**        xs <  360// нет в figme
     *  360 <= sm <  780
     *  780 <= md < 1020
     * 1020 <= lg < 1200 // нет в figme
     * 1200 <= xl < 1600
     * 1600 <= xxl    */
    BP: (state) => {
      return {
        xsOnly: state.width < 360,
        smAndUp: state.width >= 360,

        smOnly: state.width >= 360 && state.width < 780,

        smAndDown: state.width < 780,
        mdAndUp: state.width >= 780,

        mdOnly: state.width >= 780 && state.width < 1020,

        mdAndDown: state.width < 1020,
        lgAndUp: state.width >= 1020,

        lgOnly: state.width >= 1020 && state.width < 1200,

        lgAndDown: state.width < 1200,
        xlAndUp: state.width >= 1200,

        xlOnly: state.width >= 1200 && state.width < 1600,

        xlAndDown: state.width < 1600,
        xxlOnly: state.width >= 1600,
      }
    },
  },
}
