<template>
<svg data-svg-lvl-3="edge-block-edge" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs>
      <mask id="le-m-si" x="0" y="0" width="500" height="430" fill="#ffffff"><polygon  points="462.58 206.55 462.58 204.75 462.73 204.75 462.73 202.94 462.98 202.94 462.98 201.14 48.03 201.14 48.03 202.94 47.77 202.94 47.77 204.75 47.52 204.75 47.52 206.55 47.13 206.55 47.13 208.36 46.88 208.36 46.88 210.17 47.13 210.17 47.13 211.97 46.91 211.97 46.91 213.78 46.84 213.78 46.84 215.58 46.99 215.58 46.99 217.39 46.99 219.19 46.99 221 46.99 222.81 46.99 224.61 46.99 226.42 47.35 226.42 47.35 228.22 47.35 230.03 47.35 231.84 47.55 231.84 47.55 233.64 47.77 233.64 47.77 235.45 48.21 235.45 48.21 237.25 48.46 237.25 48.46 239.06 48.78 239.06 48.78 240.86 462.98 240.86 462.98 239.06 462.78 239.06 462.78 237.25 462.59 237.25 462.59 235.45 462.3 235.45 462.3 233.64 462.3 233.64 462.3 231.84 462.13 231.84 462.13 230.03 462.3 230.03 462.3 228.22 462.13 228.22 462.13 226.42 461.97 226.42 461.97 222.81 461.86 222.81 461.86 219.19 461.72 219.19 461.72 215.58 461.86 215.58 461.86 213.78 462.05 213.78 462.05 211.97 462.27 211.97 462.27 210.17 462.42 210.17 462.42 208.36 462.42 208.36 462.42 206.55 462.58 206.55"/></mask>
  <linearGradient id="lg-silver" x1="45.52" x2="459.83"  y1="197.49" y2="197.49" gradientUnits="userSpaceOnUse" >
    <stop offset="0" stop-color="#989ba0" />
    <stop offset=".3" stop-color="#fff" /><stop offset=".83" stop-color="#3c3e45" /><stop offset="1" stop-color="#979a9f" />
  </linearGradient>
  <linearGradient id="lg-gold" x1="45.52" x2="459.83"  y1="197.49" y2="197.49" gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#9c6b00" /><stop offset=".3" stop-color="#fbe6a6" />
    <stop offset=".83" stop-color="#6c4209" /><stop offset="1" stop-color="#efe9cd" />
  </linearGradient></defs><g id="Обрез"><polygon :style="{ fill: params.slice.color === '#9C6B00' ? 'url(#lg-gold)' : params.slice.color === '#989BA0' ? 'url(#lg-silver)' : params.slice.color }"  class="le-c-3" points="462.58 206.55 462.58 204.75 462.73 204.75 462.73 202.94 462.98 202.94 462.98 201.14 48.03 201.14 48.03 202.94 47.77 202.94 47.77 204.75 47.52 204.75 47.52 206.55 47.13 206.55 47.13 208.36 46.88 208.36 46.88 210.17 47.13 210.17 47.13 211.97 46.91 211.97 46.91 213.78 46.84 213.78 46.84 215.58 46.99 215.58 46.99 217.39 46.99 219.19 46.99 221 46.99 222.81 46.99 224.61 46.99 226.42 47.35 226.42 47.35 228.22 47.35 230.03 47.35 231.84 47.55 231.84 47.55 233.64 47.77 233.64 47.77 235.45 48.21 235.45 48.21 237.25 48.46 237.25 48.46 239.06 48.78 239.06 48.78 240.86 462.98 240.86 462.98 239.06 462.78 239.06 462.78 237.25 462.59 237.25 462.59 235.45 462.3 235.45 462.3 233.64 462.3 233.64 462.3 231.84 462.13 231.84 462.13 230.03 462.3 230.03 462.3 228.22 462.13 228.22 462.13 226.42 461.97 226.42 461.97 222.81 461.86 222.81 461.86 219.19 461.72 219.19 461.72 215.58 461.86 215.58 461.86 213.78 462.05 213.78 462.05 211.97 462.27 211.97 462.27 210.17 462.42 210.17 462.42 208.36 462.42 208.36 462.42 206.55 462.58 206.55"/> <image v-if="params.slice.image" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.slice.image" mask="url(#le-m-si)" /></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.le-c-1{opacity:0.3;}.le-c-2{fill:#64ff00;}.le-c-3{fill:#f4e8ce;}</style>

