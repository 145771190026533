import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const inserts = {
  id: 'inserts',
  // tName:  'personalizations.inserts.inserts',
  template: 'modal',
  preview: 'spread',
  multiple: true,

  originPersonalizationsIds: [
    // 4/0,
    128, // Печать и вклейка рекламных листов формата 9x13, 4/0, 2 полосы (1лист)
    252, // Печать и вклейка рекламных листов формата 10х16,10.5х16,13х21,15х21,15.5х21 4/0, 2 полосы (1лист)
    255, // Печать и вклейка рекламных листов формата 20х26 и планинги, 4/0, 2 полосы (1 лист)

    // 4/4
    129, // Печать и вклейка рекламных листов формата 9x13, 4/4, 2 полосы (1лист)
    253, // Печать и вклейка рекламных листов формата 10х16,10.5х16,13х21,15х21,15.5х21 4/4, 2 полосы (1лист)
    256, // Печать и вклейка рекламных листов формата 20х26 и планинги, 4/4, 2 полосы (1 лист)

    // 4/4   4 полосы (2листа)
    130, // // 4/4 Печать и вклейка рекламных листов формата 9x13, 4/4, 4 полосы (2листа)
    254, // Печать и вклейка рекламных листов формата 10x16,10.5х16,13х21,15х21,15.2х21 4/4, 4 полосы (2листа)
    257, // Печать и вклейка рекламных листов формата 20х26 и планинги, 4/4, 4 полосы (2 листа)
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getInserts(params)
  getQuantity(params)
  getPosition(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  const idParam = 90
  const idValue = modelValues?.position?.id

  const key = modelValues?.inserts?.id
  const keyQuantity = modelValues?.quantity?.id
  if (key) {
    let selectedPers = []
    if (key === 'zero') {
      selectedPers = [128, 252, 255]
    } else if (key === 'four') {
      if (keyQuantity === 'one') {
        selectedPers = [129, 253, 256]
      } else if (keyQuantity === 'two') {
        selectedPers = [130, 254, 257]
      }
    }
    const idsBuhta = lodash.intersection(this.originIdsAvailable, selectedPers)
    idBuhta = idsBuhta[0]
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
  ])
}

/**
 * Выбор вклеек
 */
function getInserts(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  const values = []
  const personals = {
    zero: [128, 252, 255],
    four: [129, 130, 253, 254, 256, 257],
  }

  for (const key in personals) {
    if (lodash.intersection(originIdsAvailable, personals[key]).length > 0) {
      values.push({
        id: key,
        tName: `personalizations.inserts.${key}`,
        img: `${cnstrAssetsUrl}/personalizations/inserts/${key}.webp`,
      })
    }
  }
  if (values.length > 0) {
    items.push({
      id: 'inserts',
      tName: 'personalizations.inserts.inserts',
      template: 'selectImageText',
      sort: 10,
      values,
    })

    if (oldModelValues.inserts) {
      modelValues.inserts = values.find((i) => i.id === oldModelValues.inserts.id) ?? values[0]
    } else {
      modelValues.inserts = values[0]
    }
  }
}

/**
 * Выбор количества листов
 */
function getQuantity(params) {
  const { oldModelValues, items, modelValues, originIdsAvailable } = params

  let personals

  if (modelValues.inserts.id === 'zero') {
    personals = {
      one: [128, 252, 255],
    }
  } else if (modelValues.inserts.id === 'four') {
    personals = {
      one: [129, 253, 256],
      two: [130, 254, 257],
    }
  } else {
    return
  }

  const values = []
  for (const key in personals) {
    const found = lodash.intersection(originIdsAvailable, personals[key])
    if (found.length > 0) {
      values.push({
        id: key,
        tName: `personalizations.inserts.${key}`,
        idBuhta: found[0],
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'quantity',
      tName: 'personalizations.inserts.quantity',
      template: 'selectRadio',
      sort: 20,
      values,
    })

    if (oldModelValues.quantity) {
      modelValues.quantity = values.find((i) => i.id === oldModelValues.quantity.id) ?? values[0]
    } else {
      modelValues.quantity = values[0]
    }
  }
}

/**
 *  Положение листов
 */
function getPosition(params) {
  const { oldModelValues, items, modelValues, originPersonalizations, originIdsAvailable } = params

  if (originIdsAvailable.length === 0) {
    return
  }

  const idBuhta = modelValues.quantity?.idBuhta
  const idParam = 90
  let values = []
  if (originPersonalizations[idBuhta]?.params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'position',
      tName: 'personalizations.inserts.position',
      template: 'selectClassic',
      sort: 30,
      values,
    })

    if (oldModelValues.position) {
      modelValues.position = values.find((i) => i.id === oldModelValues.position.id) ?? values[0]
    } else {
      modelValues.position = values[0]
    }
  }
}
