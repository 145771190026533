<template>
<svg data-svg-lvl-3="cover-top-frames" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><radialGradient id="tfr-rg" cx="433.36" cy="-15.75" fx="433.36" fy="-15.75" r="461.25" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#ebf2fe"/><stop offset=".03" stop-color="#989ba0"/><stop offset=".18" stop-color="#eaf3ff"/><stop offset=".41" stop-color="#edf1fa"/><stop offset=".61" stop-color="#b0b2bd"/><stop offset=".81" stop-color="#686a70"/><stop offset=".98" stop-color="#a4a7ab"/></radialGradient></defs><g id="УголкиКрышка"><image width="997" height="52" transform="translate(146.56 33.28) scale(.24)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/top/frames/M2_1.webp`"/><path class="tfr-c-3" d="M145.52,426.18v-6.25c0-1.78,1.44-3.23,3.23-3.23h227.47c2.61,0,4.72-2.11,4.72-4.72V41.27c0-2.61-2.11-4.72-4.72-4.72h-225.97c-1.78,0-3.23-1.44-3.23-3.23v-6.25c0-.12.1-.23.23-.23h234.05c5.16,0,9.35,4.19,9.35,9.35v380.86c0,5.16-4.19,9.35-9.35,9.35h-235.55c-.12,0-.23-.1-.23-.23Z"/><image width="1024" height="1667" transform="translate(145.12 26.8) scale(.24)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/top/frames/M2_2.webp`"/><path class="tfr-c-2" d="M381.29,26.84c5.16,0,9.35,4.19,9.35,9.35v380.86c0,5.16-4.19,9.35-9.35,9.35h-235.55c-.12,0-.23-.1-.23-.23v-6.25c0-1.78,1.44-3.23,3.23-3.23h227.47c2.61,0,4.72-2.11,4.72-4.72V41.27c0-2.61-2.11-4.72-4.72-4.72h-225.97c-1.78,0-3.23-1.44-3.23-3.23v-6.25c0-.12.1-.23.23-.23h234.05M381.29,25.7h-234.05c-.75,0-1.37.61-1.37,1.37v6.25c0,2.41,1.96,4.37,4.37,4.37h225.97c1.97,0,3.58,1.6,3.58,3.58v370.72c0,1.97-1.6,3.58-3.58,3.58h-227.47c-2.41,0-4.37,1.96-4.37,4.37v6.25c0,.75.61,1.37,1.37,1.37h235.55c5.78,0,10.49-4.71,10.49-10.49V36.19c0-5.79-4.71-10.49-10.49-10.49h0Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tfr-c-1{opacity:.3;}.tfr-c-2{fill:#232728;}.tfr-c-2,.tfr-c-3,.tfr-c-4{stroke-width:0px;}.tfr-c-3{fill:url(#tfr-rg);}.tfr-c-4{fill:#64ff00;}</style>

