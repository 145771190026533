// import lodash from 'lodash'

export async function getPrices(api, { params: oldParams, selectedPersonalizationsBuhta, CONST }) {
  const params = { ...oldParams, discount: CONST.discount, fromDealerSite: CONST.fromDealerSite }
  params.personalizations = []
  for (const persId in selectedPersonalizationsBuhta) {
    for (const pers of selectedPersonalizationsBuhta[persId]) {
      params.personalizations = params.personalizations.concat(pers)
    }
  }

  const response = await api('constructor-new/get-prices', params)
  const personalizationsPrice = {}
  if (Array.isArray(response.result?.pricePersonalizations)) {
    for (const persId in selectedPersonalizationsBuhta) {
      if (personalizationsPrice[persId] === undefined) {
        personalizationsPrice[persId] = []
      }
      for (const pers of selectedPersonalizationsBuhta[persId]) {
        let tmpPrice = 0
        for (const persItem of pers) {
          if (persItem?.id >= 0) {
            tmpPrice += response.result.pricePersonalizations.shift() * 100
          }
        }
        personalizationsPrice[persId].push(tmpPrice / 100)
      }
    }
    response.result.pricePersonalizations = personalizationsPrice
  }

  return response.result
}
