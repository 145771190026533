<template>
<svg data-svg-lvl-3="cover-block-flyleaffront" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lff-m-f" x="0" y="0" width="500" height="430" fill="#ffffff"><path  d="M113.83,423.4H378a9.13,9.13,0,0,0,9.13-9.13v-375A9.13,9.13,0,0,0,378,30.18H114.33a.5.5,0,0,1-.5-.52Z"/></mask></defs><g id="Форзац"> <path  :style="{ fill: params.flyleaffrontColor }"  class="lff-c-3" d="M113.83,423.4H378a9.13,9.13,0,0,0,9.13-9.13v-375A9.13,9.13,0,0,0,378,30.18H114.33a.5.5,0,0,1-.5-.52Z"/> <image v-if="params.flyleaffrontImage" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.flyleaffrontImage" mask="url(#lff-m-f)" /> </g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lff-c-1{opacity:0.3;}.lff-c-2{fill:#64ff00;}.lff-c-3{fill:#fff;stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}</style>

