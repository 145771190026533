import Vue from 'vue'
import App from './App'

Vue.config.productionTip = false

// Подключение списка ссылок для роутинга
import { router } from './router'

// Подключение i18n
import i18n from './i18n'

import lodash from 'lodash'
import upperFirst from 'lodash/upperFirst'
import VueLodash from 'vue-lodash'
Vue.use(VueLodash, { name: '$lodash', lodash: lodash })

// Подключение хранилищ vuex
import store from './store'

/* Подключение всех компонентов из ./components */

const requireComponent = require.context('./components-autoload', true, /.+.(vue|js)$/isu)
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  let componentName = fileName
    .replace(/^(\.\/)?(.+)\.vue$/, '$2')
    .split('/')
    .map((i) => upperFirst(i))
    .join('')

  if (componentName /* && componentName.match(/^(Svg|Ui)[A-Z]/) */) {
    Vue.component(componentName, componentConfig.default || componentConfig)

    // fileName = fileName.replace(/^\./, '/src/components-autoload')

    // https://ru.vuejs.org/v2/guide/components-dynamic-async.html
    // Vue.component(componentName, () => import(fileName))

    // Vue.component(componentName, function (resolve) {
    //   // This special require syntax will instruct Webpack to
    //   // automatically split your built code into bundles which
    //   // are loaded over Ajax requests.
    //   require([fileName], resolve)
    // })

    // Vue.component(componentName, function (resolve) {
    //   setTimeout(function () {
    //     // Передаём определение компонента в коллбэк resolve
    //     resolve(() => import(fileName))
    //   }, 100)
    // })

    // Vue.component(componentName, function (resolve) {
    //   setTimeout(function () {
    //     // Передаём определение компонента в коллбэк resolve
    //     resolve({
    //       template: '<div>Я — асинхронный!</div>',
    //     })
    //   }, 1000)
    // })
  }
})

import gsap from 'gsap'
Vue.prototype.$gsap = gsap

import axios from 'axios'
Vue.prototype.$axios = axios

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

/* Директивы */
import { thousands, focus, clickOutside } from './directives'
Vue.filter('thousands', thousands)
Vue.directive('focus', focus)
Vue.directive('click-outside', clickOutside)

window.$cnstr = {
  start({
    mount = '#cnstr',
    locale,
    dog101,
    dog100,
    discount: discountDealer,
    headerHeight = '0px',
    fromDealerSite = true,
    apiKey = 'fvk34lkjf03fd93j9dfsaf0d',
    editMode = false,
    order = null,
    finishOrder = () => ({}),
  }) {
    let discount = {
      dealer: 0,
      useEdition: 1,
      useSeason: 1,
      type: 0,
    }

    if (typeof discountDealer === 'object') {
      Object.assign(discount, discountDealer)
    }
    let testMode = true
    if (process?.env?.VERCEL_BRANCH_URL) {
      if (process.env.VERCEL_BRANCH_URL === 'adjutant-cnstr-git-master-developer-poni.vercel.app') {
        testMode = false
      }
    } else {
      testMode = process.env.NODE_ENV !== 'production'
    }

    /* Константы */
    Vue.prototype.$CONST = {
      env: process.env.NODE_ENV,
      apiUrl: 'https://api.adjutant.ru',
      uploadUrl: 'https://api.adjutant.ru/tmp/upload/constructorzakaz',
      assetsUrl: 'https://api.adjutant.ru/images/catalog',
      cnstrAssetsUrl: 'https://assets.cnstr.adjutant.ru/images',
      testMode,
      fromDealerSite,
      key: apiKey,
      discount,
      dog101,
      dog100,
      headerHeight,
      editMode,
      order,
      finishOrder,
    }

    if (i18n.availableLocales.includes(locale)) {
      i18n.locale = locale
    }

    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount(mount)
  },
}
