<template>
<svg data-svg-lvl-3="flyleaffront-block-plastic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lpl-lg" x1="119.58" y1="107.79" x2="119.58" y2="327.19" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4d4d4d" stop-opacity="0.3"/><stop offset="0.59" stop-color="#4d4d4d" stop-opacity="0.32"/><stop offset="0.82" stop-color="#4d4d4d" stop-opacity="0.41"/><stop offset="0.98" stop-color="#4d4d4d" stop-opacity="0.57"/><stop offset="1" stop-color="#4d4d4d" stop-opacity="0.6"/></linearGradient><clipPath id="lpl-cp"><path class="lpl-c-1" d="M177.7,223.19A116.24,116.24,0,0,0,61.46,107V339.43A116.24,116.24,0,0,0,177.7,223.19Z"/></clipPath><radialGradient id="lpl-rg" cx="61.41" cy="222.86" r="115.38" gradientUnits="userSpaceOnUse"><stop offset="0.98" stop-opacity="0"/><stop offset="1"/></radialGradient></defs><g id="Комбинация_ЗадникМаска"><path class="lpl-c-4" d="M177.7,223.19A116.24,116.24,0,0,0,61.46,107V339.43A116.24,116.24,0,0,0,177.7,223.19Z"/></g><g id="Комбинация_Задник"><path class="lpl-c-5" d="M177.7,223.19A116.24,116.24,0,0,0,61.46,107V339.43A116.24,116.24,0,0,0,177.7,223.19Z"/><g class="lpl-c-6"><image width="514" height="422" transform="translate(0 4)" :href="`${$CONST.cnstrAssetsUrl}/others/93BB-Ice-print.webp`"/></g><path class="lpl-c-7" d="M177.7,223.19A116.24,116.24,0,0,0,61.46,107V339.43A116.24,116.24,0,0,0,177.7,223.19Z"/><path class="lpl-c-8" d="M177.7,223.19A116.24,116.24,0,0,0,61.46,107V339.43A116.24,116.24,0,0,0,177.7,223.19Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lpl-c-1,.lpl-c-8{fill:none;}.lpl-c-2{opacity:0.3;}.lpl-c-3{fill:#64ff00;}.lpl-c-4{fill:#fff;}.lpl-c-5{fill:url(#lpl-lg);}.lpl-c-6{clip-path:url(#lpl-cp);}.lpl-c-7{opacity:0.4;fill:url(#lpl-rg);}.lpl-c-8{stroke:#232728;stroke-linejoin:round;stroke-width:0.75px;}</style>

