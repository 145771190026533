<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M113.33,375.63h16.35A40.34,40.34,0,0,0,170,335.29V118.16a40.33,40.33,0,0,0-40.33-40.33H113.33"/><path class="tss-c-4" d="M113.13,375.43h16.35a40.34,40.34,0,0,0,40.33-40.34V118a40.33,40.33,0,0,0-40.33-40.33H113.13"/><path class="tss-c-5" d="M113.13,72.63h16.35A45.39,45.39,0,0,1,174.81,118V335.09a45.39,45.39,0,0,1-45.33,45.34H113.13"/><g class="tss-c-6"><line class="tss-c-7" x1="113.33" y1="72.83" x2="117.33" y2="72.83"/><path class="tss-c-8" d="M121.29,72.83h8.39A45.39,45.39,0,0,1,175,118.16V335.29a45.39,45.39,0,0,1-45.33,45.34H119.31"/><line class="tss-c-7" x1="117.33" y1="380.63" x2="113.33" y2="380.63"/></g><g class="tss-c-9"><line class="tss-c-10" x1="112.93" y1="72.43" x2="116.93" y2="72.43"/><path class="tss-c-11" d="M120.89,72.43h8.38a45.39,45.39,0,0,1,45.34,45.33V334.89a45.39,45.39,0,0,1-45.34,45.34H118.91"/><line class="tss-c-10" x1="116.93" y1="380.23" x2="112.93" y2="380.23"/></g></g><g id="Шов_Цвет"><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="113.13" y1="72.63" x2="117.13" y2="72.63"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M121.09,72.63h8.39A45.39,45.39,0,0,1,174.81,118V335.09a45.39,45.39,0,0,1-45.33,45.34H119.11"/><line :style="{ stroke: params.seamColor }"  class="tss-c-12" x1="117.13" y1="380.43" x2="113.13" y2="380.43"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#64ff00;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-4,.tss-c-5,.tss-c-7,.tss-c-8{fill:none;stroke-miterlimit:2;}.tss-c-10,.tss-c-11,.tss-c-3{stroke:#fff;}.tss-c-3,.tss-c-4,.tss-c-5{stroke-width:0.46px;}.tss-c-12,.tss-c-13,.tss-c-4{stroke:#232728;}.tss-c-5,.tss-c-7,.tss-c-8{stroke:#000;}.tss-c-5{opacity:0.5;}.tss-c-6{opacity:0.6;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-7,.tss-c-8{stroke-linecap:round;stroke-width:1.14px;}.tss-c-11,.tss-c-13,.tss-c-8{stroke-dasharray:7.92 3.96;}.tss-c-9{opacity:0.7;}</style>

