<template>
<svg data-svg-lvl-3="cover-top-plastic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="tpl-m-p" x="0" y="0" width="500" height="430" fill="#ffffff" ><path   d="M257,426H381a9.13,9.13,0,0,0,9.13-9.13V36.34A9.12,9.12,0,0,0,381,27.22H257Z"/></mask><clipPath id="tpl-cp"><path class="tpl-c-1" d="M381,27.22H257V426H381a9.16,9.16,0,0,0,9.13-9.13V36.35A9.13,9.13,0,0,0,381,27.22Z"/></clipPath><linearGradient id="tpl-lg" x1="257.04" y1="226.61" x2="259.03" y2="226.61" gradientUnits="userSpaceOnUse"><stop offset="0"/><stop offset="1" stop-opacity="0"/></linearGradient><linearGradient id="tpl-lg-2" x1="381.53" y1="33.08" x2="261.53" y2="425.75" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#b2b2b2" stop-opacity="0.1"/><stop offset="0.5" stop-color="#b2b2b2" stop-opacity="0.11"/><stop offset="0.69" stop-color="#b2b2b2" stop-opacity="0.15"/><stop offset="0.82" stop-color="#b2b2b2" stop-opacity="0.22"/><stop offset="0.93" stop-color="#b2b2b2" stop-opacity="0.31"/><stop offset="1" stop-color="#b2b2b2" stop-opacity="0.4"/></linearGradient></defs><g id="Комбинация_Картинка"><path :style="{ fill: params.flyleaffrontColor }"  class="tpl-c-4" d="M257,426H381a9.13,9.13,0,0,0,9.13-9.13V36.34A9.12,9.12,0,0,0,381,27.22H257Z"/> <image v-if="params.flyleaffrontImage" width="500" height="430" :href="params.flyleaffrontImage" :mask="`url(#tpl-m-p)`" /> </g><g id="Комбинация_Пластик"><g class="tpl-c-5"><g class="tpl-c-6"><image width="514" height="422" transform="translate(0 4)" :href="`${$CONST.cnstrAssetsUrl}/others/93BB-Ice-print.webp`"/></g></g><rect class="tpl-c-7" x="257" y="27.22" width="2" height="398.77"/><path class="tpl-c-8" d="M381,27.22H257V426H381a9.16,9.16,0,0,0,9.13-9.13V36.35A9.13,9.13,0,0,0,381,27.22Z"/><line class="tpl-c-9" x1="257" y1="27.22" x2="257" y2="425.99"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tpl-c-1{fill:none;}.tpl-c-2{opacity:0.3;}.tpl-c-3{fill:#64ff00;}.tpl-c-4{fill:lime;}.tpl-c-5{opacity:0.7;}.tpl-c-6{clip-path:url(#tpl-cp);}.tpl-c-7{opacity:0.4;fill:url(#tpl-lg);}.tpl-c-8{fill:url(#tpl-lg-2);}.tpl-c-9{fill:#fff;stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}</style>

