<template>
<svg data-svg-lvl-3="cover-top-seamPerimeter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Прошивка_по_периметру_Контур" ><path class="tsp-c-3" d="M111.57,133.19H374.08a6.06,6.06,0,0,1,6.06,6.06v175.8a6.06,6.06,0,0,1-6.06,6.06H111.57"/><g class="tsp-c-4"><line class="tsp-c-5" x1="111.77" y1="133.39" x2="115.77" y2="133.39"/><path class="tsp-c-6" d="M119.77,133.39H374.28a6.06,6.06,0,0,1,6.06,6.06v175.8a6.06,6.06,0,0,1-6.06,6.06H117.77"/><line class="tsp-c-5" x1="115.77" y1="321.31" x2="111.77" y2="321.31"/></g><g class="tsp-c-7"><line class="tsp-c-8" x1="111.37" y1="132.99" x2="115.37" y2="132.99"/><path class="tsp-c-9" d="M119.37,133H373.88a6.06,6.06,0,0,1,6.06,6.06v175.8a6.06,6.06,0,0,1-6.06,6.06H117.37"/><line class="tsp-c-8" x1="115.37" y1="320.91" x2="111.37" y2="320.91"/></g></g><g id="Прошивка_по_периметру_Цвет" ><line :style="{ stroke: params.seamColor }"  class="tsp-c-10" x1="111.57" y1="133.19" x2="115.57" y2="133.19"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-11" d="M119.57,133.19H374.08a6.06,6.06,0,0,1,6.06,6.06v175.8a6.06,6.06,0,0,1-6.06,6.06H117.57"/><line :style="{ stroke: params.seamColor }"  class="tsp-c-10" x1="115.57" y1="321.11" x2="111.57" y2="321.11"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsp-c-1{opacity:0.3;}.tsp-c-2{fill:#64ff00;}.tsp-c-10,.tsp-c-11,.tsp-c-3,.tsp-c-5,.tsp-c-6,.tsp-c-8,.tsp-c-9{fill:none;stroke-miterlimit:2;}.tsp-c-3,.tsp-c-5,.tsp-c-6{stroke:#000;}.tsp-c-3{stroke-width:0.46px;opacity:0.5;}.tsp-c-4{opacity:0.6;}.tsp-c-10,.tsp-c-11,.tsp-c-5,.tsp-c-6,.tsp-c-8,.tsp-c-9{stroke-linecap:round;stroke-width:1.14px;}.tsp-c-11,.tsp-c-6,.tsp-c-9{stroke-dasharray:8 4;}.tsp-c-7{opacity:0.7;}.tsp-c-8,.tsp-c-9{stroke:#fff;}.tsp-c-10,.tsp-c-11{stroke:#253f2d;}</style>

