import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const flyleafs = {
  id: 'flyleafs',
  // tName:  'personalizations.flyleafs.flyleafs',
  template: 'modal',
  preview: 'flyleaffront',
  multiple: true,

  originPersonalizationsIds: [
    // цветной
    421, // Установка цветного форзаца и нахзаца 13х21, 15х21, 15.5х21
    431, // Установка цветного форзаца или нахзаца 13х21, 15х21, 15.5х21

    650, // Установка цветного форзаца и нахзаца 20х26
    651, // Установка цветного форзаца или нахзаца 20х26

    672, // Установка цветного форзаца и нахзаца 10х16, 10.5х16
    673, // Установка цветного форзаца или нахзаца 10х16, 10.5х16

    // индивидуальный
    124, // Печать и установка индивидуального форзаца или нахзаца  до формата 20x26
    125, // Печать и установка индивидуального форзаца и нахзаца  до формата 20x26

    126, // Печать и установка индивидуального форзаца или нахзаца формата 20x26
    127, // Печать и установка индивидуального форзаца и нахзаца формата 20x26

    // пластиковый карман
    164, // Установка пластикового кармана для визитки
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getFlyleafs(params)
  getPosition(params)
  getColor(params)
  getUploadFront(params)
  getUploadEnd(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  let idParam = 110

  let idParamAdd = 55
  let idValue = modelValues?.color?.id
  let idValueAdd

  const key = modelValues?.flyleafs?.id
  const keyPosition = modelValues?.position?.id
  if (keyPosition === 'front') {
    idValueAdd = 327
  } else if (keyPosition === 'end') {
    idValueAdd = 328
  }
  if (key) {
    let selectedPers = []
    if (key === 'colored') {
      if (keyPosition === 'both') {
        selectedPers = [421, 650, 672]
      } else if (keyPosition === 'front' || keyPosition === 'end') {
        selectedPers = [431, 651, 673]
      }
    } else if (key === 'individual') {
      if (keyPosition === 'both') {
        selectedPers = [125, 127]
      } else if (keyPosition === 'front' || keyPosition === 'end') {
        selectedPers = [124, 126]
      }
    } else if (key === 'pocket') {
      idBuhta = 164
      idParamAdd = 151
      idValueAdd = modelValues?.position?.idBuhta
    }
    if (key !== 'pocket') {
      const idsBuhta = lodash.intersection(this.originIdsAvailable, selectedPers)
      idBuhta = idsBuhta[0]
      if ([421, 431].includes(idBuhta)) {
        idParam = 41
      }
    }
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
    {
      idParam: idParamAdd,
      idValue: idValueAdd,
    },
  ])
}

/**
 * Выбор Форзацов
 */
function getFlyleafs(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  const values = []
  const personals = {
    colored: [421, 431, 650, 651, 672, 673],
    individual: [124, 125, 126, 127],
    pocket: [164],
  }

  for (const key in personals) {
    if (lodash.intersection(originIdsAvailable, personals[key]).length > 0) {
      values.push({
        id: key,
        tName: `personalizations.flyleafs.${key}`,
        img: `${cnstrAssetsUrl}/personalizations/flyleafs/${key}.webp`,
      })
    }
  }
  if (values.length > 0) {
    items.push({
      id: 'flyleafs',
      tName: 'personalizations.flyleafs.flyleafs',
      template: 'selectImageText',
      sort: 10,
      values,
    })

    if (oldModelValues.flyleafs) {
      modelValues.flyleafs = values.find((i) => i.id === oldModelValues.flyleafs.id) ?? values[0]
    } else {
      modelValues.flyleafs = values[0]
    }
  }
}

/**
 * Выбор форзаца и/или нахзаца
 */
function getPosition(params) {
  const { oldModelValues, items, modelValues, originIdsAvailable, originPersonalizations } = params

  let values = []
  if (modelValues.flyleafs.id !== 'pocket') {
    let personals
    if (modelValues.flyleafs.id === 'colored') {
      personals = {
        both: [421, 650, 672],
        front: [431, 651, 673],
        end: [431, 651, 673],
      }
    } else if (modelValues.flyleafs.id === 'individual') {
      personals = {
        both: [125, 127],
        front: [124, 126],
        end: [124, 126],
      }
    } else {
      return
    }

    for (const key in personals) {
      if (lodash.intersection(originIdsAvailable, personals[key]).length > 0) {
        values.push({
          id: key,
          tName: `personalizations.flyleafs.${key}`,
        })
      }
    }
  } else {
    let idBuhta = 164
    const idParam = 151
    if (originPersonalizations[idBuhta]?.params) {
      const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
      if (val?.values?.length) {
        values = val.values.map((n) => {
          let key = 'front'
          if (n.id === 5816) key = 'end'
          return {
            id: key,
            tName: `personalizations.flyleafs.${key}`,
            idBuhta: n.id,
          }
        })
      }
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'position',
      tName: 'personalizations.flyleafs.position',
      template: 'selectRadio',
      sort: 20,
      values,
    })

    if (oldModelValues.position) {
      modelValues.position = values.find((i) => i.id === oldModelValues.position.id) ?? values[0]
    } else {
      modelValues.position = values[0]
    }
  }
}

/**
 * Выбор цвета форзаца и/или нахзаца
 */
function getColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations, originIdsAvailable } = params

  const personalsAll = {
    both: [421, 650, 672],
    front: [431, 651, 673],
    end: [431, 651, 673],
  }
  if (modelValues.flyleafs.id !== 'colored') {
    return
  }

  const personals = personalsAll[modelValues.position.id]

  const intersect = lodash.intersection(originIdsAvailable, personals)
  if (intersect.length === 0) {
    return
  }
  const idBuhta = intersect[0]

  let idParam = 110
  if ([421, 431].includes(idBuhta)) {
    idParam = 41
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.flyleafs.color',
      template: 'selectClassicColor',
      sort: 30,
      values,
    })

    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[0]
    } else {
      modelValues.color = values[0]
    }
  }
}

/**
 * Выбор загрузки изображения форзаца
 */
function getUploadFront(params) {
  const { oldModelValues, items, modelValues } = params
  if (
    modelValues.flyleafs.id !== 'individual' ||
    !(modelValues.position.id === 'front' || modelValues.position.id === 'both')
  ) {
    return
  }

  if (oldModelValues.uploadFront) {
    modelValues.uploadFront = oldModelValues.uploadFront
  } else {
    modelValues.uploadFront = {
      url: '',
    }
  }
  const values = [modelValues.uploadFront]
  items.push({
    id: 'uploadFront',
    tName: 'personalizations.flyleafs.uploadFront',
    template: 'upload',
    sort: 40,
    values,
  })
}

/**
 * Выбор загрузки изображения нахзаца
 */
function getUploadEnd(params) {
  const { oldModelValues, items, modelValues } = params
  if (
    modelValues.flyleafs.id !== 'individual' ||
    !(modelValues.position.id === 'end' || modelValues.position.id === 'both')
  ) {
    return
  }

  if (oldModelValues.uploadEnd) {
    modelValues.uploadEnd = oldModelValues.uploadEnd
  } else {
    modelValues.uploadEnd = {
      url: '',
      top: 0,
      left: 0,
      width: 50,
      height: 50,
    }
  }
  const values = [modelValues.uploadEnd]
  items.push({
    id: 'uploadEnd',
    tName: 'personalizations.flyleafs.uploadEnd',
    template: 'upload',
    sort: 50,
    values,
  })
}
