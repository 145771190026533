<template>
<svg data-svg-lvl-3="cover-top-seamStitching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Шов_Подложка"><path class="tss-c-3" d="M390.1,385.41A160.08,160.08,0,0,1,278,338.55,158.53,158.53,0,0,1,386.9,67.83"/><path class="tss-c-4" d="M390.39,380.41c-84.49-.78-153.78-69.05-153.82-153.72A153.82,153.82,0,0,1,390.38,72.8h0"/><path class="tss-c-5" d="M390.14,380.41c-84.49-.78-153.78-69.05-153.82-153.72A153.82,153.82,0,0,1,390.13,72.8h0"/><g class="tss-c-6"><path class="tss-c-7" d="M390.3,385.61c-1.34,0-2.67,0-4-.09"/><path class="tss-c-8" d="M382.27,385.34a160,160,0,0,1-104-46.59A158.53,158.53,0,0,1,381.09,68.26"/><path class="tss-c-7" d="M383.1,68.16c1.33-.06,2.66-.1,4-.13"/></g><g class="tss-c-9"><path class="tss-c-10" d="M389.9,385.21c-1.34,0-2.67,0-4-.09"/><path class="tss-c-11" d="M381.87,384.94a160,160,0,0,1-104-46.59A158.53,158.53,0,0,1,380.69,67.86"/><path class="tss-c-10" d="M382.7,67.76c1.33-.06,2.66-.1,4-.13"/></g></g><g id="Шов_Цвет"><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M390.1,385.41c-1.34,0-2.67,0-4-.09"/><path :style="{ stroke: params.seamColor }"  class="tss-c-13" d="M382.07,385.14a160,160,0,0,1-104-46.59A158.53,158.53,0,0,1,380.89,68.06"/><path :style="{ stroke: params.seamColor }"  class="tss-c-12" d="M382.9,68c1.33-.06,2.66-.1,4-.13"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tss-c-1{opacity:0.3;}.tss-c-2{fill:#ff931e;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-3,.tss-c-4,.tss-c-5,.tss-c-7,.tss-c-8{fill:none;stroke-miterlimit:2;}.tss-c-3,.tss-c-7,.tss-c-8{stroke:#000;}.tss-c-3,.tss-c-4,.tss-c-5{stroke-width:0.46px;}.tss-c-3{opacity:0.5;}.tss-c-10,.tss-c-11,.tss-c-4{stroke:#fff;}.tss-c-12,.tss-c-13,.tss-c-5{stroke:#232728;}.tss-c-6{opacity:0.6;}.tss-c-10,.tss-c-11,.tss-c-12,.tss-c-13,.tss-c-7,.tss-c-8{stroke-linecap:round;stroke-width:1.14px;}.tss-c-11,.tss-c-13,.tss-c-8{stroke-dasharray:8.06 4.03;}.tss-c-9{opacity:0.7;}</style>

