<template>
<svg data-svg-lvl-3="spread-block-lasseNameplate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><mask id="lln-m" x="259.05" y="361.75" width="39.7" height="39.7" maskUnits="userSpaceOnUse"><path class="lln-c-1" d="M296.43,361.75l-14.73.92.22,3.72.35,0a.46.46,0,0,1,.49.44l.06,1.16a.51.51,0,0,1-.49.53l-8.29.39a.48.48,0,0,1-.51-.46l0-1.13a.51.51,0,0,1,.48-.54h.25l-.19-3.61-15,.93,2.31,37.38,37.39-2.32Z"/></mask></defs><g id="Шильда_Контур"><path class="lln-c-4" d="M296.43,361.75l-14.73.92.22,3.72.35,0a.46.46,0,0,1,.49.44l.06,1.16a.51.51,0,0,1-.49.53l-8.29.39a.48.48,0,0,1-.51-.46l0-1.13a.51.51,0,0,1,.48-.54h.25l-.19-3.61-15,.93,2.31,37.38,37.39-2.32Z"/><g class="lln-c-5"><path class="lln-c-6" d="M297.3,394.55,294,365.66a1,1,0,0,0-1.06-.94l-30.14.41,31.53-1.95a1,1,0,0,1,1.09,1Z"/></g><path class="lln-c-7" d="M260.39,368.7l3.41,28.67a1,1,0,0,0,1,.85l29.17.1-30.65,1.9a.93.93,0,0,1-1-.87Z"/><path class="lln-c-8" d="M277.13,379.5l-.37-6.6-8.15.46.18,3.3,4.41-.24.85,15.23,14.37-.81-.67-11.94Zm7.36,8.26-6.87.39-.29-5.08,6.88-.39Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lln-c-1,.lln-c-6,.lln-c-7{fill:#fff;}.lln-c-1,.lln-c-4,.lln-c-6,.lln-c-7{fill-rule:evenodd;}.lln-c-2{opacity:0.3;}.lln-c-3{fill:#64ff00;}.lln-c-4{fill:#277fff;stroke:#232728;stroke-miterlimit:2;stroke-width:0.75px;}.lln-c-5{mask:url(#lln-m);}.lln-c-6{opacity:0.4;}.lln-c-7{opacity:0.2;}.lln-c-8{fill:#ffb62b;}</style>

