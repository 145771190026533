<template>
  <div class="ac-form-group">
    <div
      class="select"
      :class="{ open: showList, disabled: disabled }"
      tabindex="0"
      footer
      @blur="hideList"
      v-click-outside="hideList"
    >
      <div class="input" :class="{ selected: value.name }" @click.passive="toggleSelect($event)">
        <div class="option">
          <div class="option__img" v-if="showImage">
            <img :src="value.img" :alt="value.tName ? $t(value.tName) : value.name" />
          </div>
          <div class="option__color" v-else-if="showBackground" :style="{ background: value.background }"></div>
          <div class="option__filler" v-else></div>
          <div class="option__text">{{ value.tName ? $t(value.tName) : value.name }}</div>
          <div class="option__caret">
            <IconsIconCaret />
          </div>
        </div>
      </div>
      <transition name="list">
        <div class="select-list" v-if="showList" :style="{ top: hoveredTop }">
          <div class="select-list-wrap">
            <div class="select-list-wrap2">
              <div class="scroller" v-if="items.length" :style="`height: ${height}px;`">
                <div class="select-list-inner" ref="select" @mouseenter="disableActive = true">
                  <div
                    class="select-list-item option"
                    v-for="item in items"
                    :key="item.id"
                    @click="setItem(item)"
                    :class="{ _active: item.id == value.id && !disableActive, disabled: item.disabled }"
                  >
                    <div class="option__img" v-if="showImage">
                      <img :src="item.img" :alt="item.tName ? $t(item.tName) : item.name" />
                    </div>
                    <div
                      class="option__color"
                      v-else-if="showBackground"
                      :style="{ background: item.background }"
                    ></div>
                    <div class="option__filler" v-else></div>
                    <div class="option__text">{{ item.tName ? $t(item.tName) : item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    /** @var scrollHeight - длина списка для отрисовки скролла */
    scrollHeight: {
      type: Number,
      default: 245,
    },
    /** @var items - список значений */
    items: {
      type: Array,
      required: true,
    },
    /** @var value - выбранное значение */
    value: {
      type: Object,
      default: () => ({}),
    },
    /** @var placeholderTranslation - назнание перевода для плейсхолдера */
    placeholderTranslation: {
      type: String,
      default: 'ui.chooseValue',
    },
    showImage: {
      type: Boolean,
      default: () => false,
    },
    showBackground: {
      type: Boolean,
      default: () => false,
    },
    above: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      showList: false,
      height: 60,
      disableActive: false,
      hoveredTop: '0px',
    }
  },

  watch: {
    items() {
      this.setScrollHeight()
    },
    // offsetTop() {
    //   this.hideList()
    // },
  },

  computed: {
    ...mapGetters({
      offsetTop: 'window/OFFSET_TOP',
    }),
  },

  methods: {
    /**
     * При выборе элемента списка генерирует событие, если выбран новый элемент
     */
    setItem(item) {
      if (item.disabled) {
        return
      }
      if (item.id !== this.value.id) {
        this.$emit('input', item)
      }
      this.showList = false
    },
    toggleSelect($event) {
      this.disabled ? (this.showList = false) : (this.showList = !this.showList)
      this.setScrollHeight()
      this.disableActive = false
      if (this.showList) {
        this.enter($event)
      }
    },
    setScrollHeight() {
      if (this.showList) {
        setTimeout(() => {
          const innerHeight = this.$refs.select.scrollHeight
          if (innerHeight < this.scrollHeight) {
            this.$refs.select.style = `height: ${innerHeight}px`
            this.height = innerHeight
          } else {
            this.height = this.scrollHeight
          }
        }, 200)
      }
    },
    hideList() {
      window.setTimeout(() => {
        this.showList = false
      }, 200)
    },

    enter($event) {
      var rect = $event.currentTarget.getBoundingClientRect()
      this.hoveredTop = `${rect.bottom}px`
    },
  },
}
</script>

<style lang="sass" scoped>
.option
  display: flex
  align-items: center
  cursor: pointer
  transition: ease .3s
  white-space: nowrap
  max-width: 100%
  gap: 8px

  width: 100%
  &__img, &__color
    width: 30px
    height: 30px
    border-radius: 5px
    border: 1px solid $stroke-color
    overflow: hidden
    margin-left: 5px
    flex-shrink: 0
  &__filler
    width: 16px
  &__text
    flex-grow: 1
    overflow: hidden
    text-overflow: ellipsis
  &__caret
    width: 24px
    height: 24px
    margin: 0 6px 0 2px
    ::v-deep svg
      transform: scaleY(-1)
      // transform: rotate(0deg)
      *
        fill: $icon2-color
.ac-
  display: flex
  // если убрать то фильтр будет поверх но не там
  position: relative
  margin-bottom: 0 !important

.form-item
  position: relative
  margin-bottom: 17px
.select
  // position: relative
  cursor: pointer
  overflow: hidden
  &.open .input
    background: $hover-color
  &.disabled
    opacity: 0.6
    cursor: default
  .input
    transition: ease .3s
    position: relative
    display: flex
    align-items: center
    border: none
    border-radius: 5px
    background: $light-back-color
    // padding: 6px 10px
    &:hover
      background: $hover-color
    .option
      height: 30px
      &__filler
        width: 10px
      &__img, &__color
        margin-left: 0
        // height: 30px
        // padding: 3px 4px 3px 10px
  &-list
    width: 290px
    position: fixed
    // position: fixed
    z-index: 999
    // top: 35px
    // left: 0
    // min-width: 100%
    z-index: 2
    box-shadow: 0px 4px 15px 0px rgba(0, 52, 49, 0.30)
    border-radius: 15px
    overflow: hidden

    &.above
      top: unset
      bottom: 45px

    &-wrap
      padding: 10px 0
      background: $light-back-color
    &-wrap2
      overflow: hidden
      height: 100%

    // &-inner
    //   max-height: 100%
    //   border-top: none
    //   border: none
    &-item
      // padding: 0 16px
      white-space: nowrap
      height: 40px

      padding-right: 3px
      &._active, &:hover
        background-color: $hover-color
    &-load
      display: flex
      justify-content: center
      margin-bottom: 7px

  // открытие селекта
  &.open
    .input
      border-color: $alternative
    .option__caret::v-deep svg
      transform: scaleY(1)
      // transform: rotate(0deg)

  .list-enter-active, .list-leave-active
    transition: .3s

  .list-enter-to, .list-leave
    opacity: 1

  .list-enter, .list-leave-to
    opacity: 0
</style>
