import { render, staticRenderFns } from "./M2C2.vue?vue&type=template&id=6c138536&scoped=true"
import script from "./M2C2.vue?vue&type=script&lang=js"
export * from "./M2C2.vue?vue&type=script&lang=js"
import style0 from "./M2C2.vue?vue&type=style&index=0&id=6c138536&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c138536",
  null
  
)

export default component.exports