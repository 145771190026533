import Vue from 'vue'
import Vuex from 'vuex'

import window from './window'
import control from './control'
import products from './products'
import models from './models'
import blocks from './blocks'
import materials from './materials'
import personalizations from './personalizations'
import prices from './prices'
import cart from './cart'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { window, control, products, models, blocks, materials, personalizations, prices, cart },
})
