<template>
<svg data-svg-lvl-3="edge-top-base" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышки_Подложка"><polygon class="tb-c-3" points="48.46 237.25 48.45 237.25 48.46 237.26 48.46 237.25"/><path class="tb-c-3" d="M48.45,237.25l-.24-.14v.14Z"/><path class="tb-c-3" d="M60.19,192.81c-14.12,0-25.57,11.08-25.57,24.75a24.67,24.67,0,0,0,13.93,22v-.54h-.09v-1.8h-.25v-.14A22.58,22.58,0,0,1,36.7,217.56c0-12.56,10.51-22.74,23.49-22.74a23.79,23.79,0,0,1,16.19,6.29h2.89A25.84,25.84,0,0,0,60.19,192.81Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tb-c-1{opacity:0.3;}.tb-c-2{fill:#64ff00;}.tb-c-3{fill:#232728;}</style>

