import lodash from 'lodash'
import { colors, colorsHex } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'
import {
  // getDraggableArea,
  getDraggableAreaMm,
  getPosition,
  // getPixIn1Mm,
} from '/src/utils/draggable'

export const embossing = {
  id: 'embossing',
  // tName: 'personalizations.embossing.embossing',
  template: 'modal',
  preview: 'cover',
  multiple: true,

  originPersonalizationsIds: [
    // блинтовое
    14, //  Тиснение блинтовое до 50 см2
    20, //  Тиснение блинтовое до 100 см2
    293, // Тиснение блинтовое от 100 см2
    // 283, // Тиснение по коже блинтовое до 50 см2
    // 297, // Тиснение по коже блинтовое до 100 см2
    187, // Тиснение блинтовое по корешку
    122, // Стандартное тиснение блинтовое

    // фольга(золото, серебро)
    21, //  Тиснение фольгой (золото, серебро) до 50 см2
    22, //  Тиснение фольгой (золото, серебро) до 100 см2
    292, // Тиснение фольгой (золото, серебро) от 100 см2
    // 288, // Тиснение по коже фольгой(золото, серебро) до 50 см2
    // 287, // Тиснение по коже фольгой(золото, серебро) до 100 см2
    295, // Тиснение фольгой (золото, серебро) по корешку
    193, // Стандартное тиснение  фольгой (золото или серебро)

    // цветная фольга
    161, // Тиснение цветной фольгой до 50 см2
    160, // Тиснение цветной фольгой до 100 см2
    296, // Тиснение цветной фольгой от 100 см2
    302, // Тиснение фольгой (цветная) по корешку
    301, // Стандартное тиснение цветной фольгой
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getEmbossing(params)
  getClicheType(params)
  getArea(params)
  getClicheMaking(params)
  getWidth(params)
  getHeight(params)
  getTempArea(params)
  getSpine(params)
  getCliche(params)
  getFoilColor(params)
  // getUpload(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta

  let idParam
  let idValue
  let idParamAdd
  let idValueAdd

  let idWork
  let value

  let idParamInt
  let valueInt
  let idParamInt2
  let valueInt2

  const keyEmbossing = modelValues?.embossing?.id
  const keyClicheType = modelValues?.clicheType?.id
  const keySpine = modelValues?.spine?.checked
  const keyArea = modelValues?.area?.id

  const keyClicheMaking = modelValues?.clicheMaking?.checked
  const keyWidth = modelValues?.width?.value
  const keyHeight = modelValues?.height?.value

  const keyCliche = modelValues?.cliche?.id
  const keyFoilColor = modelValues?.foilColor?.id

  if (keyEmbossing && keyClicheType) {
    if (keyClicheType === 'standart') {
      idParam = 22
      idValue = keyCliche
      if (keyEmbossing === 'pancake') {
        idBuhta = 122
      } else if (keyEmbossing === 'foil') {
        idBuhta = 193
        idParamAdd = 25
        idValueAdd = keyFoilColor
      } else if (keyEmbossing === 'coloredFoil') {
        idBuhta = 301
        idParamAdd = 26
        idValueAdd = keyFoilColor
      }
    } else if (keyClicheType === 'individual') {
      if (keySpine) {
        value = keyClicheMaking
        if (keyEmbossing === 'pancake') {
          idBuhta = 187
          idWork = 656
        } else if (keyEmbossing === 'foil') {
          idBuhta = 295
          idWork = 998
          idParamAdd = 25
          idValueAdd = keyFoilColor
        } else if (keyEmbossing === 'coloredFoil') {
          idBuhta = 302
          idWork = 999
          idParam = 26
          idValue = keyFoilColor
        }
      } else if (keyEmbossing === 'pancake') {
        value = keyClicheMaking
        if (keyArea === 'to50') {
          idBuhta = 14
          idWork = 28
        } else if (keyArea === 'to100') {
          idBuhta = 20
          idWork = 37
        } else if (keyArea === 'from100') {
          idBuhta = 293
          idWork = 1600
        }
      } else if (keyEmbossing === 'foil') {
        value = keyClicheMaking
        idParam = 25
        idValue = keyFoilColor
        if (keyArea === 'to50') {
          idBuhta = 21
          idWork = 40
        } else if (keyArea === 'to100') {
          idBuhta = 22
          idWork = 43
        } else if (keyArea === 'from100') {
          idBuhta = 292
          idWork = 1601
        }
      } else if (keyEmbossing === 'coloredFoil') {
        idParam = 26
        idValue = keyFoilColor
        value = keyClicheMaking
        if (keyArea === 'to50') {
          idBuhta = 161
          idWork = 572
        } else if (keyArea === 'to100') {
          idBuhta = 160
          idWork = 569
        } else if (keyArea === 'from100') {
          idBuhta = 296
          idWork = 1602
        }
      }
      if (keyWidth && keyHeight) {
        idParamInt = 107
        valueInt = keyWidth
        idParamInt2 = 108
        valueInt2 = keyHeight
      }
    }
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
    {
      idParam: idParamAdd,
      idValue: idValueAdd,
    },
    {
      idWork,
      value,
    },
    {
      idParamInt,
      value: valueInt,
    },
    {
      idParamInt: idParamInt2,
      value: valueInt2,
    },
  ])
}

/**
 * Выбор типа тиснения(Блинтовое тиснение/Тиснение фольгой/Тиснение цветной фольгой)
 */
function getEmbossing(params) {
  const { oldModelValues, items, modelValues, originIdsAvailable } = params

  const values = []
  const pancake = [14, 20, 122, 187, 283, 293, 297]
  if (lodash.intersection(originIdsAvailable, pancake).length > 0) {
    values.push({
      id: 'pancake',
      tName: 'personalizations.embossing.pancake',
    })
  }
  const foil = [21, 22, 193, 287, 288, 292, 295]
  if (lodash.intersection(originIdsAvailable, foil).length > 0) {
    values.push({
      id: 'foil',
      tName: 'personalizations.embossing.foil',
    })
  }

  const coloredFoil = [160, 161, 296, 301, 302]
  if (lodash.intersection(originIdsAvailable, coloredFoil).length > 0) {
    values.push({
      id: 'coloredFoil',
      tName: 'personalizations.embossing.coloredFoil',
    })
  }

  if (values.length > 0) {
    items.push({
      id: 'embossing',
      tName: 'personalizations.embossing.embossing',
      template: 'selectTab',
      sort: 10,
      hideHr: true,
      values,
    })

    if (oldModelValues.embossing) {
      modelValues.embossing = values.find((i) => i.id === oldModelValues.embossing.id) ?? values[0]
    } else {
      modelValues.embossing = values[0]
    }
  }
}

/**
 * Выбор типа клише(Индивидуальное тиснение/Стандартное тиснение)
 */
function getClicheType(params) {
  const { oldModelValues, items, modelValues, originIdsAvailable } = params
  const values = []

  /** Индивидуальные тиснения */
  let individual = []
  if (modelValues.embossing.id === 'pancake') {
    individual = [14, 20, 187, 283, 293, 297]
  } else if (modelValues.embossing.id === 'foil') {
    individual = [21, 22, 287, 288, 292, 295]
  } else if (modelValues.embossing.id === 'coloredFoil') {
    individual = [160, 161, 296, 302]
  }

  if (lodash.intersection(originIdsAvailable, individual).length > 0) {
    values.push({
      id: 'individual',
      tName: 'personalizations.embossing.individual',
    })
  }

  /** Стандартное тиснения */
  let standart
  if (modelValues.embossing.id === 'pancake') {
    standart = 122
  } else if (modelValues.embossing.id === 'foil') {
    standart = 193
  } else if (modelValues.embossing.id === 'coloredFoil') {
    standart = 301
  }

  if (originIdsAvailable.includes(standart)) {
    values.push({
      id: 'standart',
      tName: 'personalizations.embossing.standart',
    })
  }

  if (values.length > 0) {
    items.push({
      id: 'clicheType',
      tName: 'personalizations.embossing.clicheType',
      template: 'selectRadio',
      sort: 30,
      values,
    })

    if (oldModelValues.clicheType) {
      modelValues.clicheType = values.find((i) => i.id === oldModelValues.clicheType.id) ?? values[0]
    } else {
      modelValues.clicheType = values[0]
    }
  }
}

/**
 * Выбор площади Индивидуального тиснения(до 50 см²/до 100 см²/от 100 см²)
 */
function getArea(params) {
  const { store, oldModelValues, items, modelValues, originPersonalizations } = params

  if (modelValues.clicheType.id !== 'individual') {
    return
  }
  let areas = []
  if (modelValues.embossing.id === 'pancake') {
    areas = [14, 20, 293]
    if (store?.selectedMaterials[0]?.typeUnited === 2) {
      // Тиснение по коже
      areas = [283, 297, 293]
    }
  } else if (modelValues.embossing.id === 'foil') {
    areas = [21, 22, 292]
    if (store?.selectedMaterials[0]?.typeUnited === 2) {
      // Тиснение по коже
      areas = [288, 287, 292]
    }
  } else if (modelValues.embossing.id === 'coloredFoil') {
    areas = [161, 160, 296]
  }

  const values = []
  for (const idIndividual of areas) {
    if (originPersonalizations[idIndividual]) {
      let id
      if ([14, 283, 21, 161, 288].includes(idIndividual)) {
        id = 'to50'
      }
      if ([20, 297, 22, 160, 287].includes(idIndividual)) {
        id = 'to100'
      }
      if ([293, 292, 296].includes(idIndividual)) {
        id = 'from100'
      }

      values.push({
        id,
        tName: `personalizations.embossing.${id}`,
        idBuhta: idIndividual,
        r: 0,
        w: sizes[id].w,
        h: sizes[id].h,
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'area',
      tName: 'personalizations.embossing.area',
      template: 'selectRadio',
      sort: 50,
      values,
    })

    if (oldModelValues.area) {
      modelValues.area = values.find((i) => i.id === oldModelValues.area.id) ?? values[0]
      const { r, x, y, w, h } = oldModelValues.area
      if (x === undefined || y === undefined) {
        const draggableArea = getDraggableAreaMm(store)
        const draggablePosition = getPosition({ w, h }, draggableArea)
        modelValues.area = { ...modelValues.area, ...draggablePosition }
      } else {
        modelValues.area = { ...modelValues.area, r, x, y, w, h }
      }
    } else {
      const draggableArea = getDraggableAreaMm(store)
      const draggablePosition = getPosition({ w: values[0].w, h: values[0].h }, draggableArea)
      modelValues.area = { ...values[0], ...draggablePosition }
    }
  }
}

/**
 * Выбор флага изготавления клише
 */
function getClicheMaking(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params
  if (modelValues.clicheType.id !== 'individual') {
    return
  }
  let values = []
  const param = originPersonalizations[modelValues.area.idBuhta].params.find(
    (i) => i.nameWork === 'клише' || i.nameWork === 'Клише',
  )

  const checked = Number(param.InOut) === 1
  values = [
    { id: 0, idWork: param.idWork, checked },
    { id: 1, idWork: param.idWork, checked: !checked },
  ]

  if (values.length > 0) {
    items.push({
      id: 'clicheMaking',
      tName: 'personalizations.embossing.clicheMaking',
      template: 'checkbox',
      sort: 40,
      values,
    })
    if (oldModelValues.clicheMaking) {
      modelValues.clicheMaking = values.find((i) => i.checked === oldModelValues.clicheMaking.checked) ?? values[0]
    } else {
      modelValues.clicheMaking = values[0]
    }
  }
}

/**
 * Выбор ширины тиснения от 100 см²
 */
function getWidth(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  if (modelValues.area?.id !== 'from100' || !modelValues.clicheMaking?.checked) {
    return
  }

  let idBuhta
  if (modelValues.embossing.id === 'pancake') {
    idBuhta = 293
  } else if (modelValues.embossing.id === 'foil') {
    idBuhta = 292
  } else if (modelValues.embossing.id === 'coloredFoil') {
    idBuhta = 296
  }

  const idParam = 107

  const values = []

  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)

    if (val) {
      let value = Number(val.def)
      if (oldModelValues.width?.value) {
        value = oldModelValues.width.value
      }
      values.push({
        value,
        min: Number(val.min),
        max: Number(val.max),
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'width',
      tName: 'personalizations.embossing.width',
      template: 'inputNumber',
      sort: 60,
      errorMsgMin: 'ui.inputErrorMinWidth',
      errorMsgMax: 'ui.inputErrorMaxWidth',
      hideHr: true,
      values,
    })

    modelValues.width = values[0]
  }
}

/**
 * Выбор высоты тиснения от 100 см²
 */
function getHeight(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  if (modelValues.area?.id !== 'from100' || !modelValues.clicheMaking?.checked) {
    return
  }

  let idBuhta
  if (modelValues.embossing.id === 'pancake') {
    idBuhta = 293
  } else if (modelValues.embossing.id === 'foil') {
    idBuhta = 292
  } else if (modelValues.embossing.id === 'coloredFoil') {
    idBuhta = 296
  }

  const idParam = 108

  const values = []

  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)

    if (val) {
      let value = Number(val.def)
      if (oldModelValues.height?.value) {
        value = oldModelValues.height.value
      }
      values.push({
        value,
        min: Number(val.min),
        max: Number(val.max),
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'height',
      tName: 'personalizations.embossing.height',
      template: 'inputNumber',
      sort: 70,
      errorMsgMin: 'ui.inputErrorMinHeight',
      errorMsgMax: 'ui.inputErrorMaxHeight',
      hideHr: true,
      values,
    })

    modelValues.height = values[0]
  }
}

/**
 * Временная площадь изделия
 */
function getTempArea(params) {
  const { items, modelValues } = params

  if (modelValues.area?.id !== 'from100' || !modelValues.clicheMaking?.checked) {
    return
  }

  let error = false
  const tempWidth = modelValues.width?.value
  const tempHeight = modelValues.height?.value
  if (
    tempWidth > modelValues.width?.max ||
    tempWidth < modelValues.width?.min ||
    tempHeight > modelValues.height?.max ||
    tempHeight < modelValues.height?.min
  ) {
    error = true
  }
  const values = []

  values.push({
    id: 'tempArea',
    value: (tempWidth * tempHeight) / 100,
    error,
  })
  items.push({
    id: 'tempArea',
    tName: 'personalizations.embossing.area',
    template: 'number',
    sort: 80,
    values,
  })

  // modelValues.tempArea = values[0]
}

/**
 * Выбор флага тиснения по корешку
 */
function getSpine(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params
  if (modelValues.clicheType.id !== 'individual') {
    return
  }

  let values = []

  let idSpine
  if (modelValues.embossing.id === 'pancake') {
    idSpine = 187
  } else if (modelValues.embossing.id === 'foil') {
    idSpine = 295
  } else if (modelValues.embossing.id === 'coloredFoil') {
    idSpine = 302
  }

  if ([14, 283, 21, 161, 288].includes(modelValues.area.idBuhta) && originPersonalizations[idSpine]) {
    const param = originPersonalizations[idSpine].params.find(
      (item) => item.nameWork === 'клише' || item.nameWork === 'Клише',
    )

    // const checked = Number(param.InOut) === 1
    const checked = false
    values = [
      { id: 0, idWork: param.idWork, checked },
      { id: 1, idWork: param.idWork, checked: !checked },
    ]

    if (values.length > 0) {
      items.push({
        id: 'spine',
        tName: 'personalizations.embossing.spine',
        template: 'checkbox',
        sort: 90,
        values,
      })
      if (oldModelValues.spine) {
        modelValues.spine = values.find((i) => i.checked === oldModelValues.spine.checked) ?? values[0]
      } else {
        modelValues.spine = values[0]
      }
    }
  }
}

/**
 * Выбор клише стандартного тиснения
 */
function getCliche(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originPersonalizations,
    store,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  if (modelValues.clicheType.id !== 'standart') {
    return
  }
  let idBuhta
  if (modelValues.embossing.id === 'pancake') {
    idBuhta = 122
  } else if (modelValues.embossing.id === 'foil') {
    idBuhta = 193
  } else if (modelValues.embossing.id === 'coloredFoil') {
    idBuhta = 301
  }

  const idParam = 22

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)

    if (val?.values?.length) {
      values = val.values.map((n) => {
        let w = 514
        let h = 430
        if (sizes[n.id]) {
          w = sizes[n.id].w
          h = sizes[n.id].h
        }
        return {
          ...n,
          idBuhta,
          img: `${cnstrAssetsUrl}/personalizations/embossing/small/${n.id}.webp`,
          r: 0,
          w,
          h,
          hint: n.name,
        }
      })
      values = lodash.sortBy(values, ['name'])

      // Для планингов должны отображаться не все клише
      if (store?.selectedProduct?.id === 3) {
        values = values.filter((n) => n.w !== 306 || n.id === 1083 || n.id === 1084)
      }
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'cliche',
      tName: 'personalizations.embossing.cliche',
      template: 'selectImage',
      sort: 100,
      values,
    })

    if (oldModelValues.cliche) {
      modelValues.cliche = values.find((i) => i.id === oldModelValues.cliche.id) ?? values[0]
      const { x, y, w, h } = oldModelValues.cliche
      let { r } = oldModelValues.cliche
      if (x === undefined || y === undefined) {
        const draggableArea = getDraggableAreaMm(store)
        let posX = 'center'
        let posY = 'middle'
        const obj = { w, h }
        switch (modelValues?.cliche?.id) {
          case 412:
            posX = 'right'
            posY = 'top'
            break
          case 413:
            posX = 'center'
            posY = 'bottom'
            break
          case 414:
            posX = 'right'
            posY = 'bottom'
            break
        }

        if (store?.selectedProduct?.id === 3) {
          if ([412, 413, 414, 5340, 5684].includes(modelValues?.cliche?.id)) {
            r = 90
            switch (modelValues?.cliche?.id) {
              case 412:
                posY = 'bottom'
                break
              case 413:
                posX = 'left'
                posY = 'middle'
                break
              case 414:
                posX = 'left'
                break
            }
          }
        }
        const draggablePosition = getPosition(obj, draggableArea, posX, posY)
        if (store?.selectedProduct?.id === 3) {
          if ([412, 413, 414, 5340, 5684].includes(modelValues?.cliche?.id)) {
            if (posX === 'left') {
              draggablePosition.x += (h - w) / 2
            } else if (posX === 'right') {
              draggablePosition.x += (w - h) / 2
            }
            if (posY === 'top') {
              draggablePosition.y += (h - w) / 2
            } else if (posY === 'bottom') {
              draggablePosition.y += (h - w) / 2
            }
          }
        }

        modelValues.cliche = {
          ...modelValues.cliche,
          r,
          ...draggablePosition,
        }
      } else {
        modelValues.cliche = { ...modelValues.cliche, r, x, y, w, h }
      }
    } else {
      const draggableArea = getDraggableAreaMm(store)
      const draggablePosition = getPosition({ w: values[0].w, h: values[0].h }, draggableArea)
      modelValues.cliche = { ...values[0], ...draggablePosition }
    }
  }
}

/**
 * Выбор цвета фольги
 */
function getFoilColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params
  if (modelValues.embossing.id === 'pancake') {
    return
  }

  let idBuhta
  let idParam
  if (modelValues.embossing.id === 'foil') {
    idParam = 25
    if (modelValues.clicheType.id === 'standart') {
      idBuhta = 193
    } else {
      idBuhta = modelValues.area.idBuhta
    }
  } else if (modelValues.embossing.id === 'coloredFoil') {
    idParam = 26
    if (modelValues.clicheType.id === 'standart') {
      idBuhta = 301
    } else {
      idBuhta = modelValues.area.idBuhta
    }
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: colors[n.id] ?? n.hex,
        hex: colorsHex[n.id] ?? n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'foilColor',
      tName: 'personalizations.embossing.foilColor',
      template: 'selectClassicColor',
      sort: 20,
      values,
    })

    if (oldModelValues.foilColor) {
      modelValues.foilColor = values.find((i) => i.id === oldModelValues.foilColor.id) ?? values[0]
    } else {
      modelValues.foilColor = values[0]
    }
  }
}
/**
 * Размеры тиснения в мм.
 * Вычисляется из расчета 500px = 306мм
 * Xcm = Ypx * 306 / 500 = Ypx * 0.612
 */
const sizes = {
  to50: { w: 70.7, h: 70.7 },
  to100: { w: 100, h: 100 },
  from100: { w: 306, h: 256 },

  5: { w: 33, h: 11 },
  6: { w: 21, h: 20 },
  7: { w: 38, h: 13.4 },
  8: { w: 32.4, h: 10 },
  9: { w: 26, h: 29 },
  10: { w: 19, h: 19 },
  11: { w: 35, h: 12 },
  12: { w: 16, h: 18 },
  15: { w: 30, h: 24 },
  16: { w: 15, h: 27 },
  19: { w: 86, h: 11 },
  20: { w: 86, h: 63 },
  21: { w: 65.4, h: 12.4 },
  22: { w: 32, h: 35 },
  107: { w: 50, h: 45 },
  108: { w: 40, h: 40 },
  109: { w: 67, h: 42 },
  110: { w: 56, h: 5.8 },
  111: { w: 97, h: 9 },
  113: { w: 78, h: 14 },
  114: { w: 74, h: 29 },
  115: { w: 65, h: 14 },
  116: { w: 88, h: 31 },
  117: { w: 21, h: 47 },
  411: { w: 68, h: 68 },
  412: { w: 100, h: 135 },
  413: { w: 21, h: 132 },
  414: { w: 82, h: 114 },
  1075: { w: 306, h: 256 },
  1076: { w: 306, h: 256 },
  1077: { w: 306, h: 256 },
  1078: { w: 306, h: 256 },
  1079: { w: 306, h: 256 },
  1080: { w: 306, h: 256 },
  1081: { w: 306, h: 256 },
  1082: { w: 306, h: 256 },
  1083: { w: 306, h: 256 },
  1084: { w: 306, h: 256 },
  4902: { w: 52, h: 6.5 },
  4903: { w: 306, h: 256 },
  4904: { w: 306, h: 256 },
  4905: { w: 306, h: 256 },
  4906: { w: 306, h: 256 },
  4907: { w: 306, h: 256 },
  4908: { w: 306, h: 256 },
  4909: { w: 123.2, h: 139 },
  4910: { w: 306, h: 256 },
  4911: { w: 306, h: 256 },
  4912: { w: 306, h: 256 },
  4913: { w: 306, h: 256 },
  4914: { w: 306, h: 256 },
  4915: { w: 306, h: 256 },
  4916: { w: 20, h: 20 },
  4918: { w: 306, h: 256 },
  5340: { w: 11, h: 133.7 },
  5684: { w: 8, h: 133 },
  5685: { w: 306, h: 256 },
  5686: { w: 306, h: 256 },
  5809: { w: 306, h: 256 },
  6006: { w: 63, h: 13 },
  6007: { w: 83.4, h: 13 },
  6117: { w: 65, h: 64.2 },
  6118: { w: 44.6, h: 39 },
  I: { w: 70.7, h: 70.7 },
  IF: { w: 306, h: 256 },
}

/**
 * Выбор загрузки изображения
 */
// function getUpload(params) {
//   const { oldModelValues, items, modelValues } = params
//   if (modelValues.clicheType.id !== 'individual') {
//     return
//   }

//   if (modelValues.spine && modelValues.spine.checked) {
//     return
//   }

//   if (oldModelValues.upload) {
//     modelValues.upload = oldModelValues.upload
//   } else {
//     modelValues.upload = {
//       url: '',
//     }
//   }
//   const values = [modelValues.upload]
//   items.push({
//     id: 'upload',
//     tName: 'personalizations.embossing.upload',
//     template: 'upload',
//     sort: 110,
//     values,
//   })
// }
