<template>
<svg data-svg-lvl-3="edge-block-edge" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs>
      <mask id="le-m-si" x="0" y="0" width="500" height="430" fill="#ffffff"><polygon  points="44.8 213.71 44.68 213.71 44.68 215.1 44.59 215.1 44.59 216.49 44.59 216.49 44.59 217.88 44.7 217.88 44.7 219.27 44.59 219.27 44.59 220.66 44.82 220.66 44.82 222.05 44.74 222.05 44.74 223.44 44.59 223.44 44.59 224.83 44.84 224.83 44.84 226.22 44.59 226.22 44.59 227.62 421.91 227.62 421.91 226.22 422.15 226.22 422.15 224.83 421.91 224.83 421.91 223.44 422.05 223.44 422.05 222.05 422.13 222.05 422.13 220.66 421.91 220.66 421.91 219.27 422.01 219.27 422.01 217.88 421.91 217.88 421.91 216.49 421.91 216.49 421.91 215.1 422 215.1 422 213.71 422.11 213.71 44.8 213.71"/></mask>
  <linearGradient id="lg-silver" x1="45.52" x2="459.83"  y1="197.49" y2="197.49" gradientUnits="userSpaceOnUse" >
    <stop offset="0" stop-color="#989ba0" />
    <stop offset=".3" stop-color="#fff" /><stop offset=".83" stop-color="#3c3e45" /><stop offset="1" stop-color="#979a9f" />
  </linearGradient>
  <linearGradient id="lg-gold" x1="45.52" x2="459.83"  y1="197.49" y2="197.49" gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#9c6b00" /><stop offset=".3" stop-color="#fbe6a6" />
    <stop offset=".83" stop-color="#6c4209" /><stop offset="1" stop-color="#efe9cd" />
  </linearGradient></defs><g id="Обрез"><polygon :style="{ fill: params.slice.color === '#9C6B00' ? 'url(#lg-gold)' : params.slice.color === '#989BA0' ? 'url(#lg-silver)' : params.slice.color }"  class="le-c-3" points="44.8 213.71 44.68 213.71 44.68 215.1 44.59 215.1 44.59 216.49 44.59 216.49 44.59 217.88 44.7 217.88 44.7 219.27 44.59 219.27 44.59 220.66 44.82 220.66 44.82 222.05 44.74 222.05 44.74 223.44 44.59 223.44 44.59 224.83 44.84 224.83 44.84 226.22 44.59 226.22 44.59 227.62 421.91 227.62 421.91 226.22 422.15 226.22 422.15 224.83 421.91 224.83 421.91 223.44 422.05 223.44 422.05 222.05 422.13 222.05 422.13 220.66 421.91 220.66 421.91 219.27 422.01 219.27 422.01 217.88 421.91 217.88 421.91 216.49 421.91 216.49 421.91 215.1 422 215.1 422 213.71 422.11 213.71 44.8 213.71"/> <image v-if="params.slice.image" preserveAspectRatio="xMidYMid slice" width="500" height="430" :href="params.slice.image" mask="url(#le-m-si)" /></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.le-c-1{opacity:0.3;}.le-c-2{fill:#64ff00;}.le-c-3{fill:#f4e8ce;}</style>

