import Vue from 'vue'

import VueRouter from 'vue-router'

Vue.use(VueRouter)

/** routes желательно сортировать по алфавиту(по полю name)*/
/** routes - маршруты и связанные с ними динамические асинхронные компоненты */
/* webpackChunkName: "home"  - эти комментарии нельзя удалять, в них задаются названия асинхронных компонентов */
const routes = [
  //   {
  //     name: 'test',
  //     path: '/test',
  //     component: () => import(/* webpackChunkName: "airports" */ '../pages/test'),
  //   },
]

function parseQuery(queryString) {
  const normalize = function (inputVal) {
    let input
    if (inputVal === undefined || inputVal === 'undefined' || inputVal === 'null') {
      input = ''
    } else {
      try {
        input = JSON.parse(inputVal)
      } catch (e) {
        input = inputVal
      }
    }

    let query
    if (Array.isArray(input)) {
      query = [...input]
      input.forEach((val, key) => {
        query[key] = normalize(val)
      })
    } else if (typeof input === 'object') {
      query = { ...input }

      for (const key in input) {
        query[key] = normalize(input[key])
      }
    } else if (typeof input === 'string' && input === String(Number(input))) {
      query = Number(input)
    } else {
      query = input
    }

    return query
  }
  const decodedQueryString = decodeURIComponent(queryString)

  const queryObject = {}
  const params = decodedQueryString.split('&')

  for (const item of params) {
    const keyVal = item.split('=')
    const key = keyVal[0]
    const val = keyVal[1]

    if (key) {
      queryObject[key] = normalize(val)
    }
  }

  return queryObject
}

function stringifyQuery(queryObject) {
  const normalize = function (inputVal) {
    let query
    if (Array.isArray(inputVal)) {
      const input = []
      for (const i of inputVal) {
        if (typeof i === 'string' && i === String(Number(i))) {
          input.push(Number(i))
        } else {
          input.push(i)
        }
      }
      query = JSON.stringify(input)
    } else if (typeof inputVal === 'object') {
      query = JSON.stringify(inputVal)
    } else {
      query = inputVal
    }

    return query
  }

  let queryStrings = []
  for (const item in queryObject) {
    queryStrings.push(`${item}=${normalize(queryObject[item])}`)
  }
  queryStrings = queryStrings.join('&')

  return queryStrings ? '?' + queryStrings : ''
}

export const router = new VueRouter({
  mode: 'history',
  parseQuery,
  stringifyQuery,
  routes,
})
