<template>
<svg data-svg-lvl-3="spread-back-loop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Петли_Контур"><path class="bl-c-3" d="M446.61,229.22h0a60.38,60.38,0,0,0,9.14.83c4.46-.09,7.93-1.3,7.86-2.21h0V214c-.08-.91-3.71-1.52-8.12-1.08-3.59.36-5.43,1.39-8.88,2.45Z"/><path class="bl-c-4" d="M446.61,229.22h0a60.38,60.38,0,0,0,9.14.83c4.46-.09,7.93-1.3,7.86-2.21h0V214c-.08-.91-3.71-1.52-8.12-1.08-3.59.36-5.43,1.39-8.88,2.45Z"/><path class="bl-c-5" d="M463.61,227.74c-.08-.91-3.71-1.52-8.12-1.08-3.59.37-5.43,1.39-8.88,2.45,3.84.32,5.27.91,9.14.83C460.21,229.85,463.68,228.64,463.61,227.74Z"/><path class="bl-c-5" d="M463.61,227.74c-.08-.91-3.71-1.52-8.12-1.08-3.59.37-5.43,1.39-8.88,2.45,3.84.32,5.27.91,9.14.83C460.21,229.85,463.68,228.64,463.61,227.74Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bl-c-1{opacity:0.3;}.bl-c-2{fill:#64ff00;}.bl-c-3{fill:gray;}.bl-c-4{fill:none;}.bl-c-4,.bl-c-5{stroke:#232728;stroke-miterlimit:2;stroke-width:0.46px;}.bl-c-5{fill:#232728;}</style>

