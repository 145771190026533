<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="tc-lg" x1="46.38" y1="206.99" x2="164.71" y2="206.99" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.3"/><stop offset="0.08" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="tc-lg-2" x1="256.75" y1="278.68" x2="256.75" y2="317.21" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.5"/><stop offset="0.39" stop-opacity="0"/></linearGradient></defs><g id="Крышки_Контур"><image width="422" height="156" transform="translate(46.3 128.77)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/top/contour/PH2V_1.webp`"/><path class="tc-c-3" d="M46.38,129.33H164.71a0,0,0,0,1,0,0V284.65a0,0,0,0,1,0,0H61.38a15,15,0,0,1-15-15V129.33A0,0,0,0,1,46.38,129.33Z"/><image width="422" height="156" transform="translate(46.3 128.77)" :href="`${$CONST.cnstrAssetsUrl}/preview-layers/cover/top/contour/PH2V_2.webp`"/><path class="tc-c-4" d="M452.62,284.65H61.38a14.93,14.93,0,0,1-12-6v31.41a7.6,7.6,0,0,0,7.6,7.6H456.48a7.6,7.6,0,0,0,7.6-7.6V279.32A15,15,0,0,1,452.62,284.65Z"/><path class="tc-c-5" d="M467.62,269.65V129.33H46.38V269.65a15,15,0,0,0,15,15H452.62A15,15,0,0,0,467.62,269.65Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1{opacity:0.3;}.tc-c-2{fill:#64ff00;}.tc-c-3{fill:url(#tc-lg);}.tc-c-4{fill:url(#tc-lg-2);}.tc-c-5{fill:none;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

