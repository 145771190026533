<template>
<svg data-svg-lvl-3="spread-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bc-lg" x1="61.46" y1="225.16" x2="452.54" y2="225.16" gradientUnits="userSpaceOnUse"><stop offset="0.47" stop-color="#727272"/><stop offset="0.48" stop-color="#545454"/><stop offset="0.48" stop-color="#525252"/><stop offset="0.49" stop-color="#484848"/><stop offset="0.5" stop-color="#454545"/><stop offset="0.51" stop-color="#494949"/><stop offset="0.52" stop-color="#545454"/><stop offset="0.53" stop-color="#727272"/></linearGradient></defs><g id="Задник_Контур"><polygon class="bc-c-3" points="457.73 85.24 457.73 351.35 452.54 356.24 452.54 90.13 457.73 85.24"/><path class="bc-c-4" d="M466.4,63.91V324.7a14.65,14.65,0,0,1-.57,3.73c-.31,1.12-8.1,23.53-8.1,22.92V85.24a.77.77,0,0,1,0-.15l7.62-21.33A.53.53,0,0,1,466.4,63.91Z"/><path class="bc-c-5" d="M452.54,90.13h-186A3.16,3.16,0,0,0,264,91.42a6,6,0,0,1-4.82,2.44h-4.94a6,6,0,0,1-4.82-2.44,3.16,3.16,0,0,0-2.54-1.29H73.46a12,12,0,0,0-12,12V344.24a12,12,0,0,0,12,12H247.34a2.17,2.17,0,0,1,1.82,1.07,6,6,0,0,0,5.11,2.88h4.94a6,6,0,0,0,5.13-2.9,2.1,2.1,0,0,1,1.78-1.05H452.54Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{opacity:0.3;}.bc-c-2{fill:#64ff00;}.bc-c-3{fill:#666;}.bc-c-3,.bc-c-4,.bc-c-5{stroke:#232728;stroke-width:0.75px;}.bc-c-3,.bc-c-4{stroke-miterlimit:2;}.bc-c-4{fill:#4d4d4d;}.bc-c-5{stroke-linejoin:round;fill:url(#bc-lg);}</style>

