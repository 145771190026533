import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'
import { getDraggableAreaMm, getPosition } from '/src/utils/draggable'

export const nfc = {
  id: 'nfc',
  // tName:  'personalizations.nfc.nfc',
  template: 'modal',
  preview: 'cover',

  originPersonalizationsIds: [
    685, // Установка NFC метки
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getCliche(params)
  getRecording(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  const idBuhta = 685
  const idParam = 128
  const idValue = modelValues?.cliche?.id
  const idWork = 1694
  const value = modelValues?.recording?.checked

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
    {
      idWork,
      value,
    },
  ])
}

/**
 * Выбор тиснения
 */
function getCliche(params) {
  const { store, oldModelValues, items, modelValues, originIdsAvailable, originPersonalizations } = params

  if (originIdsAvailable.length === 0) {
    return
  }

  const idBuhta = 685
  const idParam = 128
  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values
    }
  }
  if (values.length > 0) {
    items.push({
      id: 'cliche',
      tName: 'personalizations.nfc.cliche',
      template: 'selectRadio',
      sort: 10,
      values,
    })

    // Дефолтноое клише 45
    let defaultIndex = values.findIndex((n) => n.id === 3400)

    if (defaultIndex < 0) {
      defaultIndex = 0
    }
    values[defaultIndex] = { ...values[defaultIndex], r: 0, w: 24, h: 24 }
    if (oldModelValues.cliche) {
      modelValues.cliche = values.find((i) => i.id === oldModelValues.cliche.id) ?? values[defaultIndex]
      if (oldModelValues.cliche.id === 3400) {
        const { r, x, y, w, h } = oldModelValues.cliche
        if (x === undefined || y === undefined) {
          const draggableArea = getDraggableAreaMm(store)
          const draggablePosition = getPosition({ w, h }, draggableArea)
          modelValues.cliche = { ...modelValues.cliche, ...draggablePosition }
        } else {
          modelValues.cliche = { ...modelValues.cliche, r, x, y, w, h }
        }
      }
    } else {
      const draggableArea = getDraggableAreaMm(store)
      const draggablePosition = getPosition({ w: values[defaultIndex].w, h: values[defaultIndex].h }, draggableArea)
      modelValues.cliche = { ...values[defaultIndex], ...draggablePosition }
    }
  }
}

/**
 * Выбор флага Запись NFC метки
 */
function getRecording(params) {
  const { oldModelValues, items, modelValues, originPersonalizations, originIdsAvailable } = params

  if (originIdsAvailable.length === 0) {
    return
  }

  const idBuhta = 685
  const idWork = 1694
  let values = []
  const param = originPersonalizations[idBuhta]?.params?.find((item) => Number(item.idWork) === idWork)
  if (!param) {
    return
  }

  const checked = Number(param?.InOut) === 1
  values = [
    { id: 0, idWork: param.idWork, checked },
    { id: 1, idWork: param.idWork, checked: !checked },
  ]
  if (values.length > 0) {
    items.push({
      id: 'recording',
      tName: 'personalizations.nfc.recording',
      template: 'checkbox',
      sort: 20,
      values,
    })
    if (oldModelValues.recording) {
      modelValues.recording = values.find((i) => i.checked === oldModelValues.recording.checked) ?? values[0]
    } else {
      modelValues.recording = values[0]
    }
  }
}
