<template>
<svg data-svg-lvl-3="flyleaffront-block-pocket" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="lp-lg" x1="45.47" y1="302.31" x2="125" y2="302.31" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#333"/><stop offset="1" stop-color="#b2b2b2" stop-opacity="0.5"/></linearGradient><linearGradient id="lp-lg-2" x1="85.22" y1="286.66" x2="85.22" y2="328.16" gradientUnits="userSpaceOnUse"><stop offset="0" stop-opacity="0.05"/><stop offset="1" stop-opacity="0.3"/></linearGradient><clipPath id="lp-cp"><path class="lp-c-1" d="M45.47,275.8H125a0,0,0,0,1,0,0v49.73a3.29,3.29,0,0,1-3.29,3.29h-73a3.29,3.29,0,0,1-3.29-3.29V275.8A0,0,0,0,1,45.47,275.8Z"/></clipPath></defs><g id="КонвертКонтур_Пластиковый_Карман" ><g class="lp-c-4"><path class="lp-c-5" d="M45.47,275.8H125a0,0,0,0,1,0,0v49.73a3.29,3.29,0,0,1-3.29,3.29h-73a3.29,3.29,0,0,1-3.29-3.29V275.8A0,0,0,0,1,45.47,275.8Z"/><path class="lp-c-6" d="M45.47,286.15H125a0,0,0,0,1,0,0v39.38a3.29,3.29,0,0,1-3.29,3.29h-73a3.29,3.29,0,0,1-3.29-3.29V286.15A0,0,0,0,1,45.47,286.15Z"/><path class="lp-c-7" d="M121.67,328.43H48.78a2.89,2.89,0,0,1-2.89-2.89V286.15h.52v39.39a2.38,2.38,0,0,0,2.37,2.37h72.89a2.37,2.37,0,0,0,2.36-2.37V286.15h.53v39.39A2.89,2.89,0,0,1,121.67,328.43Z"/></g><g class="lp-c-4"><g class="lp-c-8"><g class="lp-c-9"><image width="276" height="396" transform="translate(31.07 208.18) scale(0.39 0.39)" :href="`${$CONST.cnstrAssetsUrl}/others/pocket.webp`"/></g></g></g></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.lp-c-1{fill:none;}.lp-c-2{opacity:0.3;}.lp-c-3{fill:#64ff00;}.lp-c-4{opacity:0.8;}.lp-c-5,.lp-c-9{opacity:0.5;}.lp-c-5{fill:url(#lp-lg);}.lp-c-6{fill:#828282;opacity:0.4;}.lp-c-7{fill:url(#lp-lg-2);}.lp-c-8{clip-path:url(#lp-cp);}</style>

