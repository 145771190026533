<template>
<svg data-svg-lvl-3="edge-top-flap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Хлястик_Контур"><path :style="{ fill: params.flap.sliceColor }"  class="tf-c-3" d="M468.42,250.79H383.84a1.85,1.85,0,1,1,0-3.7h84.83c1.87,0,11.83-4.72,11.83-26.59,0-16.12-5.41-24.47-8.94-26.64-1.26-.78-2.71,1-5.53,1H289.58a1.86,1.86,0,0,1-1.85-1.72s0-2.89,0-2.93a1.85,1.85,0,0,1,1.86-1.85H466.82a15.28,15.28,0,0,1,6.49,2c5.15,2.66,11.09,12.1,11.09,29.93C484.4,244.75,473.33,250.79,468.42,250.79Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tf-c-1{opacity:0.3;}.tf-c-2{fill:#64ff00;}.tf-c-3{fill:#fff;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

