let gold = 'linear-gradient(141.72deg, #9C6B00 -15.04%, #FBE6A6 24.84%, #6C4209 95.31%, #EFE9CD 117.91%)'
let silver = 'linear-gradient(141.72deg, #989BA0 -15.04%, #FFFFFF 24.84%, #3C3E45 95.31%, #979A9F 117.91%)'

let goldHex = '#9C6B00'
let silverHex = '#989BA0'

export const colors = {
  // Тиснение фольгой + окрас среза блока
  32: gold, // Золото
  33: silver, // Серебро

  // Тиснение цветной фольгой
  36: 'linear-gradient(141.72deg, #048B1B -15.04%, #4CF664 24.84%, #113A17 69.95%, #4CF664 117.91%)', // Зелёный глянцевый
  37: 'linear-gradient(141.72deg, #840808 -15.04%, #FF1711 24.84%, #6A0404 95.31%, #FF6D66 117.91%)', // Красный глянцевый
  38: 'linear-gradient(141.72deg, #1A1D97 -11.62%, #9BC7FF 24.84%, #1F2986 69.95%, #818FEB 117.91%)', // глянцевый синий
  1943: 'rgba(0, 0, 0, 0.1)', // Прозрачный глянцевый

  // Нитка
  1292: 'conic-gradient(from 180deg at 50% 50%, #DE2000 -29.8deg, #DE2000 28.95deg, #750AE0 73.87deg, #750AE0 107.04deg, #00C7FF 137.27deg, #00C7FF 174.6deg, #24CC03 192.14deg, #25D004 227deg, #F1960C 259.35deg, #F3970D 297.09deg, #DE2000 330.2deg, #DE2000 388.95deg)', // в соответствии с материалом

  // Металлические уголки
  329: gold, // Золото
  330: silver, // Серебро

  // Обрез
  3306: gold, // Золото
  3305: silver, // Серебро

  // конверт
  white: '#FFFFFF', // белый
  toned: '#F4E8CE', // тонированный

  // Молния Арт. 993 // Металлостикер
  3340: gold, // Золото
  3339: silver, // Серебро

  // Кнопка Арт. 993
  4919: gold, // Золото
  4920: silver, // Серебро

  // Кнопка
  1336: gold, // Золото
  1337: silver, // Серебро

  // Пружина
  2098: gold, // Золото
  2101: silver, // Серебро
  1132: gold, // Золото
  1134: silver, // Серебро

  // Печать фольгой
  5793: 'linear-gradient(141.72deg, #048B1B -15.04%, #4CF664 24.84%, #113A17 69.95%, #4CF664 117.91%)', // Зелёный глянцевый
  5792: 'linear-gradient(141.72deg, #840808 -15.04%, #FF1711 24.84%, #6A0404 95.31%, #FF6D66 117.91%)', // Красный глянцевый
  5794: 'linear-gradient(141.72deg, #1A1D97 -11.62%, #9BC7FF 24.84%, #1F2986 69.95%, #818FEB 117.91%)', // глянцевый синий
  5790: gold, // Золото
  5791: silver, // Серебро
}

export const colorsHex = {
  // Тиснение фольгой + окрас среза блока
  32: goldHex, // Золото
  33: silverHex, // Серебро

  // Тиснение цветной фольгой
  36: '#048B1B', // Зелёный глянцевый
  37: '#840808', // Красный глянцевый
  38: '#1A1D97', // глянцевый синий

  // Металлические уголки
  329: goldHex, // Золото
  330: silverHex, // Серебро

  // Обрез
  3306: goldHex, // Золото
  3305: silverHex, // Серебро

  // конверт
  white: '#FFFFFF', // белый
  toned: '#F4E8CE', // тонированный

  //     // Молния Арт. 993 // Металлостикер
  3340: goldHex, // Золото
  3339: silverHex, // Серебро

  // Кнопка Арт. 993
  4919: goldHex, // Золото
  4920: silverHex, // Серебро

  // Кнопка
  1336: goldHex, // Золото
  1337: silverHex, // Серебро

  // Пружина
  2098: goldHex, // Золото
  2101: silverHex, // Серебро
  1132: goldHex, // Золото
  1134: silverHex, // Серебро

  // Печать фольгой
  5793: '#048B1B', // Зелёный глянцевый
  5792: '#840808', // Красный глянцевый
  5794: '#1A1D97', // глянцевый синий
  5790: goldHex, // Золото
  5791: silverHex, // Серебро
}
