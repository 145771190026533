<template>
<svg data-svg-lvl-3="edge-top-slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышка_Срез"><path :style="{ fill: params.sliceColor }"  class="tsl-c-3" d="M476.24,234.91H59.81a8,8,0,0,1-8-8V215.09a8,8,0,0,1,8-8H476.24a1.24,1.24,0,0,0,1.24-1.23v-3.4a1.24,1.24,0,0,0-1.24-1.23H60.86a15,15,0,0,0-15,15v9.54a15,15,0,0,0,15,15H476.24a1.24,1.24,0,0,0,1.24-1.23v-3.4A1.24,1.24,0,0,0,476.24,234.91Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsl-c-1{opacity:0.3;}.tsl-c-2{fill:#64ff00;}.tsl-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

