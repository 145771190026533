import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const plastic = {
  id: 'plastic',
  // tName: 'personalizations.plastic.plastic',
  template: 'modal',
  preview: 'cover',

  originPersonalizationsIds: [
    428, // Пластиковая сторонка ЛИЦО "Вертикальная" 15х21, 15.5х21
    608, // Пластиковая сторонка ЛИЦО "Вертикальная" 10х16, 10.5х16

    611, // Пластиковая сторонка ЛИЦО "Сфера" 10х16, 10.5х16
    612, // Пластиковая сторонка ЛИЦО "Сфера" 15х21, 15.5х21

    553, // Пластиковая сторонка ЛИЦО и ОБОРОТ "Вертикальная" 15х21, 15.5х21
    609, // Пластиковая сторонка ЛИЦО и ОБОРОТ "Вертикальная" 10х16, 10.5х16
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */

function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getPlastic(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta

  const keyPlastic = modelValues?.plastic?.id
  if (keyPlastic) {
    let selectedPers = []
    if (keyPlastic === 'vertical') {
      selectedPers = [428, 608]
    } else if (keyPlastic === 'sphere') {
      selectedPers = [611, 612]
    } else if (keyPlastic === 'verticalBoth') {
      selectedPers = [553, 609]
    }
    const idsBuhta = lodash.intersection(this.originIdsAvailable, selectedPers)
    idBuhta = idsBuhta[0]
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [])
}

/**
 * Выбор Пластиковой сторонки
 */
function getPlastic(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  const values = []
  const personals = {
    vertical: [428, 608],
    sphere: [611, 612],
    verticalBoth: [553, 609],
  }

  for (const key in personals) {
    if (lodash.intersection(originIdsAvailable, personals[key]).length > 0) {
      values.push({
        id: key,
        tName: `personalizations.plastic.${key}`,
        img: `${cnstrAssetsUrl}/personalizations/plastic/${key}.webp`,
      })
    }
  }
  if (values.length > 0) {
    items.push({
      id: 'plastic',
      tName: 'personalizations.plastic.plastic',
      template: 'selectImageText',
      sort: 10,
      values,
    })

    if (oldModelValues.plastic) {
      modelValues.plastic = values.find((i) => i.id === oldModelValues.plastic.id) ?? values[0]
    } else {
      modelValues.plastic = values[0]
    }
  }
}
