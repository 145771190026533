<template>
<svg data-svg-lvl-3="edge-top-slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Крышка_Срез"><path :style="{ fill: params.sliceColor }"  class="tsl-c-3" d="M468.81,188.47H353a.9.9,0,0,0-.91.91v4a.91.91,0,0,0,.91.91H467.14v46.88H55.88s-8.33-1-8.33-8.39V210.91c0-7.87,8.33-10.12,8.33-10.12H465.24a1.24,1.24,0,0,0,1.23-1.23v-3.4a1.23,1.23,0,0,0-1.23-1.23H55.88s-13.73,1.79-13.73,16.44v19.44c0,15.43,13.73,16.26,13.73,16.26H468.81c3.09,0,4.19-2.11,4.19-4.63V192.27C473,190.42,471.79,188.47,468.81,188.47Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsl-c-1{opacity:0.3;}.tsl-c-2{fill:#64ff00;}.tsl-c-3{fill:gray;stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

