<template>
<svg data-svg-lvl-3="cover-top-seamPerimeter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs></defs><g id="Прошивка_по_периметру_Контур" ><g class="tsp-c-3"><path class="tsp-c-4" d="M356.35,37.42H116.69a3.56,3.56,0,0,1-3.56-3.56"/><path class="tsp-c-4" d="M113.13,412.24a3.56,3.56,0,0,0,3.56,3.56H374.1a6.08,6.08,0,0,0,6.07-6.06V77.53"/></g><g class="tsp-c-5"><line class="tsp-c-6" x1="356.55" y1="37.62" x2="352.55" y2="37.62"/><line class="tsp-c-7" x1="348.46" y1="37.62" x2="117.35" y2="37.62"/><path class="tsp-c-6" d="M115.36,37.27a3.55,3.55,0,0,1-2-3.21"/><path class="tsp-c-6" d="M113.33,412.44a3.54,3.54,0,0,0,2,3.21"/><path class="tsp-c-8" d="M119.33,416h255a6.08,6.08,0,0,0,6.07-6.06V83.74"/><line class="tsp-c-6" x1="380.37" y1="81.73" x2="380.37" y2="77.73"/></g><g class="tsp-c-9"><line class="tsp-c-10" x1="356.15" y1="37.22" x2="352.15" y2="37.22"/><line class="tsp-c-11" x1="348.06" y1="37.22" x2="116.95" y2="37.22"/><path class="tsp-c-10" d="M115,36.87a3.55,3.55,0,0,1-2-3.21"/><path class="tsp-c-10" d="M112.93,412a3.54,3.54,0,0,0,2,3.21"/><path class="tsp-c-12" d="M118.93,415.6h255a6.08,6.08,0,0,0,6.07-6.06V83.34"/><line class="tsp-c-10" x1="379.97" y1="81.33" x2="379.97" y2="77.33"/></g></g><g id="Прошивка_по_периметру_Цвет" ><line :style="{ stroke: params.seamColor }"  class="tsp-c-13" x1="356.35" y1="37.42" x2="352.35" y2="37.42"/><line :style="{ stroke: params.seamColor }"  class="tsp-c-14" x1="348.26" y1="37.42" x2="117.15" y2="37.42"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-13" d="M115.16,37.07a3.55,3.55,0,0,1-2-3.21"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-13" d="M113.13,412.24a3.54,3.54,0,0,0,2,3.21"/><path :style="{ stroke: params.seamColor }"  class="tsp-c-15" d="M119.13,415.8h255a6.08,6.08,0,0,0,6.07-6.06V83.54"/><line :style="{ stroke: params.seamColor }"  class="tsp-c-13" x1="380.17" y1="81.53" x2="380.17" y2="77.53"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tsp-c-1{opacity:0.3;}.tsp-c-2{fill:#64ff00;}.tsp-c-3{opacity:0.5;}.tsp-c-10,.tsp-c-11,.tsp-c-12,.tsp-c-13,.tsp-c-14,.tsp-c-15,.tsp-c-4,.tsp-c-6,.tsp-c-7,.tsp-c-8{fill:none;stroke-linecap:round;stroke-miterlimit:2;}.tsp-c-4,.tsp-c-6,.tsp-c-7,.tsp-c-8{stroke:#000;}.tsp-c-4{stroke-width:0.5px;}.tsp-c-5{opacity:0.6;}.tsp-c-10,.tsp-c-11,.tsp-c-12,.tsp-c-13,.tsp-c-14,.tsp-c-15,.tsp-c-6,.tsp-c-7,.tsp-c-8{stroke-width:1.14px;}.tsp-c-11,.tsp-c-14,.tsp-c-7{stroke-dasharray:8.18 4.09;}.tsp-c-12,.tsp-c-15,.tsp-c-8{stroke-dasharray:8.06 4.03;}.tsp-c-9{opacity:0.7;}.tsp-c-10,.tsp-c-11,.tsp-c-12{stroke:#fff;}.tsp-c-13,.tsp-c-14,.tsp-c-15{stroke:#021823;}</style>

