<template>
  <button
    :class="['btn', `col-${btnColor}`, `fcol-${btnFontColor}`, `sz-${btnSize}`]"
    :disabled="disabled"
    :type="type"
    v-on="$listeners"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    btnColor: {
      type: String,
      default: 'contrast-regular',
    },
    btnFontColor: {
      type: String,
      default: 'white',
    },
    btnSize: {
      type: String,
      default: 'md',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
.btn
  padding: 8px 10px
  border-radius: 5px
  border: none
  transition: ease .3s
  white-space: nowrap
  cursor: pointer
  &:hover, &:focus
    border-color: transparent
    box-shadow: none
  &:disabled, &.disabled
    opacity: .5 !important
    cursor: default
  &.col
    &-contrast-regular
      background-color: $contrast-regular
      &:hover
        background-color: $contrast-light
    &-hover-color
      background-color: $hover-color
      &:hover
        background-color: $icon-color

  &.fcol
    &-white
      color: $white
  &.fcol
    &-black
      color: $black

  &.sz
    &-sm
      padding: 5.5px 15px
      font-size: 13px
      border-radius: 30px
      color: white
    &-lg
      // width: 100%
      padding: 8px 20px
      font-size: 16px
      line-height: 24px
</style>
